/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { ApiCredentialsService } from './services/ApiCredentialsService';
import { AuthService } from './services/AuthService';
import { AuthClientService } from './services/AuthClientService';
import { BryntumGridSettingsService } from './services/BryntumGridSettingsService';
import { CodexConfigurationService } from './services/CodexConfigurationService';
import { CustomerService } from './services/CustomerService';
import { FilesService } from './services/FilesService';
import { GatewayService } from './services/GatewayService';
import { HealthService } from './services/HealthService';
import { MachineService } from './services/MachineService';
import { MachineAlarmStateService } from './services/MachineAlarmStateService';
import { MqttBrokerService } from './services/MqttBrokerService';
import { NotificationService } from './services/NotificationService';
import { PrivilegeService } from './services/PrivilegeService';
import { SensorService } from './services/SensorService';
import { SteamTrapService } from './services/SteamTrapService';
import { SteamTrapCondensateThresholdCrossedService } from './services/SteamTrapCondensateThresholdCrossedService';
import { SteamTrapEquipmentOnService } from './services/SteamTrapEquipmentOnService';
import { SteamTrapFailureService } from './services/SteamTrapFailureService';
import { SteamTrapGroundTruthService } from './services/SteamTrapGroundTruthService';
import { SteamTrapUattrService } from './services/SteamTrapUattrService';
import { SteamTrapUnexpectedColdService } from './services/SteamTrapUnexpectedColdService';
import { UserService } from './services/UserService';
import { WebhookSubscriptionsService } from './services/WebhookSubscriptionsService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class Api {

	public readonly apiCredentials: ApiCredentialsService;
	public readonly auth: AuthService;
	public readonly authClient: AuthClientService;
	public readonly bryntumGridSettings: BryntumGridSettingsService;
	public readonly codexConfiguration: CodexConfigurationService;
	public readonly customer: CustomerService;
	public readonly files: FilesService;
	public readonly gateway: GatewayService;
	public readonly health: HealthService;
	public readonly machine: MachineService;
	public readonly machineAlarmState: MachineAlarmStateService;
	public readonly mqttBroker: MqttBrokerService;
	public readonly notification: NotificationService;
	public readonly privilege: PrivilegeService;
	public readonly sensor: SensorService;
	public readonly steamTrap: SteamTrapService;
	public readonly steamTrapCondensateThresholdCrossed: SteamTrapCondensateThresholdCrossedService;
	public readonly steamTrapEquipmentOn: SteamTrapEquipmentOnService;
	public readonly steamTrapFailure: SteamTrapFailureService;
	public readonly steamTrapGroundTruth: SteamTrapGroundTruthService;
	public readonly steamTrapUattr: SteamTrapUattrService;
	public readonly steamTrapUnexpectedCold: SteamTrapUnexpectedColdService;
	public readonly user: UserService;
	public readonly webhookSubscriptions: WebhookSubscriptionsService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? 'https://api.data.everactive.com/v2020-07',
			VERSION: config?.VERSION ?? '2020-07',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
		});

		this.apiCredentials = new ApiCredentialsService(this.request);
		this.auth = new AuthService(this.request);
		this.authClient = new AuthClientService(this.request);
		this.bryntumGridSettings = new BryntumGridSettingsService(this.request);
		this.codexConfiguration = new CodexConfigurationService(this.request);
		this.customer = new CustomerService(this.request);
		this.files = new FilesService(this.request);
		this.gateway = new GatewayService(this.request);
		this.health = new HealthService(this.request);
		this.machine = new MachineService(this.request);
		this.machineAlarmState = new MachineAlarmStateService(this.request);
		this.mqttBroker = new MqttBrokerService(this.request);
		this.notification = new NotificationService(this.request);
		this.privilege = new PrivilegeService(this.request);
		this.sensor = new SensorService(this.request);
		this.steamTrap = new SteamTrapService(this.request);
		this.steamTrapCondensateThresholdCrossed = new SteamTrapCondensateThresholdCrossedService(this.request);
		this.steamTrapEquipmentOn = new SteamTrapEquipmentOnService(this.request);
		this.steamTrapFailure = new SteamTrapFailureService(this.request);
		this.steamTrapGroundTruth = new SteamTrapGroundTruthService(this.request);
		this.steamTrapUattr = new SteamTrapUattrService(this.request);
		this.steamTrapUnexpectedCold = new SteamTrapUnexpectedColdService(this.request);
		this.user = new UserService(this.request);
		this.webhookSubscriptions = new WebhookSubscriptionsService(this.request);
	}
}

