/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MachineAlarmState } from './MachineAlarmState';

/**
 * An event describing a machine state change.
 */
export type MachineAlarmStateChangeEvent = {
	/**
	 * The alarm state change event id.
	 */
	readonly id: string;
	/**
	 * The unix timestamp when the machine alarm state event was created.
	 */
	readonly createdAtTimestamp?: number;
	/**
	 * The unix timestamp when the event occurred.
	 */
	effectiveTimestamp: number;
	endState: MachineAlarmState;
	/**
	 * the Everactive ID for this machine
	 */
	machineId: string;
	startState: MachineAlarmState;
	stateChangeSource: MachineAlarmStateChangeEvent.stateChangeSource;
	/**
	 * The id of the user that set the state of the machine.
	 */
	userId?: string;
	/**
	 * The user name that set the state of the machine.
	 */
	userName?: string;
};

export namespace MachineAlarmStateChangeEvent {

	export enum stateChangeSource {
		THRESHOLD_CROSSED = 'threshold crossed',
		USER = 'user',
	}


}

