/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Message received from gateway when requesting it's configuration from the cloud.
 */
export type GatewayConfigRequestV3 = {
	/**
	 * The schema version of this object.
	 */
	schema: GatewayConfigRequestV3.schema;
	/**
	 * The time the packet was sent by the gateway.
	 */
	timestamp: string;
	/**
	 * Details about the gateway sending the configuration request.
	 */
	gateway: {
		/**
		 * The Gateway serial number.
		 */
		serial: string;
		/**
		 * The software version of the configuration application currently running on the Gateway.
		 */
		software_version: string;
		/**
		 * A flag indicating if evernet 2 is supported. Boomerang hardware must be present to be `true`.
		 */
		can_support_evernet_2: boolean;
		/**
		 * The method the gateway uses to authenticate / authorize when connecting to an MQTT broker.
		 */
		mqtt_auth_type: GatewayConfigRequestV3.mqtt_auth_type;
	};
};

export namespace GatewayConfigRequestV3 {

	/**
	 * The schema version of this object.
	 */
	export enum schema {
		GATEWAY_CONFIG_REQUEST_V3_SCHEMA_JSON = 'gateway_config_request_v3.schema.json',
	}

	/**
	 * The method the gateway uses to authenticate / authorize when connecting to an MQTT broker.
	 */
	export enum mqtt_auth_type {
		USERPASS = 'userpass',
		CERTIFICATE = 'certificate',
	}


}

