/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCredentials } from '../models/ApiCredentials';
import type { PaginationInfo } from '../models/PaginationInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ApiCredentialsService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get api credentials list
	 * Return a paginated list of api credentials matching passed filter query parameters.
	 * @returns any A paginated list of api credentials.
	 * @throws ApiError
	 */
	public getApiCredentials({
		createdByUserId,
		customerId,
		name,
		page = 1,
		pageSize = 50,
		privilegeKey,
		sortBy = 'last-login-timestamp',
		sortDir,
	}: {
		/**
		 * Filter the list of api credentials returned by the user id who created the credentials. If the client
		 * is not authorized for the requested user an error will be returned.
		 */
		createdByUserId?: string,
		/**
		 * Filter the list of api credentials returned by the customer id. Multiple values can be passed
		 * as a comma separated value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for this filter. If the client requesting api credentials does not have
		 * access to the customer.id passed an error will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the list of api credentials using a `LIKE` statement with the name of the api credentials.
		 */
		name?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the api credentials returned by the privilege key. An exact match is used. If any of the privileges
		 * in the array of privileges the api credential has match it will be included in the list.
		 */
		privilegeKey?: 'customer-admin' | 'developer' | 'integration-partner' | 'super-admin' | 'user',
		/**
		 * The field used to sort the api credentials. If the field is omitted the results are returned sorted by the `lastLoginTimestamp`.
		 */
		sortBy?: 'customer-name' | 'last-login-timestamp',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<ApiCredentials>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/api-credentials',
			query: {
				'createdByUserId': createdByUserId,
				'customerId': customerId,
				'name': name,
				'page': page,
				'pageSize': pageSize,
				'privilegeKey': privilegeKey,
				'sortBy': sortBy,
				'sortDir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create api credentials
	 * Create a new set of api credentials with the designated privileges.
	 * @returns any A single set of API credentials.
	 * @throws ApiError
	 */
	public postApiCredentials({
		requestBody,
	}: {
		/**
		 * A new api credential to create.
		 */
		requestBody?: ApiCredentials,
	}): CancelablePromise<{
		data?: ApiCredentials;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api-credentials',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get api credentials
	 * Retrieve a single api credentials object.
	 * @returns any A single set of API credentials.
	 * @throws ApiError
	 */
	public getApiCredentialsClientId({
		clientId,
		includeSecret = false,
	}: {
		/**
		 * The clientId of the api credentials.
		 */
		clientId: string,
		/**
		 * A flag indicating the clientSecret should be included in the returned JSON body.
		 * If omitted the clientSecret is not returned.
		 */
		includeSecret?: boolean,
	}): CancelablePromise<{
		data?: ApiCredentials;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/api-credentials/{clientId}',
			path: {
				'clientId': clientId,
			},
			query: {
				'includeSecret': includeSecret,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * delete api credentials
	 * Delete api credentials.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteApiCredentialsClientId({
		clientId,
	}: {
		/**
		 * The clientId of the api credentials.
		 */
		clientId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/api-credentials/{clientId}',
			path: {
				'clientId': clientId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * rotate api credentials secret
	 * Rotate the secret associated with the api credentials. This action will
	 * require all clients that authenticate with the api credentials update to use
	 * the new secret.
	 * @returns any A single set of API credentials.
	 * @throws ApiError
	 */
	public postApiCredentialsClientIdRotateSecret({
		clientId,
	}: {
		/**
		 * The clientId of the api credentials.
		 */
		clientId: string,
	}): CancelablePromise<{
		data?: ApiCredentials;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/api-credentials/{clientId}/rotate-secret',
			path: {
				'clientId': clientId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get api credentials for auth0 action
	 * Retrieve a single api credentials object. This endpoint is specifically
	 * to be used by Auth0 to retrieve the privileges associated with a set of credentials.
	 * The privileges are then included in the access_token when it is returned to the client.
	 *
	 * The basic auth header is only valid for the Auth0 action application. Other API credentials will fail
	 * when attempting to authenticate this endpoint using basic auth.
	 * @returns any A single set of API credentials.
	 * @throws ApiError
	 */
	public getApiCredentialsIdAuth0({
		clientId,
	}: {
		/**
		 * The clientId of the api credentials.
		 */
		clientId: string,
	}): CancelablePromise<{
		data?: ApiCredentials;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/api-credentials/{clientId}/auth0',
			path: {
				'clientId': clientId,
			},
			errors: {
				404: `The requested resource was not found.`,
			},
		});
	}

}
