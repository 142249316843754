/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Customer } from '../models/Customer';
import type { CustomerUpdate } from '../models/CustomerUpdate';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { Privilege } from '../models/Privilege';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomerService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get customers
	 * Get a paginated list of customers.
	 * @returns any A list of customers.
	 * @throws ApiError
	 */
	public getCustomers({
		customerId,
		enabledProduct,
		name,
		page = 1,
		pageSize = 50,
		sortDir,
		sortBy = 'name',
		ssoDomain,
		status,
	}: {
		/**
		 * Filter the list of customers returned by customerId(s). Multiple values can be passed as a
		 * comma separated value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting customers does not have access to the customerId passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the returned list of customers by enabled product type(s).
		 * If more than one value is sent the filter for enabled products is
		 * combined using an OR condition.
		 */
		enabledProduct?: Array<'flareSystemMonitor' | 'machineHealthMonitor' | 'steamTrapMonitor' | 'dataServices'>,
		/**
		 * Filter the returned list by the name of the customer. This filter is
		 * case-insensitive and returns all customers that are like the name
		 * filter.
		 */
		name?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
		sortBy?: 'name' | 'status',
		/**
		 * Filter the returned list by the ssoDomains list associated with the customer.
		 */
		ssoDomain?: string,
		/**
		 * Filter the returned list by the status of the customer.
		 */
		status?: 'Decommissioned' | 'Demo' | 'Production' | 'To Be Installed',
	}): CancelablePromise<{
		data?: Array<Customer>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/customers',
			query: {
				'customerId': customerId,
				'enabledProduct': enabledProduct,
				'name': name,
				'page': page,
				'pageSize': pageSize,
				'sortDir': sortDir,
				'sortBy': sortBy,
				'ssoDomain': ssoDomain,
				'status': status,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create customer
	 * Create a new customer.
	 * @returns any A customer.
	 * @throws ApiError
	 */
	public postCustomers({
		requestBody,
	}: {
		/**
		 * A new customer to create.
		 */
		requestBody?: Customer,
	}): CancelablePromise<{
		data?: Customer;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/customers',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get customer
	 * Return a single customer.
	 * @returns any A customer.
	 * @throws ApiError
	 */
	public getCustomersCustomerId({
		customerId,
	}: {
		customerId: string,
	}): CancelablePromise<{
		data?: Customer;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/customers/{customerId}',
			path: {
				'customerId': customerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update an existing customer
	 * Update an existing customer.
	 * @returns any A customer.
	 * @throws ApiError
	 */
	public updateCustomersCustomerId({
		customerId,
		requestBody,
	}: {
		customerId: string,
		/**
		 * A customer to update.
		 */
		requestBody?: CustomerUpdate,
	}): CancelablePromise<{
		data?: Customer;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/customers/{customerId}',
			path: {
				'customerId': customerId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * hard delete a customer
	 * Delete a customer from the Everactive system.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteCustomersCustomerId({
		customerId,
	}: {
		customerId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/customers/{customerId}',
			path: {
				'customerId': customerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get list of privileges for a customer
	 * Retrieve the list of privileges available for a customer.
	 * @returns any A list of privilege keys.
	 * @throws ApiError
	 */
	public getCustomersCustomerIdPrivileges({
		customerId,
	}: {
		/**
		 * The id of the customer
		 */
		customerId: string,
	}): CancelablePromise<{
		data?: Array<Privilege>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/customers/{customerId}/privileges',
			path: {
				'customerId': customerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
