import { CustomersApi } from '@/client'
import { maxCustomerSelectionLimit } from '@/env'
import { arrayToObject } from '@/lib/utils/arrayToObject'
import { Customer } from '@/types'
import { watchOnce } from '@vueuse/core'
import { mapValues, sortBy } from 'lodash'
import { computed, ref, Ref, watch } from 'vue'
import { asyncComputedLoading } from './asyncComputedLoading'
import { useCurrentUser } from './useCurrentUser'

interface CustomersStateApi {
  customers: Ref<Customer[]>
  customerIds: Ref<string[]>
  customersMap: Ref<Record<string, Customer>>
  totalCustomers: Ref<number>
  selectedCustomers: Ref<Customer[]>
  totalSelectedCustomers: Ref<number>
  areCustomersLoading: Ref<boolean>
  customerSelection: Ref<Record<string, boolean>>
  customerIdQueryParam: Ref<string>
  selectedSageCustomers: Ref<Customer[]>
  shouldShowSageWarning: Ref<boolean>
  selectedSteamOffCustomers: Ref<Customer[]>
  maxSelectionReached: Ref<boolean>
  maxSelectionExceeded: Ref<boolean>
  maxCustomerSelectionLimit: number
  selectAllCustomers: () => void
  deselectAllCustomers: () => void
  toggleCustomer: (customerId: string) => void
  isCustomerSelected: (customerId: string) => boolean
  addCustomer: (customer: Customer) => void
  removeCustomer: (customerId: string) => void
}

const { userLoaded, isSuperAdmin } = useCurrentUser()

const [customers, areCustomersLoading] = asyncComputedLoading<Customer[]>(async () => {
  await userLoaded
  return CustomersApi.fetchAllCustomers()
}, [])

const localStorageKey = 'customerSelection'
const customerSelection = ref<Record<string, boolean>>(
  JSON.parse(localStorage.getItem(localStorageKey) ?? '{}')
)

watchOnce(customers, () => {
  customerSelection.value = customers.value.reduce(
    (agg, c) => ({
      ...agg,
      [c.id]: customerSelection.value[c.id] ?? false
    }),
    {}
  )
})

watch(customerSelection, () =>
  localStorage.setItem(localStorageKey, JSON.stringify(customerSelection.value))
)

const customerIds = computed(() => customers.value.map((c) => c.id))
const customersMap = computed(() => arrayToObject(customers.value, (c) => c.id))
const totalCustomers = computed(() => customers.value.length)
const totalSelectedCustomers = computed(
  () =>
    Object.keys(customerSelection.value).filter((customerId) => customerSelection.value[customerId])
      .length
)
const selectedCustomerIds = computed(() => {
  const res = Object.keys(customerSelection.value).filter((k) => customerSelection.value[k])
  return res
})
const selectedCustomers = computed(() => {
  const res = selectedCustomerIds.value.length
    ? selectedCustomerIds.value.map((id) => customersMap.value[id])
    : customers.value
  return res
})
const maxSelectionReached = computed(
  () => totalSelectedCustomers.value === maxCustomerSelectionLimit
)
const maxSelectionExceeded = computed(
  () => totalSelectedCustomers.value > maxCustomerSelectionLimit
)
const customerIdQueryParam = computed(() => {
  const res =
    selectedCustomerIds.value.length === 0 || maxSelectionExceeded.value
      ? undefined
      : selectedCustomerIds.value.join(',')
  return res
})
const selectedSageCustomers = computed(() =>
  selectedCustomers.value.filter((c) => c?.integrations?.sage?.enabled)
)
const shouldShowSageWarning = computed(
  () => selectedSageCustomers.value.length > 0 && !isSuperAdmin.value
)
const selectedSteamOffCustomers = computed(() =>
  selectedCustomers.value.filter((c) => c.steamTrapInfo.isSteamShutdown)
)

function selectAllCustomers() {
  customerSelection.value = mapValues(customerSelection.value, () => true)
}

function deselectAllCustomers() {
  customerSelection.value = mapValues(customerSelection.value, () => false)
}

function toggleCustomer(customerId: string) {
  customerSelection.value = {
    ...customerSelection.value,
    [customerId]: !customerSelection.value[customerId]
  }
}

function isCustomerSelected(customerId: string) {
  return customerSelection.value[customerId]
}

function addCustomer(customer: Customer) {
  customers.value = sortBy(customers.value.concat(customer), (c) => c.name.toLowerCase())
  customerSelection.value = {
    ...customerSelection.value,
    [customer.id]: false
  }
}

function removeCustomer(customerId: string) {
  customers.value = customers.value.filter((c) => c.id !== customerId)
  customerSelection.value = {
    ...customerSelection.value,
    [customerId]: false
  }
}

export function useCustomersState(): CustomersStateApi {
  return {
    customerIds,
    customers,
    customersMap,
    totalCustomers,
    selectedCustomers,
    totalSelectedCustomers,
    areCustomersLoading,
    customerSelection,
    selectAllCustomers,
    deselectAllCustomers,
    toggleCustomer,
    customerIdQueryParam,
    selectedSageCustomers,
    shouldShowSageWarning,
    selectedSteamOffCustomers,
    maxCustomerSelectionLimit,
    maxSelectionReached,
    maxSelectionExceeded,
    isCustomerSelected,
    addCustomer,
    removeCustomer
  }
}
