import { EveractiveApiResponse, MachinesApi } from '@/client'
import { Machine as ApiMachine } from '@/client/api'
import {
  ConversionTypes,
  Customer,
  CustomerMinimal,
  IMachineHistoryItem,
  IPaginationInfo,
  Machine,
  MachineWithSensorInstallation,
  SensorReading
} from '@/types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { MachineState } from './types/machineState'
import { RootState } from './types/root'

function initialState() {
  Machine.initialize()

  return {
    machines: [],
    isFetching: false,
    isFetchingData: false,
    isFetchingHistory: false,
    isFetchingMTC: false,
    isFetchingAlarmTrendSummary: false,
    isEditing: false,
    isEditingMachineSensor: false,
    isAdding: false
  }
}

const state: MachineState = initialState()

const getters: GetterTree<MachineState, RootState> = {
  getById(state): Function {
    return (id: string) => {
      if (!state.machines) {
        return null
      }
      return state.machines.find((machine) => machine.id == id) || null
    }
  }
}

const mutations: MutationTree<MachineState> = {
  reset(state: MachineState) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },

  addMachine(state: MachineState, value: Machine) {
    if (!state.machines) {
      state.machines = []
    }

    const updatedMachinesArray = [...state.machines]
    const updatedMachineIndex = updatedMachinesArray.findIndex((m) => m.id === value.id)

    if (updatedMachineIndex === -1) {
      state.machines = [value, ...state.machines]
      return
    }

    updatedMachinesArray[updatedMachineIndex] = new Machine(value)
    state.machines = updatedMachinesArray
  },

  setAllMachines(state: MachineState, value: Machine[]): void {
    state.machines = value
  },

  removeMachineById(state: MachineState, id: string): void {
    if (!state.machines) {
      return
    }

    const filteredMachines = state.machines.filter((machine) => machine.id !== id)
    state.machines = filteredMachines
  },

  updateMachine(state: MachineState, machine: Machine) {
    if (!state.machines) {
      return
    }

    const updatedMachinesArray = [...state.machines]
    const updatedMachineIndex = updatedMachinesArray.findIndex((m) => m.id === machine.id)

    if (updatedMachineIndex === -1) {
      return
    }

    updatedMachinesArray[updatedMachineIndex] = new Machine(machine)
    state.machines = updatedMachinesArray
  },

  updateMachineCustomer(state: MachineState, machine: Machine) {
    if (!state.machines) {
      return
    }

    const updatedMachinesArray = [...state.machines]
    const updatedMachineIndex = updatedMachinesArray.findIndex((m) => m.id === machine.id)

    if (updatedMachineIndex === -1) {
      return
    }

    updatedMachinesArray[updatedMachineIndex].customer = new CustomerMinimal(machine.customer)
    state.machines = updatedMachinesArray
  },

  setMachineCustomer(state: MachineState, params: { machine: Machine; customer: Customer }) {
    if (!state.machines) {
      return
    }

    const updatedMachinesArray = [...state.machines]
    const updatedMachineIndex = updatedMachinesArray.findIndex((m) => m.id === params.machine.id)

    if (updatedMachineIndex === -1) {
      return
    }

    updatedMachinesArray[updatedMachineIndex].customer = params.customer
    state.machines = updatedMachinesArray
  },
  setFetching(state: MachineState, isFetching: boolean) {
    state.isFetching = isFetching
  },
  setIsFetchingData(state: MachineState, isFetching: boolean) {
    state.isFetchingData = isFetching
  },
  setIsFetchingMTC(state: MachineState, isFetching: boolean) {
    state.isFetchingMTC = isFetching
  },
  setIsFetchingAlarmTrendSummary(state: MachineState, isFetching: boolean) {
    state.isFetchingAlarmTrendSummary = isFetching
  },
  setIsFetchingHistory(state: MachineState, isFetching: boolean) {
    state.isFetchingHistory = isFetching
  },
  setEditing(state: MachineState, isEditing: boolean) {
    state.isEditing = isEditing
  },
  setEditingMachineSensor(state: MachineState, isEditing: boolean) {
    state.isEditingMachineSensor = isEditing
  },
  setAdding(state: MachineState, isAdding: boolean) {
    state.isAdding = isAdding
  }
}

const actions: ActionTree<MachineState, RootState> = {
  async fetchMachines({ commit }, params) {
    commit('setFetching', true)
    try {
      const response = await MachinesApi.fetchMachines(params)
      if (response) {
        const machines = response.map((m) => new Machine(m))
        commit('setAllMachines', machines)
        return true
      }
      commit('setAllMachines', [])
      return false
    } finally {
      commit('setFetching', false)
    }
  },

  async fetchMachineById({ commit }, machineId: string): Promise<Machine | null> {
    commit('setFetching', true)

    try {
      const response = await MachinesApi.fetchMachineById(machineId)
      if (response) {
        const machine = new Machine(response)
        machine.convertThresholds(ConversionTypes.inchesPerSecond)
        commit('addMachine', machine)
        return machine
      }
    } finally {
      commit('setFetching', false)
    }

    return null
  },

  async fetchMachineTimeseriesData(
    { commit },
    { machineId, startTime, endTime, downsampleRate }
  ): Promise<any | null> {
    if (state.isFetchingData) {
      return
    }
    commit('setIsFetchingData', true)

    try {
      const { success, data, error } = await MachinesApi.fetchTimeseriesData({
        machineId,
        startTime,
        endTime,
        downsampleRate
      })

      if (success && data) {
        return {
          success,
          data: data.map((sensorReading) => new SensorReading(sensorReading))
        }
      }
      return { success, error }
    } finally {
      commit('setIsFetchingData', false)
    }
  },

  async fetchMachineHistory(
    { commit, state },
    { machineId, page, pageSize }
  ): Promise<{ data?: IMachineHistoryItem[]; paginationInfo?: IPaginationInfo }> {
    if (state.isFetchingHistory) {
      return {}
    }
    commit('setIsFetchingHistory', true)
    try {
      const response = await MachinesApi.fetchHistory({
        machineId,
        page,
        pageSize
      })

      return response || {}
    } finally {
      commit('setIsFetchingHistory', false)
    }
  },

  async addMachine({ commit }, machine: MachineWithSensorInstallation): Promise<Machine | string> {
    commit('setAdding', true)

    try {
      // ensure machine thresholds are sent as mm/s
      const machineToSend = new MachineWithSensorInstallation(machine)
      machineToSend.convertThresholds(ConversionTypes.millimetersPerSecond)
      machineToSend.prepMetadataFieldsForAPI()

      const { errors, data } = await MachinesApi.addMachine(machineToSend)

      if (data) {
        const newMachine = new Machine(data)
        commit('addMachine', newMachine)
        return newMachine
      }

      if (errors) {
        return errors.map((e) => e.message ? e.message : e.toString()).join(' ')
      }
      return 'Unable to create new machine'
    } finally {
      commit('setAdding', false)
    }
  },

  async removeMachine({ commit }, id: string): Promise<boolean> {
    const response = await MachinesApi.deleteMachine(id)

    if (response) {
      commit('removeMachineById', id)
      return true
    }

    return false
  },

  async updateMachine({ commit }, machine: Machine): Promise<Machine | any> {
    commit('setEditing', true)
    try {
      // ensure machine thresholds are sent as mm/s
      const machineToSend = new Machine(machine)
      machineToSend.convertThresholds(ConversionTypes.millimetersPerSecond)
      machineToSend.prepMetadataFieldsForAPI()

      const { errors, data } = await MachinesApi.updateMachine(machineToSend)

      if (data) {
        const updMachine = new Machine(data)
        commit('updateMachine', updMachine)
        return updMachine
      }

      if (errors) {
        return errors.map((e) => e.toString()).join(' ')
      }

      return 'Unable to update machine.'
    } finally {
      commit('setEditing', false)
    }
  },

  async updateMachineCustomer({ commit }, machine: Machine): Promise<boolean> {
    commit('setEditing', true)

    try {
      const response = await MachinesApi.updateMachineCustomer(machine)

      if (response) {
        commit('updateMachineCustomer', machine)
        return true
      }

      return false
    } finally {
      commit('setEditing', false)
    }
  },

  async updateAlarmStatus({ commit }, { id, status }): Promise<boolean> {
    commit('setEditing', true)

    try {
      const response = await MachinesApi.updateAlarmStatus({ id, status })

      if (response) {
        const updatedMachine = new Machine(response as ApiMachine)
        commit('updateMachine', updatedMachine)
        return true
      }

      return false
    } finally {
      commit('setEditing', false)
    }
  },

  async removeSensorFromMachine(
    { commit, dispatch },
    { machineId, sensorMacAddress, endTimestamp, startTimestamp }
  ): Promise<EveractiveApiResponse> {
    commit('setEditingMachineSensor', true)
    try {
      const response = await MachinesApi.removeSensorFromMachine({
        machineId,
        sensorMacAddress,
        startTimestamp,
        endTimestamp
      })

      if (response.success) {
        const updatedMachine = await dispatch('fetchMachineById', machineId)
        commit('updateMachine', updatedMachine)
      }

      return response
    } finally {
      commit('setEditingMachineSensor', false)
    }
  },

  async changeSensorOnMachine(
    { commit, dispatch },
    { machineId, sensorMacAddress, startTimestamp, installationNotes }
  ): Promise<boolean> {
    commit('setEditingMachineSensor', true)
    try {
      const response = await MachinesApi.changeSensorOnMachine({
        machineId,
        sensorMacAddress,
        startTimestamp,
        installationNotes
      })

      if (response) {
        const updatedMachine = await dispatch('fetchMachineById', machineId)
        commit('updateMachine', updatedMachine)
        return true
      }

      return false
    } finally {
      commit('setEditingMachineSensor', false)
    }
  }
}

const Machines: Module<MachineState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default Machines
