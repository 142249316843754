/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * type of this machine
 */
export enum MachineTypeList {
	BLOWER = 'Blower',
	COMPRESSOR = 'Compressor',
	FAN = 'Fan',
	GEAR_BOX = 'Gear Box',
	PUMP = 'Pump',
	MOTOR = 'Motor',
	OTHER = 'Other',
}
