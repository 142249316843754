/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The operation type of a steam trap.
 */
export enum SteamTrapOperationType {
	CONTINUOUS = 'Continuous',
	INTERMITTENT_PROCESS = 'Intermittent Process',
	SEASONAL = 'Seasonal',
}
