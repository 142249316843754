/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GatewayStatusPayloadV1 = {
	connectivity?: {
		provider?: string;
		devices?: Array<{
			address?: Array<string>;
			/**
			 * status as provided by the system network manager
			 */
			status: string;
			type: string;
			quality?: {
				/**
				 * The signal strength as a percentage, example: 88
				 */
				signalStrength?: number;
				/**
				 * The access points that are seen or connected to
				 */
				accessPoints?: Array<{
					name?: string;
					connected?: boolean;
				}>;
			};
		}>;
	};
	environmental?: {
		humidity?: number;
		temperature?: number;
	};
	serialNumber: string;
	system?: {
		cpuUsage?: Array<{
			id?: string;
			user?: number;
			nice?: number;
			system?: number;
			idle?: number;
			ioWait?: number;
		}>;
		diskUsage?: Array<{
			mount?: string;
			available?: number;
			free?: number;
			size?: number;
			used?: number;
		}>;
		memoryUsage?: number;
		uptime?: number;
	};
	cpuUsagePercentage: number;
	timestamp: string;
	/**
	 * The schema of this packet, as a partial-path reference.
	 */
	schema?: GatewayStatusPayloadV1.schema;
};

export namespace GatewayStatusPayloadV1 {

	/**
	 * The schema of this packet, as a partial-path reference.
	 */
	export enum schema {
		GATEWAY_STATUS_SCHEMA_JSON = 'gateway_status.schema.json',
	}


}

