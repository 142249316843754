/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { SteamTrapUATTRDetectionEvent } from '../models/SteamTrapUATTRDetectionEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SteamTrapUattrService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get uattr detection events
	 * Retrieve a list of failures for a steam trap.
	 * @returns any A list of uattr detection events.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapidUattrs({
		trapId,
		page = 1,
		pageSize = 50,
	}: {
		trapId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<SteamTrapUATTRDetectionEvent>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/uattrs',
			path: {
				'trapId': trapId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create uattr detection event
	 * Create a new detection uattr event for the steam trap.
	 * @returns any A steam trap uattr event.
	 * @throws ApiError
	 */
	public postSteamTrapsTrapidUattrs({
		trapId,
		page = 1,
		pageSize = 50,
		requestBody,
	}: {
		trapId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Create a steam trap uattr detection event.
		 */
		requestBody?: SteamTrapUATTRDetectionEvent,
	}): CancelablePromise<{
		data?: SteamTrapUATTRDetectionEvent;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/uattrs',
			path: {
				'trapId': trapId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
