/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserNotificationPreference = {
	/**
	 * Flag indicating if the user wants to receive this type of notification via email.
	 */
	email?: boolean;
	notificationType?: UserNotificationPreference.notificationType;
};

export namespace UserNotificationPreference {

	export enum notificationType {
		BLOWTHROUGH = 'blowthrough',
		DIGEST = 'digest',
		FAILED_COLD = 'failed_cold',
		MACHINE_THRESHOLD_BREACH = 'machine_threshold_breach',
		OFFLINE_GATEWAYS = 'offline_gateways',
		SENSOR_OFFLINE = 'sensor_offline',
		SENSOR_SLEEP = 'sensor_sleep',
		SENSOR_SERVICE_NEEDED = 'sensor_service_needed',
		STEAM_ON_OFF = 'steam_on_off',
	}


}

