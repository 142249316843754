/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserNotificationActionCreate = {
	/**
	 * The type of action the user performed on the notification.
	 * - `1`: Snoozed
	 * - `2`: Resolved
	 */
	actionTypeId: UserNotificationActionCreate.actionTypeId;
	/**
	 * Snoozed notifications expire after a period of time. The expiresTimstamp is the timestamp
	 * when the snooze user action expires. If this field is omitted a snooze type notification will
	 * expire after 7 days.
	 * This field is ignored for "Resolved" user notification actions.
	 */
	expiresTimestamp?: number;
};

export namespace UserNotificationActionCreate {

	/**
	 * The type of action the user performed on the notification.
	 * - `1`: Snoozed
	 * - `2`: Resolved
	 */
	export enum actionTypeId {
		'_1' = 1,
		'_2' = 2,
	}


}

