import { NotificationsApi } from "@/client/notificationsApi";
import { Notification as EANotification } from "@/types";
import { computed, ref, Ref } from "vue";
import { asyncComputedWithRefresh } from "./asyncComputedWithRefresh";
import { useCurrentCustomer } from "./useCurrentCustomer";

interface NotificationStateApi {
  activeNotificationCount: Ref<number>;
  hasActiveNotifications: Ref<boolean>;
  notifications: Ref<EANotification[]>;
  refreshNotificationsCount: () => void;
}

const { customerLoaded, customerIdQueryParam } = useCurrentCustomer();

const [notificationSummary, refreshNotificationsCount] =
  asyncComputedWithRefresh(
    () =>
      customerLoaded.then(() =>
        NotificationsApi.getNotificationCountsSummary({
          customerId: customerIdQueryParam.value
        })
      ),
    {
      activeCount: 0,
      resolvedCount: 0,
      snoozedCount: 0,
      totalCount: 0
    }
  );

const activeNotificationCount = computed(
  () => notificationSummary.value.activeCount
);
const hasActiveNotifications = computed(
  () => activeNotificationCount.value > 0
);
const notifications = ref<EANotification[]>([]) as Ref<EANotification[]>;

export function useNotificationState(): NotificationStateApi {
  return {
    activeNotificationCount,
    hasActiveNotifications,
    notifications,
    refreshNotificationsCount
  };
}
