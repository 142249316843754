/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A brief status message from the gateway, containing rapidly changing/dynamic values
 */
export type GatewayStatusPayloadV3 = {
	Connectivity: {
		devices: Array<{
			/**
			 * Name of the interface
			 */
			name: string;
			/**
			 * IP address in CIDR notation
			 */
			address?: Array<string>;
			/**
			 * status as provided by the system network manager
			 */
			status: string;
			/**
			 * type as provided by the system network manager
			 */
			type: string;
			ConnectionQuality?: {
				/**
				 * The signal strength as a percentage, example: 88
				 */
				signalStrength?: number;
				/**
				 * LTE operator (for wwan interfaces only)
				 */
				operator?: string;
				/**
				 * The access points that are seen or connected to (for wlan interfaces only)
				 */
				accessPoints?: Array<{
					name?: string;
					connected?: boolean;
				}>;
			};
		}>;
	};
	Environmental: {
		power?: {
			VDC?: number;
			VBAT?: number;
			VETH?: number;
			source?: GatewayStatusPayloadV3.source;
		};
		ledState: {
			status?: GatewayStatusPayloadV3.status;
			condition?: boolean;
		};
		sensors: {
			caseTemperature?: number;
			caseHumidity?: number;
		};
	};
	System: any;
	Services: {
		online: boolean;
		ntpDrift?: {
			lastoffset?: number;
			rmsoffset?: number;
		};
		mqttQueueSize?: {
			queueSize?: number;
			heapSize?: number;
			databaseSize?: number;
		};
		/**
		 * Version strings of all installed snaps
		 */
		snapVersions?: Array<{
			snap: string;
			version: string;
		}>;
		/**
		 * Deprecated
		 */
		softwareVersions?: {
			core?: string;
			caracalla?: string;
			'caracalla-kernel'?: string;
			'everactive-apex-gadget'?: string;
			'everactive-apex-kernel'?: string;
			'everactive-nats'?: string;
			'everactive-gateway-controller'?: string;
			'everactive-gateway-base'?: string;
			'everactive-cloud-connector'?: string;
			'everactive-gateway-services'?: string;
			'everactive-psiclone'?: string;
			'everactive-evernet'?: string;
			'everactive-firmware-utilities'?: string;
			'everactive-chrony'?: string;
			'everactive-ufw'?: string;
			'everactive-dnsmasq'?: string;
			'everactive-firmware-sensor-radio'?: string;
			'everactive-modemreboot'?: string;
			'everactive-ramcharger'?: string;
			'everactive-dingo'?: string;
			'everactive-firmware-ramcharger'?: string;
			'everactive-firmware-mc'?: string;
			'everactive-gateway-essentials'?: string;
			'everactive-iot-agent'?: string;
		};
		firmwareVersions?: {
			mc?: string;
			ramcharger?: string;
			boomerang?: string;
			sundance?: string;
		};
	};
	/**
	 * The schema of this packet, as a partial-path reference.
	 */
	schema: GatewayStatusPayloadV3.schema;
	timestamp: string;
	serial: string;
};

export namespace GatewayStatusPayloadV3 {

	export enum source {
		VDC = 'VDC',
		POE = 'POE',
		BAT = 'BAT',
	}

	export enum status {
		START = 'start',
		STOP = 'stop',
		UNREGISTERED = 'unregistered',
		REGISTERED = 'registered',
		CLOUD_CONNECTED = 'cloud_connected',
		CLOUD_DISCONNECTED = 'cloud_disconnected',
		APP_STATUS = 'app_status',
		PRODUCTION_PROGRAM = 'production_program',
		SELF_TEST = 'self_test',
		ON_WAKEUP = 'on_wakeup',
		NETWORK_CONNECTION_OFF = 'network_connection_off',
		NETWORK_CONNECTION_QUALITY_NONE = 'network_connection_quality_none',
		NETWORK_CONNECTION_QUALITY_LOW = 'network_connection_quality_low',
		NETWORK_CONNECTION_QUALITY_MEDIUM = 'network_connection_quality_medium',
		NETWORK_CONNECTION_QUALITY_HIGH = 'network_connection_quality_high',
		LTEM_CONNECTION_OFF = 'ltem_connection_off',
		LTEM_CONNECTION_QUALITY_NONE = 'ltem_connection_quality_none',
		LTEM_CONNECTION_QUALITY_LOW = 'ltem_connection_quality_low',
		LTEM_CONNECTION_QUALITY_MEDIUM = 'ltem_connection_quality_medium',
		LTEM_CONNECTION_QUALITY_HIGH = 'ltem_connection_quality_high',
	}

	/**
	 * The schema of this packet, as a partial-path reference.
	 */
	export enum schema {
		GATEWAY_STATUS_V3_SCHEMA_JSON = 'gateway_status_v3.schema.json',
	}


}

