/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A message to notify the cloud that the gateway successfully connected to the most recent published MQTT broker.
 */
export type GatewayVHostConnectionSuccessAckV3 = {
	/**
	 * The schema version of this message.
	 */
	schema: GatewayVHostConnectionSuccessAckV3.schema;
	/**
	 * The time this message was produced.
	 */
	timestamp: string;
	/**
	 * Detailed MQTT connection properties to connect to the cloud MQTT broker.
	 */
	connection: {
		/**
		 * The protocol to use to connect to the vhost
		 */
		protocol: GatewayVHostConnectionSuccessAckV3.protocol;
		/**
		 * The host for the MQTT connection
		 */
		host: string;
		/**
		 * The port for the MQTT connection
		 */
		port: number;
		/**
		 * The virtual host (vhost) to use when connecting to the MQTT broker.
		 * The vhost value is pre-pended to the username when connecting to the
		 * MQTT broker separated by a colon (e.g. `{{vhost}}:{{username}}`).
		 */
		vhost?: string;
	};
};

export namespace GatewayVHostConnectionSuccessAckV3 {

	/**
	 * The schema version of this message.
	 */
	export enum schema {
		GATEWAY_VHOST_CONN_ACK_V3_SCHEMA_JSON = 'gateway_vhost_conn_ack_v3.schema.json',
	}

	/**
	 * The protocol to use to connect to the vhost
	 */
	export enum protocol {
		MQTT = 'mqtt',
	}


}

