/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * NEMA mounting frame specification. List is from https://www.engineeringtoolbox.com/nema-electrical-motor-frame-dimensions-d_1504.html.
 */
export enum MachineNEMAFrameList {
	_42C = '42C',
	_48 = '48',
	_48C = '48C',
	_48H = '48H',
	_56 = '56',
	_56C = '56C',
	_56H = '56H',
	_56HZ = '56HZ',
	_56J = '56J',
	_66 = '66',
	_140T = '140T',
	_142AT = '142AT',
	_143AT = '143AT',
	_143JM = '143JM',
	_143JP = '143JP',
	_143T = '143T',
	_143TC = '143TC',
	_143TR = '143TR',
	_144AT = '144AT',
	_145AT = '145AT',
	_145JM = '145JM',
	_145JP = '145JP',
	_145T = '145T',
	_145TC = '145TC',
	_145TR = '145TR',
	_146AT = '146AT',
	_146ATC = '146ATC',
	_147AT = '147AT',
	_148AT = '148AT',
	_149AT = '149AT',
	_1410AT = '1410AT',
	_1411AT = '1411AT',
	_1412AT = '1412AT',
	_1412ATC = '1412ATC',
	_162AT = '162AT',
	_163AT = '163AT',
	_164AT = '164AT',
	_165AT = '165AT',
	_166AT = '166AT',
	_167AT = '167AT',
	_168AT = '168AT',
	_169AT = '169AT',
	_1610AT = '1610AT',
	_182 = '182',
	L182ACY = 'L182ACY',
	_182AT = '182AT',
	L182AT = 'L182AT',
	_182JM = '182JM',
	_182JP = '182JP',
	_182T = '182T',
	_182TC = '182TC',
	_182TR = '182TR',
	_183AT = '183AT',
	_184 = '184',
	_184AT = '184AT',
	_184JM = '184JM',
	_184JP = '184JP',
	_184TC = '184TC',
	_184T = '184T',
	_184TR = '184TR',
	_185AT = '185AT',
	_186ACY = '186ACY',
	_186AT = '186AT',
	L186AT = 'L186AT',
	_186ATC = '186ATC',
	_187AT = '187AT',
	_188AT = '188AT',
	_189AT = '189AT',
	_189ATC = '189ATC',
	_1810AT = '1810AT',
	_203 = '203',
	_204 = '204',
	_213 = '213',
	_213AT = '213AT',
	_213JM = '213JM',
	_213JP = '213JP',
	_213T = '213T',
	_213TC = '213TC',
	_213TR = '213TR',
	_214AT = '214AT',
	_215 = '215',
	_215AT = '215AT',
	_215JM = '215JM',
	_215JP = '215JP',
	_215T = '215T',
	_215TC = '215TC',
	_215TR = '215TR',
	_216AT = '216AT',
	_217AT = '217AT',
	_218AT = '218AT',
	_219AT = '219AT',
	_219ATC = '219ATC',
	_2110AT = '2110AT',
	_2110ATC = '2110ATC',
	_224 = '224',
	_225 = '225',
	_253AT = '253AT',
	_254 = '254',
	_254AT = '254AT',
	_254T = '254T',
	_254TC = '254TC',
	_254TR = '254TR',
	_254U = '254U',
	_255AT = '255AT',
	_256AT = '256AT',
	_256T = '256T',
	_256TC = '256TC',
	_256TR = '256TR',
	_256U = '256U',
	_257AT = '257AT',
	_258AT = '258AT',
	_259AT = '259AT',
	_283AT = '283AT',
	_284 = '284',
	_284AT = '284AT',
	_284T = '284T',
	_284TC = '284TC',
	_284TR = '284TR',
	_284U = '284U',
	_285AT = '285AT',
	_286AT = '286AT',
	_286T = '286T',
	_286TC = '286TC',
	_286TR = '286TR',
	_286TS = '286TS',
	_286U = '286U',
	_287AT = '287AT',
	_288AT = '288AT',
	_289AT = '289AT',
	_323AT = '323AT',
	_324 = '324',
	_324AT = '324AT',
	_324TR = '324TR',
	_324TS = '324TS',
	_324U = '324U',
	_325AT = '325AT',
	_326 = '326',
	_326AT = '326AT',
	_326T = '326T',
	_326TR = '326TR',
	_326TS = '326TS',
	_326U = '326U',
	_327AT = '327AT',
	_328AT = '328AT',
	_329AT = '329AT',
	_363AT = '363AT',
	_364 = '364',
	_364AT = '364AT',
	_364S = '364S',
	_364T = '364T',
	_364TR = '364TR',
	_364TS = '364TS',
	_364U = '364U',
	_365 = '365',
	_365AT = '365AT',
	_365T = '365T',
	_324T = '324T',
	_365TR = '365TR',
	_365TS = '365TS',
	_365U = '365U',
	_366AT = '366AT',
	_367AT = '367AT',
	_368AT = '368AT',
	_369AT = '369AT',
}
