import {
  MachineHealthFFTThresholds as ApiMachineHealthFFTThresholds,
  MachineHealthThresholds as ApiMachineHealthThresholds
} from '@/client/api'
import { ConversionTypes } from '@/types'

const DECIMAL_PLACES = 2
const MILLIMETERS_TO_INCHES_CONVERSION_NUMBER = 25.4

export default class ConversionHelper {
  public static getConvertedValue(
    value: number,
    sourceType:
      | ConversionTypes
      | ApiMachineHealthThresholds.energyThresholdUnit
      | ApiMachineHealthFFTThresholds.thresholdUnit,
    destinationType:
      | ConversionTypes
      | ApiMachineHealthThresholds.energyThresholdUnit
      | ApiMachineHealthFFTThresholds.thresholdUnit
  ) {
    if (value === undefined) {
      return null
    }
    if (sourceType.toString() === destinationType.toString()) {
      return parseFloat(value.toFixed(DECIMAL_PLACES))
    }

    if (
      sourceType.toString() === ConversionTypes.inchesPerSecond.toString() &&
      destinationType.toString() === ConversionTypes.millimetersPerSecond.toString()
    ) {
      return parseFloat((value * MILLIMETERS_TO_INCHES_CONVERSION_NUMBER).toFixed(DECIMAL_PLACES))
    }

    if (
      sourceType.toString() === ConversionTypes.millimetersPerSecond.toString() &&
      destinationType.toString() === ConversionTypes.inchesPerSecond.toString()
    ) {
      return parseFloat((value / MILLIMETERS_TO_INCHES_CONVERSION_NUMBER).toFixed(DECIMAL_PLACES))
    }

    return parseFloat(value.toFixed(DECIMAL_PLACES))
  }
}
