import { NavigationGuard } from "vue-router";

export function createNavGuard(
  label: string,
  guard: NavigationGuard
): NavigationGuard {
  return (to, from, next) => {
    if (to.path === "/authentication-error") {
      return next();
    }

    try {
      guard(to, from, next);
    } catch (e) {
      console.error(`error caught in navigation guard pipeline ${label}`, e);
    }
  };
}
