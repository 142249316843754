import {
  Gateway as ApiGateway,
  GatewayDailyStatusPayloadV3 as ApiGatewayDailyStatusPayloadV3,
  GatewayDeploymentStatus as ApiGatewayDeploymentStatus,
  GatewayReading as ApiGatewayReading,
  GatewayStatusPayloadV1 as ApiGatewayStatusPayloadV1,
  GatewayStatusPayloadV3 as ApiGatewayStatusPayloadV3,
  GatewayStatusSummary as ApiGatewayStatusSummary,
  MqttBroker as ApiMqttBroker,
  HazardousLocationClassification
} from '@/client/api'
import { CodexDropdownOption } from './codexTypes'
import { CustomerMinimal } from './customer'
import { IFileSummary } from './files'

export interface IBroker extends ApiMqttBroker {}

export interface IGatewayReading extends ApiGatewayReading {}

export interface IGatewayStatusPayloadV1 extends ApiGatewayStatusPayloadV1 {}
export interface IGatewayStatusPayloadV3 extends ApiGatewayStatusPayloadV3 {}
export interface IGatewayDailyStatusPayloadV3 extends ApiGatewayDailyStatusPayloadV3 {}

export interface IGatewayStatusSummary extends ApiGatewayStatusSummary {}

/* The key matches the backend enum for gateway deployment status,
   the value is the user-facing text. */
export enum GatewayDeploymentStatus {
  Commissioned = 'Commissioned',
  Decommissioned = 'Decommissioned',
  Returned = 'Returned',
  ToBeInstalled = 'To Be Installed',
  CustomerActionNeeded = 'Customer Action Needed'
}

export interface Evernet1Settings {
  enabled: boolean
  wakeupPeriod?: number
  wakeupOffset?: number
  wakeupCode?: string
}
export interface Evernet2Settings {
  enabled: boolean
  broadcastPeriod?: number
  beaconOffset?: number
  panId?: number
}

export interface IGateway extends ApiGateway {}

export class Gateway implements IGateway {
  static FLAVOR_PRODUCTION = 'production'
  static FLAVOR_VIRTUAL = 'virtual'

  public id: string = null
  public autoAuthorizeExistingSensors: boolean = true
  public name = ''
  public serialNumber = ''
  public hazardousLocationClassification?: HazardousLocationClassification;
  public location: string = null
  public status?: string
  public customer: CustomerMinimal = null
  public lastReading?: IGatewayReading
  public brokers?: IBroker[]
  public deploymentStatus: ApiGatewayDeploymentStatus = ApiGatewayDeploymentStatus.COMMISSIONED
  public installNotes?: string = null
  public enableSSH: boolean = false
  public enableWakeup = true
  public canSupportEvernet2 = false
  public lastStatus?: IGatewayStatusSummary
  public lastDailyStatus?: IGatewayStatusSummary
  public profileImage?: IFileSummary
  public softwareVersion?: string
  public flavor: ApiGateway.flavor = ApiGateway.flavor.PRODUCTION
  public vmDownloadAvailable: boolean
  public vmWasDownloaded?: boolean
  public evernet1Settings: Evernet1Settings = {
    enabled: true,
    wakeupCode: '7C6EA12C',
    wakeupPeriod: 60,
    wakeupOffset: 0
  }
  public evernet2Settings: Evernet2Settings = {
    enabled: false,
    broadcastPeriod: 60,
    beaconOffset: 0,
    panId: 0
  }
  public createdTimestamp?: number

  constructor(gateway?: Partial<IGateway>) {
    if (gateway) {
      const cleanGateway = JSON.parse(JSON.stringify(gateway))
      Object.assign(this, cleanGateway)
    }
  }

  get staticDisplayFields() {
    return {
      //name: "Name",
      //id: "ID",
      serialNumber: 'Serial Number',
      location: 'Location'
    }
  }

  get evernet1DisplayFields() {
    return {
      wakeupPeriod: 'Wake-up Interval',
      wakeupOffset: 'Wake-up Offset',
      wakeupCode: 'Wake-up Code'
    }
  }

  get evernet2DisplayFields() {
    return {
      broadcastPeriod: 'Broadcast Period',
      beaconOffset: 'Beacon Offset',
      panId: 'PAN ID'
    }
  }

  public getEditableFields() {
    return [
      'name',
      'hazardousLocationClassification',
      'location',
      'installNotes',
      'deploymentStatus',
      'evernet1Settings.enabled',
      'evernet1Settings.wakeupCode',
      'evernet1Settings.wakeupPeriod',
      'evernet1Settings.wakeupOffset',
      'evernet2Settings.broadcastPeriod',
      'evernet2Settings.beaconOffset',
      'evernet2Settings.panId',
      'enableWakeup'
    ]
  }

  public static deploymentStatusOptions: CodexDropdownOption[] = Object.entries(
    GatewayDeploymentStatus
  ).map(([key, value]) => ({ label: value, value: key }) as CodexDropdownOption)

  public constructObjectForUpdate() {
    const editGateway = {
      id: this.id,
      evernet2Settings: {
        enabled: this.evernet2Settings.enabled
      }
    }

    this.getEditableFields().forEach((field) => {
      if (field.includes('.')) {
        const [first, second] = field.split('.')
        if (!editGateway[first]) {
          editGateway[first] = {}
        }
        editGateway[first][second] = this[first][second]
      } else {
        editGateway[field] = this[field]
      }
    })

    return editGateway
  }
}
