/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerMinimal } from './CustomerMinimal';

/**
 * Metadata defining an mqtt subscription, containing connection information
 * that Evercloud can use to send data to an external mqtt service.
 */
export type MqttSubscription = {
	/**
	 * The unique id of the mqtt subscription.
	 */
	readonly id?: string;
	/**
	 * Specifies whether the mqtt subscription is enabled.
	 */
	enabled?: boolean;
	/**
	 * The ID of the customer whose sensor readings should be sent to the mqtt service.
	 */
	customerId?: string;
	readonly customer?: CustomerMinimal;
	/**
	 * Target MQTT connection parameters.
	 */
	mqttConfig: {
		/**
		 * The mqtt service URL that the subscription will connect to. It should contain the protocol, the hostname, and the port.
		 */
		mqttUrl: string;
		/**
		 * A prefix for the mqtt client id value. This is useful when using authentication policies based on the client id.
		 */
		mqttClientPrefix?: string;
		/**
		 * A prefix for the mqtt topics. This is useful to insert the everactive data in an existing topic hierarchy.
		 */
		mqttTopicPrefix?: string;
		/**
		 * Mqtt connection QoS. Supported values are 0 and 1.
		 */
		mqttQos?: number;
		/**
		 * The type of authentication for the target mqtt service.
		 */
		mqttAuth?: MqttSubscription.mqttAuth;
		/**
		 * The mqtt service username, required when the mqttAuth is password
		 */
		mqttUsername?: string;
		/**
		 * The mqtt service password, required when the mqttAuth is password
		 */
		mqttPassword?: string;
		/**
		 * PEM string of the TLS Private Key, required when the mqttAuth is tls
		 */
		mqttKey?: string;
		/**
		 * PEM string of the TLS Certificate, required when the mqttAuth is tls
		 */
		mqttCert?: string;
		/**
		 * PEM string of the CA Certificate for the secure connection. This is optional.
		 */
		mqttCA?: string;
	};
};

export namespace MqttSubscription {

	/**
	 * The type of authentication for the target mqtt service.
	 */
	export enum mqttAuth {
		NONE = 'none',
		PASSWORD = 'password',
		TLS = 'tls',
	}


}

