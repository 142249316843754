import { isProductPrivilege } from '@/lib/isProductPrivilege'
import { isSageIntegrated as _isSageIntegrated } from '@/lib/utils/userutils'
import {
  Customer,
  Notification as EaNotification,
  IApiCredentials,
  IGateway,
  IMachine,
  ISensor,
  ISteamTrap,
  Privilege,
  PrivilegeKey,
  Product
} from '@/types'
import {
  CustomerProducts,
} from '@/client/api'
import { computed, Ref, ToRefs } from 'vue'
import { useCurrentUser } from './useCurrentUser'
import { useCustomersState } from './useCustomersState'

type privileges =
  | 'isSuperAdmin'
  | 'isItAdmin'
  | 'hasOnlyDeveloperPrivilege'
  | 'hasOnlyDsProductAccess'
  | 'canViewUsers'
  | 'canEditUsers'
  | 'canEditUserCustomer'
  | 'canAddUsers'
  | 'hasUserManagementAccess'
  | 'canViewCustomers'
  | 'canEditCustomers'
  | 'canAddCustomers'
  | 'canDeleteCustomers'
  | 'hasCustomerManagementAccess'
  | 'canViewApiCredentials'
  | 'canCreateApiCredentials'
  | 'canViewWebhooks'
  | 'canEditWebhooks'
  | 'hasDeveloperToolsAccess'
  | 'canViewUserNotificationEmails'
  | 'canViewSensors'
  | 'canDeleteSensors'
  | 'canViewDevKitSensors'
  | 'canViewGateways'
  | 'canAddGateways'
  | 'canEditGateways'
  | 'canDeleteGateways'
  | 'hasGatewayManagementAccess'
  | 'canViewSteamTraps'
  | 'canAddSteamTraps'
  | 'canEditSteamTraps'
  | 'canBulkUploadSteamTraps'
  | 'canViewStmNotifications'
  | 'canViewMachines'
  | 'canAddMachines'
  | 'canEditMachines'
  | 'canEditMachineThresholds'
  | 'canDeleteMachines'
  | 'canBulkUploadMachines'
  | 'canViewStmEmails'
  | 'canViewMhmEmails'
  | 'canViewGatewayEmails'

type PrivilegeApi = ToRefs<Record<privileges, boolean>> & {
  enabledProducts: Ref<Product[]>
  hasMultipleProductsEnabled: Ref<boolean>
  shouldShowSageDashboard: Ref<boolean>
  userAdminCustomers: Ref<Customer[]>
  customerAdminCustomers: Ref<Customer[]>
  steamTrapAdminCustomers: Ref<Customer[]>
  machineAdminCustomers: Ref<Customer[]>
  gatewayPairingCustomerIds: Ref<string[]>
  devAdminCustomers: Ref<Customer[]>
  webhookAdminCustomerIds: Ref<string[]>
  dataServicesCustomerIds: Ref<string[]>
  checkPrivileges: (keys: PrivilegeKey[]) => boolean
  canEditGatewayMetadata: (gateway: IGateway) => boolean
  canEditGatewayCustomer: (gateway: IGateway) => boolean
  canDeleteGateway: (gateway: IGateway) => boolean
  canEditSteamTrap: (steamTrap: ISteamTrap) => boolean
  canDeleteSteamTrap: (steamTrap: ISteamTrap) => boolean
  canEditSteamTrapStatus: (steamTrap: ISteamTrap) => boolean
  canEditSteamTrapCustomer: (steamTrap: ISteamTrap) => boolean
  canAdminSteamTrapGroundTruths: (steamTrap: ISteamTrap) => boolean
  canViewSteamTrapRawData: (steamTrap: ISteamTrap) => boolean
  canViewSteamTrapNotifications: (steamTrap: ISteamTrap) => boolean
  canResolveSteamTrapNotification: (notification: EaNotification) => boolean
  canDeleteApiCredential: (apiCredential: IApiCredentials) => boolean
  canRotateApiCredential: (apiCredential: IApiCredentials) => boolean
  canDeleteSensor: (sensor: ISensor) => boolean
  canEditMachine: (machine: IMachine) => boolean
  canEditMachineCustomer: (machine: IMachine) => boolean
  canDeleteMachine: (machine: IMachine) => boolean
}

const { privileges, isSuperAdmin } = useCurrentUser()
const { customersMap, customers, customerIds } = useCustomersState()

function customerHasProductsEnabled(
  customer: Privilege['customer'],
  product: keyof CustomerProducts
) {
  return customer && product && customer?.enabledProducts?.[product]
}

function hasAnyPrivilege(
  privilegesToCheck: PrivilegeKey[] | Set<PrivilegeKey>,
  product?: keyof CustomerProducts,
  allowSage: boolean = true
): Ref<boolean> {
  return computed<boolean>(() =>
    privileges.value.some((p) => {
      const hasPrivilege = Array.isArray(privilegesToCheck)
        ? privilegesToCheck.includes(p.key)
        : privilegesToCheck.has(p.key)
      const isProductEnabled =
        !product || isSuperAdmin.value || customerHasProductsEnabled(p.customer, product)
      const isSageAllowed = allowSage || !p.customer?.integrations?.sage?.enabled

      return hasPrivilege && isProductEnabled && isSageAllowed
    })
  )
}

function getCustomersWithPrivilege(privilegesFilter: Set<PrivilegeKey>) {
  return computed(() =>
    isSuperAdmin.value
      ? customers.value
      : privileges.value
          .filter((p) => privilegesFilter.has(p.key))
          .map((p) => customersMap.value[p.customer.id])
  )
}

function getCustomerIdsWithPrivilege(
  privilegesFilter: Set<PrivilegeKey>,
  product?: keyof CustomerProducts
) {
  return computed(() =>
    isSuperAdmin.value
      ? customerIds.value
      : privileges.value
          .filter((p) => {
            const hasPrivilege = privilegesFilter.has(p.key)

            return hasPrivilege && (!product || customerHasProductsEnabled(p.customer, product))
          })
          .map((p) => p.customer.id)
  )
}

function hasPrivilegeForAsset<AssetType extends { customer?: { id: string }; customerId?: string }>(
  asset: AssetType,
  privilegesToCheck: Set<PrivilegeKey>
): boolean {
  return (
    isSuperAdmin.value ||
    privileges.value.some(
      (p) =>
        p.customer.id === (asset?.customer?.id ?? asset?.customerId ?? '') &&
        privilegesToCheck.has(p.key)
    )
  )
}

const enabledProducts = computed(() => {
  const _enabledProducts = {
    [Product.MachineHealth]: false,
    [Product.Steam]: false
  }

  if (isSuperAdmin.value) {
    _enabledProducts[Product.MachineHealth] = true
    _enabledProducts[Product.Steam] = true
  } else {
    for (let privilege of privileges.value) {
      if (!privilege.customer || !isProductPrivilege(privilege.key)) {
        continue
      }

      const privilegeCustomer = customersMap.value[privilege.customer.id]

      if (!privilegeCustomer) {
        continue
      }

      const { machineHealthMonitor, steamTrapMonitor } = privilegeCustomer.enabledProducts
      _enabledProducts[Product.MachineHealth] ||= machineHealthMonitor
      _enabledProducts[Product.Steam] ||= steamTrapMonitor
    }
  }

  return [Product.MachineHealth, Product.Steam].filter((p) => _enabledProducts[p])
})

const shouldShowSageDashboard = computed(
  () =>
    privileges.value.filter(
      (p) => isProductPrivilege(p.key) && p.customer?.integrations?.sage?.enabled
    ).length === 1
)

const hasMultipleProductsEnabled = computed(() => enabledProducts.value.length > 1)

const checkPrivileges: PrivilegeApi['checkPrivileges'] = (keys) =>
  privileges.value.some((p) => keys.includes(p.key))

const isItAdmin = hasAnyPrivilege([PrivilegeKey.IT_ADMIN])
const hasOnlyDeveloperPrivilege = computed(() =>
  privileges.value.every((p) => p.key === PrivilegeKey.DEVELOPER)
)

const hasOnlyDsProductAccess = computed<boolean>(() =>
  privileges.value.every(
    (p) =>
      p.customer?.enabledProducts?.dataServices &&
      Object.values(p?.customer?.enabledProducts).filter(Boolean).length === 1
  )
)

const userAdminPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.IT_ADMIN
])
const userAdminCustomers = getCustomersWithPrivilege(userAdminPrivileges)

const canViewUsers = hasAnyPrivilege(userAdminPrivileges)
const canEditUsers = hasAnyPrivilege(userAdminPrivileges)
const canAddUsers = hasAnyPrivilege(userAdminPrivileges)
const hasUserManagementAccess = computed(
  () => canEditUsers.value || canViewUsers.value || canAddUsers.value
)
const canEditUserCustomer = computed(() => canEditUsers.value)

const customerAdminPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER
])
const customerAdminCustomers = getCustomersWithPrivilege(userAdminPrivileges)

const canViewCustomers = hasAnyPrivilege(customerAdminPrivileges)
const canEditCustomers = hasAnyPrivilege(customerAdminPrivileges)
const canAddCustomers = hasAnyPrivilege([PrivilegeKey.SUPER_ADMIN])
const canDeleteCustomers = hasAnyPrivilege([PrivilegeKey.SUPER_ADMIN])
const hasCustomerManagementAccess = computed(
  () => canViewCustomers.value || canEditCustomers.value || canAddCustomers.value
)

const devAdminPrivileges = new Set([PrivilegeKey.SUPER_ADMIN, PrivilegeKey.DEVELOPER])
const canCreateApiCredentials = hasAnyPrivilege(devAdminPrivileges)
const canViewApiCredentials = hasAnyPrivilege(devAdminPrivileges)

function canDeleteApiCredential(apiCredential: IApiCredentials) {
  return hasPrivilegeForAsset(apiCredential, devAdminPrivileges)
}

function canRotateApiCredential(apiCredential: IApiCredentials) {
  return hasPrivilegeForAsset(apiCredential, devAdminPrivileges)
}

const webhookAdminPrivileges = new Set([PrivilegeKey.SUPER_ADMIN, PrivilegeKey.DEVELOPER])
const canViewWebhooks = hasAnyPrivilege(webhookAdminPrivileges, 'dataServices')
const canEditWebhooks = hasAnyPrivilege(webhookAdminPrivileges, 'dataServices')

const hasDeveloperToolsAccess = computed(
  () =>
    canViewApiCredentials.value ||
    canCreateApiCredentials.value ||
    canEditWebhooks.value ||
    canViewWebhooks.value
)

const devAdminCustomers = getCustomersWithPrivilege(devAdminPrivileges)
const webhookAdminCustomerIds = getCustomerIdsWithPrivilege(devAdminPrivileges, 'dataServices')

const sensorReadPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER,
  PrivilegeKey.READ_ONLY
])
const sensorDeletePrivileges = new Set([PrivilegeKey.SUPER_ADMIN])
const canViewSensors = hasAnyPrivilege(sensorReadPrivileges)
const canDeleteSensors = hasAnyPrivilege(sensorDeletePrivileges)
const canViewDevKitSensors = hasAnyPrivilege(sensorReadPrivileges, 'dataServices')
const dataServicesCustomerIds = getCustomerIdsWithPrivilege(sensorReadPrivileges, 'dataServices')

function canDeleteSensor(sensor: ISensor) {
  return hasPrivilegeForAsset(sensor, sensorDeletePrivileges)
}

const editGatewayCustomerPrivileges = new Set([PrivilegeKey.SUPER_ADMIN])
const editGatewayMetadataPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const gatewayCreatePrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const deleteGatewayPrivileges = new Set([PrivilegeKey.SUPER_ADMIN])
const canViewGateways = hasAnyPrivilege([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER,
  PrivilegeKey.READ_ONLY
])
const canAddGateways = hasAnyPrivilege(gatewayCreatePrivileges)
const canEditGateways = hasAnyPrivilege(editGatewayMetadataPrivileges)
const canDeleteGateways = hasAnyPrivilege(deleteGatewayPrivileges)
const hasGatewayManagementAccess = computed(
  () => canViewGateways.value || canAddGateways.value || canEditGateways.value
)
const gatewayPairingCustomerIds = getCustomerIdsWithPrivilege(gatewayCreatePrivileges)

function canEditGatewayMetadata(gateway: IGateway) {
  return hasPrivilegeForAsset(gateway, editGatewayMetadataPrivileges)
}

function canEditGatewayCustomer(gateway: IGateway) {
  return hasPrivilegeForAsset(gateway, editGatewayCustomerPrivileges)
}

function canDeleteGateway(gateway: IGateway) {
  return hasPrivilegeForAsset(gateway, deleteGatewayPrivileges)
}

const gatewayStatusNotificationsPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const monitoredAssetReadPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER,
  PrivilegeKey.READ_ONLY
])
const monitoredAssetCreatePrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const monitoredAssetUpdatePrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const monitoredAssetUpdateCustomerPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const monitoredAssetDeletePrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER
])
const monitoredAssetEmailNotificationPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const monitoredAssetUpdateStatusPrivileges = new Set([PrivilegeKey.SUPER_ADMIN])
const monitoredAssetReadRawDataPrivileges = new Set([PrivilegeKey.SUPER_ADMIN])
const steamTrapAdminGroundTruthPrivileges = new Set([PrivilegeKey.SUPER_ADMIN])
const steamTrapReadNotificationsPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER,
  PrivilegeKey.READ_ONLY
])
const steamTrapResolveNotificationsPrivileges = new Set([
  PrivilegeKey.SUPER_ADMIN,
  PrivilegeKey.CUSTOMER_ADMIN,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.USER
])
const canViewSteamTraps = hasAnyPrivilege(monitoredAssetReadPrivileges, 'steamTrapMonitor')
const canAddSteamTraps = hasAnyPrivilege(monitoredAssetCreatePrivileges, 'steamTrapMonitor', false)
const canEditSteamTraps = hasAnyPrivilege(monitoredAssetUpdatePrivileges, 'steamTrapMonitor', false)
const canBulkUploadSteamTraps = computed(() => isSuperAdmin.value)
const canViewStmNotifications = hasAnyPrivilege(
  steamTrapReadNotificationsPrivileges,
  'steamTrapMonitor',
  false
)
const canViewStmEmails = hasAnyPrivilege(
  monitoredAssetEmailNotificationPrivileges,
  'steamTrapMonitor',
  false
)
const canViewMhmEmails = hasAnyPrivilege(
  monitoredAssetEmailNotificationPrivileges,
  'machineHealthMonitor'
)
const canViewGatewayEmails = hasAnyPrivilege(gatewayStatusNotificationsPrivileges)
const canViewUserNotificationEmails = computed(
  () => canViewStmEmails.value || canViewMhmEmails.value || canViewGatewayEmails.value
)

const monitoredAssetAdminCustomers = getCustomersWithPrivilege(
  monitoredAssetUpdateCustomerPrivileges
)
const steamTrapAdminCustomers = computed(() =>
  monitoredAssetAdminCustomers.value.filter((c) => c.enabledProducts.steamTrapMonitor)
)
const machineAdminCustomers = computed(() =>
  monitoredAssetAdminCustomers.value.filter((c) => c.enabledProducts.machineHealthMonitor)
)

function canEditSteamTrap(steamTrap: ISteamTrap): boolean {
  return hasPrivilegeForAsset(steamTrap, monitoredAssetUpdatePrivileges)
}

function canEditSteamTrapCustomer(steamTrap: ISteamTrap): boolean {
  return hasPrivilegeForAsset(steamTrap, monitoredAssetUpdateCustomerPrivileges)
}

function canDeleteSteamTrap(steamTrap: ISteamTrap): boolean {
  return hasPrivilegeForAsset(steamTrap, monitoredAssetDeletePrivileges)
}

function canEditSteamTrapStatus(steamTrap: ISteamTrap): boolean {
  return (
    !_isSageIntegrated(steamTrap.customer.integrations) &&
    hasPrivilegeForAsset(steamTrap, monitoredAssetUpdateStatusPrivileges)
  )
}

function canAdminSteamTrapGroundTruths(steamTrap: ISteamTrap): boolean {
  return hasPrivilegeForAsset(steamTrap, steamTrapAdminGroundTruthPrivileges)
}

function canViewSteamTrapRawData(steamTrap: ISteamTrap): boolean {
  return hasPrivilegeForAsset(steamTrap, monitoredAssetReadRawDataPrivileges)
}

function canViewSteamTrapNotifications(steamTrap: ISteamTrap): boolean {
  return hasPrivilegeForAsset(steamTrap, steamTrapReadNotificationsPrivileges)
}

function canResolveSteamTrapNotification(notification: EaNotification): boolean {
  return hasPrivilegeForAsset(notification, steamTrapResolveNotificationsPrivileges)
}

const canViewMachines = hasAnyPrivilege(monitoredAssetReadPrivileges, 'machineHealthMonitor')
const canAddMachines = hasAnyPrivilege(monitoredAssetCreatePrivileges, 'machineHealthMonitor')
const canEditMachines = hasAnyPrivilege(monitoredAssetUpdatePrivileges, 'machineHealthMonitor')
const canEditMachineThresholds = hasAnyPrivilege(
  [PrivilegeKey.SUPER_ADMIN, PrivilegeKey.CUSTOMER_ADMIN, PrivilegeKey.ASSET_MANAGER],
  'machineHealthMonitor'
)
const canDeleteMachines = hasAnyPrivilege(monitoredAssetDeletePrivileges, 'machineHealthMonitor')
const canBulkUploadMachines = computed(() => isSuperAdmin.value)

function canEditMachine(machine: IMachine) {
  return hasPrivilegeForAsset(machine, monitoredAssetUpdatePrivileges)
}

function canEditMachineCustomer(machine: IMachine) {
  return hasPrivilegeForAsset(machine, monitoredAssetUpdateCustomerPrivileges)
}

function canDeleteMachine(machine: IMachine) {
  return hasPrivilegeForAsset(machine, monitoredAssetDeletePrivileges)
}

export function useUserPrivileges(): PrivilegeApi {
  return {
    isItAdmin,
    hasOnlyDeveloperPrivilege,
    isSuperAdmin,
    hasOnlyDsProductAccess,
    canViewUsers,
    canEditUsers,
    canEditUserCustomer,
    canAddUsers,
    hasUserManagementAccess,
    canViewCustomers,
    canEditCustomers,
    canAddCustomers,
    canDeleteCustomers,
    hasCustomerManagementAccess,
    canViewApiCredentials,
    canCreateApiCredentials,
    canViewWebhooks,
    canEditWebhooks,
    hasDeveloperToolsAccess,
    canViewUserNotificationEmails,
    canViewSensors,
    canDeleteSensors,
    canViewDevKitSensors,
    canViewGateways,
    canAddGateways,
    canEditGateways,
    canEditGatewayCustomer,
    canEditGatewayMetadata,
    canDeleteGateway,
    canDeleteGateways,
    hasGatewayManagementAccess,
    canAddSteamTraps,
    canViewSteamTraps,
    canEditSteamTraps,
    canBulkUploadSteamTraps,
    canViewStmNotifications,
    canViewMachines,
    canAddMachines,
    canEditMachines,
    canEditMachineThresholds,
    canDeleteMachines,
    canBulkUploadMachines,
    checkPrivileges,
    enabledProducts,
    hasMultipleProductsEnabled,
    shouldShowSageDashboard,
    userAdminCustomers,
    customerAdminCustomers,
    canEditSteamTrap,
    canDeleteSteamTrap,
    canEditSteamTrapStatus,
    canAdminSteamTrapGroundTruths,
    canViewSteamTrapRawData,
    canViewSteamTrapNotifications,
    canResolveSteamTrapNotification,
    canEditSteamTrapCustomer,
    gatewayPairingCustomerIds,
    devAdminCustomers,
    webhookAdminCustomerIds,
    canDeleteApiCredential,
    canRotateApiCredential,
    canDeleteSensor,
    canEditMachine,
    canEditMachineCustomer,
    canDeleteMachine,
    steamTrapAdminCustomers,
    machineAdminCustomers,
    canViewStmEmails,
    canViewMhmEmails,
    canViewGatewayEmails,
    dataServicesCustomerIds
  }
}
