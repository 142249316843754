import { EveractiveApiResponse, GatewaysApi } from '@/client'
import {
  Gateway as ApiGateway,
  GatewayStatusV1 as ApiGatewayStatusV1,
  GatewayStatusV3 as ApiGatewayStatusV3
} from '@/client/api'
import { Gateway } from '@/types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { GatewayState } from './types/gatewayState'
import { RootState } from './types/root'

function initialState() {
  return {
    isFetching: false,
    isSaving: false,
    isPairing: false
  }
}

const state: GatewayState = initialState()

const getters: GetterTree<GatewayState, RootState> = {}

const mutations: MutationTree<GatewayState> = {
  reset(state: GatewayState) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },

  setIsFetching(state: GatewayState, value: boolean): void {
    state.isFetching = value
  },

  setIsSaving(state: GatewayState, value: boolean): void {
    state.isSaving = value
  },

  setIsPairing(state: GatewayState, value: boolean): void {
    state.isPairing = value
  }
}

const actions: ActionTree<GatewayState, RootState> = {
  async fetchGatewayById({ commit }, id): Promise<Gateway | boolean> {
    commit('setIsFetching', true)
    try {
      const response = await GatewaysApi.getGatewayById(id)
      if (response.data) {
        return new Gateway(response.data)
      }
    } finally {
      commit('setIsFetching', false)
    }
    return null
  },

  async updateGateway({ commit }, gateway: Gateway): Promise<EveractiveApiResponse<boolean>> {
    if (gateway && gateway.id) {
      commit('setIsSaving', true)
      try {
        const response = await GatewaysApi.updateGateway(gateway)
        return response
      } finally {
        commit('setIsSaving', false)
      }
    } else {
      console.log('Invalid gateway object')
      return { success: false, message: 'Gateway not updated, invalid definition provided.' }
    }
  },

  async updateGatewayCustomer(
    { commit },
    { gatewayId, customerId }
  ): Promise<EveractiveApiResponse<boolean>> {
    if (!gatewayId || !customerId) {
      console.log('Invalid gateway customer object')
      return { success: false }
    }

    commit('setIsSaving', true)
    try {
      const response = await GatewaysApi.updateGatewayCustomer(gatewayId, customerId)
      return response
    } finally {
      commit('setIsSaving', false)
    }
  },

  async removeGateway(params, id: string): Promise<boolean> {
    const response = await GatewaysApi.deleteGateway(id)
    return response ? true : false
  },

  async pairGateway(
    { commit },
    { pairingCode, customerId, name }
  ): Promise<EveractiveApiResponse<ApiGateway>> {
    commit('setIsPairing', true)
    const response = await GatewaysApi.pairGateway(pairingCode, customerId, name)
    
    commit('setIsPairing', false)
    return response    
  },

  async getVMImageUrl(params, id: string): Promise<string> {
    const response = await GatewaysApi.getDownloadVirtualImageURL(id)
    return response
  },

  async fetchGatewayStatusV1ById(
    { commit },
    { id, startTime, endTime }
  ): Promise<ApiGatewayStatusV1[] | boolean> {
    commit('setIsFetching', true)
    try {
      const response = await GatewaysApi.getGatewayStatusV1ById({
        gatewayIdOrSerialNumber: id,
        startTime,
        endTime
      })
      if (response.data) {
        return response.data
      }
    } finally {
      commit('setIsFetching', false)
    }
    return false
  },

  async fetchGatewayStatusV3ById(
    { commit },
    { id, startTime, endTime }
  ): Promise<ApiGatewayStatusV3[] | boolean> {
    commit('setIsFetching', true)
    try {
      const response = await GatewaysApi.getGatewayStatusV3ById({
        gatewayIdOrSerialNumber: id,
        startTime,
        endTime
      })
      if (response.data) {
        return response.data
      }
    } finally {
      commit('setIsFetching', false)
    }
    return false
  }
}

const Gateways: Module<GatewayState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default Gateways
