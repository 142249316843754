import {
  celsiusToKelvin,
  celsiusToFahrenheit,
  kelvinToCelsius,
  kelvinToFahrenheit,
  fahrenheitToCelsius,
  fahrenheitToKelvin
} from "temperature";

const TEMPERATURE_DECIMAL_PLACES = 2;

export default class TemperatureHelper {
  public static getConvertedTemperature(
    temperature: number,
    sourceType: string,
    destinationType: string,
    returnString = true
  ): any {
    let result: number;
    if (sourceType.toUpperCase() === destinationType.toUpperCase()) {
      result = temperature;
    }

    if (sourceType.toUpperCase() === "FAHRENHEIT") {
      if (destinationType.toUpperCase() === "CELSIUS") {
        result = fahrenheitToCelsius(temperature);
      } else if (destinationType.toUpperCase() === "KELVIN") {
        result = fahrenheitToKelvin(temperature);
      }
    } else if (sourceType.toUpperCase() === "CELSIUS") {
      if (destinationType.toUpperCase() === "FAHRENHEIT") {
        result = celsiusToFahrenheit(temperature);
      } else if (destinationType.toUpperCase() === "KELVIN") {
        result = celsiusToKelvin(temperature);
      }
    } else if (sourceType.toUpperCase() === "KELVIN") {
      if (destinationType.toUpperCase() === "CELSIUS") {
        result = kelvinToCelsius(temperature);
      } else if (destinationType.toUpperCase() === "FAHRENHEIT") {
        result = kelvinToFahrenheit(temperature);
      }
    }

    return returnString
      ? result.toFixed(TEMPERATURE_DECIMAL_PLACES)
      : Number(result.toFixed(TEMPERATURE_DECIMAL_PLACES));
  }

  public static getTemperatureAbbreviation(temperatureType: string): string {
    if (!temperatureType || !temperatureType.length) {
      return "";
    }
    return `${temperatureType[0] !== "K" ? "\xB0" : ""}${temperatureType[0]}`;
  }
}
