/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Indicates if the asset is located in an environment or location designated with
 * a hazardous location classification.
 */
export enum HazardousLocationClassification {
	C1D1 = 'C1D1',
	C1D2 = 'C1D2',
}
