/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SensorMagnitudeHolder = {
	/**
	 * The value in inches per second for velocity magnitudes
	 */
	ips?: number;
	/**
	 * The value in mg for acceleration magnitudes
	 */
	mg?: number;
	/**
	 * The value in micro-teslas for magnetic flux density measurements
	 */
	microTeslas?: number;
	/**
	 * The value in millimeters per second for velocity magnitudes
	 */
	mms?: number;
	/**
	 * The units reported from the sensor
	 */
	rawUnits?: SensorMagnitudeHolder.rawUnits;
	/**
	 * The raw reported value from the sensor
	 */
	rawValue?: number;
};

export namespace SensorMagnitudeHolder {

	/**
	 * The units reported from the sensor
	 */
	export enum rawUnits {
		MICRO_TESLAS = 'micro-teslas',
		MG = 'mg',
		MM_S = 'mm/s',
	}


}

