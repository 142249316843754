<script lang="ts">
import { useBaseMixin } from '@/composables/useBaseMixin'
import { useCurrentUser } from '@/composables/useCurrentUser'
import { useState } from '@/composables/useStore'
import { useUserPrivileges } from '@/composables/useUserPrivileges'
import ProductConfigurationHelper from '@/lib/product-config/ProductConfigurationHelper'
import { RoutedMenuItem } from '@/types/products'
import { asyncComputed } from '@vueuse/core'
import { computed, defineAsyncComponent } from 'vue'

export default {
  name: 'MainMenu',
  components: {
    ProductSelect: defineAsyncComponent(() => import('@/components/header/ProductSelect.vue'))
  },
  setup() {
    const { currentProduct } = useState<{ currentProduct: string }>('App', ['currentProduct'])
    const { user } = useCurrentUser()

    const productConfiguration = computed(() => {
      return ProductConfigurationHelper.getProductConfiguration(
        currentProduct.value,
        user.value?.customer?.integrations
      )
    })

    const routedMenuItems = asyncComputed(async () => {
      const menuItems: RoutedMenuItem[] = []

      for (const route of productConfiguration.value.routedMenuItems) {
        if (!route?.meta?.privilegeFunction) {
          menuItems.push(route)
        } else if (await route.meta.privilegeFunction()) {
          menuItems.push(route)
        }
      }

      return menuItems
    })
    return {
      routedMenuItems,
      currentProduct,
      ...useBaseMixin(),
      ...useUserPrivileges()
    }
  },
  props: {
    position: {
      type: String,
      default: 'top'
    }
  }
}
</script>

<template>
  <ul :class="[$style.navList, $style[position]]">
    <li v-if="hasMultipleProductsEnabled" :class="$style.productSelectContainer">
      <ProductSelect data-testid="productSelect__dropdown" />
    </li>
    <li v-for="item in routedMenuItems" :key="item.name">
      <router-link
        v-if="item.enabled"
        :class="$style.navLink"
        :product="currentProduct"
        :to="'/' + currentProduct + '/' + item.route"
        :routeKey="item.routeKey"
        :data-testid="`product_${currentProduct}_${item.routeKey}_link`"
      >
        {{ item.name }}
      </router-link>
      <div v-else :class="$style.navLinkDisabled">
        {{ item.name }}
      </div>
    </li>
    <li v-if="isItAdmin">
      <router-link :class="$style.navLink" to="/management"> Management </router-link>
    </li>
  </ul>
</template>

<style module>
.navList {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1030;
}
.navList.bottom {
  display: none;
}
.navList :global .router-link-active {
  border-bottom: 1px solid #14e067;
  background: rgba(255, 255, 255, 0.1);
}
.navList li {
  padding: 0px 10px;
}
.navLinkDisabled {
  padding: 9px;
  border: 1px dotted rgba(158, 158, 158, 0.4);
  color: #9e9e9e;
}
.navLink {
  padding: 10px;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid transparent;
}
.navLink:hover {
  text-decoration: underline;
  color: white;
}
.navLink:focus {
  outline: 1px dashed rgba(255, 255, 255, 0.3);
}
.productSelectContainer {
  display: flex;
  align-self: stretch;
  align-items: center;
}
@media all and (max-width: 812px) {
  .navLinkDisabled {
    padding: 4px;
  }
  .navList.bottom {
    display: flex;
    width: 100%;
    background: #03033d;
    padding: 10px;
    justify-content: flex-start;
    box-shadow: 0px 3px 13px 3px rgba(3, 3, 61, 0.3);
  }

  .navLink,
  .navLinkDisabled {
    padding: 5px;
    height: 34px;
  }
  .navList.top {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .navList {
    justify-content: space-around;
  }
}
@media all and (max-width: 425px) {
  .navList li {
    padding: 0 5px;
  }
  .navLink,
  .navLinkDisabled {
    font-size: 14px;
  }
}
</style>
