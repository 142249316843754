/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAssociateDetail } from '../models/CustomerAssociateDetail';
import type { FullTimeWaveform } from '../models/FullTimeWaveform';
import type { HazardousLocationClassification } from '../models/HazardousLocationClassification';
import type { Machine } from '../models/Machine';
import type { MachineAlarmStatusTrend } from '../models/MachineAlarmStatusTrend';
import type { MachineHealthThresholdBreachDetail } from '../models/MachineHealthThresholdBreachDetail';
import type { MachineHistoryItem } from '../models/MachineHistoryItem';
import type { MachineNotificationCreate } from '../models/MachineNotificationCreate';
import type { MachinePost } from '../models/MachinePost';
import type { MachineThresholdCrossedEvent } from '../models/MachineThresholdCrossedEvent';
import type { MachineThresholdCrossingsSummary } from '../models/MachineThresholdCrossingsSummary';
import type { Notification } from '../models/Notification';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { SensorInstallEvent } from '../models/SensorInstallEvent';
import type { SensorInstallUpdate } from '../models/SensorInstallUpdate';
import type { SensorMHMReading } from '../models/SensorMHMReading';
import type { TimeSeriesListParameterInfo } from '../models/TimeSeriesListParameterInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MachineService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get machines
	 * Retrieve a list of machines the user is authorized to view.
	 * The returned list of machines can be filtered by various
	 * fields using optional query parameters. If more than one filter
	 * value is included the filtering is combined with an `and` statement
	 * meaning the returned traps must meet all filtering criteria.
	 * @returns any A paginated list of machines.
	 * @throws ApiError
	 */
	public getMachines({
		alarmState,
		alarmStateUserName,
		customerId,
		customerName,
		customerStatus,
		equipmentName,
		facility,
		hazardousLocationClassification,
		location,
		machineType,
		manufacturer,
		page = 1,
		pageSize = 50,
		sensorGatewaySerialNumber,
		sensorId,
		sensorLastAssociationGatewaySerial,
		sensorLastFirmwareVersion,
		sensorLastPartNumber,
		sensorLastSerialNumber,
		sensorMacAddress,
		sensorManufacturedFirmwareVersion,
		sensorProductName,
		sensorStatus,
		sensorInstalled,
		sortBy,
		sortDir,
	}: {
		/**
		 * Filter the machines returned by their current alarm state. Allowed values are `Good`, `Acknowledged`,
		 * and `Alarm`. Multiple values can be passed as a comma separated value (e.g.
		 * &alarmState=Good,Acknowledged).
		 */
		alarmState?: 'Good' | 'Acknowledged' | 'Alarm',
		/**
		 * Filter the machines returned by the alarmStateUserName property. The value is filtered using a `LIKE` comparison statement.
		 */
		alarmStateUserName?: string,
		/**
		 * Filter the machines returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customer.id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting machines does not have access to the customer.id passed, an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the machines returned by the customer name property. The value is filtered using a `LIKE` comparison statement.
		 */
		customerName?: string,
		/**
		 * Filter the machines returned based upon the customer status. Multiple values can be passed
		 * as a comma separated value (e.g. &customer.status=demo,production).
		 * The `customer.status` filter is generally only valid for super-admin users.
		 */
		customerStatus?: 'decommissioned' | 'demo' | 'production' | 'toBeInstalled',
		/**
		 * Filter the machines returned by the equipmentName property. The value is filtered using a `LIKE` comparison statement.
		 */
		equipmentName?: string,
		/**
		 * Filter the machines returned by the facility property. The value is filtered using a `LIKE` comparison statement.
		 */
		facility?: string,
		hazardousLocationClassification?: HazardousLocationClassification,
		/**
		 * Filter the machines returned by the location property. The value is filtered using a `LIKE` comparison statement.
		 */
		location?: string,
		/**
		 * Filter the machines returned by the machineType property. The value is filtered using a `LIKE` comparison statement.
		 */
		machineType?: string,
		/**
		 * Filter the machines returned by the manufacturer property. The value is filtered using a `LIKE` comparison statement.
		 */
		manufacturer?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the machines returned by the sensor gatewaySerialNumber property of the last reading. The value is filtered using a `LIKE` comparison statement.
		 */
		sensorGatewaySerialNumber?: string,
		/**
		 * Filter the machines returned by the sensor id. An exact match is used effectively limiting the number
		 * of machines that will be returned to one.
		 */
		sensorId?: string,
		/**
		 * Filter the machines returned by the sensor gateway serial of the last association using a `LIKE` comparison statement.
		 */
		sensorLastAssociationGatewaySerial?: string,
		/**
		 * Filter the machines returned by the sensor last firmware version using a `LIKE` comparison statement.
		 */
		sensorLastFirmwareVersion?: string,
		/**
		 * Filter the machines returned by the sensor last part number using a `LIKE` comparison statement.
		 */
		sensorLastPartNumber?: string,
		/**
		 * Filter the machines returned by the sensor last serial number using a `LIKE` comparison statement.
		 */
		sensorLastSerialNumber?: string,
		/**
		 * Filter the machines returned by the sensor mac address property. The value is filtered using a `LIKE` comparison statement.
		 */
		sensorMacAddress?: string,
		/**
		 * Filter the machines returned by the sensor manufactured firmware version using a `LIKE` comparison statement.
		 */
		sensorManufacturedFirmwareVersion?: string,
		/**
		 * Filter the machines returned by the sensor product name using a `LIKE` comparison statement.
		 */
		sensorProductName?: string,
		/**
		 * Filter the machines returned by the sensor status property. The value is filtered using a `LIKE` comparison statement.
		 */
		sensorStatus?: string,
		/**
		 * Filter the machines by if a sensor is currently installed or not. If set to `true` only machines with a
		 * sensor currently installed will be returned. If set to `false` only machines without a sensor will be
		 * returned.
		 */
		sensorInstalled?: boolean,
		/**
		 * The field to sort the results. If the value passed does not match any of the valid options or the `sortBy` query parameter is omitted
		 * the results will be returned sorted by sensor.lastReading in descending order.
		 */
		sortBy?: 'alarmState' | 'alarmStateTimestamp' | 'alarmStateUserName' | 'customer.name' | 'equipmentName' | 'facility' | 'id' | 'hazardousLocationClassification' | 'location' | 'machineType' | 'manufacturer' | 'sensor.xAxisMaxAcceleration' | 'sensor.yAxisMaxAcceleration' | 'sensor.zAxisMaxAcceleration' | 'sensor.gatewaySerialNumber' | 'sensor.id' | 'sensor.lastAssociation' | 'sensor.lastAssociationGatewaySerial' | 'sensor.lastCondensateTemp' | 'sensor.lastInfo' | 'sensor.lastInfoFirmwareVersion' | 'sensor.lastInfoPartNumber' | 'sensor.lastInfoSerialNumber' | 'sensor.lastReading' | 'sensor.macAddress' | 'sensor.manufacturedFirmwareVersion' | 'sensor.productName' | 'sensor.overallXVibration' | 'sensor.overallYVibration' | 'sensor.overallZVibration' | 'sensor.status',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<Machine>;
		paginationInfo?: PaginationInfo;
		listSummary?: {
			/**
			 * An object that shows the total number of each alarm state for machine.
			 */
			totalsByAlarmState?: {
				/**
				 * The number of machines with alarm state `Alarm`.
				 */
				Alarm: number;
				/**
				 * The number of machines with alarm state `Good`.
				 */
				Good: number;
				/**
				 * The number of machines with alarm state `Acknowledged`.
				 */
				Acknowledged: number;
			};
		};
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines',
			query: {
				'alarmState': alarmState,
				'alarmStateUserName': alarmStateUserName,
				'customer.id': customerId,
				'customer.name': customerName,
				'customer.status': customerStatus,
				'equipmentName': equipmentName,
				'facility': facility,
				'hazardousLocationClassification': hazardousLocationClassification,
				'location': location,
				'machineType': machineType,
				'manufacturer': manufacturer,
				'page': page,
				'pageSize': pageSize,
				'sensor.gatewaySerialNumber': sensorGatewaySerialNumber,
				'sensor.id': sensorId,
				'sensor.lastAssociationGatewaySerial': sensorLastAssociationGatewaySerial,
				'sensor.lastFirmwareVersion': sensorLastFirmwareVersion,
				'sensor.lastPartNumber': sensorLastPartNumber,
				'sensor.lastSerialNumber': sensorLastSerialNumber,
				'sensor.macAddress': sensorMacAddress,
				'sensor.manufacturedFirmwareVersion': sensorManufacturedFirmwareVersion,
				'sensor.productName': sensorProductName,
				'sensor.status': sensorStatus,
				'sensor.installed': sensorInstalled,
				'sortBy': sortBy,
				'sortDir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create machine
	 * Create a new machine
	 * @returns any A machine.
	 * @throws ApiError
	 */
	public postMachines({
		requestBody,
	}: {
		/**
		 * A machine to create (or update).
		 */
		requestBody?: MachinePost,
	}): CancelablePromise<{
		data?: Machine;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/machines',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get machine alarm status trend for a certain time range
	 * Retrieve a history of number of machines per status daily between a time range.
	 * @returns any The machine alarm status trend for a certain time range.
	 * @throws ApiError
	 */
	public getMachinesAlarmstatustrend({
		customerId,
		startTime,
		endTime,
	}: {
		/**
		 * Filter the machine alarm status trend data returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting machines does not have access to the customerId passed, an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * The UNIX timestamp of a start date for an event
		 */
		startTime?: number,
		/**
		 * The UNIX timestamp of an end date for an event
		 */
		endTime?: number,
	}): CancelablePromise<{
		data?: Array<MachineAlarmStatusTrend>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/alarmstatustrend',
			query: {
				'customerId': customerId,
				'startTime': startTime,
				'endTime': endTime,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get machines thresholdcrossings
	 * Retrieve a summary of total machine threshold crossings for all machines the user is authorized to view.
	 * The returned summary of machine threshold crossings can be filtered by different
	 * fields using optional query parameters.
	 * @returns any A summary of total machine threshold crossings for all machines of a customer.
	 * @throws ApiError
	 */
	public getMachinesThresholdcrossings({
		customerId,
		startTime,
		endTime,
		page = 1,
		pageSize = 50,
	}: {
		/**
		 * Filter the machine threshold crossings  returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting machines does not have access to the customerId passed, an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * The UNIX timestamp of a start date for an event (inclusive)
		 */
		startTime?: number,
		/**
		 * The UNIX timestamp of an end date for an event (inclusive)
		 */
		endTime?: number,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<MachineThresholdCrossingsSummary>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/thresholdcrossings',
			query: {
				'customerId': customerId,
				'startTime': startTime,
				'endTime': endTime,
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete machine
	 * Delete a machine. This operation is a "hard" delete of the machine and can not be reverted.
	 * Customer admin users are limited to deleting machines assigned to the same customer.
	 *
	 * If the machine has an installed sensor, the sensor will NOT be deleted as part of this operation.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteMachinesMachineId({
		machineId,
	}: {
		/**
		 * The machine id.
		 */
		machineId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/machines/{machineId}',
			path: {
				'machineId': machineId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get machine
	 * Get details for a machine using the Everactive machine ID.
	 * @returns any A machine.
	 * @throws ApiError
	 */
	public getMachinesMachineId({
		machineId,
	}: {
		/**
		 * The machine id.
		 */
		machineId: string,
	}): CancelablePromise<{
		data?: Machine;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/{machineId}',
			path: {
				'machineId': machineId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update machine
	 * Updates machine details. This endpoint is to update the machine properties and does not change customer association,
	 * install or uninstall a sensor, or update the machine alarm state.
	 * @returns any A machine.
	 * @throws ApiError
	 */
	public putMachinesMachineId({
		machineId,
		requestBody,
	}: {
		/**
		 * The machine id.
		 */
		machineId: string,
		/**
		 * A machine to update.
		 */
		requestBody?: Machine,
	}): CancelablePromise<{
		data?: Machine;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/machines/{machineId}',
			path: {
				'machineId': machineId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update machine customer association
	 * Set the customer association of the machine. If passed, the start timestamp is used as the effective start
	 * date. If not passed the current time is used as the start date of the association.
	 *
	 * If the machine was associated with another customer the old association will be deleted and replaced with
	 * the association passed in the request.
	 * @returns any A machine.
	 * @throws ApiError
	 */
	public putMachinesMachineIdCustomersCustomerId({
		machineId,
		customerId,
		requestBody,
	}: {
		/**
		 * The machine id.
		 */
		machineId: string,
		/**
		 * The id of the customer the machine is associated with.
		 */
		customerId: string,
		/**
		 * Details about associating a piece of equipment with a specific customer.
		 */
		requestBody?: CustomerAssociateDetail,
	}): CancelablePromise<{
		data?: Machine;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/machines/{machineId}/customers/{customerId}',
			path: {
				'machineId': machineId,
				'customerId': customerId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get machine history
	 * Retrieve a descending event date order list of events for a machine.
	 * @returns any A paginated list of machine historical events in descending date order.
	 * @throws ApiError
	 */
	public getMachinesMachineIdHistory({
		machineId,
		page = 1,
		pageSize = 50,
	}: {
		machineId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<MachineHistoryItem>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/{machineId}/history',
			path: {
				'machineId': machineId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create machine notification
	 * Create a new machine notification.
	 * @returns any Detail about a single notification.
	 * @throws ApiError
	 */
	public postMachinesMachineIdNotifications({
		machineId,
		requestBody,
	}: {
		/**
		 * The machine id.
		 */
		machineId: string,
		/**
		 * A machine notification to create.
		 */
		requestBody?: MachineNotificationCreate,
	}): CancelablePromise<{
		data?: Notification;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/machines/{machineId}/notifications',
			path: {
				'machineId': machineId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update machine sensor installation
	 * Install a sensor on a machine, or remove a sensor from a machine.
	 *
	 * If the `{sensorIdOrMac}` value in the path does not match the currently installed sensor
	 * on the machine the existing sensor installation will be ended with an `endTimestamp` matching
	 * the passed `startTimestamp`.
	 *
	 * If the `{sensorIdOrMac}` value matches the currently installed sensor on the machine, the installation
	 * event will be updated with the passed values. Specifically if an `endTimestamp` is passed the sensor will
	 * be "removed" from the machine by ending the sensor installation event.
	 *
	 * If the `{sensorIdOrMac}` value is installed on another piece of equipment an error will be returned. This endpoint
	 * will not "move" a sensor from one piece of equipment to another. The existing installation event must be ended before
	 * a sensor can be installed on a new piece of equipment.
	 * @returns any A sensor installation event.
	 * @throws ApiError
	 */
	public putMachinesMachineIdSensorsSensorIdOrMacInstall({
		machineId,
		sensorIdOrMac,
		requestBody,
	}: {
		/**
		 * The machine id.
		 */
		machineId: string,
		/**
		 * The id or MAC address of the sensor being installed or removed from the machine.
		 */
		sensorIdOrMac: string,
		/**
		 * A request to install a sensor on a piece of equipment or to end an existing installation.
		 */
		requestBody?: SensorInstallUpdate,
	}): CancelablePromise<{
		data?: SensorInstallEvent;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/machines/{machineId}/sensors/{sensorIdOrMac}',
			path: {
				'machineId': machineId,
				'sensorIdOrMac': sensorIdOrMac,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create or update a machine threshold crossed event
	 * Create a new machine threshold crossing event or update an existing event.
	 *
	 * For an `eventDirection` of "out" a new event is created if the `limitType` and `metricName` combination
	 * does not already have an open event for the machine. If an open event already exists, the existing event is returned.
	 * For outbound breach events the machine will be set to an alarm state of "alarm" if it is in another alarm state.
	 *
	 * For an `eventDirection` of "in" the open event for the `limitType` and `metricName` combination is found and
	 * ended by updating the inbound measured value and the end timestamp of the event. If no open event is found the
	 * posted event will return an error response.
	 * @returns any A machine threshold crossed event.
	 * @throws ApiError
	 */
	public postMachinesMachineIdThresholdcrossings({
		machineId,
		requestBody,
	}: {
		machineId: string,
		/**
		 * Machine threshold breach details to either create a new event (outbound) or end an existsing event (inbound).
		 */
		requestBody?: MachineHealthThresholdBreachDetail,
	}): CancelablePromise<{
		data?: MachineThresholdCrossedEvent;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/machines/{machineId}/thresholdcrossings',
			path: {
				'machineId': machineId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get machine timeseries
	 * Retrieve a list of Eversensor readings for a machine. This endpoint is intended
	 * to provide data for a user to view. It is not intended as a method to download
	 * large volumes of sensor reading data. As such, the maximum number of data points that
	 * will be returned from this endpoint is 5000.
	 *
	 * Readings will only be provided for time periods the machine has a sensor installed. If the `startTime`
	 * requested is prior to any sensors installed it will be adjusted to match the earliest possible
	 * sensor reading. If the `endTime` provided is after any sensors installed it will be adjusted to
	 * match the last possible sensor reading.
	 *
	 * If the requested time period will produce a payload that exceeds the maximum it will
	 * behave in one of two ways:
	 *
	 * If a `downsampleRate` has been provided an error will be returned to the client explaining
	 * the requested parameters will exceed the maximum.
	 *
	 * If no `downsampleRate` is specified the server will calculate a `downsampleRate` based upon default
	 * sensor settings that results in a result set close to the maximum number of data points. If a sensor
	 * is reporting with a non-default period (due to setup or environmental conditions) the actual number
	 * of returned sensor readings may deviate from the stated maximum.
	 *
	 * The response payload will contain both the data and a summary of the start and end dates, the
	 * returned item count and the `downsampleRate` used.
	 *
	 * The expected number of sensor readings is based upon a sensor returning a reading every minute,
	 * resulting in 1440 sensor readings per 24 hour period.
	 * @returns any A list of MHM sensor readings limited to 5000 data points.
	 * @throws ApiError
	 */
	public getMachinesMachineIdTimeseries({
		machineId,
		startTime,
		endTime,
		downsampleRate,
	}: {
		machineId: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<SensorMHMReading>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/{machineId}/timeseries',
			path: {
				'machineId': machineId,
			},
			query: {
				'startTime': startTime,
				'endTime': endTime,
				'downsampleRate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get a list of all the available machine FTWF readings for a single machine for a for a specified time period
	 * Retrieve a list of FTWF change in descending data order for a single machine for
	 * a specific time period.
	 * @returns any A summary of total full time waveforms readings for an asset.
	 * @throws ApiError
	 */
	public getMachinesMachineIdTimeWaveforms({
		machineId,
		sensorId,
		sensorMacAddress,
		startTime,
		endTime,
		sortDir,
		page = 1,
		pageSize = 50,
	}: {
		machineId: string,
		/**
		 * Filter the FTWFs returned by the sensor id.
		 */
		sensorId?: string,
		/**
		 * Filter the FTWFs returned by the sensor mac address property. The value is filtered using a `LIKE` comparison statement.
		 */
		sensorMacAddress?: string,
		/**
		 * The UNIX timestamp of a start date range for a FTWF reading
		 */
		startTime?: number,
		/**
		 * The UNIX timestamp of an end date range for a FTWF reading
		 */
		endTime?: number,
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<FullTimeWaveform>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/{machineId}/timewaveforms',
			path: {
				'machineId': machineId,
			},
			query: {
				'sensor.id': sensorId,
				'sensor.macAddress': sensorMacAddress,
				'startTime': startTime,
				'endTime': endTime,
				'sortDir': sortDir,
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get a single machine FTWF reading for a single machine
	 * Retrieve a single FTWF reading for a single machine.
	 * @returns any A time waveform reading.
	 * @throws ApiError
	 */
	public getMachinesMachineIdTimeWaveformsWaveformId({
		machineId,
		waveformId,
	}: {
		machineId: string,
		waveformId: string,
	}): CancelablePromise<{
		data?: FullTimeWaveform;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/{machineId}/timewaveforms/{waveformId}',
			path: {
				'machineId': machineId,
				'waveformId': waveformId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
