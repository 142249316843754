/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { SteamTrapEventEnd } from '../models/SteamTrapEventEnd';
import type { SteamTrapFailureEvent } from '../models/SteamTrapFailureEvent';
import type { SteamTrapPredictedFailureEvent } from '../models/SteamTrapPredictedFailureEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SteamTrapFailureService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get failure events
	 * Retrieve a list of failures for a steam trap.
	 * @returns any A list of failures.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapidFailures({
		trapId,
		minStartDateInclusive,
		maxEndDateInclusive,
		openOnly,
		page = 1,
		pageSize = 50,
	}: {
		trapId: string,
		/**
		 * The minimum failure event date to retrieve as a unix timestamp.
		 */
		minStartDateInclusive?: number,
		/**
		 * The maximum failure event date to retrieve as a unix timestamp.
		 */
		maxEndDateInclusive?: number,
		openOnly?: boolean,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<SteamTrapFailureEvent>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/failures',
			path: {
				'trapId': trapId,
			},
			query: {
				'minStartDateInclusive': minStartDateInclusive,
				'maxEndDateInclusive': maxEndDateInclusive,
				'openOnly': openOnly,
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get predicted failure events
	 * Retrieve a list of predicted failures for a steam trap.
	 * @returns any A list of predicted failures.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapidPredictedFailures({
		trapId,
		minStartDateInclusive,
		maxEndDateInclusive,
		openOnly,
		page = 1,
		pageSize = 50,
	}: {
		trapId: string,
		/**
		 * The minimum failure event date to retrieve as a unix timestamp.
		 */
		minStartDateInclusive?: number,
		/**
		 * The maximum failure event date to retrieve as a unix timestamp.
		 */
		maxEndDateInclusive?: number,
		openOnly?: boolean,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<SteamTrapPredictedFailureEvent>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/predicted-failures',
			path: {
				'trapId': trapId,
			},
			query: {
				'minStartDateInclusive': minStartDateInclusive,
				'maxEndDateInclusive': maxEndDateInclusive,
				'openOnly': openOnly,
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create predicted failure event
	 * Create a new predicted failure event for the steam trap.
	 * @returns any A steam trap predicted failure event.
	 * @throws ApiError
	 */
	public postSteamTrapsTrapidPredictedFailures({
		trapId,
		requestBody,
	}: {
		trapId: string,
		/**
		 * Create or update a steam trap predicted failure event.
		 */
		requestBody?: SteamTrapPredictedFailureEvent,
	}): CancelablePromise<{
		data?: SteamTrapPredictedFailureEvent;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/predicted-failures',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * partially update predicted failure
	 * Update the predicted failure. Any fields that are not included in the body will
	 * not be updated.
	 * @returns any A steam trap predicted failure event.
	 * @throws ApiError
	 */
	public patchSteamTrapsTrapidPredictedFailuresPredictedFailureId({
		trapId,
		predictedFailureId,
		requestBody,
	}: {
		trapId: string,
		predictedFailureId: string,
		/**
		 * Create or update a steam trap predicted failure event.
		 */
		requestBody?: SteamTrapPredictedFailureEvent,
	}): CancelablePromise<{
		data?: SteamTrapPredictedFailureEvent;
	}> {
		return this.httpRequest.request({
			method: 'PATCH',
			url: '/steamtraps/{trapId}/predicted-failures/{predictedFailureId}',
			path: {
				'trapId': trapId,
				'predictedFailureId': predictedFailureId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * set predicted failure event end time
	 * Set the end timestamp property of an existing predicted failure event.
	 * @returns any A steam trap predicted failure event.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdPredictedFailurePredictedFailureIdEndTime({
		trapId,
		predictedFailureId,
		requestBody,
	}: {
		trapId: string,
		predictedFailureId: string,
		/**
		 * Set the end timestamp of an existing steam trap event.
		 */
		requestBody?: SteamTrapEventEnd,
	}): CancelablePromise<{
		data?: SteamTrapPredictedFailureEvent;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/predicted-failures/{predictedFailureId}/end-time',
			path: {
				'trapId': trapId,
				'predictedFailureId': predictedFailureId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
