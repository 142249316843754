/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class HealthService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get system health
	 * Ping this endpoint to check if the Everactive Data Services API is healthy.
	 * @returns any API is healthy and working properly.
	 * @throws ApiError
	 */
	public getHealth(): CancelablePromise<{
		/**
		 * True if the API is up and working properly.
		 */
		ok?: boolean;
		/**
		 * The current deployed version of the API.
		 */
		version?: string;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/health',
			errors: {
				400: `API is unhealthy. Check application logs to determine cause of problem.`,
			},
		});
	}

}
