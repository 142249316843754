/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { WebhookSubscription } from '../models/WebhookSubscription';
import type { WebhookSubscriptionUpdate } from '../models/WebhookSubscriptionUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebhooksService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * create webhook
	 * Create an HTTP webhook subscription.
	 * @returns any Retrieve the configuration of a webhook subscription.
	 * @throws ApiError
	 */
	public postWebhooks({
		requestBody,
	}: {
		/**
		 * Create a new webhook subscription.
		 */
		requestBody?: WebhookSubscription,
	}): CancelablePromise<{
		data?: WebhookSubscription;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/webhooks',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get webhooks
	 * Retrieve the configuration of multiple webhook subscriptions.
	 * @returns any Retrieve the configuration of multiple webhook subscriptions.
	 * @throws ApiError
	 */
	public getWebhooks({
		customerId,
		customerName,
		enabled,
		callbackUrl,
		eventType,
		page = 1,
		pageSize = 50,
		sortBy,
		sortDir,
	}: {
		/**
		 * Filter the webhook subscriptions returned by the customer id.  It is possible to pass
		 * multiple customer-id query parameters as a comma separated value (e.g. `&customer-id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting the webhook list does not have access to the customer-id passed, an error will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the webhook subscriptions using a `LIKE` comparison with the customer name property.
		 */
		customerName?: string,
		/**
		 * Filter the webhook subscriptions by their enabled status. Default behavior returns webhook subscriptions regardless of their enabled status.
		 */
		enabled?: string,
		/**
		 * Filters the webhook subscriptions using a `LIKE` comparison with the webhook's callback URL property.
		 */
		callbackUrl?: string,
		/**
		 * Filter the webhook subscriptions by their event type.
		 * A case-insensitive database match string.
		 * Returns all gateways whose event-type value matches this string.
		 */
		eventType?: 'sensor_reading' | 'gateway_status',
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * The field to use for the sort order in the returned list of webhook subscriptions.
		 */
		sortBy?: 'customer-name' | 'callback-url' | 'enabled',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<WebhookSubscription>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/webhooks',
			query: {
				'customer-id': customerId,
				'customer-name': customerName,
				'enabled': enabled,
				'callback-url': callbackUrl,
				'event-type': eventType,
				'page': page,
				'page-size': pageSize,
				'sort-by': sortBy,
				'sort-dir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get webhook
	 * Retrieve the configuration of a single webhook subscription.
	 * @returns any Retrieve the configuration of a webhook subscription.
	 * @throws ApiError
	 */
	public getWebhook({
		webhookId,
	}: {
		/**
		 * The ID of the webhook subscription.
		 */
		webhookId: string,
	}): CancelablePromise<{
		data?: WebhookSubscription;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/webhooks/{webhookId}',
			path: {
				'webhookId': webhookId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * update webhook
	 * Update an existing webhook subscription.
	 * @returns any Retrieve the configuration of a webhook subscription.
	 * @throws ApiError
	 */
	public updateWebhook({
		webhookId,
		requestBody,
	}: {
		/**
		 * The ID of the webhook subscription.
		 */
		webhookId: string,
		/**
		 * Update an existing webhook subscription.
		 */
		requestBody?: WebhookSubscriptionUpdate,
	}): CancelablePromise<{
		data?: WebhookSubscription;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/webhooks/{webhookId}',
			path: {
				'webhookId': webhookId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * partial update webhook
	 * Update an existing webhook subscription. Partial body is allowed.
	 * @returns any Retrieve the configuration of a webhook subscription.
	 * @throws ApiError
	 */
	public partialUpdateWebhook({
		webhookId,
		requestBody,
	}: {
		/**
		 * The ID of the webhook subscription.
		 */
		webhookId: string,
		/**
		 * Update an existing webhook subscription.
		 */
		requestBody?: WebhookSubscriptionUpdate,
	}): CancelablePromise<{
		data?: WebhookSubscription;
	}> {
		return this.httpRequest.request({
			method: 'PATCH',
			url: '/webhooks/{webhookId}',
			path: {
				'webhookId': webhookId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete webhook
	 * Delete a webhook subscription.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteWebhook({
		webhookId,
	}: {
		/**
		 * The ID of the webhook subscription.
		 */
		webhookId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/webhooks/{webhookId}',
			path: {
				'webhookId': webhookId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
