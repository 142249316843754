/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MinMaxFloatThreshold } from './MinMaxFloatThreshold';

/**
 * FFT threshold values object for a machine
 */
export type MachineHealthFFTThresholds = {
	readonly lastUpdateTimestamp?: number;
	/**
	 * The type of magnitude measured for the fft thresholds. Valid options are `velocity` (default)
	 * and `acceleration`.
	 */
	magnitudeType?: MachineHealthFFTThresholds.magnitudeType;
	/**
	 * The units the threshold is designated in. Options for velocity are `mm/s` (millimeters per second)
	 * and `ips` (inches per second). Options for acceleration are `mm/s/s` (millimeters per second squared)
	 * and `in/s/s` (inches per second squared).
	 */
	thresholdUnit?: MachineHealthFFTThresholds.thresholdUnit;
	xHighFrequencyBand?: MinMaxFloatThreshold;
	xLowFrequencyBand?: MinMaxFloatThreshold;
	xMidFrequencyBand?: MinMaxFloatThreshold;
	yHighFrequencyBand?: MinMaxFloatThreshold;
	yLowFrequencyBand?: MinMaxFloatThreshold;
	yMidFrequencyBand?: MinMaxFloatThreshold;
	zHighFrequencyBand?: MinMaxFloatThreshold;
	zLowFrequencyBand?: MinMaxFloatThreshold;
	zMidFrequencyBand?: MinMaxFloatThreshold;
};

export namespace MachineHealthFFTThresholds {

	/**
	 * The type of magnitude measured for the fft thresholds. Valid options are `velocity` (default)
	 * and `acceleration`.
	 */
	export enum magnitudeType {
		ACCELERATION = 'acceleration',
		VELOCITY = 'velocity',
	}

	/**
	 * The units the threshold is designated in. Options for velocity are `mm/s` (millimeters per second)
	 * and `ips` (inches per second). Options for acceleration are `mm/s/s` (millimeters per second squared)
	 * and `in/s/s` (inches per second squared).
	 */
	export enum thresholdUnit {
		MM_S = 'mm/s',
		IPS = 'ips',
		MM_S_S = 'mm/s/s',
		IN_S_S = 'in/s/s',
	}


}

