/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MinMaxFloatThreshold } from './MinMaxFloatThreshold';

export type MachineHealthThresholds = {
	/**
	 * The type of magnitude measured for the fft thresholds. Valid options are `velocity` (default)
	 * and `acceleration`.
	 */
	energyMagnitudeType?: MachineHealthThresholds.energyMagnitudeType;
	/**
	 * The units the threshold is designated in. Options for velocity are `mm/s` (millimeters per second)
	 * and `ips` (inches per second). Options for acceleration are `mm/s/s` (millimeters per second squared)
	 * and `in/s/s` (inches per second squared).
	 */
	energyThresholdUnit?: MachineHealthThresholds.energyThresholdUnit;
	humidity?: MinMaxFloatThreshold;
	readonly lastUpdateTimestamp?: number;
	tempAmbient?: MinMaxFloatThreshold;
	tempDelta?: MinMaxFloatThreshold;
	tempTeg?: MinMaxFloatThreshold;
	temperatureUnit?: MachineHealthThresholds.temperatureUnit;
	xEnergy?: MinMaxFloatThreshold;
	yEnergy?: MinMaxFloatThreshold;
	zEnergy?: MinMaxFloatThreshold;
};

export namespace MachineHealthThresholds {

	/**
	 * The type of magnitude measured for the fft thresholds. Valid options are `velocity` (default)
	 * and `acceleration`.
	 */
	export enum energyMagnitudeType {
		ACCELERATION = 'acceleration',
		VELOCITY = 'velocity',
	}

	/**
	 * The units the threshold is designated in. Options for velocity are `mm/s` (millimeters per second)
	 * and `ips` (inches per second). Options for acceleration are `mm/s/s` (millimeters per second squared)
	 * and `in/s/s` (inches per second squared).
	 */
	export enum energyThresholdUnit {
		MM_S = 'mm/s',
		IPS = 'ips',
		MM_S_S = 'mm/s/s',
		IN_S_S = 'in/s/s',
	}

	export enum temperatureUnit {
		CELSIUS = 'Celsius',
		FAHRENHEIT = 'Fahrenheit',
		KELVIN = 'Kelvin',
	}


}

