/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Gateway } from '../models/Gateway';
import type { GatewayDailyStatusV3 } from '../models/GatewayDailyStatusV3';
import type { GatewayDeploymentStatus } from '../models/GatewayDeploymentStatus';
import type { GatewayEvernet1Settings } from '../models/GatewayEvernet1Settings';
import type { GatewayEvernet2Settings } from '../models/GatewayEvernet2Settings';
import type { GatewayStatusV1 } from '../models/GatewayStatusV1';
import type { GatewayStatusV3 } from '../models/GatewayStatusV3';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { TimeSeriesListParameterInfo } from '../models/TimeSeriesListParameterInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EvergatewaysService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get gateways
	 * Retrieve a list of gateways in your installation.
	 * @returns any A list of gateways.
	 * @throws ApiError
	 */
	public getGateways({
		customerId,
		customerName,
		deploymentStatus,
		flavor,
		id,
		location,
		name,
		page = 1,
		pageSize = 50,
		serialNumber,
		softwareVersion,
		sortBy = 'last-reading',
		sortDir,
		status,
		unpaired,
	}: {
		/**
		 * Filter the gateways returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customer-id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting the gateway list does not have access to the customer.id passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the gateways by the customer name property.
		 */
		customerName?: string,
		/**
		 * Filter the gateways by the deploymentStatus property.
		 * A case-insensitive database match string.
		 * Returns all gateways whose deploymentStatus value matches this string.
		 */
		deploymentStatus?: string,
		flavor?: 'production' | 'virtual',
		/**
		 * The ID of a specific gateway to return. An exact match is used for this filter.
		 */
		id?: string,
		/**
		 * Filter the returned Evergateways by the location property.
		 * A case-insensitive database match string.
		 * Returns all gateways whose location value matches this string.
		 */
		location?: string,
		/**
		 * Filter the returned Evergateways by the name property.
		 * A case-insensitive database match string.
		 * Returns all gateways whose name value matches this string.
		 */
		name?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the returned Evergateways by the serialNumber property.
		 * A case-insensitive database match string.
		 * Returns all gateways whose serialNumber value matches this string.
		 */
		serialNumber?: string,
		/**
		 * Filter the returned Evergateways by the softwareVersion property.
		 * A case-insensitive database match string.
		 * Returns all gateways whose softwareVersion value matches this string.
		 */
		softwareVersion?: string,
		/**
		 * The field by which to sort the returned list of gateways.
		 * If omitted, the list is in descending sort order by the lastReading timestamp.
		 */
		sortBy?: 'can-support-evernet2' | 'config-schema' | 'customer-name' | 'deployment-status' | 'enable-ssh' | 'enable-wakeup' | 'evernet1-settings-enabled' | 'evernet2-settings-beacon-offset' | 'evernet2-settings-pan-id' | 'flavor' | 'id' | 'last-daily-status' | 'last-reading' | 'last-status' | 'location' | 'name' | 'serial-number' | 'software-version' | 'status',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
		/**
		 * Filter the gateways by the status property.
		 * A case-insensitive database match string.
		 * Returns all gateways whose status value matches this string.
		 */
		status?: string,
		/**
		 * When true, filters the list of gateways returned to only include gateways
		 * that have not been paired. This flag is only available to super-admin users. If passed for other
		 * users it will be ignored.
		 *
		 * If omitted or set to false the list of gateways returned does not include any unpaired gateways.
		 */
		unpaired?: boolean,
	}): CancelablePromise<{
		data?: Array<Gateway>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/evergateways',
			query: {
				'customer-id': customerId,
				'customer-name': customerName,
				'deployment-status': deploymentStatus,
				'flavor': flavor,
				'id': id,
				'location': location,
				'name': name,
				'page': page,
				'page-size': pageSize,
				'serial-number': serialNumber,
				'software-version': softwareVersion,
				'sort-by': sortBy,
				'sort-dir': sortDir,
				'status': status,
				'unpaired': unpaired,
			},
			errors: {
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get gateway
	 * Retrieve configuration information for a specific gateway.
	 * @returns any A gateway.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumber({
		gatewayIdOrSerialNumber,
	}: {
		/**
		 * The serial number or unique ID of the Evergateway.
		 */
		gatewayIdOrSerialNumber: string,
	}): CancelablePromise<{
		data?: Gateway;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/evergateways/{gatewayIdOrSerialNumber}',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update gateway
	 * Update configuration information for a specific gateway.
	 * @returns any The gateway was successfully updated.
	 * @throws ApiError
	 */
	public putGatewaysGatewayIdOrSerialNumber({
		gatewayIdOrSerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number or unique ID of the Evergateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * A subset of gateway properties to update.
		 */
		requestBody?: {
			/**
			 * This flag is no longer used to designate how sensors are authorized on gateways. The value
			 * will always be set to true regardless of the value passed in the update body.
			 * @deprecated
			 */
			autoAuthorizeExistingSensors?: boolean;
			deploymentStatus?: GatewayDeploymentStatus;
			/**
			 * Indicates if SSH is enabled on the gateway.
			 */
			enableSSH?: boolean;
			/**
			 * Indicates if the Evergateway will ask Eversensors
			 * to turn on and send data. If disabled, no data will be captured.
			 */
			enableWakeup?: boolean;
			evernet1Settings?: GatewayEvernet1Settings;
			evernet2Settings?: GatewayEvernet2Settings;
			/**
			 * Notes about the Evergateway installation.
			 */
			installNotes?: string;
			/**
			 * The location of the Evergateway.
			 */
			location?: string;
			/**
			 * The name of the gateway. Typically set to the serial number.
			 */
			name?: string;
		},
	}): CancelablePromise<any> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/evergateways/{gatewayIdOrSerialNumber}',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get gateway daily status v3 series
	 * Retrieve a list of v3 daily status messages for a gateway, suitable for users to view.
	 *
	 * This call is not intended for downloading large volumes of data.
	 * It returns a maximum of 5000 data points.
	 *
	 * To control the amount of data to collect, set the date range and downsample rate.
	 * * If the requested time period and downsample rate will produce a payload that exceeds the maximum of 5000 data points,
	 * the body of the response contains an error message.
	 * * If the request does not specify a downsample rate, the call calculates a rate that results in a number of data points close to the maximum.
	 * The expected number of daily status messages assumes 1 daily status message every 24 hours. (1 day=24 hours=86400 seconds)
	 *
	 * On success, the body of the response contains a summary of the start and end times,
	 * the returned item count, and the downsample rate used, in addition to the status data.
	 * The status data is very large, including a process list that describes thousands of processes.
	 * @returns any A list of v3 daily gateway status messages.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberStatusV3Daily({
		gatewayIdOrSerialNumber,
		startTime,
		endTime,
		downsampleRate,
	}: {
		/**
		 * The serial number or unique ID of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<GatewayDailyStatusV3>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/evergateways/{gatewayIdOrSerialNumber}/status/v3/daily',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'start-time': startTime,
				'end-time': endTime,
				'downsample-rate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get gateway status v3 series
	 * Retrieve a list of v3 status messages for a gateway, suitable for users to view.
	 *
	 * This call  is not intended for downloading large volumes of data.
	 * It returns a maximum of 5000 data points.
	 *
	 * To control the amount of data to collect, set the date range and downsample rate.
	 * * If the requested time period and downsample rate will produce a payload that exceeds the maximum of 5000 data points,
	 * the body of the response contains an error message.
	 * * If the request does not specify a  downsample rate, the call calculates a rate that results in a number of data points close to the maximum.
	 * The expected number of status messages assumes 1 status message every 5 minutes.
	 *
	 * On success, the body of the response contains both the data and a summary of the start and end dates, the
	 * returned item count, and the downsampleRate used.
	 * @returns any A list of v3 gateway status messages.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberStatusV3({
		gatewayIdOrSerialNumber,
		startTime,
		endTime,
		downsampleRate,
	}: {
		/**
		 * The serial number or unique ID of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<GatewayStatusV3>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/evergateways/{gatewayIdOrSerialNumber}/status/v3',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'start-time': startTime,
				'end-time': endTime,
				'downsample-rate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get gateway status v1 series
	 * Retrieve a list of v1 status messages for a gateway, suitable for users to view.
	 *
	 * This call  is not intended for downloading large volumes of data.
	 * It returns a maximum of 5000 data points.
	 *
	 * You can control the amount of data to collect by setting the date range and downsample rate.
	 * * If the requested time period and downsample rate will produce a payload that exceeds the maximum of 5000 data points,
	 * the body of the response contains an error message.
	 * * If the request does not specify a  downsample rate, the call calculates a rate that results in a number of data points close to the maximum.
	 * The expected number of status messages assumes 1 status message every 5 minutes.
	 *
	 * On success, the body of the response contains both the data and a summary of the start and end dates, the
	 * returned item count, and the downsampleRate used.
	 * @returns any A list of v1 gateway status messages.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberStatusV1({
		gatewayIdOrSerialNumber,
		startTime,
		endTime,
		downsampleRate,
	}: {
		/**
		 * The serial number or unique ID of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<GatewayStatusV1>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/evergateways/{gatewayIdOrSerialNumber}/status/v1',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'start-time': startTime,
				'end-time': endTime,
				'downsample-rate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
