export function arrayToObject<
  ArrayElementType,
  ObjectValueType = ArrayElementType
>(
  array: ArrayElementType[],
  keySelector: (element: ArrayElementType) => string,
  valueSelector?: (element: ArrayElementType) => ObjectValueType
): Record<string, ObjectValueType> {
  return array.reduce(
    (map, element) => ({
      ...map,
      [keySelector(element)]: valueSelector ? valueSelector(element) : element
    }),
    {}
  );
}
