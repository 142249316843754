import {
  Sensor as ApiSensor,
  SensorInstallEvent as ApiSensorInstallEvent,
  SensorReading as ApiSensorReading,
  SensorStatus as ApiSensorStatus
} from '@/client/api'
import { CustomerMinimal } from './customer'

export class MagnitudeReadingTypes {
  public rawUnits: string
  public rawValue: number
  public ips?: number
  public mms?: number
  public mg?: number
  public microTeslas?: number
}

export class SpectralPeaksMeasurement {
  public frequency?: number
  public frequencyCpm?: number
  public magnitude?: MagnitudeReadingTypes
}

export class SpectralPeaks {
  public xaxis?: SpectralPeaksMeasurement[] = null
  public yaxis?: SpectralPeaksMeasurement[] = null
  public zaxis?: SpectralPeaksMeasurement[] = null

  constructor(peaks?: Partial<SpectralPeaks>) {
    if (peaks) {
      const cleanMetrics = JSON.parse(JSON.stringify(peaks))
      Object.assign(this, cleanMetrics)
    }
  }
}

export class AccelerationVelocityReadings {
  public configurationMetadata?: { sampleRate: number }
  public maxLevels?: {
    xAxisMaxLevel?: MagnitudeReadingTypes
    yAxisMaxLevel?: MagnitudeReadingTypes
    zAxisMaxLevel?: MagnitudeReadingTypes
  }
  public overallLevels?: {
    maxLevels: any
    xAxisOverall?: number
    yAxisOverall?: number
    zAxisOverall?: number
  }
  public spectralPeaks?: SpectralPeaks

  constructor(metrics?: Partial<AccelerationVelocityReadings>) {
    if (metrics) {
      const cleanMetrics = JSON.parse(JSON.stringify(metrics))
      Object.assign(this, cleanMetrics)
    }
  }
}

export class AccelerationVelocityMetrics {
  public accelerationMetrics?: AccelerationVelocityReadings
  public velocityMetrics?: AccelerationVelocityReadings

  constructor(metrics?: Partial<AccelerationVelocityMetrics>) {
    if (metrics) {
      const cleanMetrics = JSON.parse(JSON.stringify(metrics))
      Object.assign(this, cleanMetrics)

      if (metrics.accelerationMetrics) {
        this.accelerationMetrics = new AccelerationVelocityReadings(metrics.accelerationMetrics)
      }

      if (metrics.velocityMetrics) {
        this.velocityMetrics = new AccelerationVelocityReadings(metrics.velocityMetrics)
      }
    }
  }
}

export class SensorSTMMetrics {
  public tempCondensate?: number
  public tempSteam?: number

  constructor(stmMetrics?: Partial<SensorSTMMetrics>) {
    if (stmMetrics) {
      const cleanMetrics = JSON.parse(JSON.stringify(stmMetrics))
      Object.assign(this, cleanMetrics)
    }
  }
}

export class SensorMHMMetrics {
  public tempTeg?: number = null
  public vibrationMetrics?: AccelerationVelocityMetrics = null
  public electricalMetrics?: AccelerationVelocityMetrics = null

  constructor(mhmMetrics?: Partial<SensorMHMMetrics>) {
    if (mhmMetrics) {
      const cleanMetrics = JSON.parse(JSON.stringify(mhmMetrics))
      Object.assign(this, cleanMetrics)

      if (mhmMetrics.vibrationMetrics) {
        this.vibrationMetrics = new AccelerationVelocityMetrics(mhmMetrics.vibrationMetrics)
      }

      if (mhmMetrics.electricalMetrics) {
        this.electricalMetrics = new AccelerationVelocityMetrics(mhmMetrics.electricalMetrics)
      }
    }
  }
}

export interface SensorInstall {
  macAddress: string
  currentInstallStartTimestamp?: number
}

export interface ISensorInstallEvent extends ApiSensorInstallEvent {}

export enum InstallationType {
  steamTrap = 'steam_trap',
  machineHealth = 'machine_health'
}

export interface ISensor extends ApiSensor {
  customerId?: string
  installationNotes?: string
}

export class Sensor implements ISensor {
  public customerId: string
  public id: string
  public macAddress = ''
  public serialNumber?: string
  public currentInstallation?: ISensorInstallEvent
  public devkitBundled = false
  public type = ApiSensor.type.AVAILABLE
  public location?: string
  public facility?: string
  public serviceTag?: string
  public status?: ApiSensorStatus
  public timeseriesData?: any
  public customer?: CustomerMinimal | null
  public downSampledRSSI?: number
  public gatewaySerialNumber?: string
  public lastReading?: SensorReading | null
  public gatewayLastReading?: SensorReading | null
  public currentInstallEntityId?: string
  public currentInstallEntityType?: string | null
  public currentInstallStartTimestamp?: number
  public authorized?: boolean
  public installTimestamp?: number
  public installationNotes?: string

  constructor(sensor?: Partial<ISensor>) {
    if (sensor) {
      const cleanSensor = JSON.parse(JSON.stringify(sensor))
      Object.assign(this, cleanSensor)

      if (cleanSensor.customer) {
        this.customer = new CustomerMinimal(cleanSensor.customer)
      }
    }
    this.customerId = this.id
  }

  get currentInstallationEntityType() {
    const typeMap = {
      steam_trap: 'Steam Trap',
      machine_health: 'Machine'
    }
    return typeMap[this.currentInstallation?.installEntityType] || ''
  }

  get customerForSensor() {
    return this.currentInstallation?.installEntityCustomer?.name || 'None'
  }
}

export function getEntityRouterLink(sensor: Sensor) {
  if (!sensor.currentInstallation) {
    return null
  }
  if (
    sensor.currentInstallation.installEntityType === ApiSensorInstallEvent.installEntityType.MACHINE
  ) {
    return {
      name: 'Machine',
      params: {
        machineId: sensor.currentInstallation.installEntityId
      }
    }
  } else if (
    sensor.currentInstallation.installEntityType ===
    ApiSensorInstallEvent.installEntityType.STEAM_TRAP
  ) {
    return {
      name: 'Steam Trap Detail',
      params: {
        steamTrapId: sensor.currentInstallation.installEntityId
      }
    }
  }
  return null
}

export class SensorInstallation {
  public endTimestamp?: number
  public installId?: string
  public objectId: string
  public objectTypeId?: string
  public sensorId?: string
  public startTimestamp?: number

  constructor(sensorInstallation?: Partial<SensorInstallation>) {
    if (sensorInstallation) {
      const cleanSensorInstallation = JSON.parse(JSON.stringify(sensorInstallation))
      Object.assign(this, cleanSensorInstallation)
    }
  }

  get endDateTime(): Date | null {
    if (this.endTimestamp) {
      const endDate = new Date()
      endDate.setTime(this.endTimestamp)
      return endDate
    }
    return null
  }

  get startDateTime(): Date | null {
    if (this.startTimestamp) {
      const startDate = new Date()
      startDate.setTime(this.startTimestamp)
      return startDate
    }
    return null
  }
}

export interface ISensorReading extends ApiSensorReading {}

export class SensorReading implements ISensorReading {
  public gatewayId?: string
  public gatewayRSSI?: number
  public gatewaySerialNumber: string
  public scap?: number
  public rssiUplink?: number
  public tempAmbient?: number
  public timestamp: number
  public vcap?: number
  public humidity?: number = null
  public macAddress: string = null
  public utcDate: string = null

  public mhmMetrics?: SensorMHMMetrics | null = null
  public stmMetrics?: SensorSTMMetrics | null = null
  public probeState?: number = null

  constructor(reading?: Partial<SensorReading>) {
    if (reading) {
      const cleanReading = JSON.parse(JSON.stringify(reading))
      Object.assign(this, cleanReading)

      if (reading.mhmMetrics) {
        this.mhmMetrics = new SensorMHMMetrics(reading.mhmMetrics)
      }

      if (reading.stmMetrics) {
        this.stmMetrics = new SensorSTMMetrics(reading.stmMetrics)
      }
    }
  }
}
