import { EveractiveApi } from "./everactiveApi";
import { EveractiveApiResponse } from "./everactiveApiResponse";
import { SensorService, SensorInList } from "./api";
import { Sensor } from "@/types";

export class SensorsApi {
  public static everactiveApi = new EveractiveApi().client.sensor;

  public static async getSensors(
    queryParams: Parameters<SensorService["getSensors"]>[0]
  ): Promise<EveractiveApiResponse<SensorInList[]>> {

    try {
      const response = await this.everactiveApi.getSensors(queryParams)
      return response
    } catch (e) {
      return null
    }
  }

  public static async getAvailableSensors({
    customerId,
    macAddress
  }: {
    customerId?: string;
    macAddress?: string;
  }): Promise<Sensor[]> {
    try {
      const response = await this.everactiveApi.getSensors({
        installStatus: "available",
        customerId,
        macAddress: macAddress === "" ? null : macAddress,
        customerMismatch: null
      })
      return response.data.map(
        (sensor: Partial<Sensor>) => new Sensor(sensor)
      );
    } catch (e) {
      console.error(
        "SensorsApi.getAvailableSensors: unable to get list of available sensors"
      );
      return [];
    }
  }

  public static async getSensorById(
    sensorId: string | null | undefined
  ): Promise<Sensor | null> {
    if (!sensorId) {
      return null;
    }
    try {
      const response = await this.everactiveApi.getSensorSensorIdOrMac({
        sensorIdOrMac: sensorId
      })
      return response.data as Sensor
    } catch(e) {
      console.error(
        `SensorsApi.getSensorById: unable to get sensor with id ${sensorId}`
      );
      return null;
    }
  }

  public static async deleteSensor(id: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteSensorSensorIdOrMac({
        sensorIdOrMac: id
      })
      return true
    } catch (e) {
      console.log("problem deleting sensor", e);
      return false;
    }
  }
}
