<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { Dropdown } from 'floating-vue'
import CodexButton from './utils/CodexButton.vue'
import { useCustomersState } from '@/composables/useCustomersState'
import { onClickOutside } from '@vueuse/core'
import { disablePageScroll, enablePageScroll } from '@/lib/utils/pageScroll'
import WarningIcon from '@/assets/icons/icon-warning.svg?component'
import CloseButton from './utils/CloseButton.vue'
import { Customer } from '@/types'

export default defineComponent({
  components: { Dropdown, CodexButton, WarningIcon, CloseButton },
  setup() {
    const {
      totalCustomers,
      totalSelectedCustomers,
      selectAllCustomers,
      deselectAllCustomers,
      customers,
      toggleCustomer,
      maxSelectionReached,
      maxSelectionExceeded,
      isCustomerSelected,
      maxCustomerSelectionLimit
    } = useCustomersState()

    const isOpen = ref(false)
    const siteSearch = ref('')

    const showClearSiteButton = computed(() => Boolean(siteSearch.value))

    function toggleDropdown() {
      isOpen.value = !isOpen.value


      if (isOpen.value) {
        disablePageScroll()
      } else {
        enablePageScroll()
      }
    }

    function clearSearchInput() {
      siteSearch.value = ''
    }

    function closeDropdown() {
      isOpen.value = false
      clearSearchInput()
      enablePageScroll()
    }

    function filterCustomers(customers: Customer[], searchTerm: string) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase()
      return customers.filter(
        (c) => !searchTerm || c.name.toLowerCase().includes(lowerCaseSearchTerm)
      )
    }

    const filteredCustomers = computed(() => filterCustomers(customers.value, siteSearch.value))

    const dropdownHeaderRef = ref()
    const dropdownContentRef = ref()
    const buttonRef = ref()

    onClickOutside(dropdownHeaderRef, () => closeDropdown(), {
      ignore: [buttonRef, dropdownContentRef]
    })
    onClickOutside(dropdownContentRef, () => closeDropdown(), {
      ignore: [buttonRef, dropdownHeaderRef]
    })

    return {
      showClearSiteButton,
      filterCustomers,
      filteredCustomers,
      siteSearch,
      isOpen,
      toggleDropdown,
      totalSelectedCustomers,
      totalCustomers,
      selectAllCustomers,
      deselectAllCustomers,
      dropdownHeaderRef,
      dropdownContentRef,
      buttonRef,
      customers,
      toggleCustomer,
      maxSelectionReached,
      maxSelectionExceeded,
      isCustomerSelected,
      maxCustomerSelectionLimit,
      clearSearchInput
    }
  }
})
</script>
<template>
  <div class="site-dropdown-wrapper">
    <div class="divider"></div>
    <Dropdown :triggers="[]" :shown="isOpen" :autoHide="false" auto-boundary-max-size>
      <CodexButton class="site-dropdown-trigger" @click="toggleDropdown" ref="buttonRef"
        data-testid="site-dropdown_button">
        Sites
        <span data-testid="site-dropdown_badge" class="badge">{{ totalSelectedCustomers }}/{{ totalCustomers }}</span>
        <img :class="['chevron', isOpen ? 'chevron-open' : 'chevron-closed']"
          src="/assets/icons/chevron-right-white.svg" />
      </CodexButton>
      <template #popper>
        <header class="site-dropdown-header" ref="dropdownHeaderRef">
          <span class="title">Sites
            <span class="sub-title">({{ totalSelectedCustomers }} out of {{ totalCustomers }})</span></span>
          <p v-if="maxSelectionReached" class="instruction">
            You have reached the total allowed limit of
            {{ maxCustomerSelectionLimit }} selected sites.
          </p>
          <p v-else-if="maxSelectionExceeded" class="instruction">
            <WarningIcon style="padding-top: 1px" />
            You have exceeded the total allowed limit of
            {{ maxCustomerSelectionLimit }} selected sites. Data for all sites will be displayed
            until you have selected {{ maxCustomerSelectionLimit }} or fewer sites.
          </p>
          <p v-else class="instruction">Choose one or more sites to filter</p>
          <div class="site-dropdown-header-buttons">
            <CodexButton @click="selectAllCustomers" data-testid="sites_select_all_btn">Select All</CodexButton>
            <CodexButton @click="deselectAllCustomers" data-testid="sites_deselect_all_btn">Deselect All</CodexButton>
          </div>
          <div class="search-container">
            <input placeholder="search sites" v-model="siteSearch" />
            <CloseButton v-if="showClearSiteButton" @click="clearSearchInput" />
          </div>
        </header>
        <div class="site-dropdown-content" ref="dropdownContentRef" data-testid="site-dropdown_popper">
          <label v-for="customer in filteredCustomers" :key="customer.id"
            :class="maxSelectionReached && !isCustomerSelected(customer.id) ? 'disabled' : ''">
            <input class="site-checkbox" type="checkbox" :value="customer.id" :checked="isCustomerSelected(customer.id)"
              :disabled="maxSelectionReached && !isCustomerSelected(customer.id)" @input="toggleCustomer(customer.id)" />
            {{ customer.name }}
          </label>
          <div v-if="!filteredCustomers.length">
            <i style="color: gray">no matching sites</i>
          </div>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<style>
.v-popper__inner>div {
  padding: 20px 0px !important;
  display: flex !important;
  flex-direction: column !important;
  overflow: hidden !important;
  width: 370px !important;
}

.v-popper__popper.v-popper--theme-dropdown .v-popper__inner {
  box-shadow: 0px 4px 10px rgba(87, 87, 87, 0.25);
  border: none;
}

.v-popper__popper.v-popper--theme-dropdown .v-popper__arrow-outer {
  border-color: white;
}

.v-popper__arrow-container {
  display: none;
}
</style>
<style scoped>
label.disabled {
  opacity: 50%;
}

.search-container {
  --close-button-active-font-size-factor: 1;
  display: flex;
  justify-content: space-between;
}

.search-container input {
  width: 16rem;
  height: 40px;
}

.site-dropdown-wrapper {
  display: flex;
}

.site-dropdown-header {
  padding: 0px 20px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  flex-grow: 0;
  flex-shrink: 0;
}

.site-dropdown-header .title {
  font-size: 18px;
  font-weight: 600;
}

.site-dropdown-header .sub-title {
  font-size: 14px;
  font-weight: normal;
  color: #636363;
}

.site-dropdown-header .instruction {
  font-size: 14px;
  color: #636363;
  padding: 15px 0px;
  margin: 0;
}

.site-dropdown-header-buttons {
  display: flex;
  margin-bottom: 1rem;
}

.site-dropdown-header-buttons button {
  font-size: 14px;
  padding: 0;
  height: 32px;
  padding: 10px;
  margin-right: 1rem;
}

.site-dropdown-trigger {
  box-shadow: none !important;
  font-size: 18px !important;
  font-weight: 700;
  border: none;
  text-decoration: none !important;
}

.divider {
  width: 2px;
  background: white;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 4px;
}

.site-dropdown-content {
  padding: 0px 20px;
  flex-shrink: 1;
  flex-grow: 1;
  height: 100%;
  min-height: 1px;
  overflow-y: auto;
}

.site-checkbox {
  margin-right: 8px;
}

.chevron {
  width: 10px;
}

.chevron-closed {
  transform: rotate(90deg);
}

.chevron-open {
  transform: rotate(-90deg);
}

.badge {
  background-color: #4555d5;
  border-radius: 10px;
  padding: 0px 6px;
  font-size: 14px;
  margin: 0 10px;
}

label {
  display: block;
}

label:last-of-type {
  margin-bottom: 0;
}
</style>
