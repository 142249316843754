/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MqttSubscription } from '../models/MqttSubscription';
import type { MqttSubscriptionUpdate } from '../models/MqttSubscriptionUpdate';
import type { PaginationInfo } from '../models/PaginationInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MqttService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * create mqtt subscription
	 * Create an mqtt subscription.
	 * @returns any Retrieve the configuration of a mqtt subscription.
	 * @throws ApiError
	 */
	public postMqttSubscriptions({
		requestBody,
	}: {
		/**
		 * Create a new mqtt subscription.
		 */
		requestBody?: MqttSubscription,
	}): CancelablePromise<{
		data?: MqttSubscription;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/mqtt-subscriptions',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get the list of mqtt subscriptions
	 * Retrieve the configuration of multiple mqtt subscriptions.
	 * @returns any Retrieve the configuration of multiple mqtt subscriptions.
	 * @throws ApiError
	 */
	public getMqttSubscriptions({
		customerId,
		customerName,
		enabled,
		page = 1,
		pageSize = 50,
		sortBy,
		sortDir,
	}: {
		/**
		 * Filter the mqtt subscriptions returned by the customer id.  It is possible to pass
		 * multiple customer-id query parameters as a comma separated value (e.g. `&customer-id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting the mqtt list does not have access to the customer-id passed, an error will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the mqtt subscriptions using a `LIKE` comparison with the customer name property.
		 */
		customerName?: string,
		/**
		 * Filter the mqtt subscriptions by their enabled status. Default behavior returns mqtt subscriptions regardless of their enabled status.
		 */
		enabled?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * The field to use for the sort order in the returned list of mqtt subscriptions.
		 */
		sortBy?: 'customer-name' | 'enabled',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<MqttSubscription>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/mqtt-subscriptions',
			query: {
				'customer-id': customerId,
				'customer-name': customerName,
				'enabled': enabled,
				'page': page,
				'page-size': pageSize,
				'sort-by': sortBy,
				'sort-dir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get an mqtt subscription
	 * Retrieve the configuration of a single mqtt subscription.
	 * @returns any Retrieve the configuration of a mqtt subscription.
	 * @throws ApiError
	 */
	public getMqttSubscription({
		mqttSubscriptionId,
	}: {
		/**
		 * The ID of the mqtt subscription.
		 */
		mqttSubscriptionId: string,
	}): CancelablePromise<{
		data?: MqttSubscription;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/mqtt-subscriptions/{mqttSubscriptionId}',
			path: {
				'mqttSubscriptionId': mqttSubscriptionId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * update mqtt subscription
	 * Update an existing mqtt subscription.
	 * @returns any Retrieve the configuration of a mqtt subscription.
	 * @throws ApiError
	 */
	public updateMqttSubscriptions({
		mqttSubscriptionId,
		requestBody,
	}: {
		/**
		 * The ID of the mqtt subscription.
		 */
		mqttSubscriptionId: string,
		/**
		 * Update an existing mqtt subscription.
		 */
		requestBody?: MqttSubscriptionUpdate,
	}): CancelablePromise<{
		data?: MqttSubscription;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/mqtt-subscriptions/{mqttSubscriptionId}',
			path: {
				'mqttSubscriptionId': mqttSubscriptionId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete mqtt subscription
	 * Delete a mqtt subscription.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteMqttSubscriptions({
		mqttSubscriptionId,
	}: {
		/**
		 * The ID of the mqtt subscription.
		 */
		mqttSubscriptionId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/mqtt-subscriptions/{mqttSubscriptionId}',
			path: {
				'mqttSubscriptionId': mqttSubscriptionId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
