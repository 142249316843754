import userutils from "@/lib/utils/userutils";
import { CustomerMinimal } from "@/types";
import Global from "@/Global";
import { useUserPrivileges } from "@/composables/useUserPrivileges";
import { useCurrentCustomer } from "@/composables/useCurrentCustomer";
import { useCurrentUser } from "@/composables/useCurrentUser";
import { isDevelopment } from "@/env";

export function useBaseMixin() {
  const { isSuperAdmin, isItAdmin, checkPrivileges } = useUserPrivileges();
  const { isSageCustomer: isSageIntegrated } = useCurrentCustomer();
  const {
    user,
    userTimezone,
    userTemperatureUnit,
    userTimezoneAbbreviation,
    formatTimestampInUserTimezone,
    getTimestampTimezoneAbbreviation
  } = useCurrentUser();

  function checkIntegrations(
    keys: string[],
    customer: CustomerMinimal = user.value?.customer
  ) {
    return userutils.checkIntegrations(keys, customer?.integrations ?? {});
  }

  return {
    currentLoggedInUser: user,
    isItAdmin,
    isSuperAdmin,
    isSageIntegrated,
    isDevelopment,
    currencySymbol: Global.getCurrencySymbol(),
    currencyString: Global.getCurrencyString(),
    userTimezone,
    userTemperatureUnit,
    userTimezoneAbbreviation,
    formatTimestampInUserTimezone,
    getTimestampTimezoneAbbreviation,
    checkPrivileges,
    checkIntegrations
  };
}
