/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The level of privilege.
 */
export enum PrivilegeKey {
	ASSET_MANAGER = 'asset-manager',
	CUSTOMER_ADMIN = 'customer-admin',
	DEVELOPER = 'developer',
	INTEGRATION_PARTNER = 'integration-partner',
	IT_ADMIN = 'it-admin',
	READ_ONLY = 'read-only',
	SUPER_ADMIN = 'super-admin',
	USER = 'user',
}
