import { Customer, PrivilegeSummary } from '@/types'
import { EveractiveApi } from './everactiveApi'

export class CustomersApi {
  public static everactiveApi = new EveractiveApi().client.customer

  public static async fetchAllCustomers(): Promise<Customer[]> {
    let totalPages = 1
    const customers = []

    for (let page = 1; page <= totalPages; page++) {
      await this.fetchCustomers({ page })
        .then(({ data, paginationInfo }) => {
          totalPages = paginationInfo.totalPages
          customers.push(...data.map((c) => new Customer(c)))
        })
        .catch((error) => {
          console.error('error fetching all customers. exiting.', error)
          totalPages = 0
        })
    }

    return customers
  }

  public static async fetchCustomers(queryParams: {
    customerId?: string
    enabledProducts?: Array<
      'flareSystemMonitor' | 'machineHealthMonitor' | 'steamTrapMonitor' | 'dataServices'
    >
    name?: string
    page?: number
    pageSize?: number
    sortDir?: 'asc' | 'desc'
    sortBy?: 'name' | 'status'
    ssoDomain?: string
    status?: 'Decommissioned' | 'Demo' | 'Production' | 'To Be Installed'
  }): Promise<any | null> {
    try {
      const response = await this.everactiveApi.getCustomers(queryParams)
      return response
    } catch (e) {
      console.log('problem fetching customers!', e.status, e.data)
      return { data: [], paginationInfo: {} }
    }
  }

  public static async fetchCustomer(id: string): Promise<Customer | null> {
    const target = id

    try {
      const response = await this.everactiveApi.getCustomersCustomerId({
        customerId: target
      })

      return new Customer(response.data)
    } catch (e) {
      console.log('problem fetching single customer ' + id + ':', e.status, e.data)
      return null
    }
  }

  public static async addCustomer(customer: Customer): Promise<Customer | null> {
    try {
      const response = await this.everactiveApi.postCustomers({
        requestBody: customer
      })
      return new Customer(response.data)
    } catch (e) {
      console.log('problem adding customer!', e.status, e.data)
      return null
    }
  }

  public static async updateCustomer(customer: Customer): Promise<Customer | null> {
    const target = customer.id

    try {
      const response = await this.everactiveApi.updateCustomersCustomerId({
        customerId: target,
        requestBody: customer
      })

      return new Customer(response.data)
    } catch (e) {
      console.log('problem updating customer!', e.status, e.data)
      return null
    }
  }

  public static async deleteCustomer(customer: Customer): Promise<boolean> {
    try {
      await this.everactiveApi.deleteCustomersCustomerId({
        customerId: customer.id
      })

      return true
    } catch (e) {
      console.log('problem deleting customer!', e.status, e.data)
      return false
    }
  }

  public static fetchCustomerPrivileges(customerId: string): Promise<PrivilegeSummary[]> {
    return this.everactiveApi
      .getCustomersCustomerIdPrivileges({ customerId })
      .then((r) => {
        const results = r.data
          .filter((p) => p.key !== 'integration-partner')
          .map((p) => ({ key: p.key, label: p.displayValue }))

        return results
      })
      .catch((e) => {
        console.error('fetchCustomerPrivileges', e)
        return []
      })
  }
}
