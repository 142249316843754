import { WebhookSubscription as ApiWebhook } from '@/client/ds'
import { CustomerMinimal } from '.'
import { CodexDropdownOption } from './codexTypes'

/* The key matches the backend enum for gateway deployment status,
   the value is the user-facing text. */
export enum eventTypes {
  sensor_reading = 'sensor_reading',
  gateway_status = 'gateway_status'
}

export interface IWebhook extends ApiWebhook {}

export class Webhook implements IWebhook {
  callbackUrl: string
  customer?: CustomerMinimal
  customerId?: string
  enabled: boolean
  eventType: ApiWebhook.eventType
  headers?: Header[]
  id: string

  constructor(data?: Partial<IWebhook>) {
    if (data) {
      Object.assign(this, data)
    }
  }

  public static eventTypeOptions: CodexDropdownOption[] = Object.entries(eventTypes).map(
    ([key, value]) => ({ label: value, value: key }) as CodexDropdownOption
  )
}

export interface Header {
  key: string
  value: string
}
