import store from "@/data-store/index";
import userutils from "@/lib/userutils";
import { RouteMeta } from "@/types";
import { RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import ProductConfigurationHelper from "@/lib/ProductConfigurationHelper";
import { useCurrentUser } from "@/composables/useCurrentUser";

async function ifPrivileged(to: any) {
  const { user } = useCurrentUser();
  let currentLoggedInUser = user.value;

  if (!currentLoggedInUser || !currentLoggedInUser.privileges) {
    // try to load the user into the store since on page reload this would be before store is loaded
    await store.dispatch("User/checkLoginStatus");
    currentLoggedInUser = user.value;

    // if either one of these is still true, then
    // not logged in, not allowed, something else
    if (!currentLoggedInUser || !currentLoggedInUser.privileges) {
      return false;
    }
  }

  return userutils.checkPrivilegesWithArray(
    to.meta.privilegeRequirement,
    currentLoggedInUser
  );
}

function updateProductFromRoute(routeMeta: RouteMeta) {
  // Once we decide we can go to a route, set the current product to whatever exists in the route,
  // but if we're going to a route that has no product in it, then leave the current selection alone
  if (routeMeta && routeMeta.productString) {
    store.commit("App/setCurrentProduct", routeMeta.productString);
    localStorage.setItem("CurrentProduct__", routeMeta.productString);
  } else {
    const currentProduct = localStorage.getItem("CurrentProduct__");
    if (currentProduct) {
      store.commit("App/setCurrentProduct", currentProduct);
    }
  }
}

const PREVIOUS_URL_KEY = "previousURLInfo";

function savePreviousUrlInfo(to: RouteLocationNormalized) {
  // If we need to redirect the user to get login or get an access token,
  // we want to save their desired URL to redirect upon successful login
  // Unless it's one of these paths
  if (
    ![
      "/access-denied",
      "/no-products-enabled",
      "/login",
      "/logout",
      "/"
    ].includes(to.path)
  ) {
    localStorage.setItem(PREVIOUS_URL_KEY, to.fullPath);
  }
}

function getPreviousUrlInfo() {
  return localStorage.getItem(PREVIOUS_URL_KEY);
}

function clearPreviousUrlInfo() {
  localStorage.removeItem(PREVIOUS_URL_KEY);
}

function addProductConfigurationRoutes(routes: RouteRecordRaw[]): RouteRecordRaw[] {
  const productConfig = ProductConfigurationHelper.getProductConfigurations();
  // Flatten the product configuration's routes into a single array
  const productRoutes = productConfig.reduce(
    (routes, current) => routes.concat(current.routes),
    []
  );
  return [...routes, ...productRoutes];
}

const RouteHelpers = {
  ifPrivileged,
  updateProductFromRoute,
  savePreviousUrlInfo,
  getPreviousUrlInfo,
  clearPreviousUrlInfo,
  addProductConfigurationRoutes
};
export default RouteHelpers;
