/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthClient } from '../models/AuthClient';
import type { AuthClientCreate } from '../models/AuthClientCreate';
import type { PaginationInfo } from '../models/PaginationInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthClientService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get auth client list
	 * Retrieve a list of auth clients.
	 * @returns any A list of auth clients.
	 * @throws ApiError
	 */
	public getAuthclients({
		page = 1,
		pageSize = 50,
	}: {
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<AuthClient>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/authclients',
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create auth client
	 * Create a new auth client to be used to obtain a bearer token via Outh2 flows.
	 * @returns any An auth client.
	 * @throws ApiError
	 */
	public postAuthclients({
		requestBody,
	}: {
		/**
		 * A new auth client to create.
		 */
		requestBody?: AuthClientCreate,
	}): CancelablePromise<{
		data?: AuthClient;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/authclients',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete auth client
	 * Delete an auth client.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteAuthclientsClientId({
		clientId,
	}: {
		/**
		 * The auth client id.
		 */
		clientId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/authclients/{clientId}',
			path: {
				'clientId': clientId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get auth client
	 * Get an auth client for the passed clientId.
	 * @returns any An auth client.
	 * @throws ApiError
	 */
	public getAuthclientsClientId({
		clientId,
	}: {
		/**
		 * The auth client id.
		 */
		clientId: string,
	}): CancelablePromise<{
		data?: AuthClient;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/authclients/{clientId}',
			path: {
				'clientId': clientId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
