import { SensorsApi } from "@/client";
import { Sensor } from "@/types";
import { SensorState } from "./types/sensorState";
import { RootState } from "./types/root";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

function initialState() {
  return {
    sensors: [],
    availableSensors: [],
    isFetching: false,
    isFetchingAvailable: false,
    isFetchingData: false,
    isAdding: false
  };
}

const state: SensorState = initialState();

const getters: GetterTree<SensorState, RootState> = {};

const mutations: MutationTree<SensorState> = {
  reset(state: SensorState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key];
    });
  },

  setIsFetching(
    state: SensorState,
    { isFetchingStatus, applyToFetchingAvailable }
  ): void {
    state.isFetching = isFetchingStatus;
    if (applyToFetchingAvailable) {
      state.isFetchingAvailable = isFetchingStatus;
    }
  },

  setIsFetchingData(state: SensorState, value): void {
    state.isFetchingData = value;
  },

  setIsAdding(state: SensorState, value: boolean): void {
    state.isAdding = value;
  },

  addAvailableSensor(state: SensorState, value: Sensor): void {
    if (!state.availableSensors) {
      state.availableSensors = [];
    }
    const updatedSensorsArray = [...state.availableSensors];
    const updatedSensorIndex = updatedSensorsArray.findIndex(
      (s) => s.id === value.id
    );

    if (updatedSensorIndex === -1) {
      state.availableSensors.push(value);
      return;
    }

    updatedSensorsArray[updatedSensorIndex] = value;
    state.availableSensors = updatedSensorsArray;
  },

  removeSensorById(state: SensorState, id: string): void {
    if (!state.sensors) {
      return;
    }
    const filteredSensors = state.sensors.filter((s) => s.id !== id);
    state.sensors = filteredSensors;
  },

  removeAvailableSensorByMacAddress(
    state: SensorState,
    macAddress: string
  ): void {
    if (!state.availableSensors) {
      return;
    }
    const filteredSensors = state.availableSensors.filter(
      (s) => s.macAddress !== macAddress
    );
    state.availableSensors = filteredSensors;
  }
};

const actions: ActionTree<SensorState, RootState> = {
  async removeSensor({ commit }, id: string): Promise<boolean> {
    const response = await SensorsApi.deleteSensor(id);

    if (!response) {
      console.log("problem removing the sensor: " + id);
      return false;
    }

    commit("removeSensorById", id);
    return true;
  },

  async fetchAvailableSensors({ commit }, params): Promise<Sensor[] | null> {
    commit("setIsFetching", {
      isFetchingStatus: true,
      applyToFetchingAvailable: true
    });

    try {
      const response = await SensorsApi.getAvailableSensors(params);
      if (!response) {
        return [];
      }

      const availableSensors: Sensor[] = response;

      commit("setIsFetching", {
        isFetchingStatus: false,
        applyToFetchingAvailable: true
      });

      return availableSensors;
    } finally {
      commit("setIsFetching", {
        isFetchingStatus: false,
        applyToFetchingAvailable: true
      });
    }
  },

  async fetchSensorById({ commit }, sensorId): Promise<boolean | Sensor> {
    commit("setIsFetching", { isFetchingStatus: true });

    try {
      const response = await SensorsApi.getSensorById(sensorId);
      if (response) {
        const sensor = new Sensor(response);
        return sensor;
      }
    } finally {
      commit("setIsFetching", { isFetchingStatus: false });
    }
    return false;
  },

  async fetchAvailableSensorById({ commit }, sensorId): Promise<boolean> {
    commit("setIsFetching", {
      isFetchingStatus: true,
      applyToFetchingAvailable: true
    });
    const sensor = await SensorsApi.getSensorById(sensorId);
    commit("setIsFetching", {
      isFetchingStatus: false,
      applyToFetchingAvailable: true
    });

    if (!sensor) {
      console.log(`problem fetching available sensor with id ${sensorId}`);
      return false;
    }

    commit("addAvailableSensor", sensor);
    return true;
  }
};

const Sensors: Module<SensorState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default Sensors;
