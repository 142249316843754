import { AuthApi } from '@/client'
import Vue from 'vue'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AuthState } from './types/authState'
import { RootState } from './types/root'

function initialState() {
  return {
    ssoDomains: [],
    isFetchingSSODomains: false
  }
}

const state: AuthState = initialState()

const getters: GetterTree<AuthState, RootState> = {
  getIsSSODomain(state): Function {
    return (emailOrDomain: string) => {
      if (!state.ssoDomains?.length) {
        return false
      }
      let domain = emailOrDomain
      if (emailOrDomain.includes('@')) {
        const splitEmail = emailOrDomain.split('@')
        if (splitEmail.length > 1) {
          domain = splitEmail[1]
        } else {
          return false
        }
      }
      return state.ssoDomains.includes(domain)
    }
  }
}

const mutations: MutationTree<AuthState> = {
  reset(state: AuthState) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },

  setIsFetchingSSODomains(state: AuthState, value: boolean): void {
    state.isFetchingSSODomains = value
  },

  setSSODomains(state: AuthState, value: string[]): void {
    if (!state.ssoDomains) {
      state.ssoDomains = []
    }
    state.ssoDomains = [...value]
  }
}

const actions: ActionTree<AuthState, RootState> = {
  async fetchSSODomains({ commit }): Promise<undefined> {
    commit('setIsFetchingSSODomains', true)
    try {
      const ssoDomains = await AuthApi.getSSODomains()
      commit('setSSODomains', ssoDomains)
    } finally {
      commit('setIsFetchingSSODomains', false)
    }
    return
  }
}

const AuthData: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default AuthData
