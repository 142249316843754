/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Notification } from '../models/Notification';
import type { NotificationCountSummary } from '../models/NotificationCountSummary';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { UserNotificationAction } from '../models/UserNotificationAction';
import type { UserNotificationActionCreate } from '../models/UserNotificationActionCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NotificationService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get notifications
	 * Return a paginated list of notification that meet the requested filter criteria.
	 * @returns any A list of notifications.
	 * @throws ApiError
	 */
	public getNotifications({
		customerId,
		endTimestamp,
		entityId,
		eventId,
		notificationType,
		page = 1,
		pageSize = 50,
		resolutionStatus,
		resolvedSinceTimestamp,
		startTimestamp,
	}: {
		/**
		 * Filter the notifications returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customer.id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting the notifications does not have access to the customer.id passed an
		 * error will be returned.
		 */
		customerId?: string,
		/**
		 * The latest notification eventTimestamp to return (inclusive) as a unix timestamp.
		 */
		endTimestamp?: number,
		/**
		 * Filter the notifications by the entity the notification is related to.
		 */
		entityId?: string,
		/**
		 * Filter the notifications by the event the notification is related to.
		 */
		eventId?: string,
		/**
		 * Filter the notifications by the notificationType property. Multiple values can be passed as a comma
		 * separated list (e.g. `notificationType=BLOWTHROUGH,INTERMITTENT_BLOWTHROUGH`)
		 */
		notificationType?: 'BLOWTHROUGH' | 'FAILED_COLD' | 'INTERMITTENT_BLOWTHROUGH' | 'SERVICE_NEEDED' | 'SLEEP',
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the list of notifications by resolution status.
		 */
		resolutionStatus?: 'resolved' | 'unresolved',
		/**
		 * Used to filter the returned list of notifications to only include those resolved after the
		 * provided query value. The `resolvedSinceTimestamp` filter is ignored if the `resolutionStatus` is
		 * not set to **resolved**.
		 */
		resolvedSinceTimestamp?: number,
		/**
		 * The earliest notification eventTimestamp to return (inclusive) as a unix timestamp.
		 */
		startTimestamp?: number,
	}): CancelablePromise<{
		data?: Array<Notification>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/notifications',
			query: {
				'customer.id': customerId,
				'endTimestamp': endTimestamp,
				'entityId': entityId,
				'eventId': eventId,
				'notificationType': notificationType,
				'page': page,
				'pageSize': pageSize,
				'resolutionStatus': resolutionStatus,
				'resolvedSinceTimestamp': resolvedSinceTimestamp,
				'startTimestamp': startTimestamp,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get notification
	 * Return a single notification detail object
	 * @returns any Detail about a single notification.
	 * @throws ApiError
	 */
	public getNotificationsNotificationId({
		notificationId,
	}: {
		/**
		 * The notification id.
		 */
		notificationId: string,
	}): CancelablePromise<{
		data?: Notification;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/notifications/{notificationId}',
			path: {
				'notificationId': notificationId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * delete notification
	 * Delete a single notification
	 * @returns void
	 * @throws ApiError
	 */
	public deleteNotificationsNotificationId({
		notificationId,
	}: {
		/**
		 * The notification id.
		 */
		notificationId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/notifications/{notificationId}',
			path: {
				'notificationId': notificationId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update notification
	 * Update the properties of a notification.
	 * @returns any Detail about a single notification.
	 * @throws ApiError
	 */
	public updateNotificationsNotificationId({
		notificationId,
		requestBody,
	}: {
		/**
		 * The notification id.
		 */
		notificationId: string,
		/**
		 * Notification detail to update.
		 */
		requestBody?: Notification,
	}): CancelablePromise<{
		data?: Notification;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/notifications/{notificationId}',
			path: {
				'notificationId': notificationId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get list of user notification actions
	 * Return a list of all user notification action for a specific notification.
	 * @returns any A list of user actions for a notification.
	 * @throws ApiError
	 */
	public getNotificationsNotificationidUseractions({
		notificationId,
		actionTypeId,
		filterExpired,
		userId,
	}: {
		/**
		 * The notification id.
		 */
		notificationId: string,
		/**
		 * Filter the list of user notification actions returned by the actionTypeId.
		 * - `1`: Snoozed
		 * - `2`: Resolved
		 */
		actionTypeId?: 1 | 2,
		/**
		 * Filter the list of user notification actions to not include any expired user notification actions.
		 */
		filterExpired?: boolean,
		/**
		 * Filter the list of user notification actions to only include those for the passed userId.
		 */
		userId?: string,
	}): CancelablePromise<{
		data?: Array<UserNotificationAction>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/notifications/{notificationId}/useractions',
			path: {
				'notificationId': notificationId,
			},
			query: {
				'actionTypeId': actionTypeId,
				'filterExpired': filterExpired,
				'userId': userId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create user notification action
	 * Create a new user notification action.
	 * @returns any A user notification action.
	 * @throws ApiError
	 */
	public postNotificationsNotificationIdUseractions({
		notificationId,
		requestBody,
	}: {
		/**
		 * The notification id.
		 */
		notificationId: string,
		/**
		 * A user notification action to create.
		 */
		requestBody?: UserNotificationActionCreate,
	}): CancelablePromise<{
		data?: UserNotificationAction;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/notifications/{notificationId}/useractions',
			path: {
				'notificationId': notificationId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * delete user notification action
	 * Delete a single user notification action.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteNotificationsNotificationIdUseractionsUserActionId({
		notificationId,
		userActionId,
	}: {
		/**
		 * The notification id.
		 */
		notificationId: string,
		/**
		 * The user notification action id.
		 */
		userActionId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/notifications/{notificationId}/useractions/{userActionId}',
			path: {
				'notificationId': notificationId,
				'userActionId': userActionId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get notification count summary
	 * Get a summary object with the number of notifications in each possible state The
	 * user has access to view.
	 * @returns any A summary of the nuber of notifications in all possible states for a user.
	 * @throws ApiError
	 */
	public getNotificationsCountsummary({
		customerId,
	}: {
		/**
		 * Filter the notification summary object returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting the notifications does not have access to the customerId passed an
		 * error will be returned.
		 */
		customerId?: string,
	}): CancelablePromise<{
		data?: NotificationCountSummary;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/notifications/countsummary',
			query: {
				'customerId': customerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
