/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { SteamTrapEventEnd } from '../models/SteamTrapEventEnd';
import type { SteamTrapUnexpectedColdEvent } from '../models/SteamTrapUnexpectedColdEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SteamTrapUnexpectedColdService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get unexpected cold events
	 * Retrieve a list of unexpected colds for a steam trap.
	 * @returns any A list of unexpected cold events.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapidUnexpectedColds({
		trapId,
		minStartDateInclusive,
		maxEndDateInclusive,
		openOnly,
		page = 1,
		pageSize = 50,
	}: {
		trapId: string,
		/**
		 * The minimum failure event date to retrieve as a unix timestamp.
		 */
		minStartDateInclusive?: number,
		/**
		 * The maximum failure event date to retrieve as a unix timestamp.
		 */
		maxEndDateInclusive?: number,
		openOnly?: boolean,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<SteamTrapUnexpectedColdEvent>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/unexpected-colds',
			path: {
				'trapId': trapId,
			},
			query: {
				'minStartDateInclusive': minStartDateInclusive,
				'maxEndDateInclusive': maxEndDateInclusive,
				'openOnly': openOnly,
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create unexpected cold event
	 * Create a new unexpected cold event for the steam trap.
	 * @returns any A steam trap unexpected cold event.
	 * @throws ApiError
	 */
	public postSteamTrapsTrapidUnexpectedColds({
		trapId,
		requestBody,
	}: {
		trapId: string,
		/**
		 * Create a steam trap unexpected cold event.
		 */
		requestBody?: SteamTrapUnexpectedColdEvent,
	}): CancelablePromise<{
		data?: SteamTrapUnexpectedColdEvent;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/unexpected-colds',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * set unexpected cold event end time
	 * Set the end timestamp property of an existing unexpected cold event for a steam trap.
	 * @returns any A steam trap unexpected cold event.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdUnexpectedColdsUnexpectedColdIdEndTime({
		trapId,
		unexpectedColdId,
		requestBody,
	}: {
		trapId: string,
		unexpectedColdId: string,
		/**
		 * Set the end timestamp of an existing steam trap event.
		 */
		requestBody?: SteamTrapEventEnd,
	}): CancelablePromise<{
		data?: SteamTrapUnexpectedColdEvent;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/unexpected-colds/{unexpectedColdId}/end-time',
			path: {
				'trapId': trapId,
				'unexpectedColdId': unexpectedColdId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
