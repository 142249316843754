import { watch, WatchCallback, WatchOptions, WatchStopHandle } from "vue";

export function watchOnceWithFilter<WatchSourceType>(
  watchSource: any,
  filter: (value: WatchSourceType, oldValue: WatchSourceType) => boolean,
  callback: WatchCallback<WatchSourceType, WatchSourceType>,
  watchOptions: WatchOptions = {}
) {
  let terminated = false;
  let terminateWatch: WatchStopHandle;

  terminateWatch = watch(
    watchSource,
    (newValue, oldValue, onInvalidate) => {
      if (filter(newValue, oldValue)) {
        if (!terminated) {
          callback(newValue, oldValue, onInvalidate);
          terminated = true;
        }

        if (terminateWatch) {
          terminateWatch();
        }
      }
    },
    { immediate: true, ...watchOptions }
  );
}
