/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { MachineHealthThresholdBreachDetail } from './MachineHealthThresholdBreachDetail';

export type MachineNotificationCreate = {
	/**
	 * Additional information about the event creating the notification.
	 */
	eventDetail?: string;
	/**
	 * The id of the event causing the notification.
	 */
	eventId?: string;
	/**
	 * The timestamp (seconds from 1/1/1970) the event occurred.
	 */
	eventTimestamp?: number;
	/**
	 * One or more threshold breached event details that caused the machine to be in
	 * an alarm state.
	 */
	machineThresholdBreachDetails?: Array<MachineHealthThresholdBreachDetail>;
	/**
	 * The subset of notification types for machines.
	 */
	notificationType?: MachineNotificationCreate.notificationType;
};

export namespace MachineNotificationCreate {

	/**
	 * The subset of notification types for machines.
	 */
	export enum notificationType {
		MACHINE_THRESHOLD_BREACH_ALARM = 'MACHINE_THRESHOLD_BREACH_ALARM',
	}


}

