import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "./types/root";
import { BryntumGridSettingsState } from "./types/bryntumGridSettingsState";
import {
  BryntumGridSettings as BryntumGridSettingsType,
  BryntumGridSettingsBody
} from "@/types/bryntumGridSetting";
import { BryntumGridSettingsApi } from "@/client/bryntumGridSettingsApi";

function initialState() {
  return {
    allGridSettings: null,
    isFetching: false,
    hasFetched: false
  };
}

const state: BryntumGridSettingsState = initialState();

const getters: GetterTree<BryntumGridSettingsState, RootState> = {
  getGridSetting(state): Function {
    return (gridName: string) => {
      if (!state.allGridSettings) {
        return null;
      }

      return state.allGridSettings.find((g) => g.gridName === gridName) || null;
    };
  },
  getHasFetched(state): boolean {
    return state.hasFetched;
  },
  getIsFetching(state): boolean {
    return state.isFetching;
  }
};

const mutations: MutationTree<BryntumGridSettingsState> = {
  reset(state: BryntumGridSettingsState) {
    const s = initialState();
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    });
  },

  hasFetched(state: BryntumGridSettingsState, value: boolean) {
    state.hasFetched = value;
  },
  isFetching(state: BryntumGridSettingsState, value: boolean) {
    state.isFetching = value;
  },
  allGridSettings(
    state: BryntumGridSettingsState,
    value: BryntumGridSettingsType[]
  ) {
    state.allGridSettings = value;
  },
  gridSettings(
    state: BryntumGridSettingsState,
    value: BryntumGridSettingsType
  ) {
    const { allGridSettings } = state;
    const existingEntry = allGridSettings.findIndex(
      (g) => g.gridName === value.gridName
    );
    if (existingEntry) {
      allGridSettings[existingEntry] = value;
      state.allGridSettings = allGridSettings
    } else {
      state.allGridSettings = [value, ...state.allGridSettings];
    }
  }
};

const actions: ActionTree<BryntumGridSettingsState, RootState> = {
  async fetchGridSettings(
    { commit },
    userId: string
  ): Promise<boolean | BryntumGridSettingsType[]> {
    if (!userId) {
      return false;
    }

    commit("isFetching", true);

    try {
      const response = await BryntumGridSettingsApi.fetchAllGridSettings(
        userId
      );
      if (response) {
        commit("allGridSettings", response);
        return response;
      }

      return false;
    } finally {
      commit("isFetching", false);
      commit("hasFetched", true);
    }
  },
  async saveGridSettings(
    { commit },
    payload: {
      userId: string;
      gridName: string;
      gridSettings: BryntumGridSettingsBody;
    }
  ): Promise<boolean | BryntumGridSettingsType> {
    if (!payload.userId) {
      return false;
    }

    commit("isFetching", true);

    try {
      const response = await BryntumGridSettingsApi.saveGridSettings(
        payload.userId,
        payload.gridName,
        payload.gridSettings
      );
      if (response) {
        commit("gridSettings", response);
        return response;
      }

      return false;
    } finally {
      commit("isFetching", false);
    }
  },
  async deleteGridSetting(
    { commit },
    payload: {
      userId: string;
      gridName: string;
    }
  ): Promise<boolean | BryntumGridSettingsType> {
    if (!payload.userId) {
      return false;
    }

    commit("isFetching", true);

    try {
      const response = await BryntumGridSettingsApi.deleteGridSetting(
        payload.userId,
        payload.gridName
      );
      if (response) {
        commit("gridSettings", response);
        return response;
      }

      return false;
    } finally {
      commit("isFetching", false);
    }
  }
};

const BryntumGridSettings: Module<BryntumGridSettingsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default BryntumGridSettings;
