import { Ref, ref } from "vue";
import {
  asyncComputed,
  AsyncComputedOnCancel,
} from "@vueuse/core";

export function asyncComputedLoading<T = unknown>(
  evaluationCallback: (
    initialState: T,
    onCancel: AsyncComputedOnCancel
  ) => T | Promise<T>,
  initialState: T = null
): [Ref<T>, Ref<boolean>] {
  const isLoading = ref(true);

  const result = asyncComputed(
    (onCancel) => evaluationCallback(initialState, onCancel),
    initialState,
    isLoading
  );

  return [result, isLoading];
}
