<template>
  <Gear
    :class="[$style.icon, superAdmin ? $style.superAdmin : '']"
    evrac-wl="settings-icon"
  />
</template>

<script lang="ts">
import Gear from "@/assets/icons/gear.svg?component";

export default {
  name: "AppSettingsIcon",
  components: {
    Gear
  },
  props: {
    superAdmin: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style module>
.icon :global .gear {
  transition: fill 0.25s ease-out;
}

.icon :global .gear-center {
  transition: transform 0.25s ease-out;
}
.icon :global .gear {
  fill: white;
  stroke: #14e067;
}

.icon :global .gear-center {
  fill: #a1ffc7;
  transform-origin: center;
  transform: scale(0);
}
.superAdmin :global .gear {
  fill: #14e067;
}

.superAdmin :global .gear-center {
  fill: #a1ffc7;
  transform: scale(1);
}
</style>
