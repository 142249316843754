import {
  SteamState as ApiSteamState,
  SteamTrap as ApiSteamTrap,
  SteamTrapHistoryItem as ApiSteamTrapHistoryItem,
  SteamTrapOperationType as ApiSteamTrapOperationType,
  SteamTrapState as ApiSteamTrapState,
  SteamTrapsStateCount as ApiTrapStateCount,
  HazardousLocationClassification as ApiHazardousLocationClassification
} from "@/client/api";
import TemperatureHelper from "@/lib/TemperatureHelper";
import _ from "lodash";
import { BulkUploadDetail } from ".";
import { CodexDropdownOption } from "./codexTypes";
import { CustomerMinimal } from "./customer";
import { IFileSummary } from "./files";
import { ISensor, Sensor, SensorReading } from "./sensors";

export enum HazardousLocationClassification {
  C1D1 = 'C1D1',
	C1D2 = 'C1D2'
}

export interface SteamIntegrationInfo {
  assetId: string;
  extraInfo?: { [key: string]: string };
  everactiveIntegrationId?: string;
  everactiveIntegrationName?: string;
  name?: string;
}

export interface TrapDetail {
  manufacturer: Manufacturer;
  model?: string;
  orificeDiameter?: number;
  type?: SteamTrapType;
}

export interface StmMetrics {
  tempCondensate: number;
  tempSteam: number;
}

export interface SiteDetail {
  facility: string;
  location: string;
  application?: string;
  applicationDetail?: string;
  hazardousLocationClassification?: ApiHazardousLocationClassification;
  inletPSI?: number;
  installDetail?: InstallDetail;
  operation?: ApiSteamTrapOperationType;
  pipeSize?: number;
  surveyData?: SteamTrapSiteSurveyData;
  svcTag?: string;
}

export interface InstallDetail {
  checkValve?: boolean;
  condensateMainPipeSize?: number;
  condensateMainPressure?: number;
  condensateReturned?: boolean;
  hasStrainer?: boolean;
  insulationThickness?: number;
  insulationThicknessChecked?: boolean;
  isOutdoor?: boolean;
  isTrapSizeReduced?: boolean;
  poorPiping?: boolean;
  secondaryReducedPipeSize?: number;
  steamMainPipeSize?: number;
}

export interface SteamTrapSiteSurveyData {
  controlNodeNumber?: number;
  evergatewayAssociationNumber?: string;
  notes?: string;
}

export interface Status {
  amountLost: number;
  steamState: ApiSteamState;
  steamStateChangeTimestamp: number;
  trapState: ApiSteamTrapState;
  trapFailureTimestamp: number;
}

export enum Manufacturer {
  Armstrong = "Armstrong",
  BarnesAndJones = "Barnes & Jones",
  Bestobell = "Bestobell",
  DunhamBushMEPCO = "Dunham-Bush (now MEPCO)",
  GestraFlowserve = "Gestra (Flowserve)",
  ForbesMarshall = "Forbes Marshall",
  Hoffman = "Hoffman",
  IllinoisWatts = "Illinois (now Watts)",
  Marsh = "Marsh",
  MEPCO = "MEPCO",
  Miyawaki = "Miyawaki",
  Nicholson = "Nicholson",
  Nortec = "Nortec",
  Other = "OTHER",
  Pennant = "Pennant",
  SarcoSpiraxSarco = "Sarco (Now Spirax Sarco)",
  SpiraxSarco = "Spirax Sarco",
  Sterling = "Sterling",
  TLV = "TLV",
  Trane = "Trane",
  Tunstall = "Tunstall",
  UnknownOption = "UNKNOWN (or not populated by installer during pairing)",
  Velan = "Velan",
  WatsonMcDaniel = "Watson McDaniel",
  WarrenWebster = "Warren-Webster",
  Watts = "Watts",
  YarwayEmerson = "Yarway (now Emerson)"
}

export enum SteamTrapType {
  BiMetal = "Bi-Metal",
  Disc = "Disc",
  Float = "Float",
  InvertedBucket = "Inverted Bucket",
  Orifice = "Orifice",
  PumpTrap = "Pump Trap",
  Thermostatic = "Thermostatic",
  Venturi = "Venturi",
  UnknownOption = "UNKNOWN (or not populated by installer during pairing)"
}

export interface TrapStateCount extends ApiTrapStateCount {}

export interface ISteamTrap extends ApiSteamTrap {}

export const applicationOptions = {
  "Process Equipment": [
    "Heat Exchanger",
    "Autoclave/Sterilizer",
    "Process Coil/Jacketed Vessel",
    "Other Process"
  ],
  Heating: ["AHU Coil", "Humidifier", "Fan Coil", "Other Heat"],
  "Flash Tank": null,
  "Drip Leg": null,
  Tracing: null,
  "UNKNOWN (or not populated by installer during pairing)": null
};

export const pipeSizeOptions: CodexDropdownOption[] = [
  { label: '1/4"', value: 0.25 },
  { label: '3/8"', value: 0.375 },
  { label: '1/2"', value: 0.5 },
  { label: '3/4"', value: 0.75 },
  { label: '1"', value: 1 },
  { label: '1-1/4"', value: 1.25 },
  { label: '1-1/2"', value: 1.5 },
  { label: '2"', value: 2 },
  { label: '2-1/2"', value: 2.5 },
  { label: '3"', value: 3 },
  { label: '4"', value: 4 },
  {
    label: "UNKNOWN (or not populated by installer during pairing)",
    value: 0
  }
];

export class SteamTrap implements ISteamTrap {
  id: string;
  siteDetail: SiteDetail = {
    facility: null,
    location: null,
    operation: ApiSteamTrapOperationType.INTERMITTENT_PROCESS,
    pipeSize: null,
    inletPSI: null,
    application: null,
    applicationDetail: null,
    installDetail: {
      condensateMainPressure: null,
      isOutdoor: false,
      hasStrainer: false,
      poorPiping: false,
      checkValve: false,
      condensateMainPipeSize: null,
      insulationThicknessChecked: false,
      insulationThickness: null,
      isTrapSizeReduced: false,
      secondaryReducedPipeSize: null,
      condensateReturned: false
    },
    surveyData: {
      evergatewayAssociationNumber: null,
      notes: null
    }
  };
  trapDetail: TrapDetail = {
    manufacturer: Manufacturer.UnknownOption,
    type: SteamTrapType.UnknownOption,
    orificeDiameter: null
  };
  profileImage?: IFileSummary;
  customerAssociationTimestamp?: number | null = null;
  customer?: CustomerMinimal = {
    id: null,
    name: null,
    status: null
  };
  customerId?: string;
  integrationInfo?: SteamIntegrationInfo[];
  sensor?: Sensor;
  sensorInstallTempProbesSwapped?: boolean;
  sensorInstallTimestamp?: number;
  status?: Status;
  bulkUploadDetail?: BulkUploadDetail;
  lastReading?: SensorReading | null;

  public static applicationOptions = applicationOptions;

  public static pipeSizeOptions = pipeSizeOptions;

  constructor(trap?: Partial<SteamTrap | ApiSteamTrap>) {
    if (trap) {
      // Cloning it to remove Vuex Observables
      const cleanTrapData = _.cloneDeep(trap);
      _.mergeWith(this, cleanTrapData, (objValue, srcValue) => {
        if (typeof objValue === "object" && !srcValue) {
          return objValue;
        }
      });

      if (cleanTrapData.sensor) {
        this.sensor = new Sensor(cleanTrapData.sensor as ISensor);
      }

      if (cleanTrapData.customer) {
        this.customer = new CustomerMinimal(cleanTrapData.customer);
        this.customerId = cleanTrapData.customer.id;
      }
    }
  }
}

export interface ISteamTrapHistoryItem extends ApiSteamTrapHistoryItem {}

export enum SteamTrapHistoryEventType {
  alarm = "alarm",
  alarm_acknowledged = "alarm_acknowledged",
  alarm_cleared = "alarm_cleared",
  blowthrough = "blowthrough",
  customer_associate = "customer_associate",
  customer_disassociate = "customer_disassociate",
  sensor_install = "sensor_install",
  sensor_uninstall = "sensor_uninstall",
  steam_off = "steam_off",
  steam_on = "steam_on",
  trap_replaced = "trap_replaced",
  unexpected_cold = "unexpected_cold"
}

export class SteamTrapTemperatureStats {
  ambientTemp?: TemperatureStats;
  condensateTemp?: TemperatureStats;
  maxTimestamp: number;
  minTimestamp: number;
  sampleRangeHours: number;
  samples: number;
  steamTemp?: TemperatureStats;

  constructor(data?: Partial<SteamTrapTemperatureStats>) {
    if (data) {
      Object.assign(this, data);

      if (data.ambientTemp) {
        this.ambientTemp = new TemperatureStats(data.ambientTemp);
      }

      if (data.condensateTemp) {
        this.condensateTemp = new TemperatureStats(data.condensateTemp);
      }

      if (data.steamTemp) {
        this.steamTemp = new TemperatureStats(data.steamTemp);
      }
    }
  }

  updateToTempUnits(selectedTempUnit) {
    if (this.ambientTemp) {
      this.ambientTemp.unpdateToTempUnits(selectedTempUnit);
    }
    if (this.condensateTemp) {
      this.condensateTemp.unpdateToTempUnits(selectedTempUnit);
    }
    if (this.steamTemp) {
      this.steamTemp.unpdateToTempUnits(selectedTempUnit);
    }
  }
}

export class TemperatureStats {
  max?: number;
  min?: number;
  mean?: number;
  median?: number;
  stddev?: number;

  constructor(data?: Partial<TemperatureStats>) {
    if (data) {
      Object.assign(this, data);
    }
  }

  unpdateToTempUnits(selectedTempUnit) {
    for (const key of Object.keys(this).filter((key) => key !== "stddev")) {
      this[key] = this[key]
        ? TemperatureHelper.getConvertedTemperature(
            this[key],
            "CELSIUS",
            selectedTempUnit
          )
        : undefined;
    }
  }
}
