<script lang="ts">
import { defineComponent } from 'vue'
import useCodexButton from '@/composables/useCodexButton'

export default defineComponent({
  props: {
    ariaLabel: {
      type: String,
      required: false,
      default: 'close.'
    }
  },
  setup(props, { emit }) {
    const { handleClick } = useCodexButton(emit)

    return { handleClick }
  }
})
</script>

<template>
  <button class="close" :aria-label="ariaLabel" @click="handleClick">
    <span aria-hidden="true"><slot>&times;</slot></span>
  </button>
</template>

<style scoped lang="scss">
* {
  --close-button-color_default: #666;
  --close-button-color-hover_default: #555;
  --close-button-active-font-size-factor_default: 1.1;
}

.close {
  font-family: Helvetica, sans-serif;
  color: var(--close-button-color, var(--close-button-color_default));
  cursor: pointer;
  background-color: transparent;
  padding: 0;
  border: 0;
  font-size: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;

  &:hover,
  &:focus {
    /* font-size: 1.65rem; */
    font-size: calc(
      1.5rem *
        var(
          --close-button-active-font-size-factor,
          var(--close-button-active-font-size-factor_default)
        )
    );

    color: var(--close-button-color-hover, var(--close-button-color-hover_default));
  }

  span {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    transition:
      font-size 0.15s,
      color 0.15s;
  }
}
</style>
