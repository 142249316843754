import {
  ApiError,
  Notification as EANotification,
  IPaginationInfo,
  ISteamTrap,
  ISteamTrapHistoryItem,
  ISteamTrapsAggregateCosts,
  SteamTrap,
  SteamTrapGroundTruth,
  SteamTrapNotificationResolution,
  SteamTrapTemperatureStats,
  TrapStateCount
} from "@/types";
import {
  CustomerAssociateDetail as ApiCustomerAssociateDetail,
  IntegrationInfo as ApiIntegrationInfo,
  SensorInstallUpdate as ApiSensorInstallUpdate,
  SteamTrapGroundTruth as ApiSteamTrapGroundTruth,
  SteamTrapNotificationResolution as ApiSteamTrapNotificationResolution,
  SteamTrapPost as ApiSteamTrapPost,
  SteamTrapUpdate as ApiSteamTrapUpdate,
  SensorInstallEvent
} from "./api";
import { EveractiveApi } from "./everactiveApi";
import { EveractiveApiResponse } from "./everactiveApiResponse";

export class SteamTrapsApi {
  public static everactiveApi = new EveractiveApi().client.steamTrap;
  private static groundTruthApi = new EveractiveApi().client
    .steamTrapGroundTruth;

  public static async superAdminUpdateTrapStatus({
    trapId,
    trapState,
    effectiveTimestamp,
    priorStateConfirmed
  }): Promise<any | null> {
    try {
      const response = await this.everactiveApi.putSteamtrapsTrapIdState({
        trapId,
        requestBody: {
          trapId,
          trapState,
          effectiveTimestamp,
          priorStateConfirmed
        }
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public static async fetchTriageList({
    page,
    customerId
  }: {
    page: number;
    customerId: string | undefined;
  }): Promise<any | null> {
    try {
      const response = await this.everactiveApi.getSteamtraps({
        isFailed: true,
        sortBy: "trapState",
        sortDir: "desc",
        page,
        customerId
      });
      return response;
    } catch (e) {
      return null;
    }
  }

  public static async fetchTotalMonitoredTraps({
    customerId
  }: {
    customerId: string | undefined;
  }): Promise<any | null> {
    try {
      const response = await this.everactiveApi.getSteamtraps({
        pageSize: 1,
        sensorInstalled: true,
        customerId
      });
      return response.paginationInfo;
    } catch (e) {
      return null;
    }
  }

  public static async fetchTrapStateCounts({
    customerId
  }: {
    customerId: string | undefined;
  }): Promise<TrapStateCount[]> {
    try {
      const response = await this.everactiveApi.getSteamtrapsCountStates({
        customerId
      });
      return response.data as TrapStateCount[];
    } catch (e) {
      return null;
    }
  }

  public static async fetchTimeseriesData({
    trapId,
    startTime,
    endTime,
    downsampleRate
  }): Promise<any | null> {
    try {
      const response = await this.everactiveApi.getSteamtrapsTrapIdTimeseries({
        trapId,
        downsampleRate: downsampleRate ? downsampleRate : null,
        startTime,
        endTime
      });
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, error: e.errors };
    }
  }

  public static async createSteamTrap(
    steamTrap: Partial<ISteamTrap>
  ): Promise<EveractiveApiResponse<ISteamTrap>> {
    const requestBody = <ApiSteamTrapPost>{
      customerId: steamTrap.customer?.id || (steamTrap as any).customerId,
      integrationInfo:
        steamTrap.integrationInfo as unknown as ApiIntegrationInfo,
      siteDetail: steamTrap.siteDetail,
      trapDetail: steamTrap.trapDetail
    };

    if (steamTrap.sensor?.macAddress) {
      requestBody.sensor = {
        macAddress: steamTrap.sensor?.macAddress
      };
      if (steamTrap.sensorInstallTimestamp) {
        requestBody.sensor.installTimestamp = steamTrap.sensorInstallTimestamp;
      }
    }

    try {
      const response = await this.everactiveApi.postSteamtraps({
        requestBody
      });
      return { success: true, data: response.data };
    } catch (e) {
      console.error(`SteamTrapsApi.createSteamTrap - error`, steamTrap, e);
      if (e.body) {
        return e.body;
      }
    }
    return {
      success: false,
      message: "Steam trap was not created successfully."
    };
  }

  public static async deleteSteamTrap(id: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteSteamtrapsTrapId({
        trapId: id
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  public static async fetchSteamTraps({
    customerId,
    sensorInstalled = null,
    svcTag
  }): Promise<any | null> {
    try {
      const response = await this.everactiveApi.getSteamtraps({
        customerId,
        sensorInstalled,
        svcTag
      });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public static async getSteamTraps(
    queryParams
  ): Promise<EveractiveApiResponse<ISteamTrap[]>> {
    // Must filter out blank string keys
    const filteredQueryParams = Object.fromEntries(
      Object.entries(queryParams).filter(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, value]) => value !== null && value !== undefined && value !== ""
      )
    );

    try {
      const response =
        await this.everactiveApi.getSteamtraps(filteredQueryParams);
      return response;
    } catch (e) {
      return null;
    }
  }

  public static async getSteamTrap(id: string): Promise<SteamTrap | null> {
    try {
      const response = await this.everactiveApi.getSteamtrapsTrapId({
        trapId: id
      });
      return new SteamTrap(response.data);
    } catch (e) {
      console.error("SteamTrapApis.getSteamTrap: unable to get steam trap", id);

      return null;
    }
  }

  public static async getSteamTrapTemperatureStats(
    id: string,
    timestamp: number
  ): Promise<SteamTrapTemperatureStats[] | null> {
    try {
      const response =
        await this.everactiveApi.getSteamtrapsTrapIdTemperatureStats({
          trapId: id,
          endTime: timestamp
        });
      return response.data;
    } catch (e) {
      return null;
    }
  }

  public static async updateSteamTrap(
    steamTrap: Partial<ISteamTrap>
  ): Promise<EveractiveApiResponse<ISteamTrap>> {
    if (!steamTrap.id) {
      return { success: false, message: "Steam trap is missing an id value" };
    }

    const requestBody = <ApiSteamTrapUpdate>{
      id: steamTrap.id,
      siteDetail: steamTrap.siteDetail,
      trapDetail: steamTrap.trapDetail
    };

    try {
      const response = await this.everactiveApi.putSteamtrapsTrapId({
        trapId: steamTrap.id,
        requestBody
      });
      return { success: true, data: response.data };
    } catch (e) {
      console.error("SteamTrapApis.updateSteamTrap - error", steamTrap, e);
      if (e.body) {
        return e.body;
      }
    }
    return {
      success: false,
      message: "Steam trap was not successfully updated."
    };
  }

  public static async updateSteamTrapCustomer(
    steamTrap: Partial<ISteamTrap>
  ): Promise<EveractiveApiResponse<ISteamTrap>> {
    if (!steamTrap.id) {
      return { success: false, message: "Steam trap is missing an id value." };
    }

    const requestBody = <ApiCustomerAssociateDetail>{
      customerId: steamTrap.customer.id
    };

    try {
      const response =
        await this.everactiveApi.putSteamtrapsTrapIdCustomersCustomerId({
          trapId: steamTrap.id,
          customerId: steamTrap.customer.id,
          requestBody
        });
      return { success: true, data: response.data };
    } catch (e) {
      console.error(
        "SteamTrapApis.updateSteamTrapCustomer - error",
        steamTrap,
        e
      );
      if (e.body) {
        return e.body;
      }
    }

    return {
      success: false,
      message: "Steam trap site assignment was not successfully updated."
    };
  }

  public static async removeSensorFromSteamTrap({
    steamTrapId,
    sensorMacAddress,
    startTimestamp,
    endTimestamp
  }): Promise<EveractiveApiResponse<SensorInstallEvent>> {
    if (steamTrapId && sensorMacAddress) {
      const requestBody = <ApiSensorInstallUpdate>{
        endTimestamp,
        sensorIdOrMac: sensorMacAddress,
        startTimestamp
      };

      try {
        const response = await this.everactiveApi.putSteamtrapsTrapIdSensorsSensorIdOrMacInstall(
          {
            trapId: steamTrapId,
            sensorIdOrMac: sensorMacAddress,
            requestBody
          }
        );
        return { success: true, ...response }
      } catch (e) {
        console.error(`SteamTrapsApi.removeSensorFromSteamTrap - error`, e);
        if (e.body) {
          return e.body
        }
      }
    }

    return { success: false }
  }

  public static async getLastSteamTrapsTrapidGroundtruth(trapId: string): Promise<ApiSteamTrapGroundTruth> {
    try {
      const response = await this.groundTruthApi.getSteamTrapsTrapidGroundtruths({
        trapId,
        page: 1,
        pageSize: 1,
      });
      return response?.data[0] || null;
    } catch (e) {
      console.error("SteamTrapsApi.getLastSteamTrapsTrapidGroundtruth - error", e);
      return null;
    }
  }

  public static async addSteamTrapGroundTruth(
    id: string,
    groundTruth: SteamTrapGroundTruth
  ): Promise<string | null> {
    const requestBody = <ApiSteamTrapGroundTruth>groundTruth;

    try {
      const response =
        await this.groundTruthApi.postSteamTrapsTrapidGroundtruths({
          trapId: id,
          requestBody
        });
      return response.data?.id;
    } catch (e) {
      console.error(`SteamTrapsApi.addSteamTrapGroundTruth - error`, e);
    }
    return null;
  }

  public static async editSteamTrapGroundTruth(
    id: string,
    groundTruth: SteamTrapGroundTruth
  ): Promise<boolean> {
    const groundTruthId = groundTruth.id;
    if (!groundTruthId) {
      throw new Error("Unable to edit a ground truth without an id value.");
    }

    const requestBody = <ApiSteamTrapGroundTruth>groundTruth;

    try {
      await this.groundTruthApi.putSteamTrapsTrapidGroundtruthsGroundtruthid({
        trapId: id,
        groundTruthId,
        requestBody
      });
      return true;
    } catch (e) {
      console.error("SteamTrapsApi.editSteamTrapGroundTruth - error", e);
    }
    return false;
  }

  public static async deleteSteamTrapGroundTruth(
    trapId: string,
    groundTruthId: string
  ): Promise<boolean> {
    try {
      await this.groundTruthApi.deleteSteamTrapsTrapidGroundtruthsGroundtruthid(
        {
          trapId,
          groundTruthId
        }
      );
      return true;
    } catch (e) {
      console.error("SteamTrapsApi.deleteSteamTrapGroundTruth - error", e);
    }
    return false;
  }

  public static async getCustomerSteamCost({
    startTime,
    endTime,
    customerId,
    calculationMethod
  }: {
    startTime: number;
    endTime?: number;
    customerId?: string;
    calculationMethod?: "sage" | "everactive" | undefined;
  }): Promise<ISteamTrapsAggregateCosts | null> {
    try {
      const response = await this.everactiveApi.getSteamtrapsAggregateCosts({
        customerId,
        startTime,
        endTime,
        calculationMethod
      });
      return response.data;
    } catch (e) {
      console.error("SteamTrapsApi.getCustomerSteamCost - error", e);
    }
    return null;
  }

  public static async resolveSteamNotification(
    steamTrapId: string,
    notificationId: string,
    resolution: SteamTrapNotificationResolution
  ): Promise<EANotification | ApiError | null> {
    const requestBody = <ApiSteamTrapNotificationResolution>resolution;
    try {
      const response =
        await this.everactiveApi.postSteamtrapsTrapIdNotificationsNotificationIdResolve(
          {
            trapId: steamTrapId,
            notificationId,
            requestBody
          }
        );
      return new EANotification(response.data);
    } catch (e) {
      console.log("SteamTrapsApi.resolveSteamNotification - error", e);
      if (e.body) {
        return e.body;
      }
    }
    return <ApiError>{ message: "Steam trap notification was not resolved" };
  }

  public static async changeSensorOnSteamTrap({
    trapId,
    sensorMacAddress,
    startTimestamp
  }): Promise<EveractiveApiResponse> {
    if (!trapId || !sensorMacAddress) {
      return { success: false }
    }

    const requestBody = <ApiSensorInstallUpdate>{
      sensorIdOrMac: sensorMacAddress,
      startTimestamp
    };

    try {
      const response = await this.everactiveApi.putSteamtrapsTrapIdSensorsSensorIdOrMacInstall({
        trapId,
        sensorIdOrMac: sensorMacAddress,
        requestBody
      });
      return { success: true, ...response }
    } catch (e) {
      console.error(`SteamTrapsApi.changeSensorOnSteamTrap - error`, e);
      if (e.body) {
        return e.body
      }
    }
    return { success: false }
  }

  public static async fetchHistory({ trapId, page, pageSize }): Promise<{
    data?: ISteamTrapHistoryItem[];
    paginationDetails?: IPaginationInfo;
  }> {
    try {
      const response = await this.everactiveApi.getSteamtrapTrapidHistory({
        trapId,
        page,
        pageSize
      });
      return response;
    } catch (e) {
      return {};
    }
  }

  public static async toggleSensorSwappedProbesOnSteamTrap(
    trap: Partial<ISteamTrap>
  ): Promise<boolean> {
    if (!trap.sensor?.macAddress) {
      return false;
    }

    try {
      this.everactiveApi.putSteamtrapsTrapIdSensorsSensorIdOrMacInstall({
        trapId: trap.id,
        sensorIdOrMac: trap.sensor.macAddress,
        requestBody: {
          sensorIdOrMac: trap.sensor.macAddress,
          startTimestamp: trap.sensorInstallTimestamp,
          tempProbesSwapped: trap.sensorInstallTempProbesSwapped
        }
      });
    } catch (e) {
      console.error(
        `SteamTrapsApi.toggleSensorSwappedProbesOnSteamTrap - error`,
        e
      );
      return false;
    }
  }
}
