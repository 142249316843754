import { PrivilegeKey } from "@/client/api";

const ApiCredentials = () =>
  import(
    /* webpackChunkName: "APICredentials" */ "@/views/admin/ApiCredentials.vue"
  );

const ApiCredentialsProfile = () =>
  import(
    /* webpackChunkName: "APICredentialsProfile" */ "@/views/admin/ApiCredentialsProfile.vue"
  );

const Webhooks = () =>
  import(
    /* webpackChunkName: "Webhooks" */ "@/views/admin/WebhooksList.vue"
  );

const WebhooksForm = () =>
  import(
    /* webpackChunkName: "WebhooksForm" */ "@/views/admin/WebhooksForm.vue"
  );

const Customers = () =>
  import(
    /* webpackChunkName: "Customers" */ "@/views/admin/Customers.vue"
  );
const CustomerProfile = () =>
  import(
    /* webpackChunkName: "CustomerProfile" */ "@/views/admin/CustomerProfile.vue"
  );
const Management = () =>
  import(/* webpackChunkName: "Management" */ "@/views/admin/Management.vue");
const Users = () =>
  import(/* webpackChunkName: "Users" */ "@/views/admin/Users.vue");
const UserProfile = () =>
  import(
    /* webpackChunkName: "UserProfile" */ "@/views/admin/UserProfile.vue"
  );
const Sensors = () =>
  import(/* webpackChunkName: "Sensors" */ "@/views/admin/Sensors.vue");
const SensorDetail = () =>
  import(
    /* webpackChunkName: "SensorDetail" */ "@/views/admin/SensorDetail.vue"
  );
const DevKitSensors = () =>
  import(
    /* webpackChunkName: "Sensors" */ "@/views/admin/DevKitSensors.vue"
  );
const DevKitSensorDetail = () =>
  import(
    /* webpackChunkName: "SensorDetail" */ "@/views/admin/DevKitSensorDetail.vue"
  );
const Gateways = () =>
  import(
    /* webpackChunkName: "Gateways" */ "@/views/admin/Gateways.vue"
  );
const GatewayDetail = () =>
  import(
    /* webpackChunkName: "GatewayDetail" */ "@/views/admin/GatewayDetail.vue"
  );
const GatewayEdit = () =>
  import(
    /* webpackChunkName: "GatewayEdit" */ "@/views/admin/GatewayEdit.vue"
  );

const apiCredentialsRoute = {
  path: "/developer-tools/api-credentials",
  name: "APICredentials",
  meta: {
    title: "API Credentials",
    privilegeRequirement: [PrivilegeKey.SUPER_ADMIN, PrivilegeKey.DEVELOPER],
    key: "management"
  },
  component: ApiCredentials
};

const apiCredentialsProfileRoute = {
  path: "/developer-tools/api-credentials/:clientId",
  props: true,
  name: "API Credentials Detail",
  meta: {
    title: "API Credentials Detail",
    privilegeRequirement: [PrivilegeKey.SUPER_ADMIN, PrivilegeKey.DEVELOPER],
    key: "management"
  },
  component: ApiCredentialsProfile
};

const webhooksRoute = {
  path: "/developer-tools/webhooks",
  name: "Webhooks",
  meta: {
    title: "Webhooks",
    privilegeRequirement: [PrivilegeKey.SUPER_ADMIN, PrivilegeKey.DEVELOPER],
    key: "management"
  },
  component: Webhooks
};

const webhooksFormRoute = {
  path: "/developer-tools/webhooks/:webhookId",
  props: true,
  name: "Webhooks Form",
  meta: {
    title: "Webhooks Form",
    privilegeRequirement: [PrivilegeKey.SUPER_ADMIN, PrivilegeKey.DEVELOPER],
    key: "management"
  },
  component: WebhooksForm
};

const customerRoute = {
  path: "/customers",
  alias: "/sites",
  name: "Customers",
  meta: {
    title: "Customers",
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.ASSET_MANAGER
    ],
    key: "management"
  },
  component: Customers
};

const customerProfileRoute = {
  path: "/customers/:customerId",
  alias: "/sites/:customerId",
  props: true,
  name: "Customer Profile",
  component: CustomerProfile,
  meta: {
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.ASSET_MANAGER
    ]
  }
};

const managementRoute = {
  path: "/management",
  name: "Management",
  meta: {
    title: "Management",
    key: "management"
  },
  component: Management
};

const usersRoute = {
  path: "/users",
  name: "Users",
  meta: {
    title: "Users",
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.IT_ADMIN
    ],
    key: "management"
  },
  component: Users
};

const userProfileRoute = {
  path: "/users/:userId",
  props: true,
  name: "User Profile",
  component: UserProfile,
  meta: {
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.IT_ADMIN
    ]
  }
};

const sensorsRoute = {
  path: "/eversensors",
  name: "Eversensor Management",
  meta: {
    title: "Eversensor Management",
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.ASSET_MANAGER,
      PrivilegeKey.USER,
      PrivilegeKey.READ_ONLY
    ],
    key: "management"
  },
  component: Sensors
};

const sensorRoute = {
  path: "/eversensors/:sensorId",
  props: true,
  name: "Eversensor Detail Page",
  component: SensorDetail,
  meta: {
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.ASSET_MANAGER,
      PrivilegeKey.USER,
      PrivilegeKey.READ_ONLY
    ]
  }
};

const devKitSensorsRoute = {
  path: "/edge-sensors",
  name: "DevKit Sensor Management",
  meta: {
    title: "Sensor Management",
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.ASSET_MANAGER,
      PrivilegeKey.USER,
      PrivilegeKey.READ_ONLY
    ],
    key: "management"
  },
  component: DevKitSensors
};

const devKitSensorRoute = {
  path: "/edge-sensors/:macAddress",
  props: true,
  name: "Eval Kit Sensor Detail Page",
  component: DevKitSensorDetail,
  meta: {
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.ASSET_MANAGER,
      PrivilegeKey.USER,
      PrivilegeKey.READ_ONLY
    ]
  }
};

const gatewaysRoute = {
  path: "/evergateways",
  name: "Manage Evergateways",
  meta: {
    title: "Manage Evergateways",
    key: "management"
  },
  component: Gateways
};

const gatewayEditRoute = {
  path: "/evergateways/:gatewayId/edit",
  props: true,
  name: "Edit Evergateway",
  privilegeRequirement: [
    PrivilegeKey.SUPER_ADMIN,
    PrivilegeKey.CUSTOMER_ADMIN,
    PrivilegeKey.ASSET_MANAGER,
    PrivilegeKey.USER
  ],
  meta: {
    title: "Edit Evergateway",
    key: "management"
  },
  component: GatewayEdit
};

const gatewayRoute = {
  path: "/evergateways/:gatewayId",
  props: true,
  name: "View Evergateway",
  meta: {
    title: "View Evergateway",
    key: "management"
  },
  component: GatewayDetail
};

const routes = [
  apiCredentialsRoute,
  apiCredentialsProfileRoute,
  webhooksRoute,
  webhooksFormRoute,
  customerRoute,
  customerProfileRoute,
  // we used to have a standalone pairing evergateway page
  { path: "/paireg", redirect: "/evergateways" },
  { path: "/sensors", redirect: "/eversensors" },
  { path: "/sensor/:sensorId", redirect: "/eversensors/:sensorId" },
  usersRoute,
  userProfileRoute,
  managementRoute,
  sensorsRoute,
  sensorRoute,
  devKitSensorsRoute,
  devKitSensorRoute,
  gatewaysRoute,
  gatewayEditRoute,
  gatewayRoute
];

export default routes;
