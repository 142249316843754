/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CodexConfigurationService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * delete codex config
	 * Delete the codex config value for the key. If no value is found
	 * for the key the "error" is ignored and a `204` response status code
	 * is returned.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteCodexconfigKey({
		key,
	}: {
		/**
		 * A unique key to lookup the associated codex configuration object.
		 */
		key: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/codexconfig/{key}',
			path: {
				'key': key,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get codex config
	 * Get a JSON object found at the key. This endpoint is not authenticated and will
	 * return an empty data object if the key does not exist.
	 * @returns any Arbitrary configuration values for Codex.
	 * @throws ApiError
	 */
	public getCodexconfigKey({
		key,
	}: {
		/**
		 * A unique key to lookup the associated codex configuration object.
		 */
		key: string,
	}): CancelablePromise<{
		/**
		 * A JSON object holding Codex configuration values.
		 */
		data?: Record<string, any>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/codexconfig/{key}',
			path: {
				'key': key,
			},
			errors: {
				400: `The request is invalid.`,
			},
		});
	}

	/**
	 * create / update codex config
	 * Create or update a codex configuration setting with the key.
	 * @returns any Arbitrary configuration values for Codex.
	 * @throws ApiError
	 */
	public putCodexconfigKey({
		key,
		requestBody,
	}: {
		/**
		 * A unique key to lookup the associated codex configuration object.
		 */
		key: string,
		/**
		 * A JSON object to be stored with the passed key.
		 */
		requestBody?: Record<string, any>,
	}): CancelablePromise<{
		/**
		 * A JSON object holding Codex configuration values.
		 */
		data?: Record<string, any>;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/codexconfig/{key}',
			path: {
				'key': key,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
