/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * alarm state of machine
 */
export enum MachineAlarmState {
	GOOD = 'Good',
	ACKNOWLEDGED = 'Acknowledged',
	ALARM = 'Alarm',
}
