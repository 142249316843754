import { useUserPrivileges } from '@/composables/useUserPrivileges'
import { PrivilegeKey, Product, RouteMeta } from '@/types'

const MachineDashboard = () =>
  import(/* webpackChunkName: "MachineDashboard" */ '@/views/machine/MachineDashboard.vue')
const MachineSummary = () =>
  import(/* webpackChunkName: "MachineSummary" */ '@/views/machine/MachineSummary.vue')
const AddMachineView = () =>
  import(/* webpackChunkName: "AddMachineView" */ '@/views/machine/AddMachineView.vue')
const MachineHome = () =>
  import(/* webpackChunkName: "MachineHome" */ '@/views/machine/MachineHome.vue')
const MachineDetails = () =>
  import(/* webpackChunkName: "MachineDetails" */ '@/views/machine/MachineDetails.vue')
const MachineBulkInstall = () =>
  import(/* webpackChunkName: "MachineBulkInstall" */ '@/views/machine/MachineBulkInstall.vue')

const dashboard = {
  path: '/machine/dashboard',
  name: 'Machine Dashboard',
  meta: {
    title: 'Monitoring Overview',
    key: 'dashboard',
    product: Product.MachineHealth,
    productString: 'machine',
    privilegeFunction: async () => useUserPrivileges().canViewMachines.value
  } as RouteMeta,
  component: MachineDashboard
}

const summary = {
  path: '/machine/summary',
  name: 'Machine Summary',
  meta: {
    title: 'Summary',
    key: 'summary',
    product: Product.MachineHealth,
    productString: 'machine',
    privilegeFunction: async () => useUserPrivileges().canViewMachines.value
  } as RouteMeta,
  component: MachineSummary
}

const addMachine = {
  path: '/machine/add',
  name: 'Add Machine',
  component: AddMachineView,
  meta: {
    product: Product.MachineHealth,
    productString: 'machine',
    privilegeFunction: async () => useUserPrivileges().canAddMachines.value
  } as RouteMeta
}

const home = {
  path: '/machine/:machineId',
  props: true,
  name: 'Machine',
  component: MachineHome,
  meta: {
    product: Product.MachineHealth,
    productString: 'machine',
    privilegeFunction: async () => useUserPrivileges().canViewMachines.value
  } as RouteMeta
}

const details = {
  path: '/machine/:machineId/details',
  props: true,
  name: 'Machine Detail Edit',
  component: MachineDetails,
  meta: {
    product: Product.MachineHealth,
    productString: 'machine',
    privilegeFunction: async () => useUserPrivileges().canViewMachines.value
  } as RouteMeta
}

const bulkUpload = {
  path: '/machine/bulkinstall',
  props: true,
  name: 'Machine Bulk Upload',
  component: MachineBulkInstall,
  meta: {
    product: Product.MachineHealth,
    productString: 'machine',
    privilegeRequirement: [PrivilegeKey.SUPER_ADMIN]
  } as RouteMeta
}

// In CLOUD-1680, we changed "motor" to "machine", so this is to handle redirects
const redirectsToMachine = [
  { path: '/motor/summary', redirect: '/machine/summary' },
  { path: '/motor/add', redirect: '/machine/add' },
  { path: '/motor/:machineId', redirect: '/machine/:machineId' },
  { path: '/motor/:machineId/details', redirect: '/machine/:machineId/details' }
]

const routedMenuItems = [
  {
    route: 'dashboard',
    icon: 'ti-server',
    name: 'Dashboard',
    routeKey: 'dashboard',
    enabled: true
  },
  {
    route: 'summary',
    icon: 'ti-server',
    name: 'Summary',
    routeKey: 'summary',
    enabled: true
  }
]

export default {
  info: {
    shortTitle: 'Machine Health',
    longTitle: 'Machine Health Monitoring',
    key: 'machine'
  },
  routedMenuItems,
  routes: [dashboard, summary, bulkUpload, addMachine, home, details, ...redirectsToMachine]
}
