/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserNotificationAction = {
	/**
	 * The type of action the user performed on the notification.
	 * - `1`: Snoozed
	 * - `2`: Resolved
	 */
	actionTypeId: UserNotificationAction.actionTypeId;
	/**
	 * The timestamp (seconds from 1/1/1970) the user action occurred.
	 */
	createdAt: number;
	/**
	 * Snoozed notifications expire after a period of time. The expiresTimstamp is the timestamp
	 * when the snooze user action expires.
	 */
	expiresTimestamp?: number;
	/**
	 * The unique id of the user notification action.
	 */
	id: string;
	/**
	 * The notification id the user action is related to.
	 */
	notificationId: string;
	/**
	 * The user that created the user notification action.
	 */
	userId: string;
};

export namespace UserNotificationAction {

	/**
	 * The type of action the user performed on the notification.
	 * - `1`: Snoozed
	 * - `2`: Resolved
	 */
	export enum actionTypeId {
		'_1' = 1,
		'_2' = 2,
	}


}

