/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerIntegrationInfo } from './CustomerIntegrationInfo';
import type { CustomerMachineInfo } from './CustomerMachineInfo';
import type { CustomerProducts } from './CustomerProducts';
import type { CustomerSteamTrapInfo } from './CustomerSteamTrapInfo';

export type CustomerUpdate = {
	/**
	 * The cost of producing a ton of steam in US $.
	 */
	costOfSteam?: number;
	enabledProducts?: CustomerProducts;
	/**
	 * The month and day the fiscal year starts for the customer in the format
	 * mm-dd
	 */
	fiscalYearStart?: string;
	/**
	 * The unique id of the customer.
	 */
	readonly id: string;
	integrations?: CustomerIntegrationInfo;
	/**
	 * Indicates if the customer uses SSO to authenticate users related to their organization.
	 */
	isSSO?: boolean;
	/**
	 * The name of the customer.
	 */
	name: string;
	/**
	 * An array of domains associated with the customer SSO identity provider.
	 * This property is required if the `isSSO` property is true.
	 */
	ssoDomains?: Array<string>;
	/**
	 * The status of the customer
	 */
	status: CustomerUpdate.status;
	steamTrapInfo?: CustomerSteamTrapInfo;
	machineInfo?: CustomerMachineInfo;
};

export namespace CustomerUpdate {

	/**
	 * The status of the customer
	 */
	export enum status {
		DECOMMISSIONED = 'Decommissioned',
		DEMO = 'Demo',
		PRODUCTION = 'Production',
		TO_BE_INSTALLED = 'To Be Installed',
	}


}

