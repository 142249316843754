/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerMinimal } from './CustomerMinimal';

/**
 * Metadata defining an HTTP webhook subscription, containing connection information
 * that Evercloud can use to send data to an external webhook endpoint.
 */
export type WebhookSubscription = {
	/**
	 * The unique id of the webhook subscription.
	 */
	readonly id?: string;
	/**
	 * The callback URL that Evercloud will post sensor readings to. Must be HTTPS.
	 */
	callbackUrl: string;
	/**
	 * The type of events that the webhook will receive.
	 */
	eventType: WebhookSubscription.eventType;
	/**
	 * Specifies whether the webhook is enabled.
	 */
	enabled?: boolean;
	/**
	 * The ID of the customer whose sensor readings should be sent to the callback URL.
	 */
	customerId?: string;
	readonly customer?: CustomerMinimal;
	/**
	 * Additional headers to forward with every HTTP request.
	 * Headers are case-insensitive and no duplicate headers are allowed.
	 */
	headers?: Array<{
		/**
		 * The HTTP header name.
		 */
		key: string;
		/**
		 * The HTTP header value.
		 */
		value: string;
	}>;
	/**
	 * Parameters related to the webhook publisher's error and retry handling. Super-admin settings only.
	 */
	errorHandling?: {
		/**
		 * The time in milliseconds to wait following an error before a retry attempt is made.
		 */
		retryDelay?: number;
		/**
		 * The retry backoff in milliseconds.
		 */
		retryBackoff?: number;
		/**
		 * The maximum number of times to retry on errors when sending a batch before failing the task.
		 */
		maxRetries?: number;
		/**
		 * HTTP Response timeout in milliseconds. Default is 30000 (30 seconds).
		 */
		requestTimeout?: number;
	};
};

export namespace WebhookSubscription {

	/**
	 * The type of events that the webhook will receive.
	 */
	export enum eventType {
		SENSOR_READING = 'sensor_reading',
		GATEWAY_STATUS = 'gateway_status',
	}


}

