/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type v202007CustomerMinimal = {
	/**
	 * The unique id of the customer.
	 */
	id: string;
	integrations?: {
		/**
		 * A collection of properties for customers that are integrated with the Armstrong Sage product.
		 */
		sage?: {
			/**
			 * Flag indicating the customer is integrated with Sage.
			 */
			enabled: boolean;
		};
	};
	/**
	 * The name of the customer.
	 */
	name: string;
	/**
	 * The status of the customer
	 */
	status?: v202007CustomerMinimal.status;
	machineInfo?: {
		axisLabels?: {
			/**
			 * The label for the x-axis.
			 */
			'x'?: string;
			/**
			 * The label for the y-axis.
			 */
			'y'?: string;
			/**
			 * The label for the z-axis.
			 */
			'z'?: string;
		};
	};
};

export namespace v202007CustomerMinimal {

	/**
	 * The status of the customer
	 */
	export enum status {
		DECOMMISSIONED = 'Decommissioned',
		DEMO = 'Demo',
		PRODUCTION = 'Production',
		TO_BE_INSTALLED = 'To Be Installed',
	}


}

