import { BryntumGridSetting as ApiBryntumGridSetting} from '@/client/api/models/BryntumGridSetting'

export class BryntumGridSettingsBody {
  public gridSettings: Record<string, any>;
  public gridVersion: string;

  constructor(gridSettings: Record<string, any> = {}, gridVersion = "0.0.0") {
    this.gridSettings = gridSettings;
    this.gridVersion = gridVersion;
  }
}

export class BryntumGridSettings extends BryntumGridSettingsBody implements ApiBryntumGridSetting {
  public id: string;
  public userId: string;
  public gridName: string;
  public updatedAt: number;
  constructor(data: Partial<BryntumGridSettings>) {
    super(data.gridSettings, data.gridVersion);
    Object.assign(this, data);
  }
}
