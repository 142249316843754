<script lang="ts">
import { Auth0PluginApi } from "@/auth/Auth0Plugin";
import RouteHelpers from "@/router/routeHelpers";
import { inject } from "vue";
import { mapActions } from "vuex";

export default {
  name: "Logout",
  async created() {
    const auth = inject("auth") as Auth0PluginApi;
    auth.logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    });
    localStorage.removeItem("currentPasswordCorrect");
    RouteHelpers.clearPreviousUrlInfo();
  },
  methods: {
    ...mapActions("User", {
      logoutUser: "logout"
    })
  }
};
</script>
<template>
  <div></div>
</template>
  
  