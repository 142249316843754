import { Privilege, PrivilegeKey, UserProfile, User } from "@/types";
import { computed, Ref } from "vue";
import moment from "moment-timezone";
import { watchOnceWithFilter } from "./watchOnceWithFilter";
import { useState } from "./useStore";
import TemperatureHelper from "@/lib/TemperatureHelper";

type CurrentUserApi = {
  user: Ref<User | undefined>;
  userId: Ref<string | undefined>;
  customerId: Ref<string | undefined>;
  privileges: Ref<Privilege[]>;
  isSuperAdmin: Ref<boolean>;
  userTimezone: Ref<string>;
  userTimezoneAbbreviation: Ref<string>;
  userTemperatureUnit: Ref<UserProfile.userDisplayTemperatureUnit>;
  formatTimestampInUserTimezone: (timestamp: number, format?: string) => string;
  getTimestampTimezoneAbbreviation: (timestamp: number) => string;
  userLoaded: Promise<void>;
  convertTemperature: (
    temperatureValue: number,
    convertFrom?: string
  ) => number;
};

const { user } = useState<{ user: User }>("User", ["user"]);
const userId = computed(() => user.value.id);
const customerId = computed(() => user.value?.customer?.id);
const privileges = computed(() => user.value?.privileges ?? []);
const isSuperAdmin = computed(() =>
  privileges.value.some((p) => p.key === PrivilegeKey.SUPER_ADMIN)
);
const userTimezone = computed(
  () => user.value?.profile?.userDisplayTimezone ?? "UTC"
);
const userTimezoneAbbreviation = computed(() =>
  moment().tz(userTimezone.value).format("z")
);
const userTemperatureUnit = computed(
  () =>
    user.value?.profile?.userDisplayTemperatureUnit ??
    UserProfile.userDisplayTemperatureUnit.FAHRENHEIT
);

const formatTimestampInUserTimezone: CurrentUserApi["formatTimestampInUserTimezone"] =
  (timestamp, format = "LTS L") => {
    if (!timestamp) {
      return "";
    }

    return moment.unix(timestamp).tz(userTimezone.value).format(format);
  };

const getTimestampTimezoneAbbreviation: CurrentUserApi["getTimestampTimezoneAbbreviation"] =
  (timestamp) => {
    return moment.unix(timestamp).tz(userTimezone.value).format("z");
  };

const userLoaded = new Promise<void>((resolve) =>
  watchOnceWithFilter(
    userId,
    () => Boolean(userId.value),
    () => resolve()
  )
);

function convertTemperature(
  temperatureValue: number,
  convertFrom = "KELVIN"
): number {
  return TemperatureHelper.getConvertedTemperature(
    temperatureValue,
    convertFrom,
    userTemperatureUnit.value,
    false
  ) as number;
}

export function useCurrentUser(): CurrentUserApi {
  return {
    user,
    userId,
    customerId,
    privileges,
    isSuperAdmin,
    userTimezone,
    userTimezoneAbbreviation,
    userTemperatureUnit,
    convertTemperature,
    formatTimestampInUserTimezone,
    getTimestampTimezoneAbbreviation,
    userLoaded
  };
}
