/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseEversensorReading } from './BaseEversensorReading';
import type { EnvironmentNodeReadingSchema } from './EnvironmentNodeReadingSchema';

/**
 * A reading received from an Eversensor with environmental sensor measurements.
 */
export type EnvironmentalNodeReading = (BaseEversensorReading & {
	schema?: EnvironmentNodeReadingSchema;
	schemaVersion?: EnvironmentalNodeReading.schemaVersion;
	humidityMeasurements?: Array<{
		/**
		 * The humidity sensor index (zero-based).
		 */
		sensorIndex?: number;
		/**
		 * The humidity measurement (percent).
		 */
		value?: number;
	}>;
	temperatureMeasurements?: Array<{
		/**
		 * The temperature sensor index (zero-based).
		 */
		sensorIndex?: number;
		/**
		 * The temperature measurement (K).
		 */
		value?: number;
	}>;
	/**
	 * Axis readings from the accelerometer instrument. Acceleration values given in milli-gravities (milli-g). Time values are in milliseconds (ms).
	 */
	movementMeasurement?: {
		/**
		 * Reports true if an acceleration exceeding the threshold has been detected since the last uplink.
		 */
		movement?: boolean;
		initialAcceleration: {
			/**
			 * The measured X-axis acceleration (milli-g).
			 */
			'x': number;
			/**
			 * The measured Y-axis acceleration (milli-g).
			 */
			'y': number;
			/**
			 * The measured Z-axis acceleration (milli-g).
			 */
			'z': number;
		};
	};
	/**
	 * Pressure measurement, measured in hectopascals (hPa).
	 */
	pressureMeasurement?: number;
	/**
	 * PMU energy transfer counts (Harvesting Counts). Each reading may have multiple load counts.  This field is deprecated in favor of "railCounts".
	 */
	loadCounts?: Array<{
		/**
		 * Count
		 */
		count?: number;
		/**
		 * Trim
		 */
		trim?: number;
		/**
		 * Measurement Time (ms)
		 */
		measurementTime?: number;
	}>;
	/**
	 * PMU energy transfer counts (Harvesting Counts), including the time since the previous measurement and a flag to show if the count register overflowed.
	 */
	railCounts?: {
		/**
		 * Time elapsed in milliseconds since the last rail count measurement was taken. Reported as an unsigned 32-bit integer (uint32).
		 */
		timeSinceLastMeasurement: number;
		/**
		 * Count and overflow bit for each of the following PMU rails in the same order: PV_IN, TEG_IN, VCAP_SRC, VCAP_LD, 1P8, 1P2, 0P9, VADJ.
		 */
		counts: Array<{
			/**
			 * The index of the rail count in the original payload.
			 */
			index: number;
			/**
			 * The count for this particular rail. Reported as an unsigned 16-bit integer (uint16).
			 */
			count: number;
			/**
			 * A flag that indicates if the register that reported the "count" property overflowed.
			 */
			overflow: boolean;
		}>;
	};
});

export namespace EnvironmentalNodeReading {

	export enum schemaVersion {
		V1 = 'v1',
	}


}

