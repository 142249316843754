import { App } from 'vue';
import { initializePlugin, Auth0Plugin, Auth0PluginOptions } from './Auth0Plugin'

// TODO: need error route in config
export const InstallAuth0Plugin = {
    install(app: App, options: Auth0PluginOptions): void {
        initializePlugin(app, options)
    },
};

export { Auth0Plugin as Auth0Plugin }
export * from './Auth0Plugin'
