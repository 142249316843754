import { AuthInfo, User } from "@/types";
import { MePasswordUpdate, MeUpdate } from "./api";
import { validateAuthToken } from "./api/validateAuthToken";
import { EveractiveApi } from "./everactiveApi";

export class UserApi {
  public static everactiveApi = new EveractiveApi().client.user;

  public static async verifyLogin(): Promise<any | null> {
    try {
      const response = await validateAuthToken();
      return Array.isArray(response) && response.length > 0
        ? (response[1] as AuthInfo)
        : null;
    } catch (e) {
      console.error("verifyLogin", e);
      return null;
    }
  }

  public static async fetchMe(): Promise<User | boolean> {
    try {
      const response = await this.everactiveApi.getMe();
      return response.data as User;
    } catch (e) {
      console.error("fetchMe", e);
      return false;
    }
  }

  public static async updateUser(user: User): Promise<{ [key: string]: any }> {
    try {
      const requestBody: MeUpdate = {
        email: user.email,
        name: user.name,
        phoneNumber: user.phoneNumber,
        profile: user.profile,
        username: user.email
      };
      const response = await this.everactiveApi.putMe({ requestBody });
      if (response.data !== null && response.data !== undefined) {
        return { success: true };
      }
    } catch (e) {
      console.error("updateUser", e);
      if (e.body) {
        return e.body;
      }
    }
    return {
      success: false,
      message: "User profile was not successfully updated."
    };
  }

  public static async updateUserPassword(updatePassword: any): Promise<any> {
    try {
      const requestBody: MePasswordUpdate = {
        currentPassword: updatePassword.currentPassword,
        newPassword: updatePassword.newPassword
      };
      await this.everactiveApi.putMePassword({ requestBody });
      return { success: true };
    } catch (e) {
      console.error("updateUserPassword", e);
      if (e.body) {
        return e.body;
      }
    }
    return {
      success: false,
      message: "Unable to update user password"
    };
  }
}
