/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DevKitBundle } from '../models/DevKitBundle';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CustomersService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * create dev kit bundle
	 * Create a dev kit bundle and associate it with the customer. This endpoint is additive,
	 * meaning each time new sensors or sunrise dongles are shipped to a customer only the new
	 * sensor mac address and sunrise dongle count need to be sent.
	 *
	 * Each sensor mac address sent creates a sensor in the system and marks it as being part of a dev kit. Each mac
	 * address is checked against the manufacturing database to ensure the mac address is valid.
	 *
	 * Each sunrise dongle will create a virtual gateway instance that is also associated with the customer.
	 * The virtual gateway(s) will be pre-authorized so the developer using the dev-kit will not be required to
	 * pair the gateway to enable sensor data to begin flowing.
	 * @returns any A summary of all dev-kit sensors and gateways for a customer.
	 * @throws ApiError
	 */
	public postCustomerCustomerIdDevkit({
		customerId,
		requestBody,
	}: {
		/**
		 * The id of the customer
		 */
		customerId: string,
		/**
		 * Details about a dev-kit to be shipped to a customer.
		 */
		requestBody?: DevKitBundle,
	}): CancelablePromise<{
		data?: DevKitBundle;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/customers/{customerId}/devkits',
			path: {
				'customerId': customerId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get customer dev kit bundle information
	 * Return a summary of all dev kit sensors and virtual gateways currently associated
	 * with the customer.
	 * @returns any A summary of all dev-kit sensors and gateways for a customer.
	 * @throws ApiError
	 */
	public getCustomersCustomerIdDevkit({
		customerId,
	}: {
		/**
		 * The id of the customer
		 */
		customerId: string,
	}): CancelablePromise<{
		data?: DevKitBundle;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/customers/{customerId}/devkits',
			path: {
				'customerId': customerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
