/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAssociateDetail } from '../models/CustomerAssociateDetail';
import type { HazardousLocationClassification } from '../models/HazardousLocationClassification';
import type { Notification } from '../models/Notification';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { SensorInstallEvent } from '../models/SensorInstallEvent';
import type { SensorInstallUpdate } from '../models/SensorInstallUpdate';
import type { SensorSTMReading } from '../models/SensorSTMReading';
import type { SteamTrap } from '../models/SteamTrap';
import type { SteamTrapAnalyticsStateUpdate } from '../models/SteamTrapAnalyticsStateUpdate';
import type { SteamTrapHistoryItem } from '../models/SteamTrapHistoryItem';
import type { SteamTrapMeasurement } from '../models/SteamTrapMeasurement';
import type { SteamTrapNotificationCreate } from '../models/SteamTrapNotificationCreate';
import type { SteamTrapNotificationResolution } from '../models/SteamTrapNotificationResolution';
import type { SteamTrapPost } from '../models/SteamTrapPost';
import type { SteamTrapSageMeta } from '../models/SteamTrapSageMeta';
import type { SteamTrapsAggregateCosts } from '../models/SteamTrapsAggregateCosts';
import type { SteamTrapsStateCount } from '../models/SteamTrapsStateCount';
import type { SteamTrapStateUpdate } from '../models/SteamTrapStateUpdate';
import type { SteamTrapSteamStateUpdate } from '../models/SteamTrapSteamStateUpdate';
import type { SteamTrapUpdate } from '../models/SteamTrapUpdate';
import type { TimeSeriesListParameterInfo } from '../models/TimeSeriesListParameterInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SteamTrapService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get steam traps
	 * Retrieve a list of steam traps the user is authorized to view.
	 * The returned list of steam traps can be filtered by various
	 * fields using optional query parameters. If more than one filter
	 * value is included the filtering is combined with an `and` statement
	 * meaning the returned traps must meet all filtering criteria.
	 * @returns any A paginated list of steam traps.
	 * @throws ApiError
	 */
	public getSteamtraps({
		customerId,
		customerName,
		customerStatus,
		facility,
		hazardousLocationClassification,
		isFailed,
		location,
		manufacturer,
		page = 1,
		pageSize = 50,
		sensorGatewaySerialNumber,
		sensorId,
		sensorInstalled,
		sensorLastAssociationGatewaySerial,
		sensorLastFirmwareVersion,
		sensorLastPartNumber,
		sensorLastSerialNumber,
		sensorMacAddress,
		sensorManufacturedFirmwareVersion,
		sensorProductName,
		sensorStatus,
		sortBy,
		sortDir,
		steamState,
		svcTag,
		trapApplication,
		trapState,
		trapType,
	}: {
		/**
		 * Filter the steam traps returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customer.id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting steam traps does not have access to the customer.id passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the steam traps returned by the customer name property. The value is filtered using a `LIKE` comparison statement.
		 */
		customerName?: string,
		/**
		 * Filter the steam traps returned based upon the customer status. Multiple values can be passed
		 * as a comma separated value (e.g. &customer.status=demo,production).
		 * The `customer.status` filter is generally only valid for super-admin users.
		 */
		customerStatus?: 'decommissioned' | 'demo' | 'production' | 'toBeInstalled',
		/**
		 * Filter the steam traps returned by the facility property. The value is filtered using a `LIKE` comparison statement.
		 */
		facility?: string,
		hazardousLocationClassification?: HazardousLocationClassification,
		/**
		 * Filter the steam traps returned by current failure status. If set to true only traps currently
		 * in a failed status will be returned. If set to false only traps in a `Good` status will be returned.
		 * If omitted no filtering is performed on trap status.
		 */
		isFailed?: boolean,
		/**
		 * Filter the steam traps returned by the location property. The value is filtered using a `LIKE` comparison statement.
		 */
		location?: string,
		/**
		 * Filter the steam traps returned by the manufacturer property. The value is filtered using a `LIKE` comparison statement.
		 */
		manufacturer?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the steam traps returned by the sensor gatewaySerialNumber property of the last reading. The value is filtered using a `LIKE` comparison statement.
		 */
		sensorGatewaySerialNumber?: string,
		/**
		 * Filter the steam traps returned by the sensor id. An exact match is used effectively limiting the number
		 * of traps that will be returned to one.
		 */
		sensorId?: string,
		/**
		 * Filter the steam traps by if a sensor is currently installed or not. If set to `true` only steam traps with a sensor currently installed
		 * will be returned. If set to `false` only steam traps with out a sensor will be returned.
		 */
		sensorInstalled?: boolean,
		/**
		 * Filter the steam traps returned by the sensor gateway serial of the last association using a `LIKE` comparison statement.
		 */
		sensorLastAssociationGatewaySerial?: string,
		/**
		 * Filter the steam traps returned by the sensor last firmware version using a `LIKE` comparison statement.
		 */
		sensorLastFirmwareVersion?: string,
		/**
		 * Filter the steam traps returned by the sensor last part number using a `LIKE` comparison statement.
		 */
		sensorLastPartNumber?: string,
		/**
		 * Filter the steam traps returned by the sensor last serial number using a `LIKE` comparison statement.
		 */
		sensorLastSerialNumber?: string,
		/**
		 * Filter the steam traps returned by the sensor mac address property. The value is filtered using a `LIKE` comparison statement.
		 */
		sensorMacAddress?: string,
		/**
		 * Filter the steam traps returned by the sensor manufactured firmware version using a `LIKE` comparison statement.
		 */
		sensorManufacturedFirmwareVersion?: string,
		/**
		 * Filter the steam traps returned by the sensor product name using a `LIKE` comparison statement.
		 */
		sensorProductName?: string,
		/**
		 * Filter the steam traps returned by the sensor status property. The value is filtered using a `LIKE` comparison statement.
		 */
		sensorStatus?: string,
		/**
		 * The field to sort the results. If the value passed does not match any of the valid options or the `sortBy` query parameter is omitted
		 * the results will be returned sorted by sensor.lastReading in descending order.
		 */
		sortBy?: 'amountLost' | 'customer.name' | 'customer.status' | 'facility' | 'failureTimestamp' | 'hazardousLocationClassification' | 'id' | 'inletPressure' | 'location' | 'manufacturer' | 'model' | 'operationType' | 'orificeDiameter' | 'pipeSize' | 'sensor.gatewaySerialNumber' | 'sensor.id' | 'sensor.lastAmbientTemp' | 'sensor.lastAssociation' | 'sensor.lastAssociationGatewaySerial' | 'sensor.lastCondensateTemp' | 'sensor.lastHumidity' | 'sensor.lastInfo' | 'sensor.lastInfoFirmwareVersion' | 'sensor.lastInfoPartNumber' | 'sensor.lastInfoSerialNumber' | 'sensor.lastReading' | 'sensor.lastRSSIUplink' | 'sensor.lastSCAP' | 'sensor.lastSteamTemp' | 'sensor.lastVCAP' | 'sensor.macAddress' | 'sensor.manufacturedFirmwareVersion' | 'sensor.productName' | 'sensor.status' | 'steamState' | 'steamStateTimestamp' | 'svcTag' | 'trapApplication' | 'trapState' | 'trapType',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
		/**
		 * Filter the steam traps returned by the current steam state of the trap. The value is filtered using a `LIKE` comparison statement.
		 */
		steamState?: string,
		/**
		 * Filter the steam traps by the steam trap svcTag property. The value is filtered using a `LIKE` comparison statement.
		 */
		svcTag?: string,
		/**
		 * Filter the steam traps by the steam trap application property. The value is filtered using a `LIKE` comparison statement.
		 */
		trapApplication?: string,
		/**
		 * Filter the steam traps by the steam trap state. The value is filtered using a `LIKE` comparison statement.
		 */
		trapState?: string,
		/**
		 * Filter the steam traps by the steam trap type property. The value is filtered using a `LIKE` comparison statement.
		 */
		trapType?: string,
	}): CancelablePromise<{
		data?: Array<SteamTrap>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps',
			query: {
				'customer.id': customerId,
				'customer.name': customerName,
				'customer.status': customerStatus,
				'facility': facility,
				'hazardousLocationClassification': hazardousLocationClassification,
				'isFailed': isFailed,
				'location': location,
				'manufacturer': manufacturer,
				'page': page,
				'pageSize': pageSize,
				'sensor.gatewaySerialNumber': sensorGatewaySerialNumber,
				'sensor.id': sensorId,
				'sensor.installed': sensorInstalled,
				'sensor.lastAssociationGatewaySerial': sensorLastAssociationGatewaySerial,
				'sensor.lastFirmwareVersion': sensorLastFirmwareVersion,
				'sensor.lastPartNumber': sensorLastPartNumber,
				'sensor.lastSerialNumber': sensorLastSerialNumber,
				'sensor.macAddress': sensorMacAddress,
				'sensor.manufacturedFirmwareVersion': sensorManufacturedFirmwareVersion,
				'sensor.productName': sensorProductName,
				'sensor.status': sensorStatus,
				'sortBy': sortBy,
				'sortDir': sortDir,
				'steamState': steamState,
				'svcTag': svcTag,
				'trapApplication': trapApplication,
				'trapState': trapState,
				'trapType': trapType,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create / update steam trap
	 * Create a new steam trap or update an existing steam trap if
	 * the passed sensor macAddress is associated with an existing
	 * steam trap in the Everactive system.
	 *
	 * **Note:** If the macAddress is already associated with a steam trap,
	 * the steam trap will only be updated if the calling client has the appropriate
	 * permissions.
	 * @returns any A steam trap.
	 * @throws ApiError
	 */
	public postSteamtraps({
		requestBody,
	}: {
		/**
		 * A steam trap to create or update.
		 */
		requestBody?: SteamTrapPost,
	}): CancelablePromise<{
		data?: SteamTrap;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get aggregated steam loss costs for the customer
	 * Get the aggregate cost of all steam trap failures over some time window for the customer the current user
	 * belongs to, or optionally a different customer if the user is a super-admin.
	 * @returns any Aggregate cost for a customer's steam trap failures.
	 * @throws ApiError
	 */
	public getSteamtrapsAggregateCosts({
		startTime,
		customerId,
		endTime,
		calculationMethod,
	}: {
		/**
		 * The start of time window over which to calculate total failure costs as a UNIX timestamp (seconds since
		 * epoch).
		 */
		startTime: number,
		/**
		 * Optional customer id list to get the aggregate costs for customers the current session
		 * has access to. Multiple values can be passed as a comma separated value
		 * (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting steam traps does not have access to the customerId passed a `403 Forbidden`
		 * will be returned;
		 */
		customerId?: string,
		/**
		 * Optional end of time window over which to calculate total failure costs as a UNIX timestamp. If omitted,
		 * defaults to "now."
		 */
		endTime?: number,
		/**
		 * If set to `sage`, use steam-loss information from the SAGE API instead of our calculated loss rate. If set
		 * to `everactive`, use our own loss calculation. Method defaults to `everactive` if this parameter is omitted.
		 */
		calculationMethod?: 'sage' | 'everactive',
	}): CancelablePromise<{
		data?: SteamTrapsAggregateCosts;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/aggregate-costs',
			query: {
				'customerId': customerId,
				'startTime': startTime,
				'endTime': endTime,
				'calculationMethod': calculationMethod,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * count occurrences of all steam trap states
	 * Count occurrences of all steam trap states for the customer the current user
	 * belongs to, or optionally a different customer if the user is a super-admin.
	 * @returns any The count of steam traps in each possible state.
	 * @throws ApiError
	 */
	public getSteamtrapsCountStates({
		customerId,
	}: {
		/**
		 * Optional customer id, to count state occurrences for a different customer than the current session. Setting
		 * this if you are not a super admin will result in a `403 Forbidden`.
		 */
		customerId?: string,
	}): CancelablePromise<{
		data?: Array<SteamTrapsStateCount>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/count-states',
			query: {
				'customerId': customerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete steam trap
	 * Delete a steam trap. This operation is a "hard" delete of the steam trap and can not be reverted.
	 * Customer admin users are limited to deleting steam traps assigned to the same customer.
	 *
	 * If the steam trap has an installed sensor, the sensor will NOT be deleted as part of this operation.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteSteamtrapsTrapId({
		trapId,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/steamtraps/{trapId}',
			path: {
				'trapId': trapId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get steam trap
	 * Get details for a steam trap using the Everactive trap ID.
	 * @returns any A steam trap.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapId({
		trapId,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
	}): CancelablePromise<{
		data?: SteamTrap;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}',
			path: {
				'trapId': trapId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update steam trap
	 * Updates a steam trap details. This endpoint is to update the steam trap properties and does not change customer association,
	 * install or uninstall a sensor or update the steam trap or steam calculated state.
	 * @returns any A steam trap.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapId({
		trapId,
		requestBody,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
		/**
		 * The details of an existing steam trap to be updated.
		 */
		requestBody?: SteamTrapUpdate,
	}): CancelablePromise<{
		data?: SteamTrap;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update steam trap analytics state
	 * Set analytics facing state properties on the steam trap.
	 * @returns any A steam trap.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdAnalyticsState({
		trapId,
		requestBody,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
		/**
		 * An object that describes the calculated "analytics state" of a steam trap.
		 */
		requestBody?: SteamTrapAnalyticsStateUpdate,
	}): CancelablePromise<{
		data?: SteamTrap;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/analytics-state',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update steam trap customer association
	 * Set the customer association of the steam trap. If passed, the start timestamp is used as the effective start
	 * date. If not passed the current time is used as the start date of the association.
	 *
	 * If the steam trap was associated with another customer the old association will be deleted and replaced with
	 * the association passed in the request.
	 * @returns any A steam trap.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdCustomersCustomerId({
		trapId,
		customerId,
		requestBody,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
		/**
		 * The id of the customer the steam trap is associated with.
		 */
		customerId: string,
		/**
		 * Details about associating a piece of equipment with a specific customer.
		 */
		requestBody?: CustomerAssociateDetail,
	}): CancelablePromise<{
		data?: SteamTrap;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/customers/{customerId}',
			path: {
				'trapId': trapId,
				'customerId': customerId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get steam loss costs for a single trap
	 * Get the aggregate cost of all failures over some time window for steam trap. If `startTime` and `endTime` are
	 * provided, it will sum all failures that fall within that window; if they are omitted, it will return the
	 * cost of the currently-open failure (if any).
	 * @returns any Aggregate cost for a customer's steam trap failures.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapIdFailureCosts({
		trapId,
		startTime,
		endTime,
		calculationMethod,
	}: {
		trapId: string,
		/**
		 * Optional start of time window over which to calculate total failure costs as a UNIX timestamp (seconds since
		 * epoch).
		 */
		startTime?: number,
		/**
		 * Optional end of time window over which to calculate total failure costs as a UNIX timestamp.
		 */
		endTime?: number,
		/**
		 * If set to `sage`, use steam-loss information from the SAGE API instead of our calculated loss rate. If set
		 * to `everactive`, use our own loss calculation. Method defaults to `everactive` if this parameter is omitted.
		 */
		calculationMethod?: 'sage' | 'everactive',
	}): CancelablePromise<{
		data?: SteamTrapsAggregateCosts;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/failure-costs',
			path: {
				'trapId': trapId,
			},
			query: {
				'startTime': startTime,
				'endTime': endTime,
				'calculationMethod': calculationMethod,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get steam trap measurements
	 * Retrieve a list of measurements made on a steam trap.
	 * The measurements are returned in descending order of
	 * when the measurement was taken (most recent first).
	 * @returns any A list of steam trap measurements.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapidMeasurements({
		trapId,
		page = 1,
		pageSize = 50,
	}: {
		trapId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<SteamTrapMeasurement>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/measurements',
			path: {
				'trapId': trapId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create steam trap measurement
	 * Create a new measurement record for the steam trap.
	 * The steam trap state may be updated if the state passed
	 * is a change from the current state of the steam trap.
	 * @returns any A steam trap measurement.
	 * @throws ApiError
	 */
	public postSteamtrapsTrapidMeasurements({
		trapId,
		requestBody,
	}: {
		trapId: string,
		/**
		 * A measurement to store for a steam trap.
		 */
		requestBody?: SteamTrapMeasurement,
	}): CancelablePromise<{
		data?: SteamTrapMeasurement;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/measurements',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * delete steam trap measurement
	 * Delete a steam trap measurement.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteSteamtrapTrapidMeasurementsMeasurementid({
		trapId,
		measurementId,
	}: {
		trapId: string,
		measurementId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/steamtraps/{trapId}/measurements/{measurementId}',
			path: {
				'trapId': trapId,
				'measurementId': measurementId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get steam trap history
	 * Retrieve a descending event date order list of events for a steam trap.
	 * @returns any A paginated list of steam trap historical events in descending date order.
	 * @throws ApiError
	 */
	public getSteamtrapTrapidHistory({
		trapId,
		page = 1,
		pageSize = 50,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<SteamTrapHistoryItem>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/history',
			path: {
				'trapId': trapId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create steam trap notification
	 * Create a new steam trap notification.
	 * @returns any Detail about a single notification.
	 * @throws ApiError
	 */
	public postSteamtrapsTrapIdNotifications({
		trapId,
		requestBody,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
		/**
		 * A steam trap notification to create.
		 */
		requestBody?: SteamTrapNotificationCreate,
	}): CancelablePromise<{
		data?: Notification;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/notifications',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * resolve steam trap notification
	 * Resolve a steam trap notification.
	 * @returns any Detail about a single notification.
	 * @throws ApiError
	 */
	public postSteamtrapsTrapIdNotificationsNotificationIdResolve({
		trapId,
		notificationId,
		requestBody,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
		notificationId: string,
		/**
		 * Information to resolve a steam trap notification.
		 */
		requestBody?: SteamTrapNotificationResolution,
	}): CancelablePromise<{
		data?: Notification;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/notifications/{notificationId}/resolve',
			path: {
				'trapId': trapId,
				'notificationId': notificationId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get steam trap sensor installations
	 * Get a list of sensor install events for the steam trap in descending start date order.
	 * @returns any A list of sensor installation events.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapIdSensors({
		trapId,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
	}): CancelablePromise<{
		data?: Array<SensorInstallEvent>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/sensors',
			path: {
				'trapId': trapId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update steam trap sensor installation
	 * Install a sensor on a steam trap, or remove a sensor from a steam trap.
	 *
	 * If the `{sensorIdOrMac}` value in the path does not match the currently installed sensor
	 * on the steam trap the existing sensor installation will be ended with an `endTimestamp` matching
	 * the passed `startTimestamp`.
	 *
	 * If the `{sensorIdOrMac}` value matches the currently installed sensor on the steam trap, the installation
	 * event will be updated with the passed values. Specifically if an `endTimestamp` is passed the sensor will
	 * be "removed" from the steam trap by ending the sensor installation event.
	 *
	 * If the `{sensorIdOrMac}` value is installed on another piece of equipment an error will be returned. This endpoint
	 * will not "move" a sensor from one piece of equipment to another. The existing installation event must be ended before
	 * a sensor can be installed on a new piece of equipment.
	 * @returns any A sensor installation event.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdSensorsSensorIdOrMacInstall({
		trapId,
		sensorIdOrMac,
		requestBody,
	}: {
		/**
		 * The steam trap id.
		 */
		trapId: string,
		/**
		 * The id or MAC address of the sensor being installed or removed from the steam trap.
		 */
		sensorIdOrMac: string,
		/**
		 * A request to install a sensor on a piece of equipment or to end an existing installation.
		 */
		requestBody?: SensorInstallUpdate,
	}): CancelablePromise<{
		data?: SensorInstallEvent;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/sensors/{sensorIdOrMac}',
			path: {
				'trapId': trapId,
				'sensorIdOrMac': sensorIdOrMac,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update steam trap state
	 * Update the state of the steam trap. If the steam trap is set to a failure condition the appropriate events are
	 * also created. If the trap is set to a `Good` state the prior failure events are ended or deleted depending on if
	 * the prior state was confirmed.
	 * @returns any A steam trap.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdState({
		trapId,
		requestBody,
	}: {
		trapId: string,
		/**
		 * A steam trap state change request.
		 */
		requestBody?: SteamTrapStateUpdate,
	}): CancelablePromise<{
		data?: SteamTrap;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/state',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update steam trap steam state
	 * Update the steam state of the steam trap.
	 * @returns any A steam trap.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdSteamState({
		trapId,
		requestBody,
	}: {
		trapId: string,
		/**
		 * An object that describes the calculated steam state of a steam trap.
		 */
		requestBody?: SteamTrapSteamStateUpdate,
	}): CancelablePromise<{
		data?: SteamTrap;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/steam-state',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get steam trap temperature stats
	 * Get a list of temperature statistical values for the steam trap for 24, 8 and 1 hour intervals
	 * prior to the passed endTimestamp parameter. If no parameter is provided, the current time (e.g. now)
	 * is used as the endTimestamp.
	 * @returns any A collection of temperature statistical values for a variety of time ranges
	 * for a specific steam trap.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapIdTemperatureStats({
		trapId,
		endTime,
	}: {
		trapId: string,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
	}): CancelablePromise<any> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/temperature-stats',
			path: {
				'trapId': trapId,
			},
			query: {
				'endTime': endTime,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get steam trap timeseries
	 * Retrieve a list of Eversensor readings for a steam trap. This endpoint is intended
	 * to provide data for a user to view. It is not intended as a method to download
	 * large volumes of sensor reading data. As such, the maximum number of data points that
	 * will be returned from this endpoint is 5000.
	 *
	 * Readings will only be provided for time periods the trap has a sensor installed. If the `startTime`
	 * requested is prior to any sensors installed it will be adjusted to match the earliest possible
	 * sensor reading. If the `endTime` provided is after any sensors installed it will be adjusted to
	 * match the last possible sensor reading.
	 *
	 * If the requested time period will produce a payload that exceeds the maximum it will
	 * behave in one of two ways:
	 *
	 * If a downsampleRate has been provided an error will be returned to the client explaining
	 * the requested parameters will exceed the maximum.
	 *
	 * If no downsampleRate is specified the server will calculate a downsampleRate based upon default
	 * sensor settings that results in a result set close to the maximum number of data points. If a sensor
	 * is reporting with a non-default period (due to setup or environmental conditions) the actual number
	 * of returned sensor readings may deviate from the stated maximum.
	 *
	 * The response payload will contain both the data and a summary of the start and end dates, the
	 * returned item count and the downsampleRate used.
	 *
	 * The expected number of sensor readings is based upon a sensor returning a reading every minute,
	 * resulting in 1440 sensor readings per 24 hour period.
	 * @returns any A list of STM sensor readings limited to 5000 data points.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapIdTimeseries({
		trapId,
		startTime,
		endTime,
		downsampleRate,
	}: {
		trapId: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<SensorSTMReading>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/timeseries',
			path: {
				'trapId': trapId,
			},
			query: {
				'startTime': startTime,
				'endTime': endTime,
				'downsampleRate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get SAGE metadata for this trap
	 * Retrieve the metadata for this trap held by SAGE. Note, this will only work for SAGE integrated traps.
	 * @returns any SAGE metadata payload.
	 * @throws ApiError
	 */
	public getSteamtrapsSageMeta({
		trapId,
	}: {
		trapId: string,
	}): CancelablePromise<{
		data?: SteamTrapSageMeta;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/sage-meta',
			path: {
				'trapId': trapId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
