/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Message received from gateway when requesting it's configuration from the cloud.
 */
export type GatewayConfigRequestV2 = {
	/**
	 * The schema version of this object.
	 */
	schema: GatewayConfigRequestV2.schema;
	/**
	 * The time the packet was sent by the gateway.
	 */
	timestamp: string;
	/**
	 * Details about the gateway sending the configuration request.
	 */
	gateway: {
		/**
		 * The Gateway serial number.
		 */
		serial: string;
		/**
		 * The software version of the configuration application currently running on the Gateway.
		 */
		software_version: string;
		/**
		 * A flag indicating if evernet 2 is supported. Boomerang hardware must be present to be `true`.
		 */
		can_support_evernet_2: boolean;
	};
};

export namespace GatewayConfigRequestV2 {

	/**
	 * The schema version of this object.
	 */
	export enum schema {
		GATEWAY_CONFIG_REQUEST_V2_SCHEMA_JSON = 'gateway_config_request_v2.schema.json',
	}


}

