/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A generic wrapper to represent a variety of historical events that occured on a steam trap.
 */
export type SteamTrapHistoryItem = {
	/**
	 * Events related to customer association / disassociation will include the customerName property.
	 */
	customerName?: string;
	/**
	 * The underlying unique id for the event.
	 */
	eventId: string;
	/**
	 * The unix timestamp when the event occurred.
	 */
	eventTimestamp: number;
	/**
	 * The specific type of historical event this item represents.
	 */
	historyType: SteamTrapHistoryItem.historyType;
	/**
	 * For alarm_acknowledged, alarm_cleared, or trap_replaced events, the unix timestamp when the trap was inspected to confirm operational state.
	 * If omitted, the trap was not inspected.
	 */
	inspectionTimestamp?: number;
	notificationType?: SteamTrapHistoryItem.notificationType;
	/**
	 * For trap_replaced events, the unix timestamp when the trap was replaced. Implies alarm_acknowledged.
	 */
	replacementTimestamp?: number;
	/**
	 * The sensor mac address for an `sensor_install` or `sensor_uninstall` historyTypes.
	 */
	sensorMacAddress?: string;
	/**
	 * The name of the user that acknowledged or cleared an alarm on the steam trap.
	 */
	userName?: string;
};

export namespace SteamTrapHistoryItem {

	/**
	 * The specific type of historical event this item represents.
	 */
	export enum historyType {
		ALARM = 'alarm',
		ALARM_ACKNOWLEDGED = 'alarm_acknowledged',
		ALARM_CLEARED = 'alarm_cleared',
		BLOWTHROUGH = 'blowthrough',
		CUSTOMER_ASSOCIATE = 'customer_associate',
		CUSTOMER_DISASSOCIATE = 'customer_disassociate',
		LEAKING_BY = 'leaking_by',
		SENSOR_INSTALL = 'sensor_install',
		SENSOR_UNINSTALL = 'sensor_uninstall',
		STEAM_OFF = 'steam_off',
		STEAM_ON = 'steam_on',
		TRAP_REPLACED = 'trap_replaced',
		UNEXPECTED_COLD = 'unexpected_cold',
	}

	export enum notificationType {
		BLOWTHROUGH = 'blowthrough',
		INTERMITTENT_BLOWTHROUGH = 'intermittent_blowthrough',
		FAILED_COLD = 'failed_cold',
	}


}

