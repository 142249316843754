/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserNotificationPreference } from './UserNotificationPreference';

export type UserProfile = {
	notifications?: Array<UserNotificationPreference>;
	/**
	 * The timezone the user selected for display on Insights.
	 */
	userDisplayTimezone?: string;
	/**
	 * The temperature unit to display to the user on Insights.
	 */
	userDisplayTemperatureUnit?: UserProfile.userDisplayTemperatureUnit;
	/**
	 * The default time range (in seconds) to pull for Eversensor data on asset page load
	 */
	userChartTimeSpan?: number;
	/**
	 * The default downsample (in seconds) to set for Eversensor data on asset page load
	 */
	userChartDownsample?: number;
};

export namespace UserProfile {

	/**
	 * The temperature unit to display to the user on Insights.
	 */
	export enum userDisplayTemperatureUnit {
		CELSIUS = 'Celsius',
		FAHRENHEIT = 'Fahrenheit',
		KELVIN = 'Kelvin',
	}


}

