/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Details related to a user-generated steam trap state change.
 */
export type SteamTrapStateUpdate = {
	/**
	 * The unix timestamp when the state change occurred. If omitted, the current time
	 * is used as the state change effective date.
	 */
	effectiveTimestamp?: number;
	/**
	 * A flag that indicates the prior state of the trap was correct. If this value is set to false
	 * and the prior state of the trap was a failure state, the events around the prior state are deleted.
	 */
	priorStateConfirmed?: boolean;
	/**
	 * The Everactive id for the steam trap.
	 */
	trapId: string;
	/**
	 * The state of the trap.
	 */
	trapState: SteamTrapStateUpdate.trapState;
};

export namespace SteamTrapStateUpdate {

	/**
	 * The state of the trap.
	 */
	export enum trapState {
		GOOD = 'Good',
		CHECK_TRAP_SUSPECTED_BLOWTHROUGH = 'Check Trap: Suspected Blowthrough',
		CHECK_TRAP_UNEXPECTED_COLD_CONDITION = 'Check Trap: Unexpected Cold Condition',
		INTERMITTENT_BLOWTHROUGH_HEAVY = 'Intermittent Blowthrough - Heavy',
		INTERMITTENT_BLOWTHROUGH_MEDIUM = 'Intermittent Blowthrough - Medium',
		INTERMITTENT_BLOWTHROUGH_LIGHT = 'Intermittent Blowthrough - Light',
	}


}

