import { EveractiveApi } from "./everactiveApi";

export class CodexConfigApi {
  public static everactiveApi = new EveractiveApi().client.codexConfiguration;

  public static async getConfig(configName?: string): Promise<any | null> {
    if (!configName) {
      return null;
    }

    try {
      const response = await this.everactiveApi.getCodexconfigKey({
        key: configName,
      })
      if (!response) {
        return null;
      }
      return response.data;
    } catch (e) {
      console.error(`error retrieving "${configName}" config`, e.status);
        return null;
    }
  }
}
