/**
 * Purpose of this class is to aggregate anything that really should be a configurable setting
 * so that at least any temporary shortcuts are easily searchable and replaceable.
 *
 */
export default class Global {
  public static SENSOR_TYPES = {
    available: "Available",
    machineHealth: "MHM",
    steamTrap: "STM"
  };

  public static getCurrencySymbol(): string {
    return "$";
  }

  public static getCurrencyString(): string {
    return "Dollars";
  }

  public static SUMMARY_PAGES = [
    "Eversensor Management",
    "Manage Evergateways",
    "Machine Summary",
    "Steam Trap Summary",
    "Customers",
    "Machine Dashboard",
    "Users",
    "APICredentials",
    "Webhooks"
  ];

  public static GRID_NAMES = [
    "eversensors",
    "evergateways",
    "machines",
    "steamtraps",
    "groundTruth",
    "sensorData",
    "reportingSensors",
    "customers",
    "users"
  ];

  public static KNOWLEDGE_BASE_URLS = {
    home: "https://everactive.zendesk.com/hc/en-us",
    evergateway:
      "https://everactive.zendesk.com/hc/en-us/sections/1500000492981-Evergateway-EGW-Basics"
  };

  public static AUTH0_URLS = {
    userMetadata: "https://sr2.ca/claims/user_metadata",
    appMetadata: "https://sr2.ca/claims/app_metadata"
  };

  public static EVERACTIVE_EMAIL_DOMAIN = "everactive.com";

  public static NEW_ITEM_SPECIAL_IDENTIFIER = "new";
}
