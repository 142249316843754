/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AuthService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get single sign-on domain list
	 * Get a list of all single sign-on domains registered.
	 * @returns any A list of single sign-on domains.
	 * @throws ApiError
	 */
	public getAuthSsodomains(): CancelablePromise<{
		data?: Array<string>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/auth/ssodomains',
			errors: {
				401: `The request did not include the correct authorization or the included authorization has expired.`,
			},
		});
	}

}
