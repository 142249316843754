import { EveractiveApi } from "./everactiveApi";

export class AuthApi {
  public static everactiveApi = new EveractiveApi().client.auth;

  public static async getSSODomains(): Promise<string[]> {
    
    const ssoDomains = [];

    try {
      const response = await this.everactiveApi.getAuthSsodomains();
      if (response?.data?.length) {
        ssoDomains.push(...response.data);
      }
    } catch (e) {
      console.error("unable to retrieve sso domains");
    }
    return ssoDomains;
  }
}
