/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerAssociateDetail } from '../models/CustomerAssociateDetail';
import type { Gateway } from '../models/Gateway';
import type { GatewayAssertions } from '../models/GatewayAssertions';
import type { GatewayConfigRequestV1 } from '../models/GatewayConfigRequestV1';
import type { GatewayConfigRequestV2 } from '../models/GatewayConfigRequestV2';
import type { GatewayConfigRequestV3 } from '../models/GatewayConfigRequestV3';
import type { GatewayDailyStatusV3 } from '../models/GatewayDailyStatusV3';
import type { GatewayMqttCredentials } from '../models/GatewayMqttCredentials';
import type { GatewayPairingRequest } from '../models/GatewayPairingRequest';
import type { GatewaySensor } from '../models/GatewaySensor';
import type { GatewaySensorsSummary } from '../models/GatewaySensorsSummary';
import type { GatewayStatusV1 } from '../models/GatewayStatusV1';
import type { GatewayStatusV3 } from '../models/GatewayStatusV3';
import type { GatewayUpdate } from '../models/GatewayUpdate';
import type { GatewayVHostConnectionSuccessAckV3 } from '../models/GatewayVHostConnectionSuccessAckV3';
import type { GatewayVMImageInfo } from '../models/GatewayVMImageInfo';
import type { HazardousLocationClassification } from '../models/HazardousLocationClassification';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { TimeSeriesListParameterInfo } from '../models/TimeSeriesListParameterInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GatewayService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get gateways
	 * Return a list of gateways the client is authorized to view. Super-admin
	 * type users will receive all gateways unless the `customerId` query
	 * parameter is included in the request.
	 * @returns any A list of gateways.
	 * @throws ApiError
	 */
	public getGateways({
		customerId,
		customerName,
		deploymentStatus,
		flavor,
		hazardousLocationClassification,
		id,
		location,
		name,
		page = 1,
		pageSize = 50,
		serialNumber,
		softwareVersion,
		sortBy = 'lastReading',
		sortDir,
		status,
		unpaired,
	}: {
		/**
		 * Filter the gateways returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customer.id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting the gateway list does not have access to the customer.id passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the gateways using a `LIKE` comparison with the customer name property.
		 */
		customerName?: string,
		/**
		 * Filter the gateways using a `LIKE` comparison with the deploymentStatus property.
		 */
		deploymentStatus?: string,
		flavor?: 'production' | 'virtual',
		hazardousLocationClassification?: HazardousLocationClassification,
		/**
		 * Filter the gateways returned by the gateway id. An exact match is used for this filter.
		 */
		id?: string,
		/**
		 * Filter the gateways using a `LIKE` comparison with the location property.
		 */
		location?: string,
		/**
		 * Filter the gateways using a `LIKE` comparison with the name property.
		 */
		name?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the gateways using a `LIKE` comparison with the serialNumber property.
		 */
		serialNumber?: string,
		/**
		 * Filter the gateways using a `LIKE` comparison with the softwareVersion property.
		 */
		softwareVersion?: string,
		/**
		 * Indicate what field should be used to sort the returned list of gateways.
		 * If omitted the gateway's are returned with a descending sort order on the lastReading
		 * timestamp.
		 */
		sortBy?: 'autoAuthorizeExistingSensors' | 'canSupportEvernet2' | 'configSchema' | 'customer.name' | 'deploymentStatus' | 'enableSSH' | 'enableWakeup' | 'evernet1Settings.enabled' | 'evernet2Settings.beaconOffset' | 'evernet2Settings.panId' | 'flavor' | 'hazardousLocationClassification' | 'id' | 'lastDailyStatus' | 'lastReading' | 'lastStatus' | 'location' | 'name' | 'serialNumber' | 'softwareVersion' | 'status',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
		/**
		 * Filter the gateways using a `LIKE` comparison with the status property. The status is determined from
		 * the time stamp of the last received status message. If received within the last 24 hours the status
		 * is `Online`. If the last status was received more than 24 hours ago the status is `Offline`. If no
		 * status message has been received the status is `Never Reported`.
		 */
		status?: string,
		/**
		 * When true, will filter the list of gateways returned to only include gateways
		 * that have not been paired. This flag is only available to super-admin users. If passed for other
		 * users it will be ignored.
		 *
		 * If omitted or set to false the list of gateways returned does not include any unpaired gateways.
		 */
		unpaired?: boolean,
	}): CancelablePromise<{
		data?: Array<Gateway>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways',
			query: {
				'customer.id': customerId,
				'customer.name': customerName,
				'deploymentStatus': deploymentStatus,
				'flavor': flavor,
				'hazardousLocationClassification': hazardousLocationClassification,
				'id': id,
				'location': location,
				'name': name,
				'page': page,
				'pageSize': pageSize,
				'serialNumber': serialNumber,
				'softwareVersion': softwareVersion,
				'sortBy': sortBy,
				'sortDir': sortDir,
				'status': status,
				'unpaired': unpaired,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * request gateway configuration (v1)
	 * A "registration" request received via The Bridge for a gateway.
	 *
	 * If a gateway with the serial number exists an asyncronous configuration message will be sent
	 * to the gateway via The Bridge.
	 *
	 * If no gateway is found with the serial number, a new gateway is created in the system but it
	 * is set to unauthorized. No configuration will be returned to the gateway until the gateway
	 * has been authorized and associated with a customer via the `POST /gateways/pair` endpoint.
	 * @returns void
	 * @throws ApiError
	 */
	public postGatewaysGatewaySerialNumberConfigV1({
		gatewaySerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number of the gateway that originated the config request.
		 */
		gatewaySerialNumber: string,
		/**
		 * A gateway configuration request (v1).
		 */
		requestBody?: GatewayConfigRequestV1,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/gateways/{gatewaySerialNumber}/config/v1/request',
			path: {
				'gatewaySerialNumber': gatewaySerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * request gateway configuration (v2)
	 * A "registration" request received via The Bridge for a gateway.
	 *
	 * If a gateway with the serial number exists an asyncronous configuration message will be sent
	 * to the gateway via The Bridge.
	 *
	 * If no gateway is found with the serial number, a new gateway is created in the system but it
	 * is set to unauthorized. No configuration will be returned to the gateway until the gateway
	 * has been authorized and associated with a customer via the `POST /gateways/pair` endpoint.
	 * @returns void
	 * @throws ApiError
	 */
	public postGatewaysGatewaySerialNumberConfigV2({
		gatewaySerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number of the gateway that originated the config request.
		 */
		gatewaySerialNumber: string,
		/**
		 * A gateway configuration request (v2).
		 */
		requestBody?: GatewayConfigRequestV2,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/gateways/{gatewaySerialNumber}/config/v2/request',
			path: {
				'gatewaySerialNumber': gatewaySerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * request gateway configuration (v3)
	 * A "registration" request received via EvernetBridge for a gateway.
	 *
	 * If a gateway with the serial number exists an asyncronous configuration message will be sent
	 * to the gateway via RabbitMQ.
	 *
	 * If no gateway is found with the serial number, a new gateway is created in the system but it
	 * is set to unauthorized. No configuration will be returned to the gateway until the gateway
	 * has been authorized and associated with a customer via the `POST /gateways/pair` endpoint.
	 * @returns void
	 * @throws ApiError
	 */
	public postGatewaysGatewaySerialNumberConfigV3({
		gatewaySerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number of the gateway that originated the config request.
		 */
		gatewaySerialNumber: string,
		/**
		 * A gateway configuration request (v3).
		 */
		requestBody?: GatewayConfigRequestV3,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/gateways/{gatewaySerialNumber}/config/v3/request',
			path: {
				'gatewaySerialNumber': gatewaySerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * acknowledge gateway vhost connection (v3)
	 * An "acknowledgement" request received via EvernetBridge for a gateway.
	 *
	 * If the gateway was ever assigned to another customer, the ACK sent to this endpoint will
	 * instruct the Broker Manager to revoke the gateway's access to any vhosts belonging
	 * to other customers.
	 * @returns void
	 * @throws ApiError
	 */
	public putGatewaysGatewaySerialNumberVhostAckV3({
		gatewaySerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number of the gateway that successfully connected to its vhost.
		 */
		gatewaySerialNumber: string,
		/**
		 * A gateway vhost connection success acknowledgement message (v3).
		 */
		requestBody?: GatewayVHostConnectionSuccessAckV3,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/gateways/{gatewaySerialNumber}/vhost/v3/ack',
			path: {
				'gatewaySerialNumber': gatewaySerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * pair gateway with a customer
	 * Authorize a gateway to relay sensor data to the Everactive system and associate the gateway
	 * with a specific customer. The gateway is setup with default configuration values as part of this
	 * action.
	 * @returns any A gateway.
	 * @throws ApiError
	 */
	public patchGatewaysPairingCodePair({
		pairingCode,
		requestBody,
	}: {
		/**
		 * The unique pairing code for a gateway.
		 */
		pairingCode: string,
		/**
		 * A request to authorize a gateway in the system and
		 * associate with a customer.
		 */
		requestBody?: GatewayPairingRequest,
	}): CancelablePromise<{
		data?: Gateway;
	}> {
		return this.httpRequest.request({
			method: 'PATCH',
			url: '/gateways/{pairingCode}/pair',
			path: {
				'pairingCode': pairingCode,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get gateway
	 * Return a single gateway. The path parameter can be either the gateway serial number or the
	 * gateway id.
	 * @returns any A gateway.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumber({
		gatewayIdOrSerialNumber,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
	}): CancelablePromise<{
		data?: Gateway;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways/{gatewayIdOrSerialNumber}',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update gateway
	 * Updates a gateway object
	 * @returns any The gateway was successfully updated.
	 * @throws ApiError
	 */
	public putGatewaysGatewayIdOrSerialNumber({
		gatewayIdOrSerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * A subset of gateway properties to update.
		 */
		requestBody?: GatewayUpdate,
	}): CancelablePromise<any> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/gateways/{gatewayIdOrSerialNumber}',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete gateway
	 * Delete a gateway. This operation is not recoverable.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteGatewaysGatewayIdOrSerialNumber({
		gatewayIdOrSerialNumber,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/gateways/{gatewayIdOrSerialNumber}',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update gateway customer association
	 * Updates a gateway customer association.
	 * @returns any The gateway was successfully updated.
	 * @throws ApiError
	 */
	public putGatewaysGatewayIdOrSerialNumberCustomersCustomerId({
		gatewayIdOrSerialNumber,
		customerId,
		requestBody,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * The customerId to associate the gateway with.
		 */
		customerId: string,
		/**
		 * Details about associating a piece of equipment with a specific customer.
		 */
		requestBody?: CustomerAssociateDetail,
	}): CancelablePromise<any> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/gateways/{gatewayIdOrSerialNumber}/customers/{customerId}',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
				'customerId': customerId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get gateway sensors
	 * Return a paginated list of all sensors that have ever reported through the gateway.
	 * @returns any A list of sensors that have ever reported through a gateway.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberSensors({
		gatewayIdOrSerialNumber,
		sensorType,
		macAddress,
		page = 1,
		pageSize = 50,
		sortBy = 'lastReading',
		sortDir,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * Filter the list of sensors returned by the sensor type
		 */
		sensorType?: 'Available' | 'Devkit' | 'MHM' | 'STM',
		/**
		 * Filter the list of sensors returned using a `LIKE` comparison with the mac address.
		 */
		macAddress?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * The field to sort the results. If the value passed does not match any of the valid options or the `sortBy` query parameter
		 * is not passed the results will be returned sorted by the lastReading in descending order.
		 */
		sortBy?: 'id' | 'installationStartTime' | 'lastReading' | 'macAddress' | 'sensorType',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<GatewaySensor>;
		listSummary?: GatewaySensorsSummary;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways/{gatewayIdOrSerialNumber}/sensors',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'sensorType': sensorType,
				'macAddress': macAddress,
				'page': page,
				'pageSize': pageSize,
				'sortBy': sortBy,
				'sortDir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get gateway daily status v3 series
	 * Retrieve a list of v3 daily status messages for a gateway.  This endpoint is intended
	 * to provide data for a user to view. It is not intended as a method to download
	 * large volumes of gateway status messages. As such, the maximum number of data points that
	 * will be returned from this endpoint is 5000.
	 *
	 * If the requested time period will produce a payload that exceeds the maximum it will
	 * behave in one of two ways:
	 *
	 * If a downsampleRate has been provided an error will be returned to the client explaining
	 * the requested parameters will exceed the maximum.
	 *
	 * If no downsampleRate is specified the server will calculate a downsampleRate that results in a result
	 * set close to the maximum number of data points.
	 *
	 * The response payload will contain both the data and a summary of the start and end dates, the
	 * returned item count and the downsampleRate used.
	 *
	 * The expected number of daily status messages assumes 1 daily status message every 24 hours.
	 * @returns any A list of v3 daily gateway status messages.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberStatusV3Daily({
		gatewayIdOrSerialNumber,
		startTime,
		endTime,
		downsampleRate,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<GatewayDailyStatusV3>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways/{gatewayIdOrSerialNumber}/status/v3/daily',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'startTime': startTime,
				'endTime': endTime,
				'downsampleRate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get gateway status v3 series
	 * Retrieve a list of v3 status messages for a gateway.  This endpoint is intended
	 * to provide data for a user to view. It is not intended as a method to download
	 * large volumes of gateway status messages. As such, the maximum number of data points that
	 * will be returned from this endpoint is 5000.
	 *
	 * If the requested time period will produce a payload that exceeds the maximum it will
	 * behave in one of two ways:
	 *
	 * If a downsampleRate has been provided an error will be returned to the client explaining
	 * the requested parameters will exceed the maximum.
	 *
	 * If no downsampleRate is specified the server will calculate a downsampleRate that results in a result
	 * set close to the maximum number of data points.
	 *
	 * The response payload will contain both the data and a summary of the start and end dates, the
	 * returned item count and the downsampleRate used.
	 *
	 * The expected number of status messages assumes 1 status message every 5 minutes.
	 * @returns any A list of v3 gateway status messages.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberStatusV3({
		gatewayIdOrSerialNumber,
		startTime,
		endTime,
		downsampleRate,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<GatewayStatusV3>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways/{gatewayIdOrSerialNumber}/status/v3',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'startTime': startTime,
				'endTime': endTime,
				'downsampleRate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get gateway status v1 series
	 * Retrieve a list of v1 status messages for a gateway.  This endpoint is intended
	 * to provide data for a user to view. It is not intended as a method to download
	 * large volumes of gateway status messages. As such, the maximum number of data points that
	 * will be returned from this endpoint is 5000.
	 *
	 * If the requested time period will produce a payload that exceeds the maximum it will
	 * behave in one of two ways:
	 *
	 * If a downsampleRate has been provided an error will be returned to the client explaining
	 * the requested parameters will exceed the maximum.
	 *
	 * If no downsampleRate is specified the server will calculate a downsampleRate that results in a result
	 * set close to the maximum number of data points.
	 *
	 * The response payload will contain both the data and a summary of the start and end dates, the
	 * returned item count and the downsampleRate used.
	 *
	 * The expected number of status messages assumes 1 status message every 5 minutes.
	 * @returns any A list of v1 gateway status messages.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberStatusV1({
		gatewayIdOrSerialNumber,
		startTime,
		endTime,
		downsampleRate,
	}: {
		/**
		 * The serial number or id of the gateway.
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * The start time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
		/**
		 * The end time for the time series data. Inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime?: number,
		/**
		 * The number of seconds for the downsample interval. (3600 = 1 hour).
		 */
		downsampleRate?: number,
	}): CancelablePromise<{
		data?: Array<GatewayStatusV1>;
		resultSetInfo?: TimeSeriesListParameterInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways/{gatewayIdOrSerialNumber}/status/v1',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'startTime': startTime,
				'endTime': endTime,
				'downsampleRate': downsampleRate,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * set gateway virtual image download info
	 * Set the virtual gateway image download information once it's known. This endpoint
	 * is used by a lambda watching the s3 bucket where the virtual gateway image files are
	 * stored.
	 * @returns any Empty body indicating the gateway virtual machine image information was successfully set.
	 * @throws ApiError
	 */
	public postGatewaysGatewayIdOrSerialNumberVmdownloadinfo({
		gatewayIdOrSerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number or id of the gateway
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * Virtual machine image location and size information.
		 */
		requestBody?: GatewayVMImageInfo,
	}): CancelablePromise<any> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/gateways/{gatewayIdOrSerialNumber}/vmdownloadinfo',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get gateway vm-image
	 * Returns the vm image for the virtual gateway.
	 * @returns any A URL to download the image file.
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberVmImage({
		gatewayIdOrSerialNumber,
	}: {
		/**
		 * The serial number or id of the gateway
		 */
		gatewayIdOrSerialNumber: string,
	}): CancelablePromise<{
		/**
		 * The URL
		 */
		data?: string;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways/{gatewayIdOrSerialNumber}/vm-image',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * upload gateway assertions
	 * Authenticates a gateway based on its Ubuntu Core model and serial assertions,
	 * returning MQTT credentials for Evercloud's RabbitMQ service.
	 * @returns any Connection information for Evercloud's MQTT broker (RabbitMQ).
	 * @throws ApiError
	 */
	public postGatewaysGatewaySerialNumberAssertions({
		gatewaySerialNumber,
		requestBody,
	}: {
		/**
		 * The serial number of the gateway
		 */
		gatewaySerialNumber: string,
		/**
		 * A request from a gateway containing its Ubuntu Core model and serial assertions,
		 * used to authenticate the gateway, register its serial number, and create MQTT
		 * credentials.
		 */
		requestBody?: GatewayAssertions,
	}): CancelablePromise<{
		data?: GatewayMqttCredentials;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/gateways/{gatewaySerialNumber}/assertions',
			path: {
				'gatewaySerialNumber': gatewaySerialNumber,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get gateway mqtt credentials
	 * Retrieves a gateway's MQTT credentials for Evercloud's RabbitMQ service.
	 * @returns any Connection information for Evercloud's MQTT broker (RabbitMQ).
	 * @throws ApiError
	 */
	public getGatewaysGatewayIdOrSerialNumberCredentials({
		gatewayIdOrSerialNumber,
		rotatePassword,
	}: {
		/**
		 * The serial number of the gateway
		 */
		gatewayIdOrSerialNumber: string,
		/**
		 * Rotates the gateway's RabbitMQ password.
		 */
		rotatePassword?: boolean,
	}): CancelablePromise<{
		data?: GatewayMqttCredentials;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/gateways/{gatewayIdOrSerialNumber}/credentials',
			path: {
				'gatewayIdOrSerialNumber': gatewayIdOrSerialNumber,
			},
			query: {
				'rotatePassword': rotatePassword,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
