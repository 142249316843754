import { RouteRecordRaw, RouteMeta as VueRouteMeta } from "vue-router";

export enum Product {
  Steam,
  MachineHealth
}

export interface RouteMeta extends VueRouteMeta{
  title?: string;
  key?: string;
  product?: Product;
  productString?: string;
  privilegeRequirement?: string[];
  privilegeFunction?: () => Promise<boolean>;
}

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
    key?: string;
    product?: Product;
    productString?: string;
    privilegeRequirement?: string[];
    privilegeFunction?: () => Promise<boolean>;
  }
}

export interface RoutedMenuItem {
  route: string;
  icon: string;
  name: string;
  routeKey: string;
  meta?: {
    privilegeFunction?: () => Promise<boolean>;
  };
  enabled?: boolean;
}

export interface ProductConfiguration {
  info: {
    shortTitle: string;
    longTitle: string;
    key: string;
  };
  routedMenuItems: RoutedMenuItem[];
  routes: RouteRecordRaw[];
}