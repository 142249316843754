import AppState from '@/data-store/appState'
import AuthData from '@/data-store/authState'
import BryntumGridSettings from './bryntumGridSettings'
import Gateways from "./gateways";
import Machines from "./machines";
import Sensors from "./sensors";
import SteamTraps from './steamTraps'
import User from './user'
import { createStore } from 'vuex'
import { isDevelopment } from '@/env'

const modules = {
  App: AppState,
  AuthData,
  BryntumGridSettings,
  Gateways,
  Machines,
  Sensors,
  SteamTraps,
  User
}

function initializeState() {
  return {
    keysAndValues: new Map<string, any>()
  }
}

const store = createStore({
  state() {
    return initializeState()
  },
  getters: {
    getKeyValue(state): Function {
      return (key: string) => {
        if (state.keysAndValues.has(key)) {
          return state.keysAndValues.get(key)
        } else {
          return null
        }
      }
    }
  },
  actions: {
    resetVuexState({ commit }): void {
      commit('reset')

      Object.keys(modules).forEach((moduleName) => {
        commit(`${moduleName}/reset`)
      })
    }
  },
  mutations: {
    reset(state): void {
      const s = initializeState()
      Object.keys(s).forEach((key) => {
        state[key] = s[key]
      })
    }
  },
  modules,
  strict: isDevelopment
})

export default store
