import {
  IntegrationInfo as ApiIntegrationInfo,
  Machine as ApiMachine,
  MachineAlarmState as ApiMachineAlarmState,
  MachineAlarmStateCountSummary as ApiMachineAlarmStateCountSummary,
  MachineHealthFFTThresholds as ApiMachineHealthFFTThresholds,
  MachineHealthThresholds as ApiMachineHealthThresholds,
  MachineNEMAFrameList as ApiMachineNEMAFrameList,
  MachinePhases as ApiMachinePhases,
  MachineThresholdCrossingsSummary as ApiMachineThresholdCrossingsSummary,
  MachineTypeList as ApiMachineTypeList
} from '@/client/api'
import ConversionHelper from '@/lib/ConversionHelper'
import TemperatureHelper from '@/lib/TemperatureHelper'
import {
  BulkUploadDetail,
  ConversionTypes,
  CustomerAxisLabels,
  CustomerMinimal,
  ISensor,
  MachineCodeLetter,
  MachineDesignLetter,
  MachineEnclosureType,
  MachineFFTThresholds,
  MachineInsulationClass,
  machineMetadataFields,
  MachineThresholds,
  MetadataField,
  Sensor
} from '@/types'

export enum ThresholdTimeRange {
  Week = 'week',
  Month = 'month',
  Year = 'year',
  AllTime = ''
}

export enum TempThreshold {
  tempAmbient = 'tempAmbient',
  tempTeg = 'tempTeg',
  tempDelta = 'tempDelta'
}

export interface IMachine extends ApiMachine {
  metadataFields: {
    [key: string]: MetadataField
  }
}

export type FFTChartTypes = {
  x: string
  y: string
  z: string
}

class BaseMachine implements IMachine {
  public static thresholdMap: Map<string, string>
  public static fftThresholdMap: Map<string, string>
  public static getFFTChartTypes: Function = ():FFTChartTypes => {
    return {
      z: 'Radial',
      x: 'Tangential',
      y: 'Axial'
    }
  }
  sensorInstallTimestamp?: number
  // Metacontrol fields
  public alarmState: ApiMachineAlarmState = ApiMachineAlarmState.GOOD
  public alarmStateTimestamp?: number = null
  public alarmStateUserName?: string = null
  public manufacturer?: string | null = null
  public equipmentName: string
  public machineType: ApiMachineTypeList = ApiMachineTypeList.MOTOR
  public catalogNumber?: string | null = null
  public customerAssociationTimestamp?: number | null = null
  public customerId?: string | null = null
  public specNumber?: string | null = null
  public horsepower?: number | null = null
  public ratedVoltage?: number | null = null
  public ratedFullLoadAmps?: number | null = null
  public ratedFullLoadRPMs?: number | null = null
  public lineFrequency?: number | null = null
  public phases?: ApiMachinePhases | null = null
  public codeLetter?: MachineCodeLetter | null = null
  public frame?: ApiMachineNEMAFrameList | null = null
  public serviceFactor?: number | null = null
  public designLetter?: MachineDesignLetter | null = null
  public insulationClass?: MachineInsulationClass | null = null
  public NEMANominalEfficiency?: number | null = null
  public powerFactor?: number | null = null
  public ratingDuty?: string | null = null
  public maxAmbientTemperature?: number | null = null
  public certifiedCompliant?: string | null = null
  public bearings?: string | null = null
  public driveEnd?: string | null = null
  public oppositeDriveEnd?: string | null = null
  public enclosureType?: MachineEnclosureType | null = null
  public facility: string
  public location: string
  public bulkUploadDetail?: BulkUploadDetail
  public integrationInfo?: Array<ApiIntegrationInfo>
  public sensor?: ISensor | null = null
  public axisLabels?: CustomerAxisLabels

  // thresholds
  public thresholds?: MachineThresholds | null = new MachineThresholds()
  public fftThresholds?: MachineFFTThresholds | null = new MachineFFTThresholds()

  // Non-metacontrol fields
  public id: string | null = null

  // Machine class specific
  public customer?: CustomerMinimal | null = null

  // Machine field definitions
  public metadataFields: {
    [key: string]: MetadataField
  } = machineMetadataFields

  public constructor(machine?: Partial<IMachine>) {
    if (machine) {
      const cleanMachine = JSON.parse(JSON.stringify(machine))
      Object.assign(this, cleanMachine)

      if (cleanMachine.customer) {
        this.customer = new CustomerMinimal(cleanMachine.customer)
        this.customerId = cleanMachine.customer.id
      }

      if (cleanMachine.thresholds) {
        this.thresholds = new MachineThresholds(cleanMachine.thresholds)
      }

      if (cleanMachine.fftThresholds) {
        this.fftThresholds = new MachineFFTThresholds(cleanMachine.fftThresholds)
      }

      if (cleanMachine.customer?.machineInfo?.axisLabels) {
        this.setAxisLabels(cleanMachine.customer.machineInfo.axisLabels)
      }
    }
  }

  public static initialize() {
    if (!BaseMachine.thresholdMap) {
      BaseMachine.thresholdMap = new Map<string, string>()
      BaseMachine.thresholdMap.set('Radial Overall Vibration Level', 'zEnergy')
      BaseMachine.thresholdMap.set('Tangential Overall Vibration Level', 'xEnergy')
      BaseMachine.thresholdMap.set('Axial Overall Vibration Level', 'yEnergy')
    }

    if (!BaseMachine.fftThresholdMap) {
      BaseMachine.fftThresholdMap = new Map<string, string>()
    }
  }

  public prepMetadataFieldsForAPI() {
    Object.keys(this.metadataFields).forEach((field) => {
      if (!this[field]) {
        this[field] = null
      }
    })
  }

  get editableThresholds() {
    return [...BaseMachine.thresholdMap.entries()]
  }

  get editableFFTThresholds() {
    return [...BaseMachine.fftThresholdMap.entries()]
  }

  static get tempThresholds(): TempThreshold[] {
    return Object.values(TempThreshold)
  }

  public refreshThresholds(): void {
    if (!this.thresholds) {
      this.thresholds = new MachineThresholds()
    }
    for (const threshold of BaseMachine.thresholdMap.values()) {
      this.thresholds[threshold] = this.thresholds[threshold] || {}
    }
  }

  public refreshFFTThresholds(): void {
    if (!this.fftThresholds) {
      this.fftThresholds = new MachineFFTThresholds()
    }
    for (const threshold of BaseMachine.fftThresholdMap.values()) {
      this.fftThresholds[threshold] = this.fftThresholds[threshold] || {}
    }
  }

  public setAxisLabels(axisLabels: CustomerAxisLabels): void {
    const x = axisLabels.x
    const y = axisLabels.y
    const z = axisLabels.z

    this.axisLabels = {
      x: x.charAt(0).toUpperCase() + x.slice(1),
      y: y.charAt(0).toUpperCase() + y.slice(1),
      z: z.charAt(0).toUpperCase() + z.slice(1)
    }
  }

  public setUpThresholdsWithTemp(
    newTemperatureUnit: ApiMachineHealthThresholds.temperatureUnit
  ): void {
    this.refreshThresholds()
    this.refreshFFTThresholds()

    const existingMachineTemperatureUnit = this.thresholds.temperatureUnit || null

    // No conversions or adjustments needed if they match
    if (existingMachineTemperatureUnit && existingMachineTemperatureUnit === newTemperatureUnit) {
      return
    }

    // Otherwise, we need to convert all existing temperature values and set the new temp unit
    try {
      Machine.tempThresholds.forEach((tempThresholdType) => {
        const tempThreshold = this.thresholds[tempThresholdType]

        if (tempThreshold && (tempThreshold.min || tempThreshold.min === 0)) {
          this.thresholds[tempThresholdType].min = parseFloat(
            TemperatureHelper.getConvertedTemperature(
              tempThreshold.min,
              existingMachineTemperatureUnit,
              newTemperatureUnit
            )
          )
        }

        if (tempThreshold && (tempThreshold.max || tempThreshold.max === 0)) {
          this.thresholds[tempThresholdType].max = parseFloat(
            TemperatureHelper.getConvertedTemperature(
              tempThreshold.max,
              existingMachineTemperatureUnit,
              newTemperatureUnit
            )
          )
        }

        this.thresholds.temperatureUnit = newTemperatureUnit
      })
    } catch (e) {
      console.error('Error converting temperature values for the machine', e)
    }
  }

  public relabelThresholdMap(): void {
    BaseMachine.thresholdMap = new Map<string, string>()
    BaseMachine.thresholdMap.set(`${this.axisLabels.z} Overall Vibration Level`, 'zEnergy')
    BaseMachine.thresholdMap.set(`${this.axisLabels.x} Overall Vibration Level`, 'xEnergy')
    BaseMachine.thresholdMap.set(`${this.axisLabels.y} Overall Vibration Level`, 'yEnergy')
  }

  public convertThresholds(newUnit: ConversionTypes): void {
    const fftThresholdUnitsMatch =
      this.fftThresholds?.thresholdUnit.toString() === newUnit.toString()
    const thresholdUnitsMatch =
      this.thresholds?.energyThresholdUnit.toString() === newUnit.toString()
    try {
      if (!fftThresholdUnitsMatch && this.fftThresholds) {
        BaseMachine.fftThresholdMap.forEach((fftThresholdName) => {
          const threshold = this.fftThresholds[fftThresholdName]

          if (threshold && (threshold.max || threshold.max === 0)) {
            this.fftThresholds[fftThresholdName].max = ConversionHelper.getConvertedValue(
              threshold.max,
              this.fftThresholds.thresholdUnit,
              newUnit
            )
          }
        })

        switch (newUnit) {
          case ConversionTypes.inchesPerSecond:
            this.fftThresholds.thresholdUnit = ApiMachineHealthFFTThresholds.thresholdUnit.IPS
            break
          case ConversionTypes.millimetersPerSecond:
            this.fftThresholds.thresholdUnit = ApiMachineHealthFFTThresholds.thresholdUnit.MM_S
            break
        }
      }

      if (!thresholdUnitsMatch && this.thresholds) {
        BaseMachine.thresholdMap.forEach((thresholdName) => {
          const threshold = this.thresholds[thresholdName]

          if (threshold && (threshold.min || threshold.min === 0)) {
            this.thresholds[thresholdName].min = ConversionHelper.getConvertedValue(
              threshold.min,
              this.thresholds.energyThresholdUnit,
              newUnit
            )
          }

          if (threshold && (threshold.max || threshold.max === 0)) {
            this.thresholds[thresholdName].max = ConversionHelper.getConvertedValue(
              threshold.max,
              this.thresholds.energyThresholdUnit,
              newUnit
            )
          }
        })

        switch (newUnit) {
          case ConversionTypes.inchesPerSecond:
            this.thresholds.energyThresholdUnit = ApiMachineHealthThresholds.energyThresholdUnit.IPS
            break
          case ConversionTypes.millimetersPerSecond:
            this.thresholds.energyThresholdUnit =
              ApiMachineHealthThresholds.energyThresholdUnit.MM_S
            break
        }
      }
    } catch (e) {
      console.error('Error converting threshold unit values', e)
    }
  }
}

export interface MachineAlarmStateTotal {
  Alarm: number
  Good: number
  Acknowledged: number
}

export interface IMachineAlarmStateCountSummary extends ApiMachineAlarmStateCountSummary {}

export class MachineAlarmStateCountSummary implements IMachineAlarmStateCountSummary {
  alarmState: ApiMachineAlarmState
  total: number
  machineIds: string[]

  constructor(data?: Partial<MachineAlarmStateCountSummary>) {
    if (data) {
      Object.assign(this, data)
    }
  }
}

export class MachineAlarmStatusTrend {
  public day: number
  public alarmStateSummary: MachineAlarmStateCountSummary[]
  public customerId: string

  public constructor(data?: Partial<MachineAlarmStatusTrend>) {
    if (data) {
      Object.assign(this, data)
    }
  }
}

export interface IMachineThresholdCrossingSummary extends ApiMachineThresholdCrossingsSummary {}
export class Machine extends BaseMachine {
  public constructor(machine?: Partial<IMachine>) {
    super(machine)
    if (machine) {
      const cleanMachine = JSON.parse(JSON.stringify(machine))

      if (cleanMachine.sensor) {
        this.sensor = new Sensor(cleanMachine.sensor)
      }
    }
  }
}

export class MachineWithSensorInstallation extends BaseMachine {
  public constructor(machine?: Partial<IMachine>) {
    super(machine)
  }
}
