import { BryntumGridSettings, BryntumGridSettingsBody } from "@/types";
import { ApiError } from "./api";
import { EveractiveApi } from "./everactiveApi";


export class BryntumGridSettingsApi {
  public static everactiveApi = new EveractiveApi().client.bryntumGridSettings;

  public static async fetchAllGridSettings(
    userId: string
  ): Promise<BryntumGridSettings[] | null> {
    const errorMessage = `problem fetching grid settings for userId: ${userId}`;

    try {
      const response = await this.everactiveApi.getBryntumGridSettingsUserId(
        { userId }
      );

      if (!response) {
        console.error(errorMessage);
        return null;
      }

      return response.data as BryntumGridSettings[];
    } catch (e) {
      console.error(errorMessage, e);
      return null;
    }
  }

  public static async getSettingsForGrid({
    userId,
    gridName
  }: {
    userId: string;
    gridName: string;
    }): Promise<BryntumGridSettings | null> {
    
    try {
  
      const response = await this.everactiveApi.getBryntumGridSettingsUserIdGridsGridName({
        userId,
        gridName
      })

      return response.data
    } catch (e) { 
      return null
    }
  }

  public static async saveGridSettings(
    userId: string,
    gridName: string,
    gridSettings: BryntumGridSettingsBody
  ): Promise<BryntumGridSettings | boolean> {

    const errorMessage = `problem saving grid settings for userId: ${userId} and gridName: ${gridName}`;

    try {
      const response = await this.everactiveApi.putBryntumGridSettingsUserIdGridsGridName(
        {
          userId,
          gridName,
          requestBody: gridSettings as BryntumGridSettings,
        }
      )

      if (!response) {
        console.error(errorMessage);
        return null;
      }

      return response.data

    } catch (e) {
      console.error(errorMessage, e);
      return null;
    }
  }

  // There's no delete endpoint, so we send an empty BryntumGridSettingsBody to overwrite previous settings
  public static async deleteGridSetting(
    userId: string,
    gridName: string
  ): Promise<BryntumGridSettings | boolean> {
    const errorMessage = `problem deleting grid settings for userId: ${userId} and gridName: ${gridName}`;
    try {
      const response = await this.everactiveApi.putBryntumGridSettingsUserIdGridsGridName(
        {
          userId,
          gridName,
          requestBody: new BryntumGridSettingsBody() as BryntumGridSettings,
        }
      )

      if (!response) {
        console.error(errorMessage);
        return null;
      }

      return response.data

    } catch (e) {
      console.error(errorMessage, e);
      return null;
    }
  }
}