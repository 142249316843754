/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerIntegrationInfo } from './CustomerIntegrationInfo';
import type { CustomerMachineInfo } from './CustomerMachineInfo';

export type CustomerMinimal = {
	/**
	 * The unique id of the customer.
	 */
	id: string;
	integrations?: CustomerIntegrationInfo;
	/**
	 * The name of the customer.
	 */
	name: string;
	/**
	 * The status of the customer
	 */
	status?: CustomerMinimal.status;
	machineInfo?: CustomerMachineInfo;
};

export namespace CustomerMinimal {

	/**
	 * The status of the customer
	 */
	export enum status {
		DECOMMISSIONED = 'Decommissioned',
		DEMO = 'Demo',
		PRODUCTION = 'Production',
		TO_BE_INSTALLED = 'To Be Installed',
	}


}

