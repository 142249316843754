import { CustomerMinimal } from './customer'
import { type Privilege } from './privilege'
import { 
  User as ApiUser, 
  UserProfile, 
  PrivilegeKey,
  UserNotificationPreference 
} from '@/client/api'

export interface NotificationSetting {
  notificationType: string
  email: boolean
}

export interface ProfileSettings extends UserProfile {}

export interface IUser extends ApiUser {}

export class User implements IUser {
  public id: string
  public auth0Id?: string
  public username: string
  public name: string
  public password: string
  public phoneNumber: string
  public email: string
  public deletedAt: number
  public customer?: CustomerMinimal | null
  public customerId?: string
  public privileges: Privilege[] = []
  lastLoginTimestamp?: number
  public profile?: ProfileSettings = {
    userDisplayTimezone: 'UTC',
    userDisplayTemperatureUnit: UserProfile.userDisplayTemperatureUnit.FAHRENHEIT,
    userChartTimeSpan: 60 * 60 * 24,
    userChartDownsample: null,
    notifications: []
  }
  public provider?: string

  public constructor(data?: Partial<IUser>) {
    if (data) {
      const cleanData = JSON.parse(JSON.stringify(data)) // this allows us to make a copy without bringing any vuex tracking with it
      Object.assign(this, cleanData)

      if (cleanData.customer) {
        this.customer = new CustomerMinimal(cleanData.customer)
      } else if (cleanData.customerId) {
        this.customer = new CustomerMinimal({ id: cleanData.customerId })
      }
    }
  }

  public get isDeveloper(): boolean {
    return this.privileges?.some((p) => p.key === PrivilegeKey.DEVELOPER)
  }

  public set isDeveloper(value: boolean) {
    if (value) {
      this.privileges = this.privileges || []
      this.privileges.push({
        key: PrivilegeKey.DEVELOPER,
        displayValue: 'Developer'
      })
    } else {
      if (this.privileges?.length > 0) {
        this.privileges = this.privileges.filter((p) => p.key !== PrivilegeKey.DEVELOPER)
      }
    }
  }

  public getTimezone(): string {
    if (this.profile && this.profile.userDisplayTimezone) {
      return this.profile.userDisplayTimezone
    }
    return 'UTC'
  }

  public getTemperatureUnit(): UserProfile.userDisplayTemperatureUnit {
    if (this.profile && this.profile.userDisplayTemperatureUnit) {
      return this.profile.userDisplayTemperatureUnit
    }
    return UserProfile.userDisplayTemperatureUnit.FAHRENHEIT
  }

  public getNotifications(): UserNotificationPreference[] {
    if (this.profile && this.profile.notifications) {
      return this.profile.notifications
    }
    return []
  }

  public getCustomerName(): string {
    return this.customer && this.customer.name ? this.customer.name : ''
  }

  public hasPrivilege(key: string): boolean {
    return this.privileges && this.privileges.some((p) => p.key === key)
  }
}
