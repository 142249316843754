import { EveractiveIntegrations, Privilege, PrivilegeKey, User } from "@/types";

export class DataPoint {
  public time?: string;
  public values?: any;
}

export interface MACAddressContext {
  title: string;
  text: string;
  invalid: boolean;
}

export function checkPrivilege(key: PrivilegeKey, user: User): boolean {
  const privileges = user.privileges;
  if (privileges) {
    for (const priv in privileges) {
      if (privileges[priv].key === key) {
        return true;
      }
    }
  }

  return false;
}

export function checkPrivilegesWithArray(keys: string[], user: User): boolean {
  if (user.privileges) {
    for (const key of keys) {
      for (const priv of user.privileges) {
        if (priv.key === key) {
          return true;
        }
      }
    }
  }
  return false;
}

export function checkPrivileges(
  keys: string[],
  privileges?: Privilege[]
): boolean {
  if (privileges) {
    for (const key of keys) {
      for (const priv of privileges) {
        if (priv.key === key) {
          return true;
        }
      }
    }
  }

  return false;
}

// Returns true if any of the integration keys match the user's enabled integrations
export function checkIntegrations(
  keys: string[],
  integrations?: EveractiveIntegrations
): boolean {
  const enabledIntegration = (key) =>
    integrations && integrations[key] && integrations[key].enabled;
  return keys.some(enabledIntegration);
}

export function isSageIntegrated(integrations: EveractiveIntegrations = {}) {
  return checkIntegrations(["sage"], integrations);
}

export default {
  checkPrivilege,
  checkPrivilegesWithArray,
  checkPrivileges,
  checkIntegrations
};
