/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MePasswordUpdate } from '../models/MePasswordUpdate';
import type { MeUpdate } from '../models/MeUpdate';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { User } from '../models/User';
import type { UserCreate } from '../models/UserCreate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get my user detail
	 * Return the user detail record for the currently logged in user.
	 * @returns any A user.
	 * @throws ApiError
	 */
	public getMe(): CancelablePromise<{
		data?: User;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/me',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * update my user detail
	 * Update the current user.
	 * @returns any A user.
	 * @throws ApiError
	 */
	public putMe({
		requestBody,
	}: {
		/**
		 * A request to update details for the currently logged in user.
		 */
		requestBody?: MeUpdate,
	}): CancelablePromise<{
		data?: User;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/me',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update my password
	 * Update the password for the currently logged in user.
	 * @returns void
	 * @throws ApiError
	 */
	public putMePassword({
		requestBody,
	}: {
		/**
		 * A request to update the current user's password.
		 */
		requestBody?: MePasswordUpdate,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/me/password',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get users
	 * Return a list of users that meet the passed query parameter conditions.
	 * @returns any A paginated list of users.
	 * @throws ApiError
	 */
	public getUsers({
		customerId,
		email,
		includeDeleted,
		name,
		page = 1,
		pageSize = 50,
		privileges,
		privilegesCustomerId,
		searchTerm,
		username,
		sortBy,
		sortDir,
	}: {
		/**
		 * Filter the users returned by the primary customer the user is assigned to. Multiple values can be passed
		 * as a comma separate value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting users does not have access to the customerId passed an error will be returned.
		 */
		customerId?: string,
		/**
		 * Used to filter the results by the email address entered for the users. The value is filtered using a `starts with` comparison statement.
		 */
		email?: string,
		/**
		 * Flag indicating that deleted users should be included in the result list. If not passed the default value is false.
		 */
		includeDeleted?: boolean,
		/**
		 * Used to filter the results by the name property of the users. The value is filtered using a `like` comparison statement.
		 */
		name?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the list of users returned by one or more privilege assigments. The privileges filter is not
		 * case sensitive.
		 */
		privileges?: Array<'asset-manager' | 'customer-admin' | 'developer' | 'integration-partner' | 'it-admin' | 'read-only' | 'super-admin' | 'user'>,
		/**
		 * Filter the list of users by the customerId of the user privileges. Returns each user that has at least one privilege
		 * for the passed customerId value.
		 */
		privilegesCustomerId?: string,
		/**
		 * Used to filter the results by name, email or username that are `like` the passed `searchTerm` value.
		 */
		searchTerm?: string,
		/**
		 * Used to filter the results by the username property of the users. The value is filtered using a `like` comparison statement.
		 */
		username?: string,
		/**
		 * Indicate what field should be used to sort the returned list of users.
		 * If omitted the users are returned with a descending order on the last login
		 * timestamp.
		 */
		sortBy?: 'email' | 'lastLoginTimestamp' | 'name',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<User>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/users',
			query: {
				'customerId': customerId,
				'email': email,
				'includeDeleted': includeDeleted,
				'name': name,
				'page': page,
				'pageSize': pageSize,
				'privileges': privileges,
				'privileges.customerId': privilegesCustomerId,
				'searchTerm': searchTerm,
				'username': username,
				'sortBy': sortBy,
				'sortDir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create user
	 * Create a user. Super admin clients can create any user. Customer admin users are limited to creating users assigned to
	 * the same customer. Super admin clients can also set the privileges of any user. Users created by customer admins will automatically be assigned a customer user privilege.
	 * @returns any A user.
	 * @throws ApiError
	 */
	public createUsers({
		requestBody,
	}: {
		/**
		 * A request to create a new user.
		 */
		requestBody?: UserCreate,
	}): CancelablePromise<{
		data?: User;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/users',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete user
	 * Delete a user. Super admin clients can delete any user. Customer admin users are limited to deleting users assigned to
	 * the same customer.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteUsersUserId({
		userId,
	}: {
		/**
		 * The user id.
		 */
		userId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/users/{userId}',
			path: {
				'userId': userId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get user
	 * Return a single user.
	 * @returns any A user.
	 * @throws ApiError
	 */
	public getUsersUserId({
		userId,
	}: {
		/**
		 * The user id.
		 */
		userId: string,
	}): CancelablePromise<{
		data?: User;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/users/{userId}',
			path: {
				'userId': userId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update user
	 * Update a user. Super admin clients can update any user. Customer admin users are limited to updating users assigned to
	 * the same customer. Super admin clients can also set the privileges of any user.
	 * @returns any A user.
	 * @throws ApiError
	 */
	public updateUsersUserId({
		userId,
		requestBody,
	}: {
		/**
		 * The user id.
		 */
		userId: string,
		/**
		 * A request to update a user.
		 */
		requestBody?: User,
	}): CancelablePromise<{
		data?: User;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/users/{userId}',
			path: {
				'userId': userId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
