/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserContactDetail = {
	/**
	 * The unique id of the user contact detail.
	 */
	id: string;
	/**
	 * The type of contact detail in the value property.
	 */
	type: UserContactDetail.type;
	/**
	 * The actual contact information.
	 */
	value: string;
};

export namespace UserContactDetail {

	/**
	 * The type of contact detail in the value property.
	 */
	export enum type {
		EMAIL = 'email',
		PHONE_NUMBER = 'phoneNumber',
	}


}

