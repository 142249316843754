/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { CustomersService } from './services/CustomersService';
import { EvergatewaysService } from './services/EvergatewaysService';
import { EversensorsService } from './services/EversensorsService';
import { HealthService } from './services/HealthService';
import { MqttService } from './services/MqttService';
import { VhostsService } from './services/VhostsService';
import { WebhooksService } from './services/WebhooksService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class Api {

	public readonly customers: CustomersService;
	public readonly evergateways: EvergatewaysService;
	public readonly eversensors: EversensorsService;
	public readonly health: HealthService;
	public readonly mqtt: MqttService;
	public readonly vhosts: VhostsService;
	public readonly webhooks: WebhooksService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? 'https://api.data.everactive.com/ds/v1',
			VERSION: config?.VERSION ?? '1',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
		});

		this.customers = new CustomersService(this.request);
		this.evergateways = new EvergatewaysService(this.request);
		this.eversensors = new EversensorsService(this.request);
		this.health = new HealthService(this.request);
		this.mqtt = new MqttService(this.request);
		this.vhosts = new VhostsService(this.request);
		this.webhooks = new WebhooksService(this.request);
	}
}

