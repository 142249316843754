/* eslint-disable @typescript-eslint/no-use-before-define */
import { RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import adminRoutes from "./adminRoutes";
import baseRoutes from "./baseRoutes";
import RouteHelpers from "./routeHelpers";
import { initializeRouterHooks } from "@/composables/router";
import {
  rewriteHashGuard,
  authorizationGuard,
  authenticationGuard,
  rootPathGuard
} from "./guards";

//import { suppressRedirectErrors } from "./suppressRedirectErrors";

const PageNotFound = () =>
  import(/* webpackChunkName: "PageNotFound" */ "@/views/PageNotFound.vue");
const UserDeniedRouteAccess = () =>
  import(
    /* webpackChunkName: "UserDeniedRouteAccess" */ "@/views/UserDeniedRouteAccess.vue"
  );
const NoProductsEnabled = () =>
  import(
    /* webpackChunkName: "NoProductsEnabled" */ "@/views/NoProductsEnabled.vue"
  );
const AuthenticationError = () =>
  import(
    /* webpackChunkName: "AuthorizationError" */ "@/views/AuthenticationError.vue"
  );

//suppressRedirectErrors(Router);

const applicationRoutes: Array<RouteRecordRaw> = [
  ...baseRoutes,
  ...adminRoutes,
  {
    path: "/no-products-enabled",
    component: NoProductsEnabled
  },
  {
    path: "/access-denied",
    name: "Access Denied",
    component: UserDeniedRouteAccess
  },
  {
    path: "/authentication-error",
    name: "Authentication Error",
    meta: { title: "Authentication Error" },
    component: AuthenticationError
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Page Not Found (404)",
    meta: { title: "Page Not Found" },
    component: PageNotFound
  }
];

const routesArray =
  RouteHelpers.addProductConfigurationRoutes(applicationRoutes);


const router = createRouter({
  history: createWebHistory(),
  routes: routesArray
});
  
initializeRouterHooks(router);

router.beforeEach(rewriteHashGuard);
router.beforeEach(authenticationGuard);
router.beforeEach(rootPathGuard);
router.beforeEach(authorizationGuard);

export default router;
