/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Machine } from '../models/Machine';
import type { MachineAlarmStateChangeEvent } from '../models/MachineAlarmStateChangeEvent';
import type { PaginationInfo } from '../models/PaginationInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MachineAlarmStateService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get machine alarm state change events
	 * Retrieve a list of alarm state change events in descending data order for
	 * a machine.
	 * @returns any A list of machine alarm state change events.
	 * @throws ApiError
	 */
	public getMachinesMachineIdAlarmStates({
		machineId,
		page = 1,
		pageSize = 50,
	}: {
		machineId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<MachineAlarmStateChangeEvent>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/{machineId}/alarmstates',
			path: {
				'machineId': machineId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get machine alarm state change event
	 * Retrieve a single machine alarm state change event.
	 * @returns any A machine alarm state change event.
	 * @throws ApiError
	 */
	public getMachinesMachineIdAlarmStatesAlarmStateOrEventId({
		machineId,
		alarmStateOrEventId,
	}: {
		machineId: string,
		/**
		 * The alarm state change event id for GET or the new alarm state for the machine,
		 * either `Acknowledged` or `Good`.
		 */
		alarmStateOrEventId: (string | 'Acknowledged' | 'Good'),
	}): CancelablePromise<{
		data?: MachineAlarmStateChangeEvent;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/machines/{machineId}/alarmstates/{alarmStateOrEventId}',
			path: {
				'machineId': machineId,
				'alarmStateOrEventId': alarmStateOrEventId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * set machine alarm state
	 * Set the alarm state of a machine. The endpoint can only be used to acknowledge a machine in a state of 'Alarm'
	 * or to set the machine alarm state to 'Good'. Attempting to set the machine alarm state to 'Alarm' will be rejected.
	 * @returns any A machine.
	 * @throws ApiError
	 */
	public putMachinesMachineIdAlarmStatesAlarmState({
		machineId,
		alarmStateOrEventId,
	}: {
		machineId: string,
		/**
		 * The alarm state change event id for GET or the new alarm state for the machine,
		 * either `Acknowledged` or `Good`.
		 */
		alarmStateOrEventId: (string | 'Acknowledged' | 'Good'),
	}): CancelablePromise<{
		data?: Machine;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/machines/{machineId}/alarmstates/{alarmStateOrEventId}',
			path: {
				'machineId': machineId,
				'alarmStateOrEventId': alarmStateOrEventId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
