export const deploymentEnvironment = import.meta.env
  .VITE_DEPLOYMENT_ENVIRONMENT;
export const isDevelopment =
  import.meta.env.VITE_DEPLOYMENT_ENVIRONMENT === "dev";
export const isProduction =
  import.meta.env.VITE_DEPLOYMENT_ENVIRONMENT === "production";
export const fileUploadMaxFilesPerAsset = Number(
  import.meta.env.VITE_FILE_UPLOAD_MAX_FILES_PER_ENTITY
);
export const fileUploadMaxFileSizeBytes = Number(
  import.meta.env.VITE_FILE_UPLOAD_MAX_FILE_SIZE_BYTES
);
export const fileUploadCaptionCharacterLimit = Number(
  import.meta.env.VITE_FILE_UPLOAD_CAPTION_CHARACTER_LIMIT
);
export const everactiveFilesApiHostname = import.meta.env
  .VITE_EVERACTIVE_FILES_API_HOSTNAME;
export const everactiveFilesApiUrl = `https://${everactiveFilesApiHostname}`;
export const apiUrl202007 = import.meta.env.VITE_EVERACTIVE_API_URL;
export const apiUrl202201 = import.meta.env.VITE_EVERACTIVE_API_V202201_URL;
export const dataServicesApiUrlv1 = import.meta.env
  .VITE_EVERACTIVE_DS_API_V1_URL;
export const auth0Domain = import.meta.env.VITE_AUTH0_DOMAIN;
export const auth0ClientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
export const auth0Audience = import.meta.env.VITE_AUTH0_AUDIENCE;
export const googleAnalyticsId = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;
export const concurrentApiRequests = import.meta.env
  .VITE_CONCURRENT_API_REQUESTS;
export const steamWatchAppUrl = import.meta.env.VITE_STEAMWATCH_URL;
export const unleashProxyUrl = import.meta.env.VITE_UNLEASH_PROXY_URL;
export const unleashProxySecret = import.meta.env.VITE_UNLEASH_PROXY_SECRET;
export const unleashProxyEnv = import.meta.env.VITE_UNLEASH_PROXY_ENV;
export const sageAppUrl = import.meta.env.VITE_SAGE_URL;
export const datadogClientToken = import.meta.env.VITE_DATADOG_CLIENT_TOKEN;
export const maxCustomerSelectionLimit = Number(
  import.meta.env.VITE_MAX_CUSTOMER_SELECTION_LIMIT
);
export const zendeskKey = import.meta.env.VITE_ZENDESK_KEY;
export const dataServicesMaxRequestRange = Number(
  import.meta.env.VITE_DATA_SERVICES_MAX_REQUEST_RANGE
);
export const dataServicesRetentionPeriod = dataServicesMaxRequestRange * 7;
export const insightsUrl = import.meta.env.VITE_AUTH0_REDIRECT_URI
