import RouteHelpers from "../routeHelpers";
import { createNavGuard } from "./createNavGuard";
import store from "@/data-store/index";
import { Auth0Plugin } from '@/auth'


let initialVerification = false;

export const authenticationGuard = createNavGuard(
  "authenticationGuard",
  async (to, _from, next) => {
    if (to?.query && to?.query["error"] === "access_denied") {
      const path = "/authentication-error";
      return next({ path, query: to.query, replace: true });
    }

    const authService = Auth0Plugin
    await authService.isLoaded;
    const isAuthenticated = await authService.isAuthenticated()


    if (!isAuthenticated) {
      RouteHelpers.savePreviousUrlInfo(to);

      // And send the user off to login
      await authService.loginWithRedirect({ targetUrl: to.fullPath });
      return next(false);
    }

    if (initialVerification) {
      return next();
    }

    const success =
      initialVerification || (await store.dispatch("User/verifyLogin"));

    if (!success) {
      return next({ path: "/authentication-error", replace: true });
    }

    initialVerification = true;

    const previousURL = RouteHelpers.getPreviousUrlInfo();


    if (previousURL) {
      // Deleting the url info to prevent infinite looping
      RouteHelpers.clearPreviousUrlInfo();
      return next({ path: previousURL, replace: true });
    } else {
      return next();
    }
  }
);
