/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseEversensorReading } from './BaseEversensorReading';
import type { TempHumidityNodeReadingSchema } from './TempHumidityNodeReadingSchema';

/**
 * A reading received from an Eversensor with humidity and temperature sensor measurements.
 */
export type TempHumidityNodeReading = (BaseEversensorReading & {
	humidityMeasurements?: Array<{
		/**
		 * The humidity sensor index (zero-based).
		 */
		sensorIndex?: number;
		/**
		 * The humidity measurement (percent).
		 */
		value?: number;
	}>;
	schema?: TempHumidityNodeReadingSchema;
	schemaVersion?: TempHumidityNodeReading.schemaVersion;
	temperatureMeasurements?: Array<{
		/**
		 * The temperature sensor index (zero-based).
		 */
		sensorIndex?: number;
		/**
		 * The temperature measurement (K).
		 */
		value?: number;
	}>;
});

export namespace TempHumidityNodeReading {

	export enum schemaVersion {
		V1 = 'v1',
	}


}

