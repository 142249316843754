import { useCurrentCustomer } from "@/composables/useCurrentCustomer";
import { useCurrentUser } from "@/composables/useCurrentUser";
import RouteHelpers from "../routeHelpers";
import { createNavGuard } from "./createNavGuard";

const { userLoaded } = useCurrentUser();
const { customerLoaded } = useCurrentCustomer();

export const authorizationGuard = createNavGuard(
  "authorizationGuard",
  (to, _from, next) => {
    // If the user is being sent to an authentication-error page do not
    // try to authenticate again or we will be in an infinite loop.
    Promise.all([userLoaded, customerLoaded])
      .then(() => {
        // Handle if the route is protected by a privilegeFunction
        if (to.meta.privilegeFunction) {
          to.meta.privilegeFunction().then((canGo: boolean) => {
            if (canGo) {
              RouteHelpers.updateProductFromRoute(to.meta);
              return next();
            } else {
              // Privilege requirement not met and can't go!
              return next({
                path: "/access-denied"
              });
            }
          });
        } else if (to.meta.privilegeRequirement) {
          // Handle if the route is protected by a privilegeRequirement array
          RouteHelpers.ifPrivileged(to).then((canGo) => {
            if (canGo) {
              RouteHelpers.updateProductFromRoute(to.meta);
              return next();
            } else {
              // Privilege requirement not met and can't go!
              return next({
                path: "/access-denied"
              });
            }
          });
        } else {
          // Otherwise, let the user through
          RouteHelpers.updateProductFromRoute(to.meta);
          return next();
        }
      })
      .catch((e) => {
        console.error("unable to get user", e);
        return next();
      });
  }
);
