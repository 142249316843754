import { createNavGuard } from "./createNavGuard";

export const rewriteHashGuard = createNavGuard(
  "rewriteHashGuard",
  (to, _from, next) => {
    // Redirect links from before history mode was turned on to the right ones
    // Only do this if path BEGINS with a hash (ignore hashes later in path)
    if (to.fullPath && to.fullPath.substring(0, 2) === "/#") {
      const path = to.fullPath.substring(2);
      return next({ path, replace: true });
      // Redirect users from the old login page to the "home" route
    } else if (to.path === "/login") {
      return next({ path: "/" });
    } else {
      return next();
    }
  }
);
