export default function (
  emit:
    | ((event: string, ...args: any[]) => void),
  preventDefault = true
) {
  const handleClick = (e: Event) => {
    if (preventDefault) {
      e.preventDefault();
    }
    emit("clickButton", e);
  };

  return { handleClick };
}
