/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerMinimal } from './CustomerMinimal';

/**
 * A sensor associated with a gateway. This object has both a `gatewayReading`
 * represesnting the last time a sensor reported through the gateway as well
 * as a `lastReading` which is the last time the sensor reported through any
 * gateway.
 */
export type GatewaySensor = {
	/**
	 * Flag indicating the sensor is part of an Everactive developer kit.
	 */
	readonly devkitBundled?: boolean;
	/**
	 * The gateway serial number the sensor information is associated with.
	 */
	gatewaySerialNumber: string;
	/**
	 * The id of the sensor.
	 */
	id: string;
	/**
	 * The MAC Address of the sensor.
	 */
	macAddress: string;
	/**
	 * The unix timestamp of when the sensor reading was recorded.
	 */
	ts: number;
	/**
	 * The unix timestamp of when the sensor was installed.
	 */
	installationStartTime: number;
	/**
	 * The id of the steam trap.
	 */
	steamTrapId?: string;
	/**
	 * The id of the machine.
	 */
	machineId?: string;
	customer?: CustomerMinimal;
	/**
	 * Used interally to indicate the type of sensor.
	 */
	type?: GatewaySensor.type;
};

export namespace GatewaySensor {

	/**
	 * Used interally to indicate the type of sensor.
	 */
	export enum type {
		AVAILABLE = 'Available',
		DEVKIT = 'Devkit',
		MHM = 'MHM',
		STM = 'STM',
	}


}

