/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { Sensor } from '../models/Sensor';
import type { SensorConfigRequestV1 } from '../models/SensorConfigRequestV1';
import type { SensorConfigRequestV2 } from '../models/SensorConfigRequestV2';
import type { SensorInList } from '../models/SensorInList';
import type { SensorInstallEvent } from '../models/SensorInstallEvent';
import type { SensorStatusUpdate } from '../models/SensorStatusUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SensorService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get sensors
	 * Retrieve a list of sensors the user is authorized to view. The returned
	 * list of sensors can be filterd by various properties using optional query parameters.
	 * If more than one filter value is included the filtering is combined with an `and` statement
	 * meaning the returned sensors must meet all filtering criteria.
	 * @returns any A paginated list of sensors.
	 * @throws ApiError
	 */
	public getSensors({
		customerId,
		customerMismatch = false,
		customerName,
		devkitBundled,
		id,
		installStatus,
		lastAssociationGatewaySerial,
		lastFirmwareVersion,
		lastPartNumber,
		lastSerialNumber,
		macAddress,
		manufacturedFirmwareVersion,
		productName,
		page = 1,
		pageSize = 50,
		status,
		type,
		sortBy,
		sortDir,
	}: {
		/**
		 * Filter the sensors returned by the customer id. Multiple values can be passed as a comma
		 * separated value (e.g. `&customer.id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting sensors does not have access to the customer.id passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * The `customer.mismatch` flag is only available to `super-admin` users. When set to true the only sensors returned are those
		 * that are installed on a piece of equipment assigned to a different customer than the gateway the last sensor reading was
		 * reported through.
		 *
		 * This flag will only return sensors that have at least one reading packet.
		 */
		customerMismatch?: boolean,
		/**
		 * Filter the sensors returned by the name of the customer the equipment the sensor is installed on is associated with.
		 * This filter will only return currently installed sensors. The value is filtered using a `LIKE` statement.
		 */
		customerName?: string,
		/**
		 * Filter the list by the devkitBundled flag. If not passed no filtering is applied.
		 */
		devkitBundled?: boolean,
		/**
		 * Filter the list by sensor id using a LIKE comparison statement.
		 */
		id?: string,
		/**
		 * Filter the list based upon the current installation status of the sensor. `available` will return
		 * sensors that are not currently installed on a piece of equipment. `installed` will return sensors that
		 * are currently installed on a piece of equipment.
		 */
		installStatus?: 'available' | 'installed',
		/**
		 * Filter the list of sensors by gateway serial of the last association using a `LIKE` comparison statement.
		 */
		lastAssociationGatewaySerial?: string,
		/**
		 * Filter the list of sensors by last firmware version using a `LIKE` comparison statement.
		 */
		lastFirmwareVersion?: string,
		/**
		 * Filter the list of sensors by last part number using a `LIKE` comparison statement.
		 */
		lastPartNumber?: string,
		/**
		 * Filter the list of sensors by last serial number using a `LIKE` comparison statement.
		 */
		lastSerialNumber?: string,
		/**
		 * Filter the list of sensors by mac address using a `LIKE` comparison statement.
		 */
		macAddress?: string,
		/**
		 * Filter the list of sensors by manufactured firmware version using a `LIKE` comparison statement.
		 */
		manufacturedFirmwareVersion?: string,
		/**
		 * Filter the list of sensors by product name using a `LIKE` comparison statement.
		 */
		productName?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Filter the sensors returned by the sensor status property using a `LIKE` comparison statement.
		 * Filtering is case-insensitive.
		 *
		 * Possible sensor status values:
		 * - Configuration Error - Energy Aware - Extreme Temp Probe - Ambient - Extreme Temp Probe - Condensate - Extreme Temp Probe - Steam - Extreme Temp Probe - Teg - Extreme Temp - Surface Too Hot - Gateway Offline - Good - Harvesting Issue - Installed Cold - Long-term Offline - Never Installed - Not Installed - Not Reporting - Offline - Offline Poor RSSI - Offline w/ Power - Online - Service Needed - Service Needed - Harvesting Issue - Sleep - Sleep While Charging - Sleep While Discharging - Unknown
		 */
		status?: string,
		/**
		 * Filter the sensors returned by the type of sensor using a `LIKE` comparison statement.
		 */
		type?: string,
		/**
		 * The field to sort the results. If the value passed does not match any of the valid options
		 * or the `sortBy` query parameter is omitted the results will be returned sorted by lastReading
		 * in descending order.
		 */
		sortBy?: 'customer.name' | 'id' | 'lastAssociation' | 'lastAssociation.gatewaySerial' | 'lastInfo' | 'lastInfo.firmwareVersion' | 'lastInfo.partNumber' | 'lastInfo.serialNumber' | 'lastReading' | 'macAddress' | 'manufacturedFirmwareVersion' | 'productName' | 'rssiUplink' | 'status' | 'type',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<SensorInList>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/sensors',
			query: {
				'customer.id': customerId,
				'customer.mismatch': customerMismatch,
				'customer.name': customerName,
				'devkitBundled': devkitBundled,
				'id': id,
				'installStatus': installStatus,
				'lastAssociationGatewaySerial': lastAssociationGatewaySerial,
				'lastFirmwareVersion': lastFirmwareVersion,
				'lastPartNumber': lastPartNumber,
				'lastSerialNumber': lastSerialNumber,
				'macAddress': macAddress,
				'manufacturedFirmwareVersion': manufacturedFirmwareVersion,
				'productName': productName,
				'page': page,
				'pageSize': pageSize,
				'status': status,
				'type': type,
				'sortBy': sortBy,
				'sortDir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * delete sensor
	 * Delete a sensor. This operation will remove all sensor installation events and metadata associated
	 * with the sensor. Data reported by the sensor will not be removed.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteSensorSensorIdOrMac({
		sensorIdOrMac,
	}: {
		/**
		 * The sensor id or mac address.
		 */
		sensorIdOrMac: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/sensors/{sensorIdOrMac}',
			path: {
				'sensorIdOrMac': sensorIdOrMac,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get sensor
	 * Get details for a sensor use ther sensor Everactive ID or the sensor MAC Address.
	 * @returns any A sensor.
	 * @throws ApiError
	 */
	public getSensorSensorIdOrMac({
		sensorIdOrMac,
	}: {
		/**
		 * The sensor id or mac address.
		 */
		sensorIdOrMac: string,
	}): CancelablePromise<{
		data?: Sensor;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/sensors/{sensorIdOrMac}',
			path: {
				'sensorIdOrMac': sensorIdOrMac,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update sensor status
	 * Update the status or type of a given sensor.
	 * @returns any A sensor.
	 * @throws ApiError
	 */
	public updateSensorSensorIdOrMac({
		sensorIdOrMac,
		requestBody,
	}: {
		/**
		 * The sensor id or mac address.
		 */
		sensorIdOrMac: string,
		/**
		 * Updated status for a sensor.
		 */
		requestBody?: SensorStatusUpdate,
	}): CancelablePromise<{
		data?: Sensor;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/sensors/{sensorIdOrMac}/status',
			path: {
				'sensorIdOrMac': sensorIdOrMac,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get sensor installations
	 * Get a list of all sensor installation events in descending start date order.
	 * @returns any A list of sensor installation events.
	 * @throws ApiError
	 */
	public getSensorSensorIdOrMacInstallations({
		sensorIdOrMac,
	}: {
		/**
		 * The sensor id or mac address.
		 */
		sensorIdOrMac: string,
	}): CancelablePromise<{
		data?: Array<SensorInstallEvent>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/sensors/{sensorIdOrMac}/installations',
			path: {
				'sensorIdOrMac': sensorIdOrMac,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * Request v1 sensor configuration.
	 * Request the v1 configuration for a sensor. Create a new sensor record if it doesn't already exist.
	 * @returns void
	 * @throws ApiError
	 */
	public postSensorSensorMacConfigV1Request({
		sensorMacAddress,
		requestBody,
	}: {
		/**
		 * The sensor mac address.
		 */
		sensorMacAddress: string,
		/**
		 * A sensor configuration request received when a gateway detects an unauthorized sensor.
		 */
		requestBody?: SensorConfigRequestV1,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/sensors/{sensorMacAddress}/config/v1/request',
			path: {
				'sensorMacAddress': sensorMacAddress,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * Request v2 sensor configuration.
	 * Request the v2 configuration for a sensor. Create a new sensor record if it doesn't already exist.
	 * @returns void
	 * @throws ApiError
	 */
	public postSensorSensorMacConfigV2Request({
		sensorMacAddress,
		requestBody,
	}: {
		/**
		 * The sensor mac address.
		 */
		sensorMacAddress: string,
		/**
		 * A sensor configuration request received when a gateway detects an unauthorized sensor.
		 */
		requestBody?: SensorConfigRequestV2,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/sensors/{sensorMacAddress}/config/v2/request',
			path: {
				'sensorMacAddress': sensorMacAddress,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
