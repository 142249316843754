/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { WebhookSubscription } from '../models/WebhookSubscription';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebhookSubscriptionsService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get webhook subscriptions
	 * Get webhook subscriptons for the passed clientId.
	 * @returns any A list of webhook subscriptions
	 * @throws ApiError
	 */
	public getWebhookSubscriptions({
		customerId,
		eventType,
		page = 1,
		pageSize = 50,
		sortBy,
		sortDir,
	}: {
		/**
		 * Filter the list of webhook subscriptions returned by customerId(s). Multiple values can be passed as a
		 * comma separated value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting webhook subscriptions does not have access to the customerId passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the list of webhook subscriptions by the type of event the subscription is notified about.
		 */
		eventType?: 'machine-alarm-state-change-event' | 'trap-state-change-event',
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Indicate what field should be used to sort the returned list of webhook subscriptions.
		 * If omitted the `createdAt` is used as the default sort by parameter.
		 */
		sortBy?: 'createdAt' | 'customerName' | 'eventType',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<WebhookSubscription>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/webhook-subscriptions',
			query: {
				'customerId': customerId,
				'eventType': eventType,
				'page': page,
				'pageSize': pageSize,
				'sortBy': sortBy,
				'sortDir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create new webhook subscription
	 * Create a new webhook subscription.
	 * @returns any A webhook subscription
	 * @throws ApiError
	 */
	public postWebhookSubscriptions({
		requestBody,
	}: {
		/**
		 * A webhook subscription to create or update.
		 */
		requestBody?: WebhookSubscription,
	}): CancelablePromise<{
		data?: WebhookSubscription;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/webhook-subscriptions',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * delete a webhook subscription by id
	 * Delete a webhook subscription.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteWebhookSubscriptionsWebhookSubscriptionId({
		webhookSubscriptionId,
	}: {
		webhookSubscriptionId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/webhook-subscriptions/{webhookSubscriptionId}',
			path: {
				'webhookSubscriptionId': webhookSubscriptionId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get webhook subscription by id
	 * Get webhook subscripton by id
	 * @returns any A webhook subscription
	 * @throws ApiError
	 */
	public getWebhookSubscriptionsWebhookSubscriptionId({
		webhookSubscriptionId,
		includeSecret,
	}: {
		webhookSubscriptionId: string,
		/**
		 * If set to `true` the webhook secret is included in the response.
		 */
		includeSecret?: boolean,
	}): CancelablePromise<{
		data?: WebhookSubscription;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/webhook-subscriptions/{webhookSubscriptionId}',
			path: {
				'webhookSubscriptionId': webhookSubscriptionId,
			},
			query: {
				'includeSecret': includeSecret,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update an existing webhook subscription
	 * Update webhook subscripton by id. This endpoint does not change the `webhookSecret` value. It is to modify The
	 * event type, url or specific authentication required to successfully post events.
	 * @returns any A webhook subscription
	 * @throws ApiError
	 */
	public putWebhookSubscriptionsWebhookSubscriptionId({
		webhookSubscriptionId,
		requestBody,
	}: {
		webhookSubscriptionId: string,
		/**
		 * A webhook subscription to create or update.
		 */
		requestBody?: WebhookSubscription,
	}): CancelablePromise<{
		data?: WebhookSubscription;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/webhook-subscriptions/{webhookSubscriptionId}',
			path: {
				'webhookSubscriptionId': webhookSubscriptionId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
