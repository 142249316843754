/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerIntegrationInfo } from './CustomerIntegrationInfo';
import type { CustomerProducts } from './CustomerProducts';
import type { PrivilegeKey } from './PrivilegeKey';

/**
 * A privilege assigned to a user or api-credential.
 */
export type AuthPrivilege = {
	readonly customer?: {
		enabledProducts?: CustomerProducts;
		/**
		 * The unique id of the customer.
		 */
		id?: string;
		integrations?: CustomerIntegrationInfo;
		/**
		 * The name of the customer.
		 */
		name?: string;
		/**
		 * The status of the customer
		 */
		status?: AuthPrivilege.status;
	};
	/**
	 * The customer id the privilege is for.
	 */
	customerId?: string;
	/**
	 * Optional label to use in the UI for this privilege.
	 */
	readonly displayValue?: string;
	key: PrivilegeKey;
};

export namespace AuthPrivilege {

	/**
	 * The status of the customer
	 */
	export enum status {
		DECOMMISSIONED = 'Decommissioned',
		DEMO = 'Demo',
		PRODUCTION = 'Production',
		TO_BE_INSTALLED = 'To Be Installed',
	}


}

