/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { SteamTrapGroundTruth } from '../models/SteamTrapGroundTruth';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SteamTrapGroundTruthService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get steam trap ground truth measurements
	 * Retrieve a list of ground truth measurements for a steam trap.
	 * The ground truths are returned in descending order of when the measurement
	 * was taken (most recent first).
	 * @returns any A paginated list of steam trap ground truth measurements.
	 * @throws ApiError
	 */
	public getSteamTrapsTrapidGroundtruths({
		trapId,
		page = 1,
		pageSize = 50,
		includeDeleted,
	}: {
		trapId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Flag indicating that deleted ground truths should be included in the result list. If not passed the default value is false.
		 */
		includeDeleted?: boolean,
	}): CancelablePromise<{
		data?: Array<SteamTrapGroundTruth>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/groundtruths',
			path: {
				'trapId': trapId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
				'includeDeleted': includeDeleted,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create steam trap ground truth measurement
	 * Create a new ground truth measurement for a steam trap.
	 * @returns any A single ground truth measurement for a steam trap.
	 * @throws ApiError
	 */
	public postSteamTrapsTrapidGroundtruths({
		trapId,
		requestBody,
	}: {
		trapId: string,
		/**
		 * A ground truth measurement for a steam trap.
		 */
		requestBody?: SteamTrapGroundTruth,
	}): CancelablePromise<{
		data?: SteamTrapGroundTruth;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/groundtruths',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get steam trap ground truth measurement
	 * Get a steam trap ground truth measurement.
	 * @returns any A single ground truth measurement for a steam trap.
	 * @throws ApiError
	 */
	public getSteamtrapsTrapIdGroundtruthsGroundTruthId({
		trapId,
		groundTruthId,
	}: {
		trapId: string,
		groundTruthId: string,
	}): CancelablePromise<{
		data?: SteamTrapGroundTruth;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/groundtruths/{groundTruthId}',
			path: {
				'trapId': trapId,
				'groundTruthId': groundTruthId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * delete steam trap ground truth measurement
	 * Delete a ground truth measurement.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteSteamTrapsTrapidGroundtruthsGroundtruthid({
		trapId,
		groundTruthId,
	}: {
		trapId: string,
		groundTruthId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/steamtraps/{trapId}/groundtruths/{groundTruthId}',
			path: {
				'trapId': trapId,
				'groundTruthId': groundTruthId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update steam trap ground truth measurement
	 * Update an existing ground truth measurement.
	 * @returns any A single ground truth measurement for a steam trap.
	 * @throws ApiError
	 */
	public putSteamTrapsTrapidGroundtruthsGroundtruthid({
		trapId,
		groundTruthId,
		requestBody,
	}: {
		trapId: string,
		groundTruthId: string,
		/**
		 * A ground truth measurement with values to update.
		 */
		requestBody?: SteamTrapGroundTruth,
	}): CancelablePromise<{
		data?: SteamTrapGroundTruth;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/groundtruths/{groundTruthId}',
			path: {
				'trapId': trapId,
				'groundTruthId': groundTruthId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
