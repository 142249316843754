import { App, ref, Ref } from "vue";
import { Router } from "vue-router";
import createDeferred from "p-defer";
import {
  createAuth0Client,
  Auth0Client,
  User,
  AuthenticationError,
  Auth0ClientOptions
} from "@auth0/auth0-spa-js";
import { getFullPath } from "@/lib";
import { validateAuthToken } from "@/client/api/validateAuthToken";

type RedirectAppState = { targetUrl: string };

interface PluginState {
  user: Ref<User | undefined>;
  error: Ref<string | undefined>;
  errorPath: Ref<string>;
  isLoaded: Promise<void>;
}

interface PluginMethods {
  loginWithRedirect: (appState?: RedirectAppState) => Promise<void>;
  logout: Auth0Client["logout"];
  isAuthenticated: Auth0Client["isAuthenticated"];
  getTokenSilently: (options?: { targetUrl?: string }) => Promise<string>;
}

export type Auth0PluginApi = PluginState & PluginMethods;

const clientDeferred = createDeferred<Auth0Client>();
const isLoadedDeferred = createDeferred<void>();
const routerDeferred = createDeferred<Router>();

const state: PluginState = {
  user: ref<User>(),
  error: ref<string>(),
  errorPath: ref(""),
  isLoaded: isLoadedDeferred.promise
};

const loginWithRedirect: PluginMethods["loginWithRedirect"] = async (
  appState
) => {
  const client = await clientDeferred.promise;
  appState = appState ?? { targetUrl: getFullPath() };
  client.loginWithRedirect<RedirectAppState>({ appState });
};

const logout: PluginMethods["logout"] = async (options = {}) => {
  state.user.value = undefined;
  const client = await clientDeferred.promise;
  options.logoutParams = {
    returnTo: options?.logoutParams?.returnTo ?? location.origin
  };
  await client.logout(options);
};

const isAuthenticated: PluginMethods["isAuthenticated"] = async () => {
  const client = await clientDeferred.promise;
  return client.isAuthenticated();
};

const getTokenSilently: PluginMethods["getTokenSilently"] = async (options) => {
  if (import.meta.env.STORYBOOK) {
    return "fake-token";
  }

  const client = await clientDeferred.promise;
  let response = "";

  try {
    response = await client.getTokenSilently();
    return response;
  } catch (e: unknown) {
    return "";
  }
};

export const Auth0Plugin: Auth0PluginApi = {
  ...state,
  loginWithRedirect,
  isAuthenticated,
  logout,
  getTokenSilently
};

export type Auth0PluginOptions = Auth0ClientOptions & { errorPath: string };

export async function initializePlugin(
  app: App,
  { errorPath, ...auth0ClientOptions }: Auth0PluginOptions
): Promise<void> {
  state.errorPath.value = errorPath;
  const client = await createAuth0Client(auth0ClientOptions);
  clientDeferred.resolve(client);
  routerDeferred.resolve(app.config.globalProperties.$router);
  const router = await routerDeferred.promise;

  try {
    if (
      window.location.search.includes("code=") &&
      window.location.search.includes("state=")
    ) {
      await client.handleRedirectCallback<RedirectAppState>();
    } else {
      await getTokenSilently();
      await validateAuthToken();
    }
  } catch (e: any) {
    // If status is 401 then we are not authenticated
    // and we should not redirect to the error page
    // otherwise we will cause extra redirects
    // which will cause the previous page to be lost
    if (e.status === 401) {
      return;
    }

    let error = e as AuthenticationError;

    state.error.value = error.error_description
      ? error.error_description
      : error.message;
    router.replace(state.errorPath.value);
  } finally {
    state.user.value = await client.getUser();
    isLoadedDeferred.resolve();
  }
}
