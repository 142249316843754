import { SteamTrapsApi } from '@/client'
import { EveractiveApiResponse } from '@/client/everactiveApiResponse'
import {
  IPaginationInfo,
  ISteamTrap,
  SensorReading,
  SteamTrap,
  SteamTrapGroundTruth,
} from '@/types'
import {
  SteamTrapHistoryItem
} from '@/client/api'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from './types/root'
import { SteamTrapState } from './types/steamTrapState'

function initialState() {
  return {
    traps: [],
    isUpdating: false,
    isFetching: false,
    isFetchingData: false,
    isFetchingHistory: false
  }
}

const state: SteamTrapState = initialState()

const getters: GetterTree<SteamTrapState, RootState> = {
  getById(state): Function {
    return (id: string) => {
      if (!state.traps) {
        return null
      }
      return state.traps.find((trap) => trap.id == id) || null
    }
  }
}

const mutations: MutationTree<SteamTrapState> = {
  reset(state: SteamTrapState) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },

  addSteamTrap(state: SteamTrapState, value: SteamTrap): void {
    if (!state.traps) {
      state.traps = []
    }

    const updatedSteamTrapArray = [...state.traps]
    const updatedSteamTrapIndex = updatedSteamTrapArray.findIndex((trap) => trap.id === value.id)

    if (updatedSteamTrapIndex === -1) {
      state.traps.push(value)
      return
    }

    updatedSteamTrapArray[updatedSteamTrapIndex] = new SteamTrap(value)
    state.traps = updatedSteamTrapArray
  },

  updateSteamTrap(state: SteamTrapState, value: SteamTrap): void {
    if (!state.traps) {
      return
    }

    const updatedSteamTrapArray = [...state.traps]
    const updatedSteamTrapIndex = updatedSteamTrapArray.findIndex((trap) => trap.id === value.id)

    if (updatedSteamTrapIndex === -1) {
      return
    }

    updatedSteamTrapArray[updatedSteamTrapIndex] = new SteamTrap(value)
    state.traps = updatedSteamTrapArray
  },

  removeSteamTrapById(state: SteamTrapState, id: string): void {
    if (!state.traps) {
      return
    }

    const filteredTraps = state.traps.filter((trap) => trap.id !== id)
    state.traps = filteredTraps
  },

  setTraps(state: SteamTrapState, value: SteamTrap[]): void {
    state.traps = value
  },

  setIsUpdating(state: SteamTrapState, value): void {
    state.isUpdating = value
  },

  setIsFetchingData(state: SteamTrapState, value): void {
    state.isFetchingData = value
  },
  setIsFetching(state: SteamTrapState, value): void {
    state.isFetching = value
  },
  setIsFetchingHistory(state: SteamTrapState, isFetching: boolean) {
    state.isFetchingHistory = isFetching
  }
}

const actions: ActionTree<SteamTrapState, RootState> = {
  async superAdminUpdateTrapStatus(
    { commit },
    { trapId, trapState, effectiveTimestamp, priorStateConfirmed }
  ): Promise<boolean | null> {
    commit('setIsUpdating', true)
    const response = await SteamTrapsApi.superAdminUpdateTrapStatus({
      trapId,
      trapState,
      effectiveTimestamp,
      priorStateConfirmed
    })
    commit('setIsUpdating', false)

    if (response) {
      const updatedSteamTrap = new SteamTrap(response)
      commit('updateSteamTrap', updatedSteamTrap)

      return true
    }
    return false
  },

  async fetchSteamTrapTimeseriesData(
    { commit, state },
    { trapId, startTime, endTime, downsampleRate }
  ): Promise<any | null> {
    if (state.isFetchingData) {
      return
    }

    commit('setIsFetchingData', true)
    try {
      const { success, data, error } = await SteamTrapsApi.fetchTimeseriesData({
        trapId,
        startTime,
        endTime,
        downsampleRate
      })

      if (success && data) {
        return {
          success,
          data: data.map((sensorReading) => new SensorReading(sensorReading))
        }
      }
      return { success, error }
    } finally {
      commit('setIsFetchingData', false)
    }
  },

  async fetchSteamTrapHistory(
    { commit, state },
    { trapId, page, pageSize }
  ): Promise<{ data?: SteamTrapHistoryItem[]; paginationInfo?: IPaginationInfo }> {
    if (state.isFetchingHistory) {
      return {}
    }
    commit('setIsFetchingHistory', true)
    try {
      const response = await SteamTrapsApi.fetchHistory({
        trapId,
        page,
        pageSize
      })
      return response || {}
    } finally {
      commit('setIsFetchingHistory', false)
    }
  },

  async updateSteamTrap({ commit }, trap: SteamTrap): Promise<SteamTrap | any> {
    const { success, data, errors } = await SteamTrapsApi.updateSteamTrap(trap)

    if (success) {
      const updatedSteamTrap = new SteamTrap(data)
      commit('updateSteamTrap', updatedSteamTrap)
      // Remove sensor from list of available sensors
      if (trap.sensor && trap.sensor.macAddress) {
        commit('Sensors/removeAvailableSensorByMacAddress', trap.sensor.macAddress, { root: true })
      }
      return updatedSteamTrap
    }

    return { success, errors }
  },

  async updateSteamTrapCustomer({ commit }, trap: SteamTrap): Promise<SteamTrap | any> {
    const { success, data, errors } = await SteamTrapsApi.updateSteamTrapCustomer(trap)

    if (success) {
      const updatedSteamTrap = new SteamTrap(data)
      commit('updateSteamTrap', updatedSteamTrap)
      return updatedSteamTrap
    }

    return { success, errors }
  },

  async getSteamTrap({ commit }, trapId: string): Promise<SteamTrap | null> {
    commit('setIsFetching', true)
    const trap = await SteamTrapsApi.getSteamTrap(trapId)
    commit('setIsFetching', false)
    if (trap !== null) {
      commit('addSteamTrap', trap)
    }

    return trap
  },

  async getLastSteamTrapsTrapidGroundtruth(_, trapId: string): Promise<Partial<SteamTrapGroundTruth>> {
    try {
      const response = await SteamTrapsApi.getLastSteamTrapsTrapidGroundtruth(trapId);
      return response
    } catch (e) {
      console.error("SteamTrapsApi.getLastSteamTrapsTrapidGroundtruth - error", e);
      return null;
    }
  },

  async addSteamTrapGroundTruth(_, groundTruth: SteamTrapGroundTruth): Promise<boolean> {
    const trapId = groundTruth.trapId

    const result = await SteamTrapsApi.addSteamTrapGroundTruth(trapId, groundTruth)
    if (result) {
      return true
    }
    return false
  },

  async editSteamTrapGroundTruth(_, groundTruth: SteamTrapGroundTruth): Promise<boolean> {
    const trapId = groundTruth.trapId

    const result = await SteamTrapsApi.editSteamTrapGroundTruth(trapId, groundTruth)

    return result
  },

  async deleteSteamTrapGroundTruth(_, { gtId, trapId }): Promise<boolean> {
    return await SteamTrapsApi.deleteSteamTrapGroundTruth(trapId, gtId)
  },

  async addSteamTrap(
    { commit },
    trap: SteamTrap
  ): Promise<SteamTrap | null | EveractiveApiResponse<ISteamTrap>> {
    const response = await SteamTrapsApi.createSteamTrap(trap)
    const steamTrap = response.data
    if (steamTrap && steamTrap.id) {
      const newTrap = new SteamTrap(steamTrap)
      commit('addSteamTrap', newTrap)

      return newTrap
    }

    return response
  },
  async fetchSteamTraps(
    { commit },
    { customerId, sensorInstalled, svcTag }
  ): Promise<SteamTrap[] | boolean> {
    commit('setIsFetching', true)

    try {
      const response = await SteamTrapsApi.fetchSteamTraps({
        customerId,
        sensorInstalled,
        svcTag
      })
      if (response) {
        const steamTraps = response.map((trap) => new SteamTrap(trap))
        commit('setTraps', steamTraps)
        return steamTraps
      }
      return false
    } finally {
      commit('setIsFetching', false)
    }
  },

  async removeSteamTrap({ commit }, id: string): Promise<boolean> {
    const success = await SteamTrapsApi.deleteSteamTrap(id)
    if (success) {
      commit('removeSteamTrapById', id)
    }
    return success
  },

  async removeSensorFromSteamTrap(
    _,
    { steamTrapId, sensorMacAddress, endTimestamp, startTimestamp }
  ): Promise<EveractiveApiResponse> {
    const response = await SteamTrapsApi.removeSensorFromSteamTrap({
      steamTrapId,
      sensorMacAddress,
      startTimestamp,
      endTimestamp
    })

    return response
  },

  async changeSensorOnSteamTrap(_, { trapId, sensorMacAddress, startTimestamp }): Promise<boolean> {
    const response = await SteamTrapsApi.changeSensorOnSteamTrap({
      trapId,
      sensorMacAddress,
      startTimestamp
    })

    if (response) {
      return true
    }

    return false
  }
}

const SteamTraps: Module<SteamTrapState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default SteamTraps
