/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Deployment status of the gateway.
 */
export enum GatewayDeploymentStatus {
	COMMISSIONED = 'Commissioned',
	DECOMMISSIONED = 'Decommissioned',
	RETURNED = 'Returned',
	TO_BE_INSTALLED = 'ToBeInstalled',
	CUSTOMER_ACTION_NEEDED = 'CustomerActionNeeded',
}
