import { Auth0PluginOptions } from '@/auth'
import { auth0ClientId, auth0Audience, auth0Domain } from '@/env'

export const auth0Config: Auth0PluginOptions = {
	errorPath: "", //Routes.AuthenticationError,
	domain: auth0Domain,
	clientId: auth0ClientId,
	authorizationParams: {
		redirect_uri: window.location.origin,
		audience: auth0Audience,
		scope: "offline_access",
		useRefreshTokens: true,
		cacheLocation: "localstorage",
		useRefreshTokensFallback: true
	},
	useRefreshTokensFallback: true,
	useRefreshTokens: true,
	cacheLocation: "localstorage"
}
