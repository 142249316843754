import { IWebhook, Webhook } from '@/types'
import { DsApi } from './dsApi'
import { EveractiveApiResponse } from './everactiveApiResponse'

export class WebhooksApi {
  public static everactiveApi = new DsApi().client.webhooks

  public static async createWebhook(webhook: Webhook): Promise<EveractiveApiResponse<IWebhook>> {
    const requestBody = new Webhook(webhook)
    if (webhook.customer?.id) {
      requestBody.customerId = webhook.customer.id
    }

    try {
      const response = await this.everactiveApi.postWebhooks({ requestBody })
      return response
    } catch (e) {
      console.error(`WebhooksApi.createWebhook - error`, e, webhook)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to create webhook.' }
  }

  public static async updateWebhook(
    webhookId: string,
    webhook: Webhook
  ): Promise<EveractiveApiResponse<IWebhook>> {
    const requestBody = new Webhook(webhook)
    if (webhook.customer?.id) {
      requestBody.customerId = webhook.customer.id
    }

    try {
      const response = await this.everactiveApi.updateWebhook({ webhookId, requestBody })
      return response
    } catch (e) {
      console.error('WebhooksApi.updateWebhook - error', e, webhook)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to update webhook' }
  }

  public static async deleteWebhook(webhookId: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteWebhook({ webhookId })
      return true
    } catch (e) {
      console.error('WebhooksApi.deleteWebhook - error', e, webhookId)
    }
    return false
  }

  public static async getWebhook(webhookId: string): Promise<EveractiveApiResponse<IWebhook>> {
    try {
      const response = await this.everactiveApi.getWebhook({ webhookId })
      return response
    } catch (e) {
      console.error('WebhooksApi.getWebhook - error', e, webhookId)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Error getting webhook' }
  }
}
