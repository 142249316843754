import {
  CustomerAssociateDetail as ApiCustomerAssociatedDetail,
  Gateway as ApiGateway,
  GatewayPairingRequest as ApiGatewayPairingRequest,
  GatewayStatusV1 as ApiGatewayStatusV1,
  GatewayStatusV3 as ApiGatewayStatusV3,
  GatewayUpdate as ApiGatewayUpdate,
  GatewayService
} from '@/client/api'
import { Gateway } from '@/types'
import { EveractiveApi } from './everactiveApi'
import { EveractiveApiResponse } from './everactiveApiResponse'

export class GatewaysApi {
  public static everactiveApi = new EveractiveApi().client.gateway

  public static async getGatewayById(id): Promise<EveractiveApiResponse<ApiGateway>> {
    try {
      const response = await this.everactiveApi.getGatewaysGatewayIdOrSerialNumber({
        gatewayIdOrSerialNumber: id
      })
      return response
    } catch (e) {
      console.error('problem getting gateway by id', e)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Gateway was not found' }
  }

  public static async updateGateway(gateway: Gateway): Promise<EveractiveApiResponse<boolean>> {
    if (gateway.id) {
      const requestBody = <ApiGatewayUpdate>{
        deploymentStatus: gateway.deploymentStatus,
        enableSSH: gateway.enableSSH,
        enableWakeup: gateway.enableWakeup,
        evernet1Settings: gateway.evernet1Settings,
        evernet2Settings: gateway.evernet2Settings,
        hazardousLocationClassification: gateway.hazardousLocationClassification,
        installNotes: gateway.installNotes,
        location: gateway.location,
        name: gateway.name
      }
      try {
        await this.everactiveApi.putGatewaysGatewayIdOrSerialNumber({
          gatewayIdOrSerialNumber: gateway.id,
          requestBody
        })
        return <EveractiveApiResponse>{ success: true, data: true }
      } catch (e) {
        console.error('GatewaysApi.updateGateway - error', e)
        if (e.body) return e.body
      }
      return { success: false, message: 'Gateway was not successfully updated.' }
    }
  }

  public static async updateGatewayCustomer(
    gatewayId: string,
    customerId: string
  ): Promise<EveractiveApiResponse<boolean>> {
    if (gatewayId && customerId) {
      const requestBody = <ApiCustomerAssociatedDetail>{
        customerId
      }
      try {
        await this.everactiveApi.putGatewaysGatewayIdOrSerialNumberCustomersCustomerId({
          gatewayIdOrSerialNumber: gatewayId,
          customerId,
          requestBody
        })
        return <EveractiveApiResponse>{ success: true, data: true }
      } catch (e) {
        console.error('GatewaysApi.updateGatewayCustomer - error', e)
        if (e.body) {
          return e.body
        }
      }
    }
    return { success: false, message: 'Gateway site was not updated' }
  }

  public static async deleteGateway(id: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteGatewaysGatewayIdOrSerialNumber({
        gatewayIdOrSerialNumber: id
      })
      return true
    } catch (e) {
      console.error('GatewaysApi.deleteGateway - error', e)
    }
    return false
  }

  public static async pairGateway(
    pairingCode: string,
    customerId: string,
    name: string
  ): Promise<EveractiveApiResponse<ApiGateway>> {
    const requestBody = <ApiGatewayPairingRequest>{
      customerId,
      gateway: { name },
      pairingCode
    }
    try {
      const response = await this.everactiveApi.patchGatewaysPairingCodePair({
        pairingCode,
        requestBody
      })
      return { success: true, data: response.data }
    } catch (e) {
      console.error('GatewaysApi.pairGateway - error', e)
      return { success: false, message: e.body.message }
    }
  }

  public static async getDownloadVirtualImageURL(id: string): Promise<string | null> {
    try {
      const response = await this.everactiveApi.getGatewaysGatewayIdOrSerialNumberVmImage({
        gatewayIdOrSerialNumber: id
      })
      return response.data
    } catch (e) {
      console.error('GatewaysApi.getDownloadVirtualImageURL - error', e)
    }
    return null
  }

  

  public static async getGatewayStatusV1ById(
    queryParams: Parameters<GatewayService['getGatewaysGatewayIdOrSerialNumberStatusV1']>[0]
  ): Promise<EveractiveApiResponse<ApiGatewayStatusV1[]>> {
    try {
      const response = await this.everactiveApi.getGatewaysGatewayIdOrSerialNumberStatusV1({
        gatewayIdOrSerialNumber: queryParams.gatewayIdOrSerialNumber,
        startTime: parseInt(queryParams.startTime.toString(), 10),
        endTime: queryParams.endTime ? parseInt(queryParams.endTime.toString(), 10) : null
      })
      return response
    } catch (e) {
      console.error('GatewaysApi.getGatewayStatusV1ById - error', e)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to retrieve gateway status messages' }
  }

  public static async getGatewayStatusV3ById(
    queryParams: Parameters<GatewayService['getGatewaysGatewayIdOrSerialNumberStatusV3']>[0]
  ): Promise<EveractiveApiResponse<ApiGatewayStatusV3[]>> {
    try {
      const response = await this.everactiveApi.getGatewaysGatewayIdOrSerialNumberStatusV3({
        gatewayIdOrSerialNumber: queryParams.gatewayIdOrSerialNumber,
        startTime: parseInt(queryParams.startTime.toString(), 10),
        endTime: queryParams.endTime ? parseInt(queryParams.endTime.toString(), 10) : null
      })
      return response
    } catch (e) {
      console.error('GatewaysApi.getGatewayStatusV3ById - error', e)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to retrieve gateway status messages' }
  }
}
