import { PrivilegeKey } from '@/client/api'
import Logout from '@/views/Logout.vue'
import { RouteRecordRaw, RouteRecordRedirect, RouteRecordSingleView } from 'vue-router'

const Profile = () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue')
const Security = () => import(/* webpackChunkName: "Security" */ '@/views/Security.vue')
const Support = () => import(/* webpackChunkName: "Support" */ '@/views/Support.vue')
const Notifications = () =>
  import(/* webpackChunkName: "Notificiations" */ '@/views/notifications/Notifications.vue')
const ResolveNotification = () =>
  import(
    /* webpackChunkName: "ResolveNotificiation" */ '@/views/notifications/ResolveNotification.vue'
  )
const defaultRoute = {
  path: '/',
  name: 'Home',
  component: null
}

const indexRedirects = [
  { path: '/index.html', redirect: '/' },
  { path: '/index', redirect: '/' }
]

const loginRoute = {
  path: '/login',
  name: 'Login',
  component: null
}

const logoutRoute = {
  path: '/logout',
  name: 'Logout',
  component: Logout
}

const profileRoute = {
  path: '/profile',
  name: 'Profile',
  meta: { title: 'Profile' },
  component: Profile
}

const settingsRoute = {
  path: '/settings',
  name: 'Settings',
  meta: { title: 'Settings' },
  component: Profile // Profile.vue wrapper is the same for both settings and profile
}

const securityRoute = {
  path: '/security',
  name: 'Security',
  meta: { title: 'Security' },
  component: Security
}

const supportRoute = {
  path: '/support',
  name: 'Support',
  meta: { title: 'Support' },
  component: Support
}

const notificationsRoute = {
  path: '/notifications',
  name: 'Notifications',
  meta: {
    title: 'Notifications',
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.USER,
      PrivilegeKey.READ_ONLY,
      PrivilegeKey.ASSET_MANAGER
    ]
  },
  component: Notifications
}

const resolveNotificationRoute = {
  path: '/resolve-notification/:notificationId',
  props: true,
  name: 'Resolve Notification',
  meta: {
    title: 'Resolve Notification',
    privilegeRequirement: [
      PrivilegeKey.SUPER_ADMIN,
      PrivilegeKey.CUSTOMER_ADMIN,
      PrivilegeKey.USER,
      PrivilegeKey.READ_ONLY,
      PrivilegeKey.ASSET_MANAGER
    ]
  },
  component: ResolveNotification
}

const routes: Array<RouteRecordRaw> = [
  defaultRoute,
  ...indexRedirects,
  loginRoute,
  logoutRoute,
  profileRoute,
  securityRoute,
  supportRoute,
  notificationsRoute,
  resolveNotificationRoute,
  settingsRoute
]

export default routes
