/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { Vhost } from '../models/Vhost';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VhostsService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get vhosts
	 * Retrieve multiple vhost records.
	 * @returns any Retrieve multiple MQTT Vhosts.
	 * @throws ApiError
	 */
	public getVhosts({
		vhostType,
		includeDeleted,
		customerId,
	}: {
		/**
		 * Filter the MQTT brokers returned by the broker type.
		 */
		vhostType?: 'data' | 'config',
		/**
		 * Flag indicating that deleted MQTT brokers should be included in the returned list.
		 */
		includeDeleted?: boolean,
		/**
		 * Filter the vhosts returned by the customer id.  It is possible to pass
		 * multiple customer-id query parameters as a comma separated value (e.g. `&customer-id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 */
		customerId?: string,
	}): CancelablePromise<{
		data?: Array<Vhost>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/vhosts',
			query: {
				'vhost-type': vhostType,
				'include-deleted': includeDeleted,
				'customer-id': customerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
