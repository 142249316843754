<script lang="ts">
import Navigation from "@/components/Navigation.vue";
import DeprecationNotice from "@/views/DeprecationNotice.vue";
import Bowser from "bowser";
import MainMenu from "@/components/header/MainMenu.vue";
import Global from "./Global";
import { defineComponent, inject } from "vue";
import { Auth0PluginApi } from './auth';
import { useZendeskProvider, zendesk } from 'zendesk-widget-vue';
import { zendeskKey } from '@/env';

export default defineComponent({
  name: "App",
  setup: () => {
    const auth: Auth0PluginApi = inject("auth")

    useZendeskProvider({
      token: zendeskKey,
      settings: {
        webWidget: {
          offset: {
            mobile: {
              vertical: "47px",
              horizontal: "-15px"
            },
          }
        },
      },
      onFinish: () => {
        zendesk.zE('webWidget', 'hide')
      }
    });
    
    return {
      auth
    };
  },
  components: {
    DeprecationNotice,
    Navigation,
    MainMenu
  },
  computed: {
    useUnauthenticatedStyles() {
      return this.$route.path.includes("login");
    },
    isInternetExplorer() {
      const browser = Bowser.getParser(window.navigator.userAgent);
      return browser.getBrowserName() === "Internet Explorer";
    },
    isSummaryPage() {
      return Global.SUMMARY_PAGES.includes(this.$route.name);
    }
  }
});
</script>

<template>
  <section id="app" :class="[
    useUnauthenticatedStyles ? $style.unauthenticated : $style.authenticated
  ]">
    <!-- IE Deprecation Notice -->
    <DeprecationNotice v-if="isInternetExplorer" />

    <!-- main app div -->
    <div v-else-if="auth.isAuthenticated" :class="[$style.appContainer, isSummaryPage ? $style.summaryPage : '']">
      <Navigation v-if="auth.isAuthenticated" />
      <div :class="[$style.routerViewWrapper, 'routerViewWrapper']">
        <router-view :key="$route.path" />
      </div>
      <MainMenu v-if="auth.isAuthenticated" position="bottom" />
    </div>
    <div v-else>
      <div :class="[$style.routerViewWrapper, 'routerViewWrapper']">
        <router-view :key="$route.path" />
      </div>
    </div>
  </section>
</template>

<style scoped>
#app {
  overflow: hidden;
}
</style>
<style module>
.appContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.appContainer.summaryPage {
  max-width: none;
}

.routerViewWrapper {
  padding: 10px;
  min-height: 1px;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
}

.routerViewWrapper>* {
  margin: 0 auto;
}

.unauthenticated {
  background: #03033d;
  display: flex;
  min-height: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.unauthenticated.showBannerMessage {
  padding: 100px 10px 10px 10px;
}

.authenticated {
  background: white;
  position: relative;
  height: 100vh;
}

.unauthenticated p {
  color: white;
  font-size: 16px;
  margin: 20px 0 10px;
}

@media all and (max-width: 750px) {
  .unauthenticated.showBannerMessage {
    padding-top: 200px;
  }
}

@media all and (max-width: 425px) {
  .unauthenticated.showBannerMessage {
    padding-top: 300px;
  }
}

@media all and (max-width: 812px) {
  .appContainer {
    /* height: 90vh; */
    height: 90vh;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}
</style>
