import {
  MachineNEMAFrameList as ApiMachineNEMAFrameList,
  MachinePhases as ApiMachinePhases,
  MachineTypeList as ApiMachineTypeList,
  HazardousLocationClassification
} from '@/client/api'
import { ValidationRules } from '@/lib/validationRules'

export interface MetadataField {
  label: string
  required: boolean
  numeric: boolean
  validationRules: string
  dropdownOptions: string[],
  dropdownClearable?: boolean,
}

// NEMA Code Letters from https://www.engineeringtoolbox.com/nema-electrical-motors-efficiency-ratings-d_1501.html
export enum MachineCodeLetter {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  P = 'P',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V'
}

export enum MachineDesignLetter {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D'
}

export enum MachineEnclosureType {
  ODP = 'ODP',
  TEFC = 'TEFC',
  TENV = 'TENV',
  TEAO = 'TEAO',
  TEWD = 'TEWD',
  TEHS = 'TEHS',
  EXPL = 'EXPL',
  HAZ = 'HAZ'
}

export enum MachineInsulationClass {
  A = 'A',
  B = 'B',
  F = 'F',
  H = 'H'
}

export const machineMetadataFields: { [key: string]: MetadataField } = {
  machineType: {
    label: 'Machine Type',
    required: true,
    numeric: false,
    validationRules: ValidationRules.isRequired,
    dropdownOptions: Object.values(ApiMachineTypeList)
  },
  equipmentName: {
    label: 'Equipment Name',
    required: true,
    numeric: false,
    validationRules: ValidationRules.isRequired,
    dropdownOptions: null
  },
  facility: {
    label: 'Facility',
    required: true,
    numeric: false,
    validationRules: ValidationRules.isRequired,
    dropdownOptions: null
  },
  location: {
    label: 'Location',
    required: true,
    numeric: false,
    validationRules: ValidationRules.isRequired,
    dropdownOptions: null
  },
  ratedFullLoadRPMs: {
    label: 'Rated Full-Load R.P.M.',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  bearings: {
    label: 'Bearings',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  driveEnd: {
    label: 'Drive End',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  oppositeDriveEnd: {
    label: 'Opposite Drive End',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  manufacturer: {
    label: 'Manufacturer',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  catalogNumber: {
    label: 'Catalog Number',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  specNumber: {
    label: 'Spec Number',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  horsepower: {
    label: 'Horsepower',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  ratedVoltage: {
    label: 'Rated Voltage',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  ratedFullLoadAmps: {
    label: 'Rated Full-Load Amperage',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  lineFrequency: {
    label: 'Line Frequency (Hz)',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  phases: {
    label: 'Phases',
    required: false,
    numeric: false,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: Object.values(ApiMachinePhases)
      .filter((o) => o.toString().indexOf('_') === -1)
      .map((o) => o.toString())
  },
  codeLetter: {
    label: 'Code Letter',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: Object.keys(MachineCodeLetter) as MachineCodeLetter[]
  },
  frame: {
    label: 'Frame',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: Object.values(ApiMachineNEMAFrameList) as ApiMachineNEMAFrameList[]
  },
  serviceFactor: {
    label: 'Service Factor',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  designLetter: {
    label: 'Design Letter',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: Object.keys(MachineDesignLetter) as MachineDesignLetter[]
  },
  insulationClass: {
    label: 'Insulation Class',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: Object.values(MachineInsulationClass) as MachineInsulationClass[]
  },
  NEMANominalEfficiency: {
    label: 'NEMA Nominal Efficiency',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  powerFactor: {
    label: 'Power Factor',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  ratingDuty: {
    label: 'Rating/Duty',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  maxAmbientTemperature: {
    label: 'Max Ambient Temperature',
    required: false,
    numeric: true,
    validationRules: ValidationRules.isNumber,
    dropdownOptions: null
  },
  certifiedCompliant: {
    label: 'Certified Compliant Number',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: null
  },
  enclosureType: {
    label: 'Enclosure Type',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: Object.keys(MachineEnclosureType) as MachineEnclosureType[]
  },
  hazardousLocationClassification: {
    label: 'Hazardous Location Classification',
    required: false,
    numeric: false,
    validationRules: '',
    dropdownOptions: Object.values(HazardousLocationClassification),
    dropdownClearable: true
  }
}

export interface MachineIntegrationInfo {
  assetId: string
  extraInfo?: { [key: string]: string }
  name?: string
}
