import { Auth0Plugin } from '@/auth'
import { dataServicesApiUrlv1 } from '@/env'
import { Api } from './ds'

export class DsApi {
  private baseUrl = dataServicesApiUrlv1
  public client: Api

  constructor(baseUrl?: string) {
    if (baseUrl) {
      this.baseUrl = baseUrl
    }

    const apiClientConfig = {
      BASE: dataServicesApiUrlv1,
      TOKEN: () => Auth0Plugin.getTokenSilently(),
      HEADERS: { 'Cache-Control': 'private, no-store, max-age=0' }
    }

    this.client = new Api(apiClientConfig)
  }
}
