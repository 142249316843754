/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BryntumGridSetting } from '../models/BryntumGridSetting';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class BryntumGridSettingsService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get list of bryntum grid settings for a user
	 * Retrieve a list of Bryntum Grid settings for a user
	 * @returns any A list of Bryntum Grid settings
	 * @throws ApiError
	 */
	public getBryntumGridSettingsUserId({
		userId,
	}: {
		userId: string,
	}): CancelablePromise<{
		data?: Array<BryntumGridSetting>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/bryntumgridsettings/{userId}',
			path: {
				'userId': userId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get a single bryntum grid setting for a user
	 * Retrieve a single Bryntum Grid setting for a user
	 * @returns any A Bryntum Grid setting
	 * @throws ApiError
	 */
	public getBryntumGridSettingsUserIdGridsGridName({
		userId,
		gridName,
	}: {
		userId: string,
		/**
		 * The name of the table where the settings apply.
		 *
		 * **Note**: The `gridName` is case sensitive and is effectively the key for a particular
		 * users's settings.
		 */
		gridName: string,
	}): CancelablePromise<{
		data?: BryntumGridSetting;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/bryntumgridsettings/{userId}/grids/{gridName}',
			path: {
				'userId': userId,
				'gridName': gridName,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * create or update a single bryntum grid setting for a user
	 * Create or update a Bryntum Grid setting for a user
	 * @returns any A Bryntum Grid setting
	 * @throws ApiError
	 */
	public putBryntumGridSettingsUserIdGridsGridName({
		userId,
		gridName,
		requestBody,
	}: {
		userId: string,
		/**
		 * The name of the table where the settings apply.
		 *
		 * **Note**: The `gridName` is case sensitive and is effectively the key for a particular
		 * users's settings.
		 */
		gridName: string,
		/**
		 * A Bryntum Grid setting to create or update.
		 */
		requestBody?: BryntumGridSetting,
	}): CancelablePromise<{
		data?: BryntumGridSetting;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/bryntumgridsettings/{userId}/grids/{gridName}',
			path: {
				'userId': userId,
				'gridName': gridName,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
