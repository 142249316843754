import { IUser, PrivilegeKey, User } from '@/types'
import { UserCreate as ApiUserCreate } from './api'
import { EveractiveApi } from './everactiveApi'
import { EveractiveApiResponse } from './everactiveApiResponse'

export class UsersApi {
  public static everactiveApi = new EveractiveApi().client.user

  public static async addUser(
    user: IUser,
    customerId?: string
  ): Promise<EveractiveApiResponse<IUser>> {
    const requestBody = <ApiUserCreate>{
      customerId,
      email: user.email,
      name: user.name,
      password: user.password,
      phoneNumber: user.phoneNumber,
      privileges: user.privileges,
      username: user.username
    }

    if (user.privileges.some((p) => p.key === PrivilegeKey.SUPER_ADMIN)) {
      requestBody.customerId = undefined
    }

    try {
      const response = await this.everactiveApi.createUsers({
        requestBody
      })
      return response
    } catch (e) {
      console.error(`UsersApi.addUser - error`, e, { email: user.email })
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to create new user.' }
  }

  public static async getUser(id: string): Promise<EveractiveApiResponse<IUser>> {
    try {
      const response = await this.everactiveApi.getUsersUserId({
        userId: id
      })

      return response
    } catch (e) {
      console.error(`UsersApi.getUser - error`, { userId: id }, e)
      return null
    }
  }

  public static async updateUser(
    user: IUser,
    customerId?: string
  ): Promise<EveractiveApiResponse<IUser>> {
    const requestBody = new User(user)
    requestBody.customerId = customerId

    if (requestBody.hasPrivilege(PrivilegeKey.SUPER_ADMIN)) {
      requestBody.customer = undefined
      requestBody.customerId = undefined
    }

    try {
      const response = await this.everactiveApi.updateUsersUserId({ userId: user.id, requestBody })
      return response
    } catch (e) {
      console.error('UsersApi.updateUser - error', { userId: user.id }, e)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to update user.' }
  }

  public static async deleteUser(id: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteUsersUserId({ userId: id })
      return true
    } catch (e) {
      console.error('UsersApi.deleteUser - error', { userId: id }, e)
    }
    return false
  }
}
