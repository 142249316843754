import { OpenAPI, OpenAPIConfig } from './core/OpenAPI'
import { ApiRequestOptions } from './core/ApiRequestOptions'
import { request as _request } from './core/request'

export function request<ResponseType = void>(
	options: ApiRequestOptions,
	apiConfig?: Partial<OpenAPIConfig>
): Promise<ResponseType> {
	return _request<ResponseType>(
		apiConfig ? { ...OpenAPI, ...apiConfig } : OpenAPI,
		options
	)
}

