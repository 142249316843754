/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A record of a failure event for a steam trap.
 */
export type SteamTrapFailureEvent = {
	/**
	 * The unix timestamp when the failure was created.
	 */
	readonly createdAtTimestamp?: number;
	/**
	 * The failure id.
	 */
	readonly id: string;
	/**
	 * The predicted failure id.
	 */
	predictedFailureId?: string;
	/**
	 * The steam trap id.
	 */
	readonly trapId: string;
	/**
	 * the description of the failure event
	 */
	description?: string;
	/**
	 * How the failure was detected.
	 */
	detectionType?: string;
	/**
	 * Failure type
	 */
	type?: SteamTrapFailureEvent.type;
	/**
	 * The unix timestamp when the failure event was started.
	 */
	startTimestamp: number;
	/**
	 * The unix timestamp when the failure event was ended.
	 */
	endTimestamp?: number;
};

export namespace SteamTrapFailureEvent {

	/**
	 * Failure type
	 */
	export enum type {
		LIGHT = 'light',
		MEDIUM = 'medium',
		HEAVY = 'heavy',
		LEAKING = 'leaking',
	}


}

