/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CustomerMinimal = {
	/**
	 * The unique id of the customer.
	 */
	id: string;
	enabledProducts?: {
		dataServices: boolean;
		flareSystemMonitor: boolean;
		machineHealthMonitor: boolean;
		steamTrapMonitor: boolean;
	};
	/**
	 * The name of the customer.
	 */
	name: string;
	/**
	 * The status of the customer
	 */
	status?: CustomerMinimal.status;
};

export namespace CustomerMinimal {

	/**
	 * The status of the customer
	 */
	export enum status {
		DECOMMISSIONED = 'Decommissioned',
		DEMO = 'Demo',
		PRODUCTION = 'Production',
		TO_BE_INSTALLED = 'To Be Installed',
	}


}

