<script lang="ts">
import HeaderUserMenu from '@/components/header/HeaderUserMenu.vue'
import MainMenu from '@/components/header/MainMenu.vue'
import NotificationsButton from '@/components/header/NotificationsButton.vue'
import { computed, defineComponent, inject } from 'vue'
import { useUserPrivileges } from '@/composables/useUserPrivileges'
import SiteDropdown from './SiteDropdown.vue'
import { useCustomersState } from '@/composables/useCustomersState'
import { Auth0PluginApi } from '@/auth'

export default defineComponent({
  name: 'Navigation',
  components: {
    HeaderUserMenu,
    MainMenu,
    NotificationsButton,
    SiteDropdown
  },
  setup() {
    const auth: Auth0PluginApi = inject('auth')
    const { canViewStmNotifications, hasOnlyDsProductAccess } = useUserPrivileges()
    const { totalCustomers } = useCustomersState()
    const hasAccessToMultipleCustomers = computed(() => totalCustomers.value > 1)

    return {
        auth,
        canViewStmNotifications,
        hasAccessToMultipleCustomers,
        hasOnlyDsProductAccess
    }
  }
})
</script>
<template>
  <header id="topnav" class="header" data-testid="site_header">
    <nav :class="['headerContainer', 'content-container']">
      <div class="header-container-left">
        <router-link class="logoLink" :to="{ name: 'Home' }" aria-label="Home">
          <img
            src="/assets/logos/logo-inverted.svg"
            alt="Everactive Logo"
            class="desktop-logo logo"
          />
          <img
            src="/assets/logos/everactive-a-logo.png"
            alt="Everactive Logo"
            class="mobile-logo logo"
          />
        </router-link>
        <SiteDropdown
          data-testid="crossSite__dropdown"
          v-if="hasAccessToMultipleCustomers && !hasOnlyDsProductAccess"
        />
      </div>
      <div v-if="auth.isAuthenticated" class="navigation">
        <MainMenu position="top" />
        <NotificationsButton
          data-testid="notificationsButton__button"
          v-if="canViewStmNotifications"
        />
        <HeaderUserMenu data-testid="headerUserMenu__dropdown" />
      </div>
    </nav>
  </header>
</template>

<style scoped>
.header {
  background: #03033d;
  z-index: 1030;
  height: 68px;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: center;
  box-shadow: 0px -3px 13px 3px rgba(3, 3, 61, 0.5);
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-family: 'Gilroy', sans-serif;
  font-size: 16px;
}
.header-container-left {
  display: flex;
  align-items: center;
}
.logoLink {
  margin: 10px;
  margin-right: 16px;
}
.logoLink:focus {
  outline-offset: 3px;
}

.logo {
  height: 48px;
  flex: 0 auto;
}

.desktop-logo {
  width: 125px;
}

@media all and (min-width: 425px) {
  .mobile-logo {
    display: none;
  }
}

@media all and (max-width: 425px) {
  .desktop-logo {
    display: none;
  }
}

.navigation {
  display: flex;
  flex: 0 auto;
  align-content: center;
}
</style>
