import { CodexConfigApi } from '@/client'
import { RootState } from '@/data-store/types/root'
import { SecurityConcernMessageConfig, WakeupCode } from '@/types'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'

export interface AppState {
  currentProduct: string
  securityConcerns: SecurityConcernMessageConfig
  wakeupCodes: WakeupCode
}

function initialState() {
  // set current product
  const currentProduct = localStorage.getItem('CurrentProduct__')

  return {
    currentProduct,
    securityConcerns: null,
    wakeupCodes: null
  }
}

const state: AppState = initialState()

const getters: GetterTree<AppState, RootState> = {
  currentProduct(state): string {
    return state.currentProduct
  }
}

const mutations: MutationTree<AppState> = {
  reset(state: AppState) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },

  setCurrentProduct(state: AppState, product: string) {
    state.currentProduct = product
  },

  setSecurityConcerns(state: AppState, securityConcerns: SecurityConcernMessageConfig) {
    state.securityConcerns = securityConcerns
  },

  setWakeupCodes(state: AppState, wakeupCodes: WakeupCode) {
    state.wakeupCodes = wakeupCodes
  }
}

const actions: ActionTree<AppState, RootState> = {
  setCurrentProduct({ commit }, { product, router }) {
    commit('setCurrentProduct', product)
    localStorage.setItem('CurrentProduct__', product)

    const route = { path: `/${product}/dashboard/` }
    router.push(route)
  },

  async getSecurityConcerns({ commit }) {
    const securityConcerns = await CodexConfigApi.getConfig('securityconcerns')
    if (!securityConcerns) {
      return null
    }
    commit('setSecurityConcerns', securityConcerns.activeConcerns)
  },

  async getWakeupCodes({ commit, state }) {
    if (state.wakeupCodes) {
      return
    }
    const response = await CodexConfigApi.getConfig('wakeupcodes')
    if (!response) {
      return null
    }
    commit('setWakeupCodes', response.wakeupCodes)
  },

  reset({ commit }) {
    commit('reset')
  }
}

const AppState: Module<AppState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
export default AppState
