/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * number of AC power phases
 */
export enum MachinePhases {
	'_1' = 1,
	'_3' = 3,
}
