/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginationInfo } from '../models/PaginationInfo';
import type { SteamTrapEquipmentOn } from '../models/SteamTrapEquipmentOn';
import type { SteamTrapEventEnd } from '../models/SteamTrapEventEnd';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class SteamTrapEquipmentOnService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get equipment on events
	 * Retrieve a list of equipment on events for a steam trap. The events are
	 * returned in descending order of the equipment on start time.
	 * @returns any A list of steam trap equipment on events.
	 * @throws ApiError
	 */
	public getSteamTrapsTrapidEquipmentOns({
		trapId,
		page = 1,
		pageSize = 50,
	}: {
		trapId: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<SteamTrapEquipmentOn>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/steamtraps/{trapId}/equipment-ons',
			path: {
				'trapId': trapId,
			},
			query: {
				'page': page,
				'pageSize': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * create equipment on event
	 * Create a new equipment on event for the steam trap.
	 * @returns any A steam trap equipment on event.
	 * @throws ApiError
	 */
	public postSteamTrapsTrapidEquipmentOns({
		trapId,
		requestBody,
	}: {
		trapId: string,
		/**
		 * An equipment on event to create.
		 */
		requestBody?: SteamTrapEquipmentOn,
	}): CancelablePromise<{
		data?: SteamTrapEquipmentOn;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/steamtraps/{trapId}/equipment-ons',
			path: {
				'trapId': trapId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * delete equipment on event
	 * Delete equipment on event.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteSteamtrapsTrapIdEquipmentOnsEquipmentOnId({
		trapId,
		equipmentOnId,
	}: {
		trapId: string,
		equipmentOnId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/steamtraps/{trapId}/equipment-ons/{equipmentOnId}',
			path: {
				'trapId': trapId,
				'equipmentOnId': equipmentOnId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * set equipment on event end time
	 * Set the end timestamp property of an existing equipment on event.
	 * @returns any A steam trap equipment on event.
	 * @throws ApiError
	 */
	public putSteamtrapsTrapIdEquipmentOnsEquipmentOnIdEndTime({
		trapId,
		equipmentOnId,
		requestBody,
	}: {
		trapId: string,
		equipmentOnId: string,
		/**
		 * Set the end timestamp of an existing steam trap event.
		 */
		requestBody?: SteamTrapEventEnd,
	}): CancelablePromise<{
		data?: SteamTrapEquipmentOn;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/steamtraps/{trapId}/equipment-ons/{equipmentOnId}/end-time',
			path: {
				'trapId': trapId,
				'equipmentOnId': equipmentOnId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
