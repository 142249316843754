const SteamSummary = () =>
  import(
    /* webpackChunkName: "SteamSummary" */ "@/views/steam/SteamSummary.vue"
  );
const SteamTrapDetail = () =>
  import(
    /* webpackChunkName: "SteamTrapDetail" */ "@/views/steam/SteamTrapDetail.vue"
  );
const AddSteamTrap = () =>
  import(
    /* webpackChunkName: "AddSteamTrap" */ "@/views/steam/AddSteamTrap.vue"
  );
const EditSteamTrap = () =>
  import(
    /* webpackChunkName: "EditSteamTrap" */ "@/views/steam/EditSteamTrap.vue"
  );
const SteamMain = () =>
  import(/* webpackChunkName: "SteamMain" */ "@/views/steam/SteamMain.vue");
const SteamBulkInstall = () =>
  import(
    /* webpackChunkName: "SteamBulkInstall" */ "@/views/steam/SteamBulkInstall.vue"
  );

import { Product, RouteMeta, PrivilegeKey } from "@/types";
import { useUserPrivileges } from "@/composables/useUserPrivileges";

export enum SteamTrapRoute {
  Summary,
  Detail,
  Edit,
  Add,
  Dashboard,
  BulkUpload
}

const summary = {
  path: "/steam/summary",
  name: "Steam Trap Summary",
  component: SteamSummary,
  meta: {
    id: SteamTrapRoute.Summary,
    title: "Steam Trap Monitoring Summary",
    key: "summary",
    product: Product.Steam,
    productString: "steam",
    privilegeFunction: async () => useUserPrivileges().canViewSteamTraps.value
  }
};

const addSteamTrap = {
  path: "/steamtrap/add",
  props: true,
  name: "Add Steam Trap",
  component: AddSteamTrap,
  meta: {
    id: SteamTrapRoute.Add,
    title: "Add Steam Trap",
    product: Product.Steam,
    productString: "steam",
    privilegeFunction: async () => useUserPrivileges().canAddSteamTraps.value
  }
};

const detail = {
  path: "/steamtrap/:steamTrapId",
  props: true,
  name: "Steam Trap Detail",
  component: SteamTrapDetail,
  meta: {
    id: SteamTrapRoute.Detail,
    title: "Steam Trap Detail",
    product: Product.Steam,
    productString: "steam",
    privilegeFunction: async () => useUserPrivileges().canViewSteamTraps.value
  }
};

const editSteamTrap = {
  path: "/steamtrap/:steamTrapId/edit",
  props: true,
  name: "Edit Steam Trap",
  component: EditSteamTrap,
  meta: {
    id: SteamTrapRoute.Edit,
    title: "Edit Steam Trap",
    product: Product.Steam,
    productString: "steam",
    privilegeFunction: async () => useUserPrivileges().canEditSteamTraps.value
  }
};

const dashboard = {
  path: "/steam/dashboard",
  name: "Steam Dashboard",
  component: SteamMain,
  meta: {
    id: SteamTrapRoute.Dashboard,
    title: "Steam Trap Overview",
    key: "dashboard",
    product: Product.Steam,
    productString: "steam",
    privilegeFunction: async () => useUserPrivileges().canViewSteamTraps.value
  }
};

const bulkUpload = {
  path: "/steam/bulkinstall",
  props: true,
  name: "Steam Bulk Install",
  component: SteamBulkInstall,
  meta: {
    id: SteamTrapRoute.BulkUpload,
    product: Product.Steam,
    productString: "steam",
    privilegeRequirement: [PrivilegeKey.SUPER_ADMIN]
  } as RouteMeta
};

export const EvercloudRoutedMenuItems = [
  {
    route: "dashboard",
    icon: "ti-server",
    name: "Dashboard",
    routeKey: "dashboard",
    enabled: true
  },
  {
    route: "summary",
    icon: "ti-server",
    name: "Summary",
    routeKey: "summary",
    enabled: true
  }
];
export const SageRoutedMenuItems = [
  {
    route: "dashboard",
    icon: "ti-server",
    name: "Dashboard",
    routeKey: "dashboard",
    enabled: true
  }
];

export default {
  info: {
    shortTitle: "Steam Traps",
    longTitle: "Steam Trap Monitoring",
    key: "steam"
  },
  routedMenuItems: EvercloudRoutedMenuItems,
  routes: [dashboard, summary, bulkUpload, addSteamTrap, editSteamTrap, detail]
};
