import { ProductConfiguration, EveractiveIntegrations } from "@/types";
import MachineHealthMonitoringConfiguration from "@/lib/MachineHealthMonitoringConfiguration";
import SteamTrapMonitoringConfiguration, {
  SageRoutedMenuItems
} from "@/lib/SteamTrapMonitoringConfiguration";

export class ProductConfigurationHelper {
  public getProductConfigurations(): ProductConfiguration[] {
    return [
      MachineHealthMonitoringConfiguration,
      SteamTrapMonitoringConfiguration
    ];
  }

  private getSteamConfiguration(integrations?: EveractiveIntegrations) {
    if (integrations?.sage?.enabled) {
      // If SAGE integrated, only show the menu items a SAGE user can use
      return {
        ...SteamTrapMonitoringConfiguration,
        routedMenuItems: SageRoutedMenuItems
      };
    } else {
      return SteamTrapMonitoringConfiguration;
    }
  }

  public getProductConfiguration(
    product: string,
    integrations?: EveractiveIntegrations
  ): ProductConfiguration {
    if (product === "machine") {
      return MachineHealthMonitoringConfiguration;
    } else if (product === "steam") {
      return this.getSteamConfiguration(integrations);
    }

    return {
      info: {
        shortTitle: "",
        longTitle: "",
        key: ""
      },
      routedMenuItems: [],
      routes: []
    };
  }
}

const productConfigurationHelper = new ProductConfigurationHelper();
export default productConfigurationHelper;
