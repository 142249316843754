/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { File } from '../models/File';
import type { FileDeleteBody } from '../models/FileDeleteBody';
import type { FileUpdate } from '../models/FileUpdate';
import type { FileUpload } from '../models/FileUpload';
import type { PaginationInfo } from '../models/PaginationInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class FilesService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * upload a file
	 * Upload a file and attach it to an existing entity.
	 *
	 * If the request references more than one entity, the API will
	 * return a validation error. If the entity does not exist, or if
	 * the requester does not have access to the entity, the API will
	 * return an authorization error.
	 *
	 * If the file is too large, the API will return a validation error.
	 *
	 * The API also returns a validation error if the entity that the file is
	 * being attached to already has the maximum number of attachments allowed
	 * by the backend.
	 * @returns any A file.
	 * @throws ApiError
	 */
	public postFiles({
		formData,
	}: {
		/**
		 * A request to upload a new file.
		 */
		formData?: FileUpload,
	}): CancelablePromise<{
		data?: File;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/files',
			formData: formData,
			mediaType: 'multipart/form-data',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get files
	 * Returns a paginated list of files that the client is authorized to view, for
	 * the given query parameter criteria.
	 * @returns any A paginated list of files.
	 * @throws ApiError
	 */
	public getFiles({
		fileName,
		mediaType,
		gatewayId,
		sensorId,
		steamTrapId,
		machineId,
		page = 1,
		pageSize = 50,
		sortBy = 'uploadedAtTimestamp',
		sortDir,
	}: {
		/**
		 * Filter the files using a `LIKE` comparison with the file name property.
		 */
		fileName?: string,
		/**
		 * Filter the list of files returned using a `LIKE` comparison with the file
		 * media type property.
		 */
		mediaType?: string,
		/**
		 * Filter the list of files returned by the id of the gateway they're attached to. An
		 * exact match is used for this filter.
		 */
		gatewayId?: string,
		/**
		 * Filter the list of files returned by the ID of the sensor they're attached to.
		 * An exact match is used for this filter.
		 */
		sensorId?: string,
		/**
		 * Filter the list of files returned by the ID of the steam trap they're attached to.
		 * An exact match is used for this filter.
		 */
		steamTrapId?: string,
		/**
		 * Filter the list of files returned by the ID of the machine they're attached to.
		 * An exact match is used for this filter.
		 */
		machineId?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * Indicate what field should be used to sort the returned list of files.
		 * If omitted the files are returned in an arbitrary order.
		 */
		sortBy?: 'fileName' | 'mediaType' | 'fileSize' | 'uploadedAtTimestamp' | 'deletedAtTimestamp' | 'deletedReason' | 'gateway.id' | 'gateway.name' | 'gateway.serialNumber' | 'sensor.id' | 'sensor.macAddress' | 'steamTrap.id' | 'machine.id',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
	}): CancelablePromise<{
		data?: Array<File>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/files',
			query: {
				'fileName': fileName,
				'mediaType': mediaType,
				'gateway.id': gatewayId,
				'sensor.id': sensorId,
				'steamTrap.id': steamTrapId,
				'machine.id': machineId,
				'page': page,
				'pageSize': pageSize,
				'sortBy': sortBy,
				'sortDir': sortDir,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * get file metadata
	 * Returns a single metadata record that describes an uploaded file.
	 * @returns any A file.
	 * @throws ApiError
	 */
	public getFile({
		fileId,
	}: {
		/**
		 * The UUID identifier of the file.
		 */
		fileId: string,
	}): CancelablePromise<{
		data?: File;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/files/{fileId}',
			path: {
				'fileId': fileId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * update file metadata
	 * Updates the user-editable metadata for a single file.
	 * @returns any A file.
	 * @throws ApiError
	 */
	public updateFile({
		fileId,
		requestBody,
	}: {
		/**
		 * The UUID identifier of the file.
		 */
		fileId: string,
		/**
		 * A request to update the user-editable metadata for a single file.
		 */
		requestBody?: FileUpdate,
	}): CancelablePromise<{
		data?: File;
	}> {
		return this.httpRequest.request({
			method: 'PATCH',
			url: '/files/{fileId}',
			path: {
				'fileId': fileId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * soft delete file
	 * Soft deletes a single file by id. Optionally accepts a reason for deleting as the body.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteFile({
		fileId,
		requestBody,
	}: {
		/**
		 * The UUID identifier of the file.
		 */
		fileId: string,
		/**
		 * A request to delete a single file (pass in delete reason).
		 */
		requestBody?: FileDeleteBody,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/files/{fileId}',
			path: {
				'fileId': fileId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
