import { IApiCredentials } from '@/types'
import { ApiCredentials as ApiApiCredentials } from './api/models/ApiCredentials'
import { EveractiveApi } from './everactiveApi'
import { EveractiveApiResponse } from './everactiveApiResponse'

export class ApiCredentialsApi {
  public static everactiveApi = new EveractiveApi().client.apiCredentials

  public static async createApiCredential(
    credentials: IApiCredentials
  ): Promise<EveractiveApiResponse<IApiCredentials>> {
    const requestBody = <ApiApiCredentials>{
      customerId: credentials?.customer?.id ?? credentials.privileges?.[0]?.customerId,
      effectiveEndTimestamp: isNaN(credentials.effectiveEndTimestamp)
        ? undefined
        : credentials.effectiveEndTimestamp,
      name: credentials.name,
      privileges: credentials.privileges
    }

    try {
      const response = await this.everactiveApi.postApiCredentials({ requestBody })
      return { success: true, data: response.data }
    } catch (e) {
      console.error('ApiCredentialsApi.createApiCredentials - error', credentials, e)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Api Credentials were not created successfully.' }
  }

  public static async deleteApiCredential(clientId: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteApiCredentialsClientId({ clientId })
      return true
    } catch (e) {
      console.error('ApiCredentialsApi.deleteApiCredential - error', clientId, e)
    }
    return false
  }

  public static async getApiCredential(
    clientId: string,
    includeSecret = false
  ): Promise<EveractiveApiResponse<IApiCredentials>> {
    try {
      const response = await this.everactiveApi.getApiCredentialsClientId({
        clientId: clientId,
        includeSecret: includeSecret
      })
      return { success: true, data: response.data }
    } catch (e) {
      console.error('ApiCredentialsApi.getApiCredential - error', clientId, e)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to view Api Credentials.' }
  }

  public static async rotateApiCredentialSecret(
    clientId: string
  ): Promise<EveractiveApiResponse<IApiCredentials>> {
    try {
      const response = await this.everactiveApi.postApiCredentialsClientIdRotateSecret({ clientId })
      return { success: true, data: response.data }
    } catch (e) {
      console.error('ApiCredentialsApi.rotateApiCredentialSecret - error', clientId, e)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to rotate ApiCredentials client secret.' }
  }
}
