import { UserApi } from '@/client'
import { PrivilegeKey } from '@/client/api'
import { Customer, IUser, Privilege, ProfileSettings, User } from '@/types'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from './types/root'
import { UserState } from './types/userState'

function initialState() {
  return {
    user: new User(),
    isUpdating: false
  }
}

const state: UserState = initialState()

function getAllowedProducts(user: IUser): string[] {
  const allowedProducts: string[] = []

  const hasStmAccess = user.privileges.some(
    (p) => p.key === PrivilegeKey.SUPER_ADMIN || p.customer?.enabledProducts?.steamTrapMonitor
  )
  if (hasStmAccess) {
    allowedProducts.push('steam')
  }

  const hasMhmAccess = user.privileges.some(
    (p) => p.key === PrivilegeKey.SUPER_ADMIN || p.customer?.enabledProducts?.machineHealthMonitor
  )
  if (hasMhmAccess) {
    allowedProducts.push('machine')
  }

  return allowedProducts
}

const mutations: MutationTree<UserState> = {
  reset(state: UserState) {
    const s = initialState()
    Object.keys(s).forEach((key) => {
      state[key] = s[key]
    })
  },

  id(state: UserState, value: string) {
    state.user.id = value
  },
  username(state: UserState, value: string) {
    state.user.username = value
  },
  email(state: UserState, value: string) {
    state.user.email = value
  },
  name(state: UserState, value: string) {
    state.user.name = value
  },
  phoneNumber(state: UserState, value: string) {
    state.user.phoneNumber = value
  },
  password(state: UserState, value: string) {
    state.user.password = value
  },
  privileges(state: UserState, value: Privilege[]) {
    state.user.privileges = value
  },
  customer(state: UserState, value: Customer) {
    state.user.customer = value
  },
  profile(state: UserState, value: ProfileSettings) {
    const newSettings = { ...state.user.profile, ...value }
    state.user.profile = newSettings
  },
  setIsUpdating(state: UserState, value: boolean): void {
    state.isUpdating = value
  }
}

const actions: ActionTree<UserState, RootState> = {
  updateUserAfterCustomerEdit({ commit }, customer): void {
    commit('customer', customer)
  },

  async updateUserFromResponse({ commit }, response): Promise<boolean> {
    commit('id', response.id)
    commit('email', response.email)
    commit('username', response.username)
    commit('phoneNumber', response.phoneNumber)
    commit('name', response.name)
    commit('privileges', response.privileges)
    commit('customer', response.customer)
    commit('profile', response.profile)
    return true
  },

  async verifyLogin({ dispatch }): Promise<boolean> {
    const authInfo = await UserApi.verifyLogin()

    // handle login failure
    if (!authInfo) {
      return false
    }

    // reset app state so no previously stored privileged data is left in vuex
    await dispatch('resetVuexState', null, { root: true })

    // get user and customer information (if needed) and load into vuex
    const setupSuccessful = await dispatch('setupUserAndCustomerInfo')
    if (!setupSuccessful) {
      return false
    }

    // set current product
    dispatch('setCurrentProduct')

    await dispatch('BryntumGridSettings/fetchGridSettings', state.user.id, {
      root: true
    })
    return true
  },

  async logout({ dispatch }): Promise<boolean> {
    localStorage.removeItem('gotAccessTokenAuth0')
    localStorage.removeItem('currentPasswordCorrect')
    localStorage.removeItem('previousURLInfo')

    // reset app state so no previously stored privileged data is left in vuex
    await dispatch('resetVuexState', null, { root: true })

    return true
  },

  // checks if the user is logged in, if so sets status and username in the state
  async checkLoginStatus({ dispatch }): Promise<boolean> {
    let loginStatus = false
    const response = await UserApi.fetchMe()

    if (response) {
      loginStatus = true
      await dispatch('updateUserFromResponse', response)
    }

    return loginStatus
  },

  async updateUser({ state, commit }): Promise<{ [key: string]: any }> {
    commit('setIsUpdating', true)

    try {
      const response = await UserApi.updateUser(state.user)
      return response
    } finally {
      commit('setIsUpdating', false)
    }
  },

  async updateUserPassword({ commit }, updatePassword): Promise<{ [key: string]: any }> {
    commit('setIsUpdating', true)

    try {
      const response = await UserApi.updateUserPassword(updatePassword)
      return response
    } finally {
      commit('setIsUpdating', false)
    }
  },

  async setupUserAndCustomerInfo({ dispatch }): Promise<boolean> {
    const me: any = await UserApi.fetchMe()

    // if we can't get the user info, we can't figure out permissions
    if (!me) {
      return false
    }

    await dispatch('updateUserFromResponse', me)

    return true
  },

  async setCurrentProduct({ commit, rootState }) {
    const storedCurrentProduct = localStorage.getItem('CurrentProduct__')
    const user: User = (rootState as any).User.user

    const allowedTypes = getAllowedProducts(user)

    // if the user has no product access, disable all products
    if (!allowedTypes.length) {
      localStorage.removeItem('CurrentProduct__')
      commit('App/setCurrentProduct', null, { root: true })

      // if the user does not have access to the current product in local storage,
      // pick the first product in the allowed list
    } else if (allowedTypes.length && !allowedTypes.includes(storedCurrentProduct)) {
      localStorage.setItem('CurrentProduct__', allowedTypes[0])
      commit('App/setCurrentProduct', allowedTypes[0], { root: true })
      // otherwise, use the saved current product
    } else {
      commit('App/setCurrentProduct', storedCurrentProduct, { root: true })
    }
  },
}

const UserStore: Module<UserState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions
}

export default UserStore
