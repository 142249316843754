/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * A daily status message from the gateway, containing slower changing/static values and data usage
 */
export type GatewayDailyStatusPayloadV3 = {
	/**
	 * Selected information about each process. All processes are sent in daily message
	 */
	processListDaily?: Array<{
		pid?: number;
		name?: string;
		cpuPercentage?: number;
		memPercentage?: number;
		wallClockSeconds?: number;
		cpuSeconds?: number;
	}>;
	/**
	 * Array of data usage (in KB) per netwrork connection created over the last day
	 */
	networkUseDaily?: Array<{
		src?: string;
		dst?: string;
		proto?: string;
		uploadKilobytes?: number;
		downloadKilobytes?: number;
	}>;
	wakeupRadio?: {
		/**
		 * part number for wakeup radio
		 */
		partNumber: string;
		/**
		 * firmware version for the wakeup radio
		 */
		firmwareVersion: string;
		/**
		 * firmware part number for the wakeup radio
		 */
		firmwarePartNumber: string;
		/**
		 * product description for the wakeup radio
		 */
		productDescription: string;
		/**
		 * product serial for the wakeup radio
		 */
		productSerial: string;
	};
	networkRouting?: {
		dnsResolvers?: Array<string>;
		defaultGateways?: Array<string>;
	};
	/**
	 * The schema of this packet, as a partial-path reference.
	 */
	schema: GatewayDailyStatusPayloadV3.schema;
	timestamp: string;
	serial: string;
};

export namespace GatewayDailyStatusPayloadV3 {

	/**
	 * The schema of this packet, as a partial-path reference.
	 */
	export enum schema {
		GATEWAY_STATUS_DAILY_V3_SCHEMA_JSON = 'gateway_status_daily_v3.schema.json',
	}


}

