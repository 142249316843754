/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { FileMinimal } from './FileMinimal';
import type { SensorAssociation } from './SensorAssociation';
import type { SensorInfo } from './SensorInfo';
import type { SensorInstallEvent } from './SensorInstallEvent';
import type { SensorReading } from './SensorReading';
import type { SensorStatus } from './SensorStatus';

/**
 * An Everactive sensor.
 */
export type Sensor = {
	currentInstallation?: SensorInstallEvent;
	/**
	 * Flag indicating the sensor is part of an Everactive developer kit.
	 */
	readonly devkitBundled: boolean;
	/**
	 * The id of the sensor.
	 */
	id: string;
	status?: SensorStatus;
	lastAssociation?: SensorAssociation;
	lastInfo?: SensorInfo;
	lastReading?: SensorReading;
	/**
	 * The MAC Address of the sensor.
	 */
	macAddress: string;
	/**
	 * The firmware version of the sensor at manufacturing time
	 */
	manufacturedFirmwareVersion?: string;
	/**
	 * The product name for the sensor
	 */
	productName?: string;
	profileImage?: FileMinimal;
	/**
	 * Flag indicating service is necessary for sensor to report valid values.
	 */
	readonly serviceNeeded?: boolean;
	/**
	 * Used internally to indicate what type of device the sensor is
	 * monitoring.
	 */
	type: Sensor.type;
};

export namespace Sensor {

	/**
	 * Used internally to indicate what type of device the sensor is
	 * monitoring.
	 */
	export enum type {
		AVAILABLE = 'Available',
		DEVKIT = 'Devkit',
		MHM = 'MHM',
		STM = 'STM',
	}


}

