/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerMinimal } from './CustomerMinimal';
import type { FileMinimal } from './FileMinimal';
import type { GatewayDeploymentStatus } from './GatewayDeploymentStatus';
import type { GatewayEvernet1Settings } from './GatewayEvernet1Settings';
import type { GatewayEvernet2Settings } from './GatewayEvernet2Settings';
import type { GatewayReading } from './GatewayReading';
import type { GatewayStatusSummary } from './GatewayStatusSummary';
import type { HazardousLocationClassification } from './HazardousLocationClassification';
import type { MqttBroker } from './MqttBroker';

/**
 * An Everactive gateway.
 */
export type Gateway = {
	/**
	 * If set to true this indicated gateway is installed.
	 */
	authorized?: boolean;
	/**
	 * This flag is no longer used to control sensor authorization behavior on gateways. All
	 * sensors that are recognized as an Everactive sensor are authorized on gateways. The value will
	 * always be set to true.
	 * @deprecated
	 */
	autoAuthorizeExistingSensors: boolean;
	/**
	 * The list of brokers the gateway is assigned to communicate through.
	 * This value is designated by the customer that the gateway is assigned
	 * to.
	 */
	brokers?: Array<MqttBroker>;
	/**
	 * True if the gateway is able to support evernet 2.
	 * Support is based on whether the required hardware is present
	 * on the gateway.
	 */
	canSupportEvernet2: boolean;
	/**
	 * This value contains the config schema last seen for each gateway,
	 * which allows the API to know if it should respond on v1, v2 or v3 topics.
	 */
	readonly configSchema?: string;
	/**
	 * The unix timestamp (seconds from 1970-01-01T00:00:00Z) when the gateway was created.
	 */
	readonly createdTimestamp?: number;
	customer: CustomerMinimal;
	deploymentStatus?: GatewayDeploymentStatus;
	/**
	 * Indicates if SSH is enabled on the gateway.
	 */
	enableSSH: boolean;
	/**
	 * Indicates if the Evergateway will ask Eversensors
	 * to turn on and send data. If disabled, no data will be captured.
	 */
	enableWakeup: boolean;
	evernet1Settings?: GatewayEvernet1Settings;
	evernet2Settings?: GatewayEvernet2Settings;
	/**
	 * An enumeration to indicate if the gateway is a production or vitual gateway.
	 */
	readonly flavor: Gateway.flavor;
	hazardousLocationClassification?: HazardousLocationClassification;
	/**
	 * The Everactive Gateway ID
	 */
	id: string;
	/**
	 * Notes about the Evergateway installation.
	 */
	installNotes?: string;
	lastReading?: GatewayReading;
	lastStatus?: GatewayStatusSummary;
	lastDailyStatus?: GatewayStatusSummary;
	/**
	 * The location of the Evergateway.
	 */
	location?: string;
	/**
	 * The name of the gateway. Typically set to the serial number.
	 */
	name?: string;
	profileImage?: FileMinimal;
	/**
	 * The serial number of the Evergateway.
	 */
	serialNumber: string;
	/**
	 * The gateway software version as reported by the gateway.
	 */
	readonly softwareVersion?: string;
	/**
	 * The Connectivity status of the Evergateway.
	 */
	status?: string;
	/**
	 * Indicates if the virtual machine image is available for download. This property
	 * only applies if the `flavor` property is **virtual**.
	 */
	readonly vmDownloadAvailable?: boolean;
	/**
	 * The size of the virtual machine image in bytes.
	 */
	readonly vmDownloadSize?: number;
	/**
	 * Indicates if a virtual machine image has already been downloaded. This property
	 * only applies if the `flavor` property is **virtual**.
	 */
	readonly vmWasDownloaded?: boolean;
};

export namespace Gateway {

	/**
	 * An enumeration to indicate if the gateway is a production or vitual gateway.
	 */
	export enum flavor {
		PRODUCTION = 'production',
		VIRTUAL = 'virtual',
	}


}

