export enum ValidationRules {
  isEmail = 'email',
  isInteger = 'integer',
  isMaxLength = 'maxlength',
  isMaxValue = 'maxvalue',
  isMinLength = 'minlength',
  isMinValue = 'minvalue',
  isNumber = 'number',
  isPasswordValid = 'passwordvalid',
  isRequired = 'required',
  matchesRegex = 'matchesregex'
}

export class ErrorState {
  /**
   * Mark required fields with a msg value of 'required' which will prevent form submission
   * but will not show the error message until the form field has been touched (i.e. isDirty = true).
   */
  static REQUIRED = 'required'

  isDirty: boolean

  /**
   * isError - will return true if a field has been touched and resulted
   * in an error message. If the field hasn't been touched will not indicate
   * it is an error. This is to prevent the required fields from being marked as an error
   * when a new form is loaded the first time.
   */
  get isError() {
    return this.msg && this.isDirty
  }
  msg: string

  constructor(data?: Partial<ErrorState>) {
    this.isDirty = false
    this.msg = ''
    if (data) {
      Object.assign(this, data)
    }
  }
}

/**
 * The rule list should be a pipe (|) concated string of the rules to apply to a specific value.
 * For rules that require additional information like a minimum or maximum to compare against, the
 * additional values should be appended as comma separated values.
 *
 * Examples:
 *
 * Simple rule where a value is required and must be an integer:
 * `required|integer`
 *
 * Rule where a value must be at least 8 characters long:
 * `required|minlength,8`
 *
 * @returns An array of error messages for each validation rule the value failed. If the value passed
 * all rules an empty array is returned.
 */
export const ApplyValidationRules = (
  ruleList: string,
  value: any,
  fieldName?: string
): Array<string> => {
  const errors: string[] = []
  const rules = ruleList.split('|')

  for (const rule of rules) {
    const ruleSplit = rule.split(';')
    const ruleName = ruleSplit[0]
    let err = ''
    switch (ruleName) {
      case ValidationRules.isEmail:
        err = isEmail(value)
        break
      case ValidationRules.isInteger:
        err = isInteger(value, fieldName)
        break
      case ValidationRules.isMaxLength:
        {
          const max = parseInt(ruleSplit[1], 10)
          err = isMaxLength(value, max, fieldName)
        }
        break
      case ValidationRules.isMaxValue:
        {
          const max = parseFloat(ruleSplit[1])
          err = isMaxValue(value, max, fieldName)
        }
        break
      case ValidationRules.isMinLength:
        {
          const min = parseInt(ruleSplit[1], 10)
          err = isMinLength(value, min, fieldName)
        }
        break
      case ValidationRules.isMinValue:
        {
          const min = parseFloat(ruleSplit[1])
          err = isMinValue(value, min, fieldName)
        }
        break
      case ValidationRules.isNumber:
        err = isNumber(value, fieldName)
        break
      case ValidationRules.isPasswordValid:
        err = isPasswordValid(value)
        break
      case ValidationRules.isRequired:
        err = isRequired(value, fieldName)
        break
      case ValidationRules.matchesRegex:
        {
          const regex = ruleSplit[1]
          const errMessage = ruleSplit[2]
          err = matchesRegex(value, regex, errMessage, fieldName)
        }
        break
    }
    if (err.length > 0) {
      errors.push(err)
    }
  }

  return errors
}

export const isEmail = (value) => {
  if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
    return ''
  }
  return 'Invalid email address.'
}

export const isInteger = (value: number | string, fieldName?: string) => {
  if (isNaN(+value)) {
    return `${fieldName || 'This field'} must be an integer.`
  }
  let v = parseFloat(value as any)
  if ((v | 0) == value) {
    return ''
  }
  return `${fieldName || 'This field'} must be an integer.`
}

export const isMaxLength = (value: string, maxLength: number, fieldName?: string) => {
  if (!value || value.length <= maxLength) {
    return ''
  }

  return `${fieldName || 'This field'} must be no more than ${maxLength} chars.`
}

export const isMaxValue = (value: number | string, maxValue: number, fieldName?: string) => {
  if (isNaN(+value)) {
    return ''
  }

  if (parseFloat(value as any) > maxValue) {
    return `${fieldName || 'This field'} can be no larger than ${maxValue}.`
  }

  return ''
}

export const isMinLength = (value: string, minLength: number, fieldName?: string) => {
  if (!value || value.length >= minLength) {
    return ''
  }

  return `${fieldName || 'This field'} must be at least ${minLength} chars.`
}

export const isMinValue = (value: number | string, minValue: number, fieldName?: string) => {
  if (isNaN(+value)) {
    return ''
  }

  if (parseFloat(value as any) < minValue) {
    return `${fieldName || 'This field'} must be at least ${minValue}.`
  }

  return ''
}

export const isNumber = (value: number | string, fieldName?: string) => {
  if (!value || (value as string).length === 0) {
    return ''
  }

  if (isNaN(Number(value.toString()))) {
    return `${fieldName || 'This field'} must be a number.`
  }

  return ''
}

export const isPasswordValid = (value: string) => {
  const containsUppercase = /(?=.*[A-Z])/
  const containsLowercase = /(?=.*[a-z])/
  const containsNumber = /(?=.*[0-9])/
  const containsSpecialCharacters = /(?=.*[!@#$&*()[\]_\-=+])/

  const isBetween8and128Characters = value && value.length >= 8 && value.length <= 128

  let criteriaMet = 0
  const criteriaRequired = 3

  const evaluateCriteria = (regex: RegExp) => {
    if (regex.test(value)) {
      criteriaMet++
    }
  }

  evaluateCriteria(containsUppercase)
  evaluateCriteria(containsLowercase)
  evaluateCriteria(containsNumber)
  evaluateCriteria(containsSpecialCharacters)

  if (isBetween8and128Characters && criteriaMet >= criteriaRequired) {
    return ''
  }

  return 'The password does not meet the requirements.'
}

export const isRequired = (value: string, fieldName?: string) => {
  if (value && value.toString().trim().length > 0) {
    return ''
  }
  return `${fieldName || 'This field'} is required.`
}

export const matchesRegex = (
  value: string,
  regex: string,
  failMessage: string,
  fieldName?: string
) => {
  if (!value || value.length === 0) {
    return ''
  }
  const re = new RegExp(regex, 'gm')
  if (!re.test(value)) {
    return `${fieldName || 'This field'} ${failMessage}`
  }
  return ''
}
