<script lang="ts">
import { useNotificationState } from '@/composables/useNotificationState'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotificationsButton',
  setup() {
    const { hasActiveNotifications } = useNotificationState()

    return {
      hasActiveNotifications
    }
  }
})
</script>

<template>
  <div :class="$style.alertsContainer">
    <router-link
      :to="{ name: 'Notifications' }"
      :class="$style.alertsLink"
      data-testid="stmNotifications__linkButton"
    >
      <img
        v-if="hasActiveNotifications"
        src="/assets/icons/alert-active.svg"
        :class="$style.alertIcon"
        alt="Active alerts"
      />
      <img
        v-else
        src="/assets/icons/alert-inactive.svg"
        :class="$style.alertIcon"
        alt="No active alerts"
      />
    </router-link>
  </div>
</template>

<style module>
.alertsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.alertsLink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 48px;
  flex: 1 auto;
}
.alertIcon {
  width: 30px;
  height: auto;
}
.alertsContainer :global .router-link-active {
  border-bottom: 1px solid #14e067;
  background: rgba(255, 255, 255, 0.1);
}
</style>
