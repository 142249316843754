import { CustomersApi } from "@/client";
import { Customer } from "@/types";
import { computed, Ref, ref, watch } from "vue";
import { useCurrentUser } from "./useCurrentUser";
import { isSageIntegrated as _isSageIntegrated } from "@/lib/utils/userutils";
import { watchOnceWithFilter } from "./watchOnceWithFilter";

type CurentCustomerApi = {
  customer: Ref<Customer | undefined>;
  customerId: Ref<string | undefined>;
  isSageCustomer: Ref<boolean>;
  customerLoaded: Promise<void>;
  currentCustomerLoaded: Ref<boolean>;
  customerIdQueryParam: Ref<string>;
  applyCustomerUpdates: (updatedCustomer: Customer) => void;
};

const { customerId: userCustomerId, isSuperAdmin } = useCurrentUser();

const customer = ref<Customer>();

const customerId = computed(() => customer.value?.id);
const isSageCustomer = computed(() =>
  _isSageIntegrated(customer.value?.integrations)
);
const currentCustomerLoaded = computed(
  () => Boolean(customerId.value) || isSuperAdmin.value
);
const customerIdQueryParam = computed(
  () => (isSuperAdmin.value && customerId.value) || null
);

watch(
  userCustomerId,
  () => {
    if (!userCustomerId.value) {
      customer.value = new Customer();
      return;
    }

    CustomersApi.fetchCustomer(userCustomerId.value).then(
      (_customer) => (customer.value = _customer)
    );
  },
  { immediate: true }
);

const customerLoaded = new Promise<void>((resolve) => {
  watchOnceWithFilter(
    [customer, isSuperAdmin],
    () => isSuperAdmin.value || Boolean(customer.value?.id),
    () => resolve()
  );
});

function applyCustomerUpdates(updatedCustomer: Customer) {
  if (customer.value.id === updatedCustomer.id) {
    customer.value = updatedCustomer;
  }
}

export function useCurrentCustomer(): CurentCustomerApi {
  return {
    customer,
    customerId,
    isSageCustomer,
    customerLoaded,
    customerIdQueryParam,
    currentCustomerLoaded,
    applyCustomerUpdates
  };
}
