<script lang="ts">
import useCodexButton from '@/composables/useCodexButton'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    secondaryButton: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as PropType<'button' | 'submit' | 'reset'>,
      default: 'button'
    },
    usePreventDefault: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { handleClick } = useCodexButton(emit, props?.usePreventDefault)
    return { handleClick }
  }
})
</script>

<template>
  <button
    :class="['codexButton', secondaryButton ? 'secondary' : '']"
    @click="handleClick"
    :type="type"
  >
    <slot />
  </button>
</template>

<style module></style>
