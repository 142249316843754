import { PrivilegeKey } from "@/client/api";

const productPrivileges = new Set([
  PrivilegeKey.READ_ONLY,
  PrivilegeKey.USER,
  PrivilegeKey.ASSET_MANAGER,
  PrivilegeKey.CUSTOMER_ADMIN
]);

export function isProductPrivilege(privilegeKey: PrivilegeKey) {
  return productPrivileges.has(privilegeKey);
}
