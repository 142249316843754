/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerMinimal } from './CustomerMinimal';

/**
 * A RabbitMQ MQTT vhost that brokers communication with Evergateways.
 */
export type Vhost = {
	/**
	 * The MQTT vhost id.
	 */
	id: string;
	/**
	 * Indicates the type of information transmitted via the vhost, either config or data.
	 */
	vhostType: Vhost.vhostType;
	customer?: CustomerMinimal;
	/**
	 * The name of the MQTT vhost
	 */
	name: string;
};

export namespace Vhost {

	/**
	 * Indicates the type of information transmitted via the vhost, either config or data.
	 */
	export enum vhostType {
		CONFIG = 'config',
		DATA = 'data',
	}


}

