/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseEversensorReading } from './BaseEversensorReading';
import type { FrequencyMagnitude } from './FrequencyMagnitude';
import type { SensorAxisMetrics } from './SensorAxisMetrics';
import type { VibrationElectricalNodeReadingSchema } from './VibrationElectricalNodeReadingSchema';

/**
 * A reading received from an Eversensor with vibrational and electrical field sensor measurements.
 */
export type VibrationElectricalNodeReading = (BaseEversensorReading & {
	electricFieldMeasurements?: {
		configurationMetadata?: any;
		/**
		 * The maximum recorded electrical field value in micro-teslas.
		 */
		maxLevel?: number;
		/**
		 * The average recorded electrical field value in micro-teslas.
		 */
		overallLevel?: number;
		spectralPeaks?: Array<FrequencyMagnitude>;
	};
	humidityMeasurements?: Array<{
		/**
		 * The humidity sensor index (zero-based).
		 */
		sensorIndex?: number;
		/**
		 * The humidity measurement (percent).
		 */
		value?: number;
	}>;
	schema?: VibrationElectricalNodeReadingSchema;
	schemaVersion?: VibrationElectricalNodeReading.schemaVersion;
	temperatureMeasurements?: Array<{
		/**
		 * The temperature sensor index (zero-based).
		 */
		sensorIndex?: number;
		/**
		 * The temperature measurement (K).
		 */
		value?: number;
	}>;
	vibrationalMeasurements?: {
		acceleration?: SensorAxisMetrics;
		velocity?: SensorAxisMetrics;
		displacement?: SensorAxisMetrics;
	};
});

export namespace VibrationElectricalNodeReading {

	export enum schemaVersion {
		V1 = 'v1',
	}


}

