/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnvironmentalNodeReading } from '../models/EnvironmentalNodeReading';
import type { Eversensor } from '../models/Eversensor';
import type { FullTimeWaveform } from '../models/FullTimeWaveform';
import type { PaginationInfo } from '../models/PaginationInfo';
import type { TempHumidityNodeReading } from '../models/TempHumidityNodeReading';
import type { VibrationElectricalNodeReading } from '../models/VibrationElectricalNodeReading';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EversensorsService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get eversensors
	 * Retrieve a list of Eversensors.
	 * @returns any A list of Eversensors.
	 * @throws ApiError
	 */
	public getEversensors({
		customerId,
		devkitBundled,
		lastAssociationGatewaySerial,
		lastFirmwareVersion,
		lastPartNumber,
		lastSerialNumber,
		macAddress,
		manufacturedFirmwareVersion,
		page = 1,
		pageSize = 50,
		sortBy,
		sortDir,
		type,
	}: {
		/**
		 * Filter the list of Eversensors returned by the customer the Eversensor is associated with. If
		 * the association is not explicit, it is inferred via the gateway the Eversensor readings are reported
		 * through. Multiple values can be passed as a comma
		 * separated value (e.g. `&customer-id=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting sensors does not have access to the customer-id passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Filter the list by the devkitBundled flag. If not passed no filtering is applied.
		 */
		devkitBundled?: boolean,
		/**
		 * Filter the list of Eversensors by gateway serial of the last association using a `LIKE` comparison statement.
		 */
		lastAssociationGatewaySerial?: string,
		/**
		 * Filter the list of Eversensors by last firmware version using a `LIKE` comparison statement.
		 */
		lastFirmwareVersion?: string,
		/**
		 * Filter the list of Eversensors by last part number using a `LIKE` comparison statement.
		 */
		lastPartNumber?: string,
		/**
		 * Filter the list of Eversensors by last serial number using a `LIKE` comparison statement.
		 */
		lastSerialNumber?: string,
		/**
		 * Filter the returned Eversensors by the macAddress property.
		 * A case-insensitive database match string.
		 * Returns all sensors whose ID matches this string.
		 */
		macAddress?: string,
		/**
		 * Filter the list of Eversensors by manufactured firmware version using a `LIKE` comparison statement.
		 */
		manufacturedFirmwareVersion?: string,
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
		/**
		 * A field by which to sort the returned list of Eversensors.
		 */
		sortBy?: 'last-association' | 'last-association-gateway-serial' | 'last-info' | 'last-info-firmware-version' | 'last-info-part-number' | 'last-info-serial-number' | 'last-reading-gateway-serial' | 'last-reading-timestamp' | 'mac-address' | 'manufactured-firmware-version' | 'type',
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
		/**
		 * Filter the list of Eversensors returned by the type of sensor using a `LIKE` comparison statement.
		 */
		type?: string,
	}): CancelablePromise<{
		data?: Array<Eversensor>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/eversensors',
			query: {
				'customer-id': customerId,
				'devkitBundled': devkitBundled,
				'last-association-gateway-serial': lastAssociationGatewaySerial,
				'last-firmware-version': lastFirmwareVersion,
				'last-part-number': lastPartNumber,
				'last-serial-number': lastSerialNumber,
				'mac-address': macAddress,
				'manufactured-firmware-version': manufacturedFirmwareVersion,
				'page': page,
				'page-size': pageSize,
				'sort-by': sortBy,
				'sort-dir': sortDir,
				'type': type,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get eversensor readings
	 * Retrieve a list of Eversensor readings within a specified time range.
	 * Maximum time range is 1 day (24 hours, 86400 seconds).
	 * - The returned readings are sorted by timestamp in descending order.
	 * - The properties returned in the response vary according the sensor type.
	 * @returns any A list of Eversensor readings.
	 * @throws ApiError
	 */
	public getEversensorsMacaddressReadings({
		macAddress,
		endTime,
		startTime,
	}: {
		macAddress: string,
		/**
		 * The end time for readings to retrieve, inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		endTime: number,
		/**
		 * The start time for readings to retrieve, inclusive.
		 * A UNIX timestamp, seconds from 1970-01-01.
		 */
		startTime: number,
	}): CancelablePromise<{
		data?: Array<(TempHumidityNodeReading | VibrationElectricalNodeReading | EnvironmentalNodeReading)>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/eversensors/{macAddress}/readings',
			path: {
				'macAddress': macAddress,
			},
			query: {
				'end-time': endTime,
				'start-time': startTime,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get eversensor last reading
	 * Retrieve the last reading received from a specific Eversensor.
	 * The properties returned in the response vary according to the sensor type.
	 * @returns any A single Eversensor reading.
	 * @throws ApiError
	 */
	public getEversensorsMacaddressReadingsLast({
		macAddress,
	}: {
		macAddress: string,
	}): CancelablePromise<{
		data?: (TempHumidityNodeReading | VibrationElectricalNodeReading | EnvironmentalNodeReading);
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/eversensors/{macAddress}/readings/last',
			path: {
				'macAddress': macAddress,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get list of eversensor machine time waveforms
	 * Retrieve a list of all available time waveform readings for an Eversensor
	 * @returns any A summary of total full time waveforms readings for an asset.
	 * @throws ApiError
	 */
	public getEversensorsMacaddressTimeWaveforms({
		macAddress,
		startTime,
		endTime,
		sortDir,
		page = 1,
		pageSize = 50,
	}: {
		macAddress: string,
		/**
		 * The UNIX timestamp of a start date range for a FTWF reading
		 */
		startTime?: number,
		/**
		 * The UNIX timestamp of an end date range for a FTWF reading
		 */
		endTime?: number,
		/**
		 * The direction to sort the list of results, ascending or descending.
		 */
		sortDir?: 'asc' | 'desc',
		/**
		 * A one-based index for the first page number to return from a paginated call.
		 */
		page?: number,
		/**
		 * The maximum number of items to return per page.
		 */
		pageSize?: number,
	}): CancelablePromise<{
		data?: Array<FullTimeWaveform>;
		paginationInfo?: PaginationInfo;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/eversensors/{macAddress}/timewaveforms',
			path: {
				'macAddress': macAddress,
			},
			query: {
				'startTime': startTime,
				'endTime': endTime,
				'sort-dir': sortDir,
				'page': page,
				'page-size': pageSize,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * get a machine time waveform
	 * Retrieve a single time waveform reading
	 * @returns any A time waveform reading.
	 * @throws ApiError
	 */
	public getEversensorsMacaddressTimeWaveformsWaveformId({
		macAddress,
		waveformId,
	}: {
		macAddress: string,
		waveformId: string,
	}): CancelablePromise<{
		data?: FullTimeWaveform;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/eversensors/{macAddress}/timewaveforms/{waveformId}',
			path: {
				'macAddress': macAddress,
				'waveformId': waveformId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
