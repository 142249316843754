import {
  Customer as ApiCustomer,
  CustomerAxisLabels as ApiCustomerAxisLabels,
  CustomerMachineInfo as ApiCustomerMachineInfo,
  CustomerMinimal as ApiCustomerMinimal,
  CustomerProducts as ApiCustomerProducts,
  CustomerSteamTrapInfo as ApiCustomerSteamTrapInfo
} from '@/client/api'

export enum CustomerStatus {
  Decommissioned = 'Decommissioned',
  Demo = 'Demo',
  Production = 'Production',
  ToBeInstalled = 'To Be Installed'
}

export interface EveractiveIntegration {
  enabled: boolean
}

export interface SageIntegration extends EveractiveIntegration {
  sageCustomerId?: string
}

export interface EveractiveIntegrations {
  sage?: SageIntegration
}

export interface ICustomerMinimal extends ApiCustomerMinimal {}

export interface CustomerAxisLabels extends ApiCustomerAxisLabels {}

export interface CustomerMachineInfo extends ApiCustomerMachineInfo {}

export class CustomerMinimal implements ICustomerMinimal {
  id: string
  name: string
  status: ApiCustomerMinimal.status
  integrations?: EveractiveIntegrations = { sage: { enabled: false } }
  createdAt?: Date
  updatedAt?: Date
  version?: string
  machineInfo?: CustomerMachineInfo

  constructor(data?: Partial<CustomerMinimal> | Partial<Customer>) {
    if (data) {
      Object.assign(this, data)
    }
  }
}

export const manualInspectionIntervalOptions = {
  'Every four years': 1460,
  'Every two years': 730,
  'Every 18 months': 547,
  Annually: 365,
  'Twice per year': 182,
  Quarterly: 91,
  Monthly: 30
}

export const availableProducts = {
  steamTrapMonitor: 'Steam Trap',
  machineHealthMonitor: 'Machine Health',
  dataServices: 'Data Services'
}

export interface ICustomer extends ApiCustomer {}

export class Customer implements ICustomer {
  public id = ''
  public costOfSteam?: number | null = 10
  public enabledProducts?: ApiCustomerProducts | null = {
    machineHealthMonitor: false,
    steamTrapMonitor: false,
    dataServices: false
  }
  public fiscalYearStart: string = '01-01'
  public name: string
  public steamTrapInfo?: CustomerSteamTrapInfo | null = {
    boilerEfficiency: 0.85,
    isSeasonal: false,
    isSteamShutdown: false,
    manualInspectionInterval: 365,
    seasonalEndDate: null,
    seasonalStartDate: null,
    totalCount: 0
  }
  public machineInfo?: CustomerMachineInfo | null = {
    axisLabels: {
      x: 'X',
      y: 'Y',
      z: 'Z'
    }
  }
  public isSSO: boolean
  public ssoDomains?: string[] | null
  public status: ApiCustomer.status = ApiCustomer.status.PRODUCTION
  public version?: string

  public integrations: EveractiveIntegrations = { sage: { enabled: false } }

  public constructor(data?: Partial<Customer>) {
    if (data) {
      const cleanData = JSON.parse(JSON.stringify(data)) // this allows us to make a copy without bringing any vuex tracking with it
      Object.assign(this, cleanData)
    }

    if (!this.steamTrapInfo) {
      this.steamTrapInfo = {}
    }

    if (!this.ssoDomains) {
      this.ssoDomains = []
    }

    if (!this.integrations) {
      this.integrations = { sage: { enabled: false } }
    }
  }

  get manualInspectionIntervalDescription() {
    return Object.keys(manualInspectionIntervalOptions).find(
      (i) => manualInspectionIntervalOptions[i] === this.steamTrapInfo?.manualInspectionInterval
    )
  }

  get availableIntegrations() {
    return {
      sage: 'Sage'
    }
  }

  get statusOptions() {
    return Object.values(CustomerStatus)
  }
}

export interface CustomerSteamTrapInfo extends ApiCustomerSteamTrapInfo {}

export type CustomerOption = {
  code: string
  label: string
}

export function toCustomerOptions(customers: Customer[]): CustomerOption[] {
  return customers.map((c) => ({ code: c.id, label: c.name }))
}
