export enum ConversionTypes {
  inchesPerSecond = "ips",
  millimetersPerSecond = "mm/s"
}

export enum ConversionTypeLabels {
  inchesPerSecond = "IPS Peak",
  millimetersPerSecond = "mm/s"
}

export enum MagnitudeTypes {
  acceleration = "acceleration",
  velocity = "velocity"
}

export enum MagnitudeTypeLabels {
  acceleration = "RMS mg",
  velocity = "IPS Peak"
}
