import { useUserPrivileges } from "@/composables/useUserPrivileges";
import store from "@/data-store/index";
import { createNavGuard } from "./createNavGuard";

const { isItAdmin, hasOnlyDsProductAccess, hasOnlyDeveloperPrivilege } =
  useUserPrivileges();
export const rootPathGuard = createNavGuard(
  "rootPathGuard",
  (to, _from, next) => {
    if (to.path !== "/") {
      return next();
    }

    const currentProduct = store.getters["App/currentProduct"];
    // if the only product the user has enabled is the Data Services product they should be sent
    // to the management view (there is no product specific view).
    if (
      isItAdmin.value ||
      hasOnlyDsProductAccess.value ||
      hasOnlyDeveloperPrivilege.value
    ) {
      return next({ path: `/management`, replace: true });
    }

    return next({
      path: currentProduct
        ? `/${currentProduct}/dashboard`
        : "/no-products-enabled",
      replace: true
    });
  }
);
