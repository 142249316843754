/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerMinimal } from './CustomerMinimal';

/**
 * An event describing the sensor installation on an entity.
 */
export type SensorInstallEvent = {
	/**
	 * The timestamp when the sensor was removed from the equipment.
	 */
	endTimestamp?: number;
	installEntityCustomer?: CustomerMinimal;
	/**
	 * The id of the entity the sensor is installed on.
	 */
	installEntityId: string;
	/**
	 * The type of entity the sensor is installed on.
	 */
	installEntityType: SensorInstallEvent.installEntityType;
	/**
	 * The id of the installation event.
	 */
	installId: string;
	/**
	 * The sensor id.
	 */
	sensorId: string;
	/**
	 * The sensor MAC Address.
	 */
	sensorMacAddress?: string;
	/**
	 * The timestamp when the sensor was installed on the equipment.
	 */
	startTimestamp: number;
	/**
	 * A flag specific to STM sensors indicating the condensate and steam temperature probes were swapped
	 * when installed. This flag is used to correct the mapping of temperatures so the values are returned
	 * correctly from the API when retrieving time series data for an STM sensor.
	 */
	tempProbesSwapped?: boolean;
};

export namespace SensorInstallEvent {

	/**
	 * The type of entity the sensor is installed on.
	 */
	export enum installEntityType {
		MACHINE = 'machine',
		STEAM_TRAP = 'steam_trap',
	}


}

