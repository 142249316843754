import {
  MachineHealthFFTThresholds as ApiMachineHealthFFTThresholds,
  MachineHealthThresholds as ApiMachineHealthThresholds
} from '@/client/api'

export interface MinMaxFloatThreshold {
  min?: number | null
  max?: number | null
}

export interface MaxFloatThreshold {
  max?: number | null
}

export interface IMachineThresholds extends ApiMachineHealthThresholds {}

export class MachineThresholds implements IMachineThresholds {
  public temperatureUnit?: ApiMachineHealthThresholds.temperatureUnit =
    ApiMachineHealthThresholds.temperatureUnit.FAHRENHEIT
  public energyMagnitudeType?: ApiMachineHealthThresholds.energyMagnitudeType =
    ApiMachineHealthThresholds.energyMagnitudeType.VELOCITY
  public energyThresholdUnit: ApiMachineHealthThresholds.energyThresholdUnit =
    ApiMachineHealthThresholds.energyThresholdUnit.MM_S
  public tempAmbient?: MinMaxFloatThreshold = {}
  public tempTeg?: MinMaxFloatThreshold = {}
  public tempDelta?: MinMaxFloatThreshold = {}
  public zEnergy?: MinMaxFloatThreshold = {}
  public xEnergy?: MinMaxFloatThreshold = {}
  public yEnergy?: MinMaxFloatThreshold = {}
  public humidity?: MinMaxFloatThreshold = {}

  public constructor(thresholds?: Partial<MachineThresholds>) {
    if (thresholds) {
      const cleanThresholds = JSON.parse(JSON.stringify(thresholds))
      Object.assign(this, cleanThresholds)

      Object.getOwnPropertyNames(this).forEach((property) => {
        if (property === 'temperatureUnit') {
          return
        }

        if (this[property].min === '') {
          delete this[property].min
        }

        if (this[property].max === '') {
          delete this[property].max
        }
      })
    }
  }
}

export interface IMachineFFTThresholds extends ApiMachineHealthFFTThresholds {}

export class MachineFFTThresholds implements IMachineFFTThresholds {
  public magnitudeType: ApiMachineHealthFFTThresholds.magnitudeType =
    ApiMachineHealthFFTThresholds.magnitudeType.VELOCITY
  public thresholdUnit: ApiMachineHealthFFTThresholds.thresholdUnit =
    ApiMachineHealthFFTThresholds.thresholdUnit.MM_S
  public zLowFrequencyBand: MaxFloatThreshold = {}
  public zMidFrequencyBand: MaxFloatThreshold = {}
  public zHighFrequencyBand: MaxFloatThreshold = {}
  public xLowFrequencyBand: MaxFloatThreshold = {}
  public xMidFrequencyBand: MaxFloatThreshold = {}
  public xHighFrequencyBand: MaxFloatThreshold = {}
  public yLowFrequencyBand: MaxFloatThreshold = {}
  public yMidFrequencyBand: MaxFloatThreshold = {}
  public yHighFrequencyBand: MaxFloatThreshold = {}

  public constructor(fftThresholds?: Partial<MachineFFTThresholds>) {
    if (fftThresholds) {
      const cleanThresholds = JSON.parse(JSON.stringify(fftThresholds))
      Object.assign(this, cleanThresholds)

      Object.getOwnPropertyNames(this).forEach((property) => {
        if (this[property].max === '') {
          delete this[property].max
        }
      })
    }
  }
}
