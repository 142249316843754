import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import Highcharts3d from 'highcharts/highcharts-3d';
import loadExporting from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import { createApp } from 'vue';
import App from './App.vue';
import { Auth0Plugin, InstallAuth0Plugin } from './auth';
import { auth0Config } from './auth0Config';
import { configureApiClient } from './client/api/configureApiClient';
import store from './data-store';
import { apiUrl202007 } from './env';
import router from './router';
import 'floating-vue/dist/style.css';
import './css/bryntum-grid-customizations.css';
import './css/style.css';
import './css/forms.css';
import './css/eversensor-icons.css';
import '@bryntum/grid/grid.classic-light.min.css';
import './css/bryntum-grid-customizations.css';
import 'flatpickr/dist/flatpickr.css';
import mitt from 'mitt';
import { Store } from "vuex";


const apiClientConfig = {
  BASE: apiUrl202007,
  TOKEN: () => Auth0Plugin.getTokenSilently(),
  HEADERS: { 'Cache-Control': 'private, no-store, max-age=0' }
}

configureApiClient(apiClientConfig)

const emitter = mitt()
const app = createApp(App)

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    emitter: typeof emitter
    $store: Store<any>;
  }
}

app.config.globalProperties.emitter = emitter

app.provide('auth', Auth0Plugin)

Highcharts.setOptions({
  colors: ['#00C8FF', '#373177', '#A4D916', '#9519B7', '#046A8B', '#E99C53'],
  lang: {
    thousandsSep: ','
  },
  accessibility: {
    enabled: false
  }
})

Highcharts3d(Highcharts)

loadExporting(Highcharts)
offlineExporting(Highcharts)

app.use(store).use(router).use(InstallAuth0Plugin, auth0Config).use(HighchartsVue)

app.mount('#app')