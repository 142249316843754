/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Privilege } from '../models/Privilege';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PrivilegeService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * get privileges
	 * Retrieve a list of all privileges available in the system.
	 * @returns any A list of privilege keys.
	 * @throws ApiError
	 */
	public getPrivileges(): CancelablePromise<{
		data?: Array<Privilege>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/privileges',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

}
