/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { SensorMagnitudeHolder } from './SensorMagnitudeHolder';
import type { SensorMagnitudeMetricName } from './SensorMagnitudeMetricName';
import type { ThresholdLimitType } from './ThresholdLimitType';

/**
 * A generic wrapper to represent a variety of historical events that occured on a machine.
 */
export type MachineHistoryItem = {
	/**
	 * Events related to customer association / disassociation will include the customerName property.
	 */
	customerName?: string;
	/**
	 * The underlying unique id for the event.
	 */
	eventId: string;
	/**
	 * The unix timestamp when the event occurred.
	 */
	eventTimestamp: number;
	/**
	 * The specific type of historical event this item represents.
	 */
	historyType: MachineHistoryItem.historyType;
	limitType?: ThresholdLimitType;
	limit?: SensorMagnitudeHolder;
	measured?: SensorMagnitudeHolder;
	metricName?: SensorMagnitudeMetricName;
	/**
	 * The sensor mac address for an `sensor_install` or `sensor_uninstall` historyTypes.
	 */
	sensorMacAddress?: string;
	/**
	 * The name of the user that acknowledged or cleared an alarm on the machine.
	 */
	userName?: string;
};

export namespace MachineHistoryItem {

	/**
	 * The specific type of historical event this item represents.
	 */
	export enum historyType {
		ALARM_ACKNOWLEDGED = 'alarm_acknowledged',
		ALARM_CLEARED = 'alarm_cleared',
		CUSTOMER_ASSOCIATE = 'customer_associate',
		CUSTOMER_DISASSOCIATE = 'customer_disassociate',
		SENSOR_INSTALL = 'sensor_install',
		SENSOR_UNINSTALL = 'sensor_uninstall',
		THRESHOLD_INBOUND = 'threshold_inbound',
		THRESHOLD_OUTBOUND = 'threshold_outbound',
	}


}

