import { OpenAPI, OpenAPIConfig } from './core/OpenAPI'
import createDeferred from 'p-defer'

const apiClientConfigured = createDeferred()

type ApiClientConfigOptions = Partial<Omit<OpenAPIConfig, 'TOKEN'>> & {
	TOKEN: () => Promise<string>
}

export function configureApiClient(config: ApiClientConfigOptions) {
	const props = Object.keys(config) as (keyof OpenAPIConfig)[]
	for (const prop of props) {
		if (prop === 'TOKEN') {
			const tokenResolver = config[prop]
			OpenAPI.TOKEN = () => apiClientConfigured.promise.then(() => tokenResolver())
		} else {
			(OpenAPI[prop] as any) = config[prop]
		}
	}

	apiClientConfigured.resolve()
}
