/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomerMinimal } from './CustomerMinimal';
import type { MachineHealthThresholdBreachDetail } from './MachineHealthThresholdBreachDetail';
import type { Sensor } from './Sensor';
import type { SteamTrapNotificationResolution } from './SteamTrapNotificationResolution';
import type { UserNotificationAction } from './UserNotificationAction';

/**
 * Detail about a single notification.
 */
export type Notification = {
	/**
	 * The unique ID for a given notification.
	 */
	id?: string;
	readonly customer?: CustomerMinimal;
	/**
	 * The ID of the steam trap or machine that a notification is for.
	 */
	entityId?: string;
	/**
	 * Detail about the event a notification is for.
	 */
	eventDetail?: string;
	/**
	 * The ID of the event a notification is for.
	 */
	eventId?: string;
	/**
	 * The timestamp of the event the notification is for.
	 */
	eventTimestamp?: number;
	/**
	 * The type of event the notification is for.
	 */
	eventType?: Notification.eventType;
	/**
	 * The facility where the notification's underlying asset is.
	 */
	facility?: string;
	/**
	 * The location where the notification's underlying asset is.
	 */
	location?: string;
	/**
	 * Notifications related to machine alarms caused by threshold breaches
	 * will have an array of details about the threshold crossed events causing
	 * the notification.
	 * This array allows the notification system to only send a notification for
	 * each unique threshold crossing contributing to a speicific machine alarm
	 * event
	 */
	machineThresholdBreachDetails?: Array<MachineHealthThresholdBreachDetail>;
	notificationType?: Notification.notificationType;
	resolutionDetailType?: Notification.resolutionDetailType;
	/**
	 * The name of the user that resolved the notification.
	 */
	resolutionName?: string;
	resolutionTimestamp?: number;
	resolutionUserId?: string;
	/**
	 * The username of the user that resolved the notification.
	 */
	resolutionUsername?: string;
	readonly sensor?: Sensor;
	serviceTag?: string;
	steamTrapResolveDetail?: SteamTrapNotificationResolution;
	readonly userActions?: Array<UserNotificationAction>;
	/**
	 * Notes about the notification.
	 */
	notes?: string;
};

export namespace Notification {

	/**
	 * The type of event the notification is for.
	 */
	export enum eventType {
		EQUIPMENT_ON = 'Equipment On',
		FAILURE = 'Failure',
		MACHINE_ALARM_STATE_CHANGED = 'Machine Alarm State Changed',
		PREDICTED_FAILURE = 'Predicted Failure',
		UNEXPECTED_COLD = 'Unexpected Cold',
	}

	export enum notificationType {
		MACHINE_THRESHOLD_BREACH_ALARM = 'MACHINE_THRESHOLD_BREACH_ALARM',
		BLOWTHROUGH = 'BLOWTHROUGH',
		FAILED_COLD = 'FAILED_COLD',
		INTERMITTENT_BLOWTHROUGH = 'INTERMITTENT_BLOWTHROUGH',
		LEAKING_BY = 'LEAKING_BY',
		OFFLINE = 'OFFLINE',
		SERVICE_NEEDED = 'SERVICE_NEEDED',
		SLEEP = 'SLEEP',
		STEAM_OFF = 'STEAM_OFF',
		STEAM_ON = 'STEAM_ON',
	}

	export enum resolutionDetailType {
		STEAM_TRAP = 'SteamTrap',
	}


}

