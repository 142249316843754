import {
  CustomerAssociateDetail as ApiCustomerAssociateDetail,
  Machine as ApiMachine,
  MachinePost as ApiMachinePost,
  SensorInstallUpdate as ApiSensorInstallUpdate
} from '@/client/api'
import {
  IMachineHistoryItem,
  IMachineThresholdCrossingSummary,
  IPaginationInfo,
  Machine,
  MachineAlarmStateCountSummary,
  MachineAlarmStateTotal,
  MachineAlarmStatusTrend,
  MachineWithSensorInstallation
} from '@/types'
import { EveractiveApi } from './everactiveApi'
import { EveractiveApiResponse } from './everactiveApiResponse'

export class MachinesApi {
  public static everactiveApi = new EveractiveApi().client.machine
  public static alarmStateApi = new EveractiveApi().client.machineAlarmState

  public static async addMachine(
    machine: MachineWithSensorInstallation
  ): Promise<EveractiveApiResponse<ApiMachine>> {
    const deepCloneMachine = structuredClone(machine)
    delete deepCloneMachine.integrationInfo
    const requestBody = deepCloneMachine as unknown as ApiMachinePost

    if (requestBody.sensor?.macAddress) {
      requestBody.sensor.installTimestamp = (machine.sensor as any).currentInstallStartTimestamp;
    }

    try {
      const response = await this.everactiveApi.postMachines({ requestBody })
      return response
    } catch (e) {
      console.error('MachinesApi.addMachine - error', e, machine)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'unable to add new machine' }
  }

  public static async fetchMachineById(machineId: string): Promise<ApiMachine | null> {
    try {
      const response = await this.everactiveApi.getMachinesMachineId({
        machineId
      })

      return response.data
    } catch (e) {
      console.log(`problem fetching machine ${machineId}`, e)
      return null
    }
  }

  public static async fetchMachinesTotalAlarmStates(
    params: {
      customerId?: string
    } = {}
  ): Promise<MachineAlarmStateTotal> {
    try {
      const response = await this.everactiveApi.getMachines({
        customerId: params.customerId
      })

      return {
        Acknowledged: Number(response.listSummary.totalsByAlarmState.Acknowledged),
        Alarm: Number(response.listSummary.totalsByAlarmState.Alarm),
        Good: Number(response.listSummary.totalsByAlarmState.Good)
      }
    } catch (e) {
      console.log('problem fetching machines!')
      return null
    }
  }

  public static async fetchMachineAlarmTrendSummary(params: {
    customerId?: string
    startTime?: number
    endTime?: number
  }): Promise<MachineAlarmStatusTrend[]> {
    try {
      const response = await this.everactiveApi.getMachinesAlarmstatustrend({
        customerId: params.customerId,
        startTime: params.startTime,
        endTime: params.endTime
      })
      const apiMachineAlarmStatusTrends = response.data || []
      return apiMachineAlarmStatusTrends.map((alarmTrend) => {
        const returnTrend = new MachineAlarmStatusTrend({
          customerId: params.customerId,
          day: alarmTrend.day
        })
        returnTrend.alarmStateSummary = alarmTrend.alarmStateSummary?.map(
          (s) => new MachineAlarmStateCountSummary(s)
        )
        return returnTrend
      })
    } catch (e) {
      return []
    }
  }

  public static async fetchMachineThresholdCrossingsSummary(
    params: {
      page?: number
      pageSize?: number
      customerId?: string
      startTime?: number
      endTime?: number
    } = {}
  ): Promise<{
    data?: IMachineThresholdCrossingSummary[]
    paginationInfo?: IPaginationInfo
  }> {
    const page = params.page
    const pageSize = params.pageSize

    try {
      const response = await this.everactiveApi.getMachinesThresholdcrossings({
        customerId: params.customerId,
        startTime: params.startTime,
        endTime: params.endTime,
        page,
        pageSize
      })
      return response
    } catch (e) {
      return {}
    }
  }

  public static async fetchMachines({
    customerId,
    sensorInstalled = null,
    equipmentName
  }): Promise<Machine[]> {

    try {
      const response = await this.everactiveApi.getMachines({
        customerId,
        sensorInstalled,
        equipmentName
      })
      return response.data?.map((m) => new Machine(m))
    } catch (e) {
      console.log('problem fetching machines!', e)
      return null
    }
  }

  public static async deleteMachine(id: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteMachinesMachineId({ machineId: id })
      return true
    } catch (e) {
      console.error('MachinesApi.deleteMachine - error', e)
    }
    return false
  }

  public static async updateMachine(machine: Machine): Promise<EveractiveApiResponse<ApiMachine>> {
    if (!machine.id || machine.id.length === 0) {
      return { success: false, message: 'Unable to update machine without an id.' }
    }

    try {
      const response = await this.everactiveApi.putMachinesMachineId({
        machineId: machine.id,
        requestBody: machine
      })
      return response
    } catch (e) {
      console.error('MachinesApi.updateMachine - error', e, machine)
      if (e.body) {
        return e.body
      }
    }
    return { success: false, message: 'Unable to update machine.' }
  }

  public static async updateMachineCustomer(machine: Machine): Promise<boolean> {
    if (!machine.id || !machine.customer?.id) {
      return false
    }

    const requestBody = <ApiCustomerAssociateDetail>{ customerId: machine.customer.id }

    try {
      await this.everactiveApi.putMachinesMachineIdCustomersCustomerId({
        machineId: machine.id,
        customerId: machine.customer.id,
        requestBody
      })
      return true
    } catch (e) {
      console.error('MachinesApi.updateMachineCustomer - error', e, machine)
    }
    return false
  }

  public static async updateAlarmStatus({ id, status }): Promise<boolean | ApiMachine> {
    if (!id || !status) {
      return false
    }

    try {
      const response = await this.alarmStateApi.putMachinesMachineIdAlarmStatesAlarmState({
        machineId: id,
        alarmStateOrEventId: status
      })
      return response.data
    } catch (e) {
      console.error('MachinesApi.updateAlarmStatus - error', e)
    }
    return false
  }

  public static async removeSensorFromMachine({
    machineId,
    sensorMacAddress,
    startTimestamp,
    endTimestamp
  }): Promise<EveractiveApiResponse> {
    if (!machineId || !sensorMacAddress) {
      return { success: false }
    }

    const requestBody = <ApiSensorInstallUpdate>{
      endTimestamp,
      sensorIdOrMac: sensorMacAddress,
      startTimestamp
    }

    try {
      const response = await this.everactiveApi.putMachinesMachineIdSensorsSensorIdOrMacInstall({
        machineId,
        sensorIdOrMac: sensorMacAddress,
        requestBody
      })
      return { success: true, ...response }
    } catch (e) {
      console.error('MachinesApi.removeSensorFromMachine - error', e, machineId, sensorMacAddress)
      if (e.body) {
        return e.body
      }
    }
    return { success: false }
  }

  public static async changeSensorOnMachine({
    machineId,
    sensorMacAddress,
    startTimestamp,
    installationNotes
  }): Promise<EveractiveApiResponse> {
    if (!machineId || !sensorMacAddress) {
      return { success: false }
    }

    const requestBody = <ApiSensorInstallUpdate>{
      installationNotes,
      sensorIdOrMac: sensorMacAddress,
      startTimestamp
    }

    try {
      const response = await this.everactiveApi.putMachinesMachineIdSensorsSensorIdOrMacInstall({
        machineId,
        sensorIdOrMac: sensorMacAddress,
        requestBody
      })
      return { success: true, ...response }
    } catch (e) {
      console.error('MachinesApi.changeSensorOnMachine - error', machineId, sensorMacAddress)
      if (e.body) {
        return e.body
      }
    }
    return { success: false }
  }

  public static async fetchTimeseriesData({
    machineId,
    startTime,
    endTime,
    downsampleRate
  }): Promise<any | null> {
    try {
      const response = await this.everactiveApi.getMachinesMachineIdTimeseries({
        machineId,
        startTime,
        endTime,
        downsampleRate
      })
      return { success: true, data: response.data }
    } catch (e) {
      return { success: false, error: e.message }
    }
  }

  public static async fetchHistory({
    machineId,
    page,
    pageSize
  }): Promise<{ data?: IMachineHistoryItem[]; paginationInfo?: IPaginationInfo }> {
    try {
      const response = await this.everactiveApi.getMachinesMachineIdHistory({
        machineId,
        page,
        pageSize
      })

      const data = response.data.map(
        (d) => <IMachineHistoryItem>Object.assign({}, d, { machineId })
      )

      return { data, paginationInfo: response.paginationInfo }
    } catch (e) {
      return {}
    }
  }
}
