import Store from "@/data-store";
import { Ref, onUnmounted, reactive, toRefs, getCurrentInstance } from "vue";

type RefTypes<T> = {
  [P in keyof T]: Ref<T[P]>;
};

function extractReactiveState<TState extends object>(
  stateKeys: (keyof TState & string)[],
  accessor: (key: string) => TState[keyof TState]
): RefTypes<TState> {
  const initialState = stateKeys.reduce(
    (accum, key) => ({
      ...accum,
      [key]: accessor(key)
    }),
    {}
  ) as TState;
  const reactiveState = toRefs(
    reactive<TState>(initialState)
  ) as RefTypes<TState>;

  const unsubscribe = Store.subscribe(() =>
    setTimeout(() => {
      for (const key of stateKeys) {
        reactiveState[key as string].value = accessor(key);
      }
    })
  );

  // we only need to clean up if we're running in the context of a component
  // we don't want to cleanup if we're being called in the global scope
  if (getCurrentInstance()) {
    onUnmounted(() => {
      if (unsubscribe) {
        unsubscribe();
      }
    });
  }

  return reactiveState;
}

export function useState<TState extends object = any>(
  namespace: string,
  stateKeys: (keyof TState & string)[]
): RefTypes<TState> {
  return extractReactiveState<TState>(
    stateKeys,
    (key) => Store.state[namespace][key]
  );
}

export function useGetters<TGetters extends object = any>(
  namespace: string,
  getterKeys: (keyof TGetters & string)[]
): RefTypes<TGetters> {
  return extractReactiveState<TGetters>(
    getterKeys,
    (key) => Store.getters[namespace + "/" + key]
  );
}

export function useActions<
  TActions extends { [key: string]: (payload?: unknown) => any }
>(namespace: string, actionKeys: (keyof TActions & string)[]): TActions {
  return actionKeys.reduce(
    (actions, key) => ({
      ...actions,
      [key]: (payload: unknown) =>
        Store.dispatch(namespace + "/" + key, payload)
    }),
    {}
  ) as TActions;
}

export function useMutations<
  TMutation extends { [key: string]: any },
  TMutator = Record<
    keyof TMutation,
    (payload: TMutation[keyof TMutation]) => void
  >
>(namespace: string, mutationKeys: (keyof TMutation & string)[]): TMutator {
  return mutationKeys.reduce(
    (mutations, key) => ({
      ...mutations,
      [key]: (payload: unknown) => Store.commit(namespace + "/" + key, payload)
    }),
    {}
  ) as TMutator;
}
