import { isPromise } from "@/lib/isPromise";
import { Ref, ref, watch } from "vue";
import { asyncComputed, AsyncComputedOnCancel } from "@vueuse/core";

export function asyncComputedWithRefresh<ReturnType = unknown>(
  evaluationCallback: (
    onCancel: AsyncComputedOnCancel
  ) => ReturnType | Promise<ReturnType>,
  initialState: ReturnType = null
): any /* [Ref<ReturnType>, () => void] */ {
  const data = ref(initialState) as Ref<ReturnType>;

  watch(asyncComputed<ReturnType>(evaluationCallback), (computedData) => {
    data.value = computedData;
  });

  const refreshData = () => {
    const callbackResult = evaluationCallback(null);

    if (isPromise(callbackResult)) {
      callbackResult.then((resultData) => (data.value = resultData));
    } else {
      data.value = callbackResult;
    }
  };

  return [data, refreshData];
}
