import { ref } from "vue";
import{ Router, RouteLocationNormalized } from "vue-router";

const _router = ref<Router | undefined>();
const currentRoute = ref<RouteLocationNormalized | undefined>();
const previousRoute = ref<RouteLocationNormalized | undefined>();
let hooksInitialized = false;
let initialRoute = true;

export function initializeRouterHooks(router: Router) {
  if (!hooksInitialized) {
    hooksInitialized = true;

    _router.value = router;

    router.afterEach((_, from) => {
      if (!initialRoute) {
        previousRoute.value = from;
      } else {
        initialRoute = false;
      }
    });

    router.afterEach((to) => {
      currentRoute.value = to;
    });
  }
}

export function useRouter() {
  return _router;
}


export function useCurrentRoute() {
  return currentRoute;
}

export function usePreviousRoute() {
  return previousRoute;
}
