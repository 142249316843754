import { NotificationService } from './api'
import { Notification } from './api'
import { EveractiveApi } from './everactiveApi'
import {
  NotificationCountSummary,
} from '@/client/api'

type NotificationQueryParams = Parameters<NotificationService['getNotifications']>[0]

export class NotificationsApi {
  public static everactiveApi = new EveractiveApi().client.notification

  public static async getActiveNotifications(queryParams: NotificationQueryParams): Promise<any> {
    try {
      const response = await this.everactiveApi.getNotifications(queryParams)
      return response
    } catch (e) {
      console.log('problem fetching notifications', e.status, e.response.data)
      return { error: e.response.data, paginationInfo: {} }
    }
  }

  public static async getNotification(notificationId: string): Promise<any | null> {
    if (!notificationId) {
      return null
    }
    try {
      const response = await this.everactiveApi.getNotificationsNotificationId({
        notificationId: notificationId
      })
      return response.data
    } catch (e) {
      console.log(
        `problem fetching notification ${notificationId}`,
        e.status,
        e.response.data
      )
      return null
    }
  }

  public static async getNotificationCountsSummary(
    queryParams: Pick<NotificationQueryParams, 'customerId'>
  ): Promise<NotificationCountSummary | null> {
    try {
      const response = await this.everactiveApi.getNotificationsCountsummary({
        customerId: queryParams.customerId as string
      })
      return response.data
    }
    catch (e) {
      console.log('problem getting notification counts summary', e.status, e.response.data)
      return null
    }
  }

  public static async fetchSteamTrapNotifications({
    id,
    page,
    startTime,
    endTime,
    notificationType,
    includeResolved
  }): Promise<any | null> {
    if (!id) {
      return null
    }

    try {
      const response = await this.everactiveApi.getNotifications(
        {
          entityId: id,
          pageSize: 20,
          page: page,
          resolutionStatus: includeResolved ? null : 'unresolved',
          startTimestamp: startTime,
          endTimestamp: endTime,
          notificationType: notificationType
        }
      )
      return response
    } catch (e) {
      console.error(
        'fetchSteamTrapNotifications: There was a problem getting steam trap notifications',
        id
      )
      return {
        data: [],
        paginationInfo: {}
      }
    }
  }

  public static async deleteNotification(notificationId: string): Promise<boolean> {
    try {
      await this.everactiveApi.deleteNotificationsNotificationId({
        notificationId: notificationId
      })
      return true
    } catch (e) {
      console.error(`deleteNotification ${notificationId}`, e)
      return false
    }
  }

  public static async updateNotification(notification: Notification): Promise<boolean> {
    try {
      await this.everactiveApi.updateNotificationsNotificationId({
        notificationId: notification.id,
        requestBody: notification
      })
      return true
    } catch (e) {
      console.error(`updateNotification ${notification.id}`, e)
      return false
    }
  }
}
