/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MqttBroker } from '../models/MqttBroker';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MqttBrokerService {

	constructor(public readonly httpRequest: BaseHttpRequest) {}

	/**
	 * @deprecated
	 * get broker list
	 * Retrieve a list of MQTT brokers. Customer admin level users will only receive the broker(s)
	 * assigned to the same customer as the user.
	 * This endpoint will be deprecated in the near future. Please migrate to /ds/v1/vhosts.
	 * @returns any A list of MQTT brokers.
	 * @throws ApiError
	 */
	public getBrokers({
		brokerType,
		customerId,
		includedDeleted,
	}: {
		/**
		 * Filter the MQTT brokers returned by the broker type.
		 */
		brokerType?: 'data' | 'config',
		/**
		 * Filter the list of MQTT brokers returned by customerId(s). Multiple values can be passed as a
		 * comma separated value (e.g. `&customerId=54c32003-0d20-4c41-a8a5-3d24e4997975,5cd1e189-20c5-40ba-aa9a-781722a09d01`).
		 * An exact match is used for each id passed in the filter.
		 * If the client requesting MQTT brokers does not have access to the customerId passed an error
		 * will be returned.
		 */
		customerId?: string,
		/**
		 * Flag indicating that deleted MQTT brokers should be included in the returned list.
		 */
		includedDeleted?: boolean,
	}): CancelablePromise<{
		data?: Array<MqttBroker>;
	}> {
		return this.httpRequest.request({
			method: 'GET',
			url: '/brokers',
			query: {
				'brokerType': brokerType,
				'customerId': customerId,
				'includedDeleted': includedDeleted,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * @deprecated
	 * create a new broker
	 * Create a new MQTT broker.
	 * @returns any An MQTT broker.
	 * @throws ApiError
	 */
	public createBroker({
		requestBody,
	}: {
		/**
		 * Properties for a new or existing Mqtt broker.
		 */
		requestBody?: MqttBroker,
	}): CancelablePromise<{
		data?: MqttBroker;
	}> {
		return this.httpRequest.request({
			method: 'POST',
			url: '/brokers',
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
			},
		});
	}

	/**
	 * @deprecated
	 * delete a broker
	 * Soft-delete an MQTT broker.
	 * @returns void
	 * @throws ApiError
	 */
	public deleteBrokerBrokerId({
		brokerId,
	}: {
		brokerId: string,
	}): CancelablePromise<void> {
		return this.httpRequest.request({
			method: 'DELETE',
			url: '/brokers/{brokerId}',
			path: {
				'brokerId': brokerId,
			},
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

	/**
	 * @deprecated
	 * update an existing broker
	 * Update an existing MQTT broker.
	 * @returns any An MQTT broker.
	 * @throws ApiError
	 */
	public updateBrokerBrokerId({
		brokerId,
		requestBody,
	}: {
		brokerId: string,
		/**
		 * Properties for a new or existing Mqtt broker.
		 */
		requestBody?: MqttBroker,
	}): CancelablePromise<{
		data?: MqttBroker;
	}> {
		return this.httpRequest.request({
			method: 'PUT',
			url: '/brokers/{brokerId}',
			path: {
				'brokerId': brokerId,
			},
			body: requestBody,
			mediaType: 'application/json',
			errors: {
				400: `The request is invalid.`,
				401: `The request did not include the correct authorization or the included authorization has expired.`,
				403: `The requested resource is not accessible to the currently authenticated client.`,
				404: `The requested resource was not found.`,
			},
		});
	}

}
